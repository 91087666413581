import {
  EDIT_PROFILE_FAIL, EDIT_PROFILE_SUCCESS, EDIT_PROFILE,
  UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL,
} from "./actionTypes"
import { CLEAR_ACTION_RESULT } from "../../baseActionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: user,
  }
}

export const profileSuccess = user => {
  return {
    type: EDIT_PROFILE_SUCCESS,
    payload: user,
  }
}

export const profileError = error => {
  return {
    type: EDIT_PROFILE_FAIL,
    payload: error,
  }
}

export const updatePassword = password => {
  return {
    type: UPDATE_PASSWORD,
    payload: password,
  }
}

export const updatePasswordSuccess = password => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: password,
  }
}

export const updatePasswordError = error => {
  return {
    type: UPDATE_PASSWORD_FAIL,
    payload: error,
  }
}

export const clearActionResult = () => ({
  type: CLEAR_ACTION_RESULT,
})
