export const appVersion = () => {
  return "v" + process.env.REACT_APP_VERSION + "." + process.env.REACT_APP_GIT_SHA;
}

export const obfuscatedValue = "Atj9H_dfE98KOOMkjk_JaIdBn1ZX3kjk091F3"

export const deployedEnvironment = () => {
  return window.Configs.DEPLOYED_ENVIRONMENT;
}

export const deployedEnvironmentFooterText = () => {
  var mode = deployedEnvironment();
  if (mode === undefined || mode === '') {
    return '';
  }
  return " | " + mode;
}

export const toDatetimeString  = (date) => {
  var newDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  }
  return date.toLocaleString(undefined, newDateOptions );
}
