import {
  CLEAR_STATE, CLEAR_ACTION_RESULT, GET_EXCEPTIONS, GET_EXCEPTIONS_FAIL, GET_EXCEPTIONS_SUCCESS, RESULT_ERROR, RESULT_OK
} from "./actionTypes"

const INIT_STATE = {
  contentPage: {},
  actionResult: {},
}

const exceptionsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_EXCEPTIONS_SUCCESS:
      {
        return {
          ...state,
          contentPage: action.payload.contentPage,
          actionResult: { action: GET_EXCEPTIONS, result: RESULT_OK, error: '' },
        }
      }

    case GET_EXCEPTIONS_FAIL: {
      return {
        ...state,
        exceptionPage: {},
        actionResult: { action: GET_EXCEPTIONS, result: RESULT_ERROR, error: action.payload },
      }
    }

    case CLEAR_STATE:
      {
        return {
          ...INIT_STATE
        }
      }

    case CLEAR_ACTION_RESULT:
      {
        return {
          ...state,
          actionResult: {},
        }
      }

    default:
      return state
  }
}

export default exceptionsReducer
