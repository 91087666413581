import { AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React, { Component, Fragment } from 'react'
import { Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { renderRejectedTrnDetails } from "../trn-helper"

class RejectedTrnDetailsPopup extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { data, modal, onToggle } = this.props
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    centered={true}
                    backdrop="static"
                    size="lg"
                >
                    <AvForm>
                        <ModalHeader toggle={onToggle} tag="h4">
                            Rejected TRN details
                        </ModalHeader>
                        <ModalBody>
                            <Card body>
                                {renderRejectedTrnDetails(data)}
                            </Card>
                        </ModalBody>
                        <ModalFooter>
                            <Row>
                                <Col>
                                    <div className="text-right">
                                        <button
                                            type="button"
                                            className="btn btn-light mr-2"
                                            onClick={onToggle}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </Fragment>
        )
    }
}

RejectedTrnDetailsPopup.propTypes = {
    modal: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
};

export default RejectedTrnDetailsPopup;