//Include Both Helper File with needed methods
import { apiAddUser, apiGetUsers, apiChangePasswordByAdmin, apiDeleteUser, apiEditUser, apiResetPwd } from "helpers/backend_helper"
import { isEmpty } from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addUserFail, addUserSuccess, changePasswordByAdminFail, changePasswordByAdminSuccess, deleteUserFail, 
  deleteUserSuccess, editUserFail, editUserSuccess, getUsersFail, getUsersSuccess, resetPwdFail,
  resetPwdSuccess
} from "./actions"
import { ADD_USER, CHANGE_PASSWORD_BY_ADMIN, DELETE_USER, EDIT_USER, GET_USERS, RESET_PWD } from "./actionTypes"


function* onGetUsers({ payload }) {
  try {
    const response = yield call(apiGetUsers, {
      params: {
        page: payload.page, size: payload.size,
        sortField: payload.sortField, sortOrder: payload.sortOrder,
        search: payload.search,
      }
    })
    yield put(getUsersSuccess({
      content: response.content,
      page: response.number,
      size: response.size,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
      sortField: payload.sortField,
      sortOrder: payload.sortOrder,
    }))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getUsersFail(error.response.data.message))
    } else {
      yield put(getUsersFail(error.message))
    }
  }
}

function* onAddUser({ payload }) {
  try {
    yield call(apiAddUser, payload)
    yield put(addUserSuccess(payload))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(addUserFail(error.response.data))
    } else {
      yield put(addUserFail(error.message))
    }
  }
}

function* onEditUser({ payload }) {
  try {
    const response = yield call(apiEditUser, payload)
    yield put(editUserSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(editUserFail(error.response.data))
    } else {
      yield put(editUserFail(error.message))
    }
  }
}

function* onDeleteUser({ payload }) {
  try {
    yield call(apiDeleteUser, payload)
    yield put(deleteUserSuccess(payload))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(deleteUserFail(error.response.data))
    } else {
      yield put(deleteUserFail(error.message))
    }
  }
}

function* onResetPwd({ payload }) {
  try {
    yield call(apiResetPwd, payload)
    yield put(resetPwdSuccess(payload))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(resetPwdFail(error.response.data))
    } else {
      yield put(resetPwdFail(error.message))
    }
  }
}

function* onChangePasswordByAdmin({ payload }) {
  try {
    yield call(apiChangePasswordByAdmin, payload.id, { newPassword: payload.newPassword })
    yield put(changePasswordByAdminSuccess(payload))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(changePasswordByAdminFail(error.response.data))
    } else {
      yield put(changePasswordByAdminFail(error.message))
    }
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, onGetUsers)
  yield takeEvery(ADD_USER, onAddUser)
  yield takeEvery(EDIT_USER, onEditUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(RESET_PWD, onResetPwd)
  yield takeEvery(CHANGE_PASSWORD_BY_ADMIN, onChangePasswordByAdmin)
}

export default usersSaga
