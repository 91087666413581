import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap"

import { isEmpty, map, size, orderBy } from "lodash"

import SweetAlert from "react-bootstrap-sweetalert"

import { getConnector, disposeConnector } from "../../helpers/edge_helper"
import { getSamDiscoveryRequest, getSamDiscoveryResponse, clearError } from "store/samDiscovery/actions"
import { verifyExchangeSecureElementSession, clearError as clearSecureElementSessionError, reset as sessionStoreReset } from "store/secureElementSession/actions"


class DiscoverySAMView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      samDiscoveryRequest: undefined,
      samDiscoveryResponse: undefined,
      rawSams: [],
      deviceFiltered: [],
      deviceSelected: [],
      discoverying: false,

      verifying: false,
      completedVerification: false,
      showConfirmVerification: false,
      current_discovery_native_device: undefined,
      current_discovery_verify_session: undefined,
      current_log: [],

      showError: undefined,
      discovery_connector: undefined,
    }
    this.handleDiscovery.bind(this)
    this.handleDeviceToggle.bind(this)
  }

  componentDidMount() {
    const { onSamDiscoveryRequest } = this.props
    onSamDiscoveryRequest()
  }

  componentDidUpdate(prevProps, prevState) {
    const { onSamDiscoveryResponse, selectionMode, current_discovery_verify_session, showVerificationButton } = this.props
    const { rawSams } = this.state

    if (this.props.toggleReset !== prevProps.toggleReset) {
      this.setState({
        samDiscoveryResponse: undefined,
        rawSams: [],
        deviceFiltered: [],
        deviceSelected: [],
        discoverying: false
      })
      return;
    }
    if (rawSams !== undefined && rawSams.length > 0 && rawSams !== prevState.rawSams) {
      let response = {};
      response.encryptedDevices = rawSams;
      onSamDiscoveryResponse(response);
      return;
    }

    if (this.props.samDiscoveryRequest !== undefined && this.props.samDiscoveryRequest !== prevProps.samDiscoveryRequest) {
      this.setState({
        samDiscoveryRequest: this.props.samDiscoveryRequest
      })
    }

    if (this.props.samDiscoveryResponse !== undefined && this.props.samDiscoveryResponse !== prevProps.samDiscoveryResponse) {
      let devices = [...this.props.samDiscoveryResponse.devices];
      let deviceSelected = [];
      for (let d of devices) {
        d.allowSelect = selectionMode === true
              && (d.error === undefined || d.error === null || d.error === "")
              && d.serial !== undefined && d.serial !== null && d.serial !== "";
        d.allowVerify = d.provisioning != undefined
                        && d.provisioning.completed !== undefined
                        && d.provisioning.completed === true;
        if (d.allowSelect) {
          d.allowSelect = d.provisioning == null || d.provisioning.completed !== true;
          if (d.allowSelect) {
            // defauult checked if mode selection
            d.checked = true;
            if (this.props.showVerificationColumn) {
              d.requiredVerification = true;
            }
            deviceSelected.push(d);
          }
        }
      }
      this.setState({
        samDiscoveryResponse: this.props.samDiscoveryResponse,
        rawSams: [],
        deviceFiltered: devices,
        deviceSelected: deviceSelected,
        discoverying: false
      })
      if (this.props.onSelectedChanged !== undefined) {
        this.props.onSelectedChanged(deviceSelected);
      }
    }

    if (showVerificationButton && prevProps.current_discovery_verify_session !== current_discovery_verify_session && typeof(current_discovery_verify_session) != "undefined") {
      this.setState({ current_discovery_verify_session: current_discovery_verify_session });
      if (typeof(current_discovery_verify_session) != "undefined"
          && (typeof(prevProps.current_discovery_verify_session) == "undefined"
            || typeof(prevProps.current_discovery_verify_session.requestKey) == "undefined"
            || prevProps.current_discovery_verify_session.requestKey != current_discovery_verify_session.requestKey)) {
        console.log("[" + current_discovery_verify_session.currentKeyId + "]: Session changed -> " + current_discovery_verify_session.requestKey);
        this.requestVerifyExchangeDeviceSessionForDiscovery(current_discovery_verify_session);
        return;
      }
    }

    if (this.props.error !== prevProps.error) {
      this.setState({
        showError: {
          title: "API ERROR",
          message: this.props.error
        }
      })
    }
  }

  handleDiscovery () {
    const { samDiscoveryRequest } = this.state;
    this.setState({
      samDiscoveryResponse: undefined,
      rawSams: [],
      deviceFiltered: [],
      deviceSelected: [],
      discoverying: true
    });
    let discovery_connector = getConnector();
    var that = this;
    // handle success callback
    let successHandler = function (devices) {
      if (devices === undefined || size(devices) === 0) {
        that.setState({
          showError: {
            title: "Discovery",
            message: "No Devices Found"
          },
          discoverying: false
        });
      } else {
        that.setState({
          rawSams: devices
        });
      }
    };
    let failureHandler = function (error) {
      that.setState({
        showError: {
          title: "Discovery Error",
          message: error
        },
        discoverying: false
      });
    };
    if (samDiscoveryRequest.useMDNS) {
      // Using mDNS to discovery Secure Element Devices
      discovery_connector.discoveryMDNSSams(
        samDiscoveryRequest.address,
        samDiscoveryRequest.port,
        samDiscoveryRequest.message,
        samDiscoveryRequest.waitingTime
      ).then(successHandler)
       .catch(failureHandler)
       .finally(() => {
         disposeConnector();
      });
    } else if (samDiscoveryRequest.useUDP) {
      // Using UDP Ping request to discovery Secure Element Devices
      // address, port, message, waitingTime
      discovery_connector.discoveryUDPSams(
        samDiscoveryRequest.address,
        samDiscoveryRequest.port,
        samDiscoveryRequest.message,
        samDiscoveryRequest.waitingTime
      ).then(successHandler)
       .catch(failureHandler)
       .finally(() => {
         disposeConnector();
      });
    } else {
      failureHandler("Cannot detect the discovery mode from service. Please contact administrator");
      disposeConnector();
    }
  }

  handleDeviceToggle (checked, ip, deviceIndex) {
    let devices = null;
    if (checked) {
      if (this.state.deviceSelected.findIndex(ds => ds.ip === ip) === -1) {
        let d = this.state.deviceFiltered[deviceIndex];
        if (d !== undefined) {
          let deviceSelected = [...this.state.deviceSelected];
          deviceSelected.push(d);

          d.checked = true;
          let deviceFiltered = [...this.state.deviceFiltered];
          deviceFiltered[deviceIndex] = d;

          this.setState({
            deviceFiltered,
            deviceSelected: deviceSelected,
            deviceProvisioning: []
          });
          devices = [...deviceSelected];
        }
      }
    } else {
      let d = this.state.deviceFiltered[deviceIndex];
      if (d !== undefined) {
        let filtered = this.state.deviceSelected.filter(function(value, index, arr){ 
            return value.ip !== ip;
        });
        d.checked = false;
        let deviceFiltered = [...this.state.deviceFiltered];
        deviceFiltered[deviceIndex] = d;
        this.setState({
          deviceFiltered,
          deviceSelected: filtered,
          deviceProvisioning: []
        });
        devices = [...filtered];
      }
    }
    if (this.props.onSelectedChanged !== undefined) {
      if (typeof(devices) == "undefined" || devices === null || devices.length === 0) {
        this.props.onSelectedChanged(undefined);
      } else {
        this.props.onSelectedChanged(devices);
      }
    }
  }

  handleVerifyDeviceToggle (checked, ip, deviceIndex) {
    if (this.state.deviceSelected.findIndex(ds => ds.ip === ip) !== -1) {
      let deviceSelected = [...this.state.deviceSelected];
      let d = deviceSelected[deviceIndex];
      if (d !== undefined) {
        d.requiredVerification = checked;

        this.setState({
          deviceSelected: deviceSelected
        });
        let devices = [...deviceSelected];
        if (this.props.onSelectedChanged !== undefined) {
          if (typeof(devices) == "undefined" || devices == null || devices.length === 0) {
            this.props.onSelectedChanged(undefined);
          } else {
            this.props.onSelectedChanged(devices);
          }
        }
      }
    }
  }

  requestVerifyExchangeDeviceSessionForDiscovery = async (current_discovery_verify_session) => {
    const { current_discovery_native_device } = this.state;
    console.log("Start verify...");
    try {
      console.log("Request Key: " + current_discovery_verify_session.requestKey);
      if (this.state.discovery_connector === undefined) {
        let conn = getConnector();
        this.setState({
          discovery_connector: conn
        });
        let connected = await conn.connectTCP(
          current_discovery_native_device.ip,
          8767
        );
        if (!connected) {
          this.appendDeviceLog(["Cannot connected"]);
          disposeConnector();
          return;
        } else {
          this.appendDeviceLog(["Connected"]);
        }
      }
      const { discovery_connector } = this.state;
      let lastResponseMsg = undefined;
      // logging prev response
      if (current_discovery_verify_session.response !== "" && current_discovery_verify_session.response !== null && current_discovery_verify_session.response !== undefined) {
        lastResponseMsg = current_discovery_verify_session.response;
      }

      let completed = current_discovery_verify_session.secureElement !== undefined && current_discovery_verify_session.requestKey === "";
      if (completed) {
        console.log("Completed Verification");
        await discovery_connector.disconnectTCP();
        disposeConnector();
        let msg = ["-------------------", "Disconnected"];
        if (lastResponseMsg !== undefined) {
          msg.unshift(lastResponseMsg);
        }
        this.appendDeviceLog(msg);

        this.setState({
          completedVerification: true,
          discovery_connector: undefined
        });
        return;
      }

      if (current_discovery_verify_session.request !== "" && current_discovery_verify_session.request !== null && current_discovery_verify_session.request !== undefined) {
        let msg = ["-------------------", "Sending verification request for [" + current_discovery_verify_session.requestKey + "]"];
        if (lastResponseMsg !== undefined) {
          msg.unshift(lastResponseMsg);
        }
        this.appendDeviceLog(msg);

        current_discovery_verify_session.response = await discovery_connector.exchangeTCP(current_discovery_verify_session.request);

        this.appendDeviceLog(["Received verification response for [" + current_discovery_verify_session.requestKey + "]"]);
      }
      this.props.onVerifyExchangeSecureElementSession(current_discovery_verify_session);
    } catch (error) {
      // got any failure -> disconnect current connection -> show failure error on record -> produce next device
      disposeConnector();
      this.appendDeviceLog([error]);
    }
  };

  appendDeviceLog = (msgs) => {
    let current_log = [...this.state.current_log];
    current_log.push(...msgs);
    this.setState({
      current_log: current_log
    });
  };

  renderShowError = () => {
    let { showError } = this.state
    if (showError !== undefined && !isEmpty(showError.message) && !isEmpty(showError.title)) {
      return <SweetAlert
        error={true}
        title={showError.title}
        onConfirm={() => {
          this.setState({
            showError: undefined
          })
        }}
      >
        {showError.message}
      </SweetAlert>
    }
    return null;
  }

  render() {
    const { deviceFiltered, samDiscoveryRequest, discoverying, current_log } = this.state;
    const { selectionMode, showVerificationColumn, showVerificationButton } = this.props;
    return (
      <Fragment>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                {/* <h4 className="card-title mb-4">Discovery Secure Element Devices</h4> */}

                {this.renderShowError()}

                <div>
                  { samDiscoveryRequest !== undefined && (
                    <div className="button-items">
                      <Button
                        color="success"
                        className="btn btn-success btn-lg waves-effect"
                        disabled={discoverying}
                        onClick={() => {
                          this.handleDiscovery()
                        }}
                      >
                        {discoverying ? "Scanning..." : "Discovery"}
                      </Button>
                    </div>
                  )}
                  <br/>

                  { this.state.showConfirmVerification ? (
                    <SweetAlert
                      title="Are you sure you want to start verification?"
                      warning
                      showCancel
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        this.props.onVerifyExchangeSecureElementSession({
                          secureElementId: this.state.current_discovery_native_device.provisioning.id,
                          requestKey: ""
                        });
                        this.setState({
                          showConfirmVerification: false,
                          verifying: true
                        });
                      }}
                      onCancel={() =>
                        this.setState({
                          showConfirmVerification: false,
                          current_discovery_native_device: undefined
                        })
                      }
                    >
                    </SweetAlert>
                  ) : null}

                  {this.state.verifying ? (
                    <SweetAlert
                      title={this.state.current_discovery_native_device.serial}
                      showConfirm={this.state.completedVerification}
                      customClass="swal2-progressVerifying"
                      onConfirm={() => {
                        this.setState({
                          current_discovery_native_device: undefined,
                          current_discovery_verify_session: undefined,
                          current_log: [],
                          completedVerification: false,
                          verifying: false
                        });
                      }}
                    >
                      <div>
                        {!this.state.completedVerification ? (
                          <div>
                              <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                              <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                              <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                              <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                              <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                          </div>
                        ) : null}
                        <div>
                          {map(current_log, (s, si) => (
                            <p className="mb-0" key={si}>{s}</p>
                          ))}
                        </div>
                      </div>
                    </SweetAlert>
                  ) : null}

                  <div className="table-responsive">
                    <Table className="table table-nowrap table-centered">
                      <thead className="thead-light">
                        <tr>
                          { selectionMode === true && (
                              <th scope="col" style={{ width: "100px" }}>Select</th>
                          )}
                          { showVerificationColumn === true && (
                              <th scope="col" style={{ width: "100px" }}>Verify?</th>
                          )}
                          <th scope="col">Device Address</th>
                          <th scope="col">Serial</th>
                          <th scope="col">Connection Mode</th>
                          <th scope="col">Status</th>
                          { showVerificationButton === true && (
                              <th scope="col" style={{ width: "100px" }}>Action</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {map(deviceFiltered, (item, index) => (
                          <tr key={index}>
                            { selectionMode === true && (
                              <td>
                                { item.allowSelect === true && (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={item.ip}
                                      checked={item.checked}
                                      onChange={e => this.handleDeviceToggle(e.target.checked, item.ip, index)}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={item.ip}
                                    />
                                  </div>
                                )}
                              </td>
                            )}
                            { showVerificationColumn === true && (
                              <td>
                                { item.allowSelect === true && item.checked && (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={"VerifyFor" + item.ip}
                                      checked={item.requiredVerification}
                                      onChange={e => this.handleVerifyDeviceToggle(e.target.checked, item.ip, index)}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"VerifyFor" + item.ip}
                                    />
                                  </div>
                                )}
                              </td>
                            )}
                            <td>{item.ip} ({item.discoveryTime} ms)</td>
                            {item.error !== undefined && item.error !== null && item.error !== "" && (
                              <td>
                                <Alert color="danger" >
                                  {item.error}
                                </Alert>
                              </td>
                            )}
                            {item.serial !== undefined && item.serial !== null && item.serial !== "" && (
                              <td>
                                <Alert color="success" >
                                  {item.serial}
                                </Alert>
                              </td>
                            )}
                            <td>{item.connectionMode === 4 ? "Secure" : "Plain"}</td>
                            {item.provisioning === null && (
                              <>
                                <td>NEW</td>
                              </>
                            )}
                            {item.provisioning !== null && item.provisioning !== undefined && (
                              <>
                                <td>
                                  <span className={"ml-2 badge " + (item.provisioning.completed === true ? "badge-soft-success" : "badge-soft-primary")}>
                                    {item.provisioning.completed === true ? "COMPLETE" : "IN-COMPLETE"}
                                  </span>
                                </td>
                              </>
                            )}
                            { showVerificationButton === true && (
                              <td>
                                { item.allowVerify === true && (
                                  <div className="button-items">
                                    <Button color="primary" className="btn btn-primary btn-sm waves-effect"
                                        onClick={() => {
                                          this.setState({
                                            showConfirmVerification: true,
                                            current_discovery_native_device: item
                                          });
                                        }}>
                                      Verify
                                    </Button>
                                  </div>
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

DiscoverySAMView.propTypes = {
  // private
  error: PropTypes.any,
  samDiscoveryRequest: PropTypes.object,
  samDiscoveryResponse: PropTypes.object,
  onSamDiscoveryRequest: PropTypes.func,
  onSamDiscoveryResponse: PropTypes.func,
  onClearError: PropTypes.func,
  onVerifyExchangeSecureElementSession: PropTypes.func,
  onClearSecureElementSessionError: PropTypes.func,
  onSessionStoreReset: PropTypes.func,
  // public
  selectionMode: PropTypes.any,
  showVerificationColumn: PropTypes.any,
  showVerificationButton: PropTypes.any,
  onSelectedChanged: PropTypes.func,
  toggleReset: PropTypes.any
}

const mapStateToProps = ({ samDiscoveryReducer, secureElementSessionReducer }) => ({
  samDiscoveryRequest: samDiscoveryReducer.samDiscoveryRequest,
  samDiscoveryResponse: samDiscoveryReducer.samDiscoveryResponse,
  error: samDiscoveryReducer.error,
  current_discovery_verify_session: secureElementSessionReducer.verifySecureElementSession,
  errorOfSession: secureElementSessionReducer.error,
})

const mapDispatchToProps = dispatch => ({
  onSamDiscoveryRequest: () => dispatch(getSamDiscoveryRequest()),
  onSamDiscoveryResponse: data => dispatch(getSamDiscoveryResponse(data)),
  onClearError: () => dispatch(clearError()),
  onVerifyExchangeSecureElementSession: session => dispatch(verifyExchangeSecureElementSession(session)),
  onClearSecureElementSessionError: () => dispatch(clearSecureElementSessionError()),
  onSessionStoreReset: () => dispatch(sessionStoreReset())
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverySAMView)