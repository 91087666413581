import { Component } from 'react';

class PageNotImplemented extends Component {
    render() {
        return (
            <div className="page-content text-center">
                <h2>Not Implemented!</h2>
            </div>
        );
    }
}

export default PageNotImplemented;