import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button, Card, CardBody, Col, Container, Modal,
  ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { getUserProfile } from "store/auth/login/actions";
import { addUser, changePasswordByAdmin, deleteUser, editUser, getUsers, resetPwd, clearState } from "store/users/actions";
import { ActionResultBox } from "./ActionResultBox";
import UserColumns from "./UserColumns";
import { avatarControl, emailControl, firstNameControl, lastNameControl, passwordConfirmControl, 
  strongPasswordControl, previewAvatarControl, roleControl, usernameControl } from "./UserFieldControls";


class UsersList extends Component {

  constructor(props) {
    super(props)
    const fieldControls = this.getFieldControls()
    this.state = {
      content: [],
      page: 1,
      size: 10,
      sortField: '',
      sortOrder: '',
      search: '',
      totalPages: 0,
      totalElements: -1,

      rowDeleting: {},
      rowResetPassword: {},
      rowEditing: {},
      userLogin: {},
      actionResult: {},
      ...fieldControls,
    }
  }

  getFieldControls = () => {
    return {
      username: '',
      password: '',
      email: '',
      firstName: '',
      lastName: '',
      role: '',
      activated: false,
      avatarBase64: '',
      avatarType: '',
    }
  }

  componentDidMount() {
    const { onSendAction_GetUsers, userProfile } = this.props
    const { page, size, sortField, sortOrder, search } = this.state
    onSendAction_GetUsers(page, size, sortField, sortOrder, search)
    this.setState({ userLogin: { username: userProfile.login, role: userProfile.role } })
  }

  componentWillUnmount() {
    this.props.onSendAction_ClearState()
  }
  
  componentDidUpdate(prevProps, prevState) {
    const { contentPage, actionResult } = this.props

    if (!isEqual(prevProps.actionResult, actionResult)) {
      this.setState({
        actionResult
      })
    }

    if (!isEmpty(contentPage)) {
      if (!isEqual(prevProps.contentPage, contentPage)) {
        this.setState({
          content: contentPage.content,
          page: contentPage.page + 1, //SpringData: Page number start with 0
          size: contentPage.size,
          sortField: contentPage.sortField,
          sortOrder: contentPage.sortOrder,
          totalPages: contentPage.totalPages,
          totalElements: contentPage.totalElements,
        })
      }
    }
  }

  onTableChange = (type, { searchText }) => {
    if (type === 'search') {
      this.setState({
        search: searchText
      })
      const { onSendAction_GetUsers } = this.props
      const { page, size, sortField, sortOrder } = this.state
      onSendAction_GetUsers(page, size, sortField, sortOrder, searchText);
    }
  }

  onChangePopupControl = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onClickCheckBoxActive = () => {
    this.setState({
      activated: !this.state.activated,
    })
  }

  onSubmitNewUser = (event) => {
    event.preventDefault();
    const { onSendAction_AddUser } = this.props
    const { username, email, password, firstName, lastName, role, activated, avatarBase64, avatarType } = this.state
    onSendAction_AddUser({
      login: username,
      email,
      password,
      firstName,
      lastName,
      role,
      activated,
      avatarBase64,
      avatarType,
    });
    this.setState({ showNewUserModal: false })
  }

  onSubmitEditUser = (event) => {
    event.preventDefault();
    const { onSendAction_EditUser } = this.props
    const { rowEditing, username, email, firstName, lastName, role, activated, avatarBase64, avatarType } = this.state
    onSendAction_EditUser({
      id: rowEditing.id,
      login: username,
      email,
      firstName,
      lastName,
      role,
      activated,
      avatarBase64,
      avatarType,
    });
    this.setState({ showEditUserModal: false })
  }

  onSubmitChangePasswordByAdmin = (event) => {
    event.preventDefault();
    const { onSendAction_ChangePasswordByAdmin } = this.props
    const { password, rowEditing } = this.state
    onSendAction_ChangePasswordByAdmin({
      id: rowEditing.id,
      newPassword: password,
    });
    this.setState({ showEditUserModal: false })
  }

  onShowConfirmDelete = (row) => {
    this.setState({
      show_confirm_delete: true,
      rowDeleting: row
    })
  }

  onShowConfirmResetPassword = (row) => {
    this.setState({
      show_confirm_reset_password: true,
      rowResetPassword: row
    })
  }

  onShowEditUserPopup = (row) => {
    this.setState({
      showEditUserModal: !this.state.modal,
      rowEditing: row,
      username: row.login,
      email: row.email,
      firstName: row.firstName,
      lastName: row.lastName,
      role: row.role,
      activated: row.activated,
      avatarBase64: row.avatarBase64,
      avatarType: row.avatarType,
    })
  }

  onSelectFile = (event) => {
    let file = event.target.files[0]
    if (file) {
      this.setState({
        avatarType: file.type
      });
      const reader = new FileReader()
      reader.onload = this.onReaderLoad.bind(this)
      reader.readAsBinaryString(file)
    }
  }

  onReaderLoad = (readerEvt) => {
    let binStr = readerEvt.target.result
    let base64 = btoa(binStr)
    document.getElementById("AvatarPreview").src = "data:image/png;base64," + base64;
    this.setState({
      avatarBase64: base64,
    });
  }

  onPageChange = (page, sizePerPage) => {
    const { onSendAction_GetUsers } = this.props
    const { size, sortField, sortOrder, search } = this.state
    onSendAction_GetUsers(page, size, sortField, sortOrder, search)
  }

  onSort = (field, order) => {
    const { onSendAction_GetUsers } = this.props
    const { page, size, search } = this.state
    onSendAction_GetUsers(page, size, field, order, search)
  }

  render() {
    const { actionResult } = this.state
    return (
      <Fragment >
        <div className="page-content">
          <ActionResultBox actionResult={ actionResult } onCloseResultDlg={ this.closeResultDlg } />
          {this.renderConfirmDeleteDlg()}
          {this.renderCreateUserPopup()}
          {this.renderEditUserPopup()}
          {this.renderConfirmResetPwdDlg()}
          <Container fluid>
            <Breadcrumbs title="User Management" breadcrumbItem="Manage Users" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    { this.renderDataGrid() }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment >
    );
  }

  closeResultDlg = (reloadPage) => {
    this.setState({ actionResult: {} })
    if (reloadPage === true) {
      this.props.onSendAction_GetUsers(this.state.page, this.state.size, this.state.sortField, this.state.sortOrder, this.state.search);
    }
    if (!isEmpty(this.state.rowEditing) && this.state.rowEditing.login === this.props.userProfile.login) {
      this.props.onSendAction_GetUserProfile()
    }
  }

  renderConfirmDeleteDlg = () => {
    if (!isEmpty(this.state.rowDeleting) && (this.state.rowDeleting.login === this.state.userLogin.username)) {
      return <SweetAlert
        title="You can't delete yourself"
        warning
        onConfirm={() => this.setState({
          show_confirm_delete: false,
          rowDeleting: {}
        })}
      ></SweetAlert>
    } else if (this.state.rowDeleting.userLogin) {
      return <SweetAlert
        title="You can't delete this user becuase this user already logged to the system once"
        warning
        onConfirm={() => this.setState({
          show_confirm_delete: false,
          rowDeleting: {}
        })}
      ></SweetAlert>
    } else if (this.state.show_confirm_delete) {
      return <SweetAlert
        title={`Are you sure you want to delete user ${this.state.rowDeleting.login}"?`}
        warning
        showCancel
        confirmButtonText="Yes, delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        closeOnClickOutside={false}
        onConfirm={() => {
          this.props.onSendAction_DeleteUser(this.state.rowDeleting.id)
          this.setState({
            show_confirm_delete: false,
            dynamic_title: "Delete User",
            dynamic_description: `User ${this.state.rowDeleting.login} has been deleted.`,
          })
        }
        }
        onCancel={() =>
          this.setState({
            show_confirm_delete: false,
            rowDeleting: {},
          })
        }
      >
        You won't be able to revert this!
      </SweetAlert>;
    }
    return null;
  }

  renderConfirmResetPwdDlg = () => {
    if (!isEmpty(this.state.rowResetPassword) && (this.state.rowResetPassword.login === this.state.userLogin.username)) {
      return <SweetAlert
        title="You can't reset password yourself"
        warning
        onConfirm={() => this.setState({
          show_confirm_reset_password: false,
          rowResetPassword: {}
        })}
      ></SweetAlert>
    } else if (this.state.show_confirm_reset_password) {
      return <SweetAlert
        title={`Are you sure you want to reset password for user ${this.state.rowResetPassword.login}"?`}
        warning
        showCancel
        confirmButtonText="Yes, reset it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        closeOnClickOutside={false}
        onConfirm={() => {
          this.props.onSendAction_ResetPwd(this.state.rowResetPassword.id)
          this.setState({
            show_confirm_reset_password: false,
          })
        }
        }
        onCancel={() =>
          this.setState({
            show_confirm_reset_password: false,
            rowResetPassword: {},
          })
        }
      >
      </SweetAlert>;
    }
    return null;
  }

  renderEditUserPopup = () => {
    return <Modal
            size="lg"
            isOpen={this.state.showEditUserModal}
            toggle={() =>
              this.setState({ showEditUserModal: !this.state.showEditUserModal, rowEditing: null })
            }
            centered={true}
            backdrop="static"
          >
            { this.renderEditUserForm() }
            { this.renderChangePasswordByAdminForm() }
          </Modal>
  }

  renderCreateUserPopup = () => {
    return <Modal
            size="lg"
            isOpen={this.state.showNewUserModal}
            toggle={() =>
              this.setState({ showNewUserModal: !this.state.showNewUserModal, rowEditing: null })
            }
            centered={true}
            backdrop="static"
          >
            {this.renderNewUserForm()}
          </Modal>;
  }

  renderNewUserForm() {
    return <AvForm
      className="form-horizontal"
      onValidSubmit={this.onSubmitNewUser}
    >
      <ModalHeader
        toggle={() => this.setState({ showNewUserModal: !this.state.showNewUserModal, rowEditing: null })}
      >
        New user
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={4}>{emailControl(this.state.email, this.onChangePopupControl)}</Col>
          <Col lg={4}>{firstNameControl(this.state.firstName, this.onChangePopupControl)}</Col>
          <Col lg={4}>{lastNameControl(this.state.lastName, this.onChangePopupControl)}</Col>
        </Row>
        <Row>
          <Col lg={4}>{usernameControl(this.state.username, this.onChangePopupControl)}</Col>
          <Col lg={4}>{strongPasswordControl("password", "Password", this.state.password, this.onChangePopupControl)}</Col>
          <Col lg={4}>{passwordConfirmControl("Confirm Password")}</Col>
        </Row>
        <Row>
          <Col lg={4}>{roleControl(this.state.role, this.onChangePopupControl, this.state.userLogin.role)}</Col>
          <Col lg={4}>{avatarControl(this.onSelectFile)}</Col>
          <Col lg={4}>{previewAvatarControl(this.state.avatarBase64)}</Col>
        </Row>
        <Row>
          <Col lg={4}>
            <div
              className="custom-control custom-switch mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
                defaultChecked={this.state.activated} />
              <label
                className="custom-control-label"
                htmlFor="customSwitch1"
                onClick={this.onClickCheckBoxActive}
              >
                Active
              </label>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col lg={12}>
            <div className="text-right">
              <button
                type="submit"
                className="btn btn-primary mr-1 "
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.setState({ showNewUserModal: !this.state.showNewUserModal })}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </AvForm>;
  }

  renderChangePasswordByAdminForm() {
    const fieldControls = this.getFieldControls()
    return <AvForm
      className="form-horizontal"
      onValidSubmit={this.onSubmitChangePasswordByAdmin}
    >
      <ModalHeader>
        Change Password
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={4}><AvField name="username" id="username" value={this.state.username} type="text" hidden autoComplete="off" /></Col>
        </Row>
        <Row>
          <Col lg={4}>{strongPasswordControl("password", "Password", this.state.password, this.onChangePopupControl)}</Col>
          <Col lg={4}>{passwordConfirmControl("Confirm Password")}</Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col lg={12}>
            <div className="text-right">
              <button
                type="submit"
                className="btn btn-primary mr-1 "
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.setState({
                  showEditUserModal: !this.state.showEditUserModal,
                  ...fieldControls
                })}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </AvForm>;
  }

  renderEditUserForm() {
    const fieldControls = this.getFieldControls()
    return <AvForm
      className="form-horizontal"
      onValidSubmit={this.onSubmitEditUser}
    >
      <ModalHeader toggle={() => this.setState({ showEditUserModal: !this.state.showEditUserModal, rowEditing: null })}>
        Edit User
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={4}>{emailControl(this.state.email, this.onChangePopupControl, true)}</Col>
          <Col lg={4}>{usernameControl(this.state.username, this.onChangePopupControl, true)}</Col>
        </Row>
        <Row>
          <Col lg={4}>{firstNameControl(this.state.firstName, this.onChangePopupControl)}</Col>
          <Col lg={4}>{lastNameControl(this.state.lastName, this.onChangePopupControl)}</Col>
          <Col lg={4}>{roleControl(this.state.role, this.onChangePopupControl, this.state.userLogin.role,
            (this.state.rowEditing !== null && this.state.rowEditing.login === this.state.userLogin.username))}</Col>
        </Row>
        <Row>
          <Col lg={8}>{avatarControl(this.onSelectFile)}</Col>
          <Col lg={4}>{previewAvatarControl(this.state.avatarBase64)}</Col>
        </Row>
        <Row>
          <Col lg={4}>
            <div
              className="custom-control custom-switch mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
                disabled={(this.state.rowEditing !== null && this.state.rowEditing.login === this.state.userLogin.username)}
                defaultChecked={this.state.activated} />
              <label
                className="custom-control-label"
                htmlFor="customSwitch1"
                onClick={this.onClickCheckBoxActive}
              >
                Active
              </label>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col lg={12}>
            <div className="text-right">
              <button
                type="submit"
                className="btn btn-primary mr-1 "
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {this.setState({
                  showEditUserModal: !this.state.showEditUserModal,
                  ...fieldControls
                  })}
                }
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </AvForm>;
  }

  renderDataGrid() {
    const { content, page, size, totalPages, totalElements } = this.state
    const pageOptions = {
      page: page,
      sizePerPage: size,
      totalSize: totalElements,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
    }
    function customMatchFunc({
      searchText,
      value,
    }) {
      if (typeof value !== 'undefined') {
        return value.startsWith(searchText);
      }
      return false;
    }

    const fieldControls = this.getFieldControls()

    return <PaginationProvider
      pagination={paginationFactory(pageOptions)}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="id"
          data={content || []}
          columns={UserColumns(this.onShowConfirmDelete, this.onShowEditUserPopup, this.onSort, this.onShowConfirmResetPassword)}
          bootstrap4
          search={{ customMatchFunc }}
        >
          {toolkitProps => (
            <Fragment>
              <Row className="mb-2">
                <Col sm="4">
                  <div className="search-box mr-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <Search.SearchBar
                        {...toolkitProps.searchProps} />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
                <Col sm="8">
                  <div className="text-sm-right">
                    <Link
                      onClick={() => this.setState({
                        showNewUserModal: true,
                        ...fieldControls
                      })}
                      to="#"
                    >
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded waves-effect waves-light mb-2 mr-2"
                      >
                        <i className="mdi mdi-plus mr-1" />
                        New user
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <div>
                    <BootstrapTable
                      keyField={"id"}
                      responsive
                      remote
                      hover={true}
                      bordered={false}
                      striped={false}
                      classes={"table table-centered table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      onTableChange={this.onTableChange}
                      {...paginationTableProps} />
                  </div>
                </Col>
              </Row>
              { totalPages > 1 ? 
                <Row className="align-items-md-center mt-30">
                  <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                    <PaginationListStandalone
                      {...paginationProps} />
                  </Col>
                </Row> : null
              }
            </Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>;
  }
}

UsersList.propTypes = {
  contentPage: PropTypes.object,
  actionResult: PropTypes.object,
  onSendAction_GetUsers: PropTypes.func,
  onSendAction_AddUser: PropTypes.func,
  onSendAction_EditUser: PropTypes.func,
  onSendAction_DeleteUser: PropTypes.func,
  onSendAction_ResetPwd: PropTypes.func,
}

const mapStateToProps = ({ Login, usersReducer }) => ({
  userProfile: Login.userProfile,
  contentPage: usersReducer.contentPage,
  actionResult: usersReducer.actionResult,
})

const mapDispatchToProps = dispatch => ({
  //SpringData: Page number start with 0.
  onSendAction_GetUsers: (page, size, sortField, sortOrder, search) => dispatch(getUsers(page - 1, size, sortField, sortOrder, search)),
  onSendAction_AddUser: (user) => dispatch(addUser(user)),
  onSendAction_EditUser: (user) => dispatch(editUser(user)),
  onSendAction_DeleteUser: (id) => dispatch(deleteUser(id)),
  onSendAction_ResetPwd: (id) => dispatch(resetPwd(id)),
  onSendAction_ChangePasswordByAdmin: (password) => dispatch(changePasswordByAdmin(password)),
  onSendAction_ClearActionResult: () => dispatch(clearActionResult()),
  onSendAction_GetUserProfile: () => dispatch(getUserProfile()),
  onSendAction_ClearState: () => dispatch(clearState())
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersList)
