import {
  RESULT_OK,
  RESULT_ERROR,
  CLEAR_STATE,
  GET_AUDITS,
  GET_AUDITS_SUCCESS,
  GET_AUDITS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  contentPage: {},
  actionResult: {}
}

const auditsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CLEAR_STATE:
      {
        return {
          ...INIT_STATE
        }
      }
    case GET_AUDITS_SUCCESS:
      {
        return {
          ...state,
          contentPage: action.payload.contentPage,
          actionResult: { action: GET_AUDITS, result: RESULT_OK, error: '' },
        }
      }
    case GET_AUDITS_FAIL: {
      return {
        ...state,
        actionResult: { action: GET_AUDITS, result: RESULT_ERROR, error: action.payload },
      }
    }

    default:
      return state
  }
}

export default auditsReducer
