import { Button } from "reactstrap"

const SecureBuildColumns = (onViewDetails, onViewAudits, onSort) => [
  {
    dataField: "createdUser",
    text: "Last Enroller",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '80px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        {!row.lastTrnVersion.enroller.avatarBase64 ? (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle" alt={row.lastTrnVersion.enroller.firstName + " " + row.lastTrnVersion.enroller.lastName}
              title={row.lastTrnVersion.enroller.firstName + " " + row.lastTrnVersion.enroller.lastName}>
              {row.lastTrnVersion.enroller.login.charAt(0)}
            </span>
          </div>
        ) : (
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={"data:image/png;base64," + row.lastTrnVersion.enroller.avatarBase64}
                alt={row.lastTrnVersion.enroller.firstName + " " + row.lastTrnVersion.enroller.lastName}
                title={row.lastTrnVersion.enroller.firstName + " " + row.lastTrnVersion.enroller.lastName}
              />
            </div>
          )}
      </>
    ),
  },
  {
    dataField: "deviceId",
    text: "Device ID",
    sort: true,
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '120px', textAlign: 'center' };
    },
  },
  {
    dataField: "serial",
    text: "SAM Serial",
    sort: true,
    onSort: onSort,
    formatter: (cellContent, row) => (
      <>
        <div style={{ width: "110px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{row.serial}</div>
      </>
    )
  },
  {
    dataField: "lastTrnVersion",
    text: "TRN/Version",
    align: "center",
    headerAlign: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '200px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <span className="ml-2 mr-1" style={ { "overflow-wrap": "break-word", "white-space": "normal" }}>
          {(row.lastTrnVersion !== undefined && row.lastTrnVersion !== null) ? row.lastTrnVersion.trnVersionSummary : ""}
        </span>
      </>
    ),
  },
  {
    dataField: "lastTrnVersion.status",
    text: "Status",
    headerAlign: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '100px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <span className={"ml-2 badge " + (row.lastTrnVersion.status === "COMPLETED" ? "badge-soft-success" : (row.lastTrnVersion.status === "FAILED" ? "badge-soft-danger" : "badge-soft-primary"))}>
          {row.lastTrnVersion.status}
        </span>
      </>
    )
  },
    {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    headerStyle: (colum, colIndex) => {
      return { width: '150px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <div className="button-items">
          <Button
            color="primary"
            className="btn btn-primary btn-sm waves-effect"
            onClick={() => onViewDetails(row)}
          >
            Summary
          </Button>
          <Button
            color="primary"
            className="btn btn-primary btn-sm waves-effect"
            onClick={() => onViewAudits(row)}
          >
            Audit
          </Button>
        </div>
      </>
    ),
  },

]

export default SecureBuildColumns
