import { Fragment } from "react";
import { Row, Col } from "reactstrap"
import { appVersion, deployedEnvironment } from "../../helpers/app_helper"

const Footer = () => {
  let deployedEnv = deployedEnvironment();
  let showDeployedEnv = deployedEnv !== undefined && deployedEnv !== '';
  return (
    <Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col sm={12} className="text-center">
                {new Date().getFullYear()} © Identiv, Inc. | All Rights Reserved | {appVersion()}
                {showDeployedEnv ? " | " : ""}
                {showDeployedEnv && (
                  <span className="badge badge-warning">{deployedEnv}</span>
                )}
            </Col>
          </Row>
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer
