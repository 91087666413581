import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SECURE_BUILD_AUDITS,
  CREATE_SECURE_BUILD_AUDIT,
} from "./actionTypes"

import {
  getSecureBuildAuditsSuccess,
  getSecureBuildAuditsFail,
  createSecureBuildAuditSuccess,
  createSecureBuildAuditFail,
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getSecureBuildAudits,
  createSecureBuildAudit,
} from "helpers/backend_helper"

function* fetchSecureBuildAudits({ payload }) {
  try {
    const response = yield call(getSecureBuildAudits, payload.secureBuildId, {
      params: {
        page: payload.page,
        size: payload.size,
        sortField: payload.sortField,
        sortOrder: payload.sortOrder,
        search: payload.search
      }
    })
    yield put(getSecureBuildAuditsSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getSecureBuildAuditsFail(error.response.data.message))
    } else {
      yield put(getSecureBuildAuditsFail(error.message))
    }
  }
}

function* onCreateSecureBuildAudit({ payload }) {
  try {
    const response = yield call(createSecureBuildAudit, payload)
    yield put(createSecureBuildAuditSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(createSecureBuildAuditFail(error.response.data))
    } else {
      yield put(createSecureBuildAuditFail(error.message))
    }
  }
}

function* secureBuildAuditSaga() {
  yield takeEvery(GET_SECURE_BUILD_AUDITS, fetchSecureBuildAudits)
  yield takeEvery(CREATE_SECURE_BUILD_AUDIT, onCreateSecureBuildAudit)
}

export default secureBuildAuditSaga