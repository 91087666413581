import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Col, Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { getSecureBuildAudits, clearActionResult } from "store/secureBuildAudit/actions"
import SecureBuildAuditColumns from "./SecureBuildAuditColumns"
import { GET_SECURE_BUILD_AUDITS } from "store/secureBuildAudit/actionTypes"
import { RESULT_OK, RESULT_ERROR } from "store/baseActionTypes"

class SecureBuildAuditListing extends Component {

  constructor(props) {
    super(props)
    this.state = {
      secureBuildAuditPage: {
        currentPage: 0,
        totalPages: 0,
        pageSize: 10,
        totalItems: 0,
        content: []
      },
      sortField: '',
      sortOrder: '',
      search: '',
      actionResult: undefined,
      isOpen: true,
      onClosed: props.onClosed
    }
  }

  componentDidMount() {
    const { onGetSecureBuildAudits, secureBuild } = this.props
    onGetSecureBuildAudits(secureBuild.id, this.state.secureBuildAuditPage.currentPage, this.state.secureBuildAuditPage.pageSize, this.state.sortField, this.state.sortOrder, this.state.search)
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGetSecureBuildAudits, secureBuild } = this.props
    const { secureBuildAuditPage, sortField, sortOrder, search } = this.state

    if (secureBuildAuditPage.currentPage !== prevState.secureBuildAuditPage.currentPage
        || secureBuildAuditPage.pageSize !== prevState.secureBuildAuditPage.pageSize
        || sortField !== prevState.sortField
        || sortOrder !== prevState.sortOrder) {
      onGetSecureBuildAudits(secureBuild.id, secureBuildAuditPage.currentPage, secureBuildAuditPage.pageSize, sortField, sortOrder, search);
      return;
    }

    if (this.props.secureBuildAuditPage !== undefined && this.props.secureBuildAuditPage.content !== undefined && this.props.secureBuildAuditPage !== prevProps.secureBuildAuditPage) {
      this.setState({
        secureBuildAuditPage: this.props.secureBuildAuditPage
      })
      return;
    }
    if (this.props.actionResult !== prevProps.actionResult) {
      this.setState({
        actionResult: this.props.actionResult
      })
      return;
    }
  }

  onPageChange = (page, sizePerPage) => {
    let secureBuildAuditPage = Object.assign({}, this.state.secureBuildAuditPage);
    secureBuildAuditPage.currentPage = page - 1;
    this.setState({
      secureBuildAuditPage
    })
  }

  onSizePerPageChange = (page, sizePerPage) => {
    let secureBuildAuditPage = Object.assign({}, this.state.secureBuildAuditPage);
    secureBuildAuditPage.currentPage = 0;
    secureBuildAuditPage.pageSize = sizePerPage;
    this.setState({
      secureBuildAuditPage
    })
  }

  onSort = (field, order) => {
    let secureBuildAuditPage = Object.assign({}, this.state.secureBuildAuditPage);
    secureBuildAuditPage.currentPage = 0;
    this.setState({
      sortField: field,
      sortOrder: order,
      secureBuildAuditPage
    })
  }

  handleTableChange = (type, { page, searchText }) => {
    if (type === 'search') {
      let secureBuildAuditPage = Object.assign({}, this.state.secureBuildAuditPage);
      secureBuildAuditPage.currentPage = 0;
      this.setState({
        search: searchText,
        secureBuildAuditPage
      })
    }
  }

  renderActionResultDlg = () => {
    let { actionResult } = this.state
    if (actionResult !== undefined) {
      let error = false
      let title = ''
      let message = ''
      if (actionResult.action === GET_SECURE_BUILD_AUDITS) {
          if (actionResult.result === RESULT_OK) {
            return null
          } else if (actionResult.result === RESULT_ERROR) {
            error = true
            title = "Failed to fetch data"
            message = actionResult.error
          }
      }
      return <SweetAlert
        error={error}
        success={!error}
        title={title}
        onConfirm={() => { this.props.onClearActionResult(); }}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  render() {
    const { isOpen, onClosed } = this.state
    const { secureBuildAuditPage } = this.state
    const pageOptions = {
      page: secureBuildAuditPage.currentPage + 1, //SpringData: Page number values start with 0.
      sizePerPage: secureBuildAuditPage.pageSize,
      totalSize: secureBuildAuditPage.totalItems,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
      onSizePerPageChange: this.onSizePerPageChange,
    }

    return (
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => {
          this.setState({ isOpen: false });
          onClosed();
        }}
        centered={true}
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            this.setState({ isOpen: false });
            onClosed();
          }}
        >
          EG-2 Device Audits
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <div>
                {this.renderActionResultDlg()}
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={secureBuildAuditPage.content || []}
                      columns={SecureBuildAuditColumns(this.onSort)}
                      bootstrap4
                    >
                      {toolkitProps => (
                        <Fragment>
                          <Row>
                            <Col xl="12">
                              <div>
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  remote
                                  hover={true}
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table table-centered table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={this.handleTableChange}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
            <Row>
              <Col lg={12}>
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.setState({ isOpen: false });
                      onClosed();
                    }}
                  >
                    Close
                  </button>
                </div>
              </Col>
            </Row>
        </ModalFooter>

      </Modal>
    );
  }
}

SecureBuildAuditListing.propTypes = {
  // private
  actionResult: PropTypes.object,
  secureBuildAuditPage: PropTypes.object,
  onGetSecureBuildAudits: PropTypes.func,
  onClearActionResult: PropTypes.func,
  // public
  onClosed: PropTypes.func,
  secureBuild: PropTypes.object,
}

const mapStateToProps = ({ secureBuildAuditReducer }) => ({
  secureBuildAuditPage: secureBuildAuditReducer.secureBuildAuditPage,
  actionResult: secureBuildAuditReducer.actionResult,
})
const mapDispatchToProps = dispatch => ({
  onGetSecureBuildAudits: (secureBuildId, page, size, sortField, sortOrder, search) => dispatch(getSecureBuildAudits(secureBuildId, page, size, sortField, sortOrder, search)),
  onClearActionResult: () => dispatch(clearActionResult()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecureBuildAuditListing)