import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//User Management
import usersReducer from "./users/reducer"
//TRN Management
import trnsReducer from "./trns/reducer"
import trnAuditReducer from "./trns/trnAuditReducer"
import reducerRejectedTrn from "./trns/rejectedTrnReducer"
import trnVersionReducer from "./trns/trnVersionReducer"
//Reports
import reportsReducer from "./reports/reportsReducer"
//Key Management
import hsmInfoReducer from "./hsmInfo/reducer"
import keysetsReducer from "./keysets/keysetsReducer"
//Maintenance
import exceptionsReducer from "./exceptions/reducer"
import confsReducer from "./configurations/confsReducer"
import auditsReducer from "./audits/reducer"
import loginsReducer from "./logins/reducer"

// secureBuildDiscovery
import secureBuildDiscoveryReducer from "./secureBuildDiscovery/secureBuildDiscoveryReducer"
// samDiscovery
import samDiscoveryReducer from "./samDiscovery/samDiscoveryReducer"
//secure element
import secureElementReducer from "./secureElement/secureElementReducer"
//secure element session
import secureElementSessionReducer from "./secureElementSession/secureElementSessionReducer"
//secure build
import secureBuildReducer from "./secureBuild/secureBuildReducer"
//secure build session
import secureBuildSessionReducer from "./secureBuildSession/secureBuildSessionReducer"
//secure build audit
import secureBuildAuditReducer from "./secureBuildAudit/secureBuildAuditReducer"
//secure element audit
import secureElementAuditReducer from "./secureElementAudit/secureElementAuditReducer"
// trn upload
import trnUploadReducer from "./trnUpload/reducer"


const rootReducer = combineReducers({
  Layout,
  Login,
  ForgetPassword,
  Profile,
  usersReducer,
  trnsReducer,
  trnAuditReducer,
  reducerRejectedTrn,
  reportsReducer,
  auditsReducer,
  loginsReducer,
  secureBuildDiscoveryReducer,
  samDiscoveryReducer,
  hsmInfoReducer,
  keysetsReducer,
  exceptionsReducer,
  confsReducer,
  secureElementReducer,
  secureElementSessionReducer,
  secureBuildReducer,
  secureBuildSessionReducer,
  secureBuildAuditReducer,
  secureElementAuditReducer,
  trnUploadReducer,
  trnVersionReducer
})

export default rootReducer
