import { Link } from "react-router-dom"
import { toDatetimeString } from "../../helpers/app_helper"

const AuditsListColumns = (onView) => [
  {
    text: "Action",
    dataField: "action",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '65px', textAlign: 'center'};
    },
  },
  {
    text: "Service",
    dataField: "serviceClass",
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.serviceClass.length >64 ? row.serviceClass.substring(0, 60) + '...' : row.serviceClass}</p>
      </>
    ),
  },
  {
    text: "Method",
    dataField: "serviceMethod",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '130px', textAlign: 'center'};
    },
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.serviceMethod.length >20 ? row.serviceMethod.substring(0, 16) + '...' : row.serviceMethod}</p>
      </>
    ),
  },
  {
    text: "Date Time",
    dataField: "createdDate",
    align: "center",
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{toDatetimeString(new Date(row.createdDate))}</p>
      </>
    ),
    headerStyle: (colum, colIndex) => {
      return { width: '135px', textAlign: 'center' };
    },
  },
  {
    text: "IP Address",
    dataField: "ipAddress",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '130px', textAlign: 'center'};
    },
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '50px', };
    },
    formatter: (cellContent, row) => (
      <>
        <Link to="#" className="text-primary">
          <i className="mdi mdi-eye font-size-18 mr-2" id="edittooltip" onClick={() => onView(row)}/>
        </Link>
      </>     
    ),
  },
]

export default AuditsListColumns