import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Col, Row } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { getKeysets, clearActionResult } from "store/keysets/actions"
import KeysetSelectionColumns from "./KeysetSelectionColumns"
import {
  GET_KEYSETS_SUCCESS,
  GET_KEYSETS_FAIL,
} from "store/keysets/actionTypes"

class KeysetSelection extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedKeys: [],
      keysetPage: {
        currentPage: 0,
        totalPages: 0,
        pageSize: 3,
        totalItems: 0,
        keys: []
      },
      sortField: '',
      sortOrder: '',
      search: '',
      actionResult: undefined,
      forceReload: false,
      syncRows: false,
      firstSync: true
    }
  }

  componentDidMount() {
    const { onGetKeysets, keysetType } = this.props
    onGetKeysets(this.state.keysetPage.currentPage, this.state.keysetPage.pageSize, this.state.sortField, this.state.sortOrder, keysetType, this.state.search)
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGetKeysets, keysetType } = this.props
    const { keysetPage, sortField, sortOrder, search, forceReload, syncRows, firstSync } = this.state

    if (keysetPage.currentPage !== prevState.keysetPage.currentPage
        || keysetPage.pageSize !== prevState.keysetPage.pageSize
        || sortField !== prevState.sortField
        || sortOrder !== prevState.sortOrder
        || search !== prevState.search) {
      onGetKeysets(keysetPage.currentPage, keysetPage.pageSize, sortField, sortOrder, keysetType, search);
      return;
    }
    if (forceReload === true) {
      keysetPage.currentPage = 0; // reset to first page
      onGetKeysets(keysetPage.currentPage, keysetPage.pageSize, sortField, sortOrder, keysetType, search);
      this.setState({
        forceReload: false,
        keysetPage,
      })
      return;
    }
    if (syncRows === true) {
      let { selectedKeys } = this.state;
      let keysetPageCopy = _.cloneDeep(this.state.keysetPage);
      for (let i = 0; i < keysetPageCopy.keys.length; i++) {
        let keyset = keysetPageCopy.keys[i];
        keyset.checked = false;
        if (firstSync) {
          if (keyset.defaultKey === true) {
            keyset.checked = true;
            selectedKeys.push(keyset);
            this.props.onSelectedChanged(keyset);
          }
        } else {
          for (let j = 0; j < selectedKeys.length; j++) {
            if (selectedKeys[j].id === keyset.id) {
              keyset.checked = true;
              break;
            }
          }
        }
        keysetPageCopy.keys[i] = keyset;
      }
      this.setState({
        syncRows: false,
        firstSync: false,
        keysetPage: keysetPageCopy,
        selectedKeys
      })
      return;
    }

    if (this.props.keysetPage !== undefined && this.props.keysetPage.keys !== undefined && this.props.keysetPage !== prevProps.keysetPage) {
      this.setState({
        keysetPage: this.props.keysetPage,
        syncRows: true
      })
      return;
    }
    if (this.props.actionResult !== prevProps.actionResult) {
      this.setState({
        actionResult: this.props.actionResult
      })
      return;
    }
  }

  onPageChange = (page, sizePerPage) => {
    let keysetPage = _.cloneDeep(this.state.keysetPage);
    keysetPage.currentPage = page - 1;
    this.setState({
      keysetPage
    })
  }

  onSizePerPageChange = (page, sizePerPage) => {
    let keysetPage = _.cloneDeep(this.state.keysetPage);
    keysetPage.currentPage = 0;
    keysetPage.pageSize = sizePerPage;
    this.setState({
      keysetPage
    })
  }

  onSort = (field, order) => {
    let keysetPage = _.cloneDeep(this.state.keysetPage);
    keysetPage.currentPage = 0;
    this.setState({
      sortField: field,
      sortOrder: order,
      keysetPage
    })
  }

  handleTableChange = (type, { page, searchText }) => {
    if (type === 'search') {
      let keysetPage = _.cloneDeep(this.state.keysetPage);
      keysetPage.currentPage = 0;
      this.setState({
        search: searchText,
        keysetPage
      })
    }
  }

  selectedChanged = (checked, keyset) => {
    if (keyset === undefined || keyset == null) {
      console.error("NO Keyset selected");
      return;
    }
    keyset.checked = checked;

    let selectedKeys = _.cloneDeep(this.state.selectedKeys);
    let found = false;
    for (let i = 0; i < selectedKeys.length; i++) {
      if (selectedKeys[i].id === keyset.id) {
        found = true;
        break;
      }
    }

    if (checked === true) {
      if (!found) {
        selectedKeys = [];
        selectedKeys.push(keyset);
        this.setState({
          selectedKeys,
          syncRows: true
        });
      }
    } else {
      selectedKeys = [];
      this.setState({
        selectedKeys,
        syncRows: true
      });
    }
    if (selectedKeys.length === 0) {
      this.props.onSelectedChanged(undefined);
    } else {
      this.props.onSelectedChanged(selectedKeys[0]);
    }
  }

  onCloseResultDlg = (reloadPage) => {
    this.props.onClearActionResult();
    if (reloadPage === true) {
      this.setState({
        forceReload: true
      })
    }
  }

  renderActionResultDlg = () => {
    let { actionResult } = this.state
    let reloadPage = false
    if (actionResult !== undefined) {
      let error = false
      let title = ''
      let message = ''
      switch (actionResult.action) {
        case GET_KEYSETS_SUCCESS:
          return null
        case GET_KEYSETS_FAIL:
          error = true
          title = "ERROR"
          message = actionResult.error
          break;
      }
      return <SweetAlert
        error={error}
        success={!error}
        title={title}
        onConfirm={() => this.onCloseResultDlg(reloadPage)}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  render() {
    const { keysetPage } = this.state
    const pageOptions = {
      page: keysetPage.currentPage + 1, //SpringData: Page number values start with 0.
      sizePerPage: keysetPage.pageSize,
      totalSize: keysetPage.totalItems,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
      onSizePerPageChange: this.onSizePerPageChange,
    }
    function customMatchFunc({
      searchText,
      value,
    }) {
      if (typeof value !== 'undefined') {
        return value.startsWith(searchText);
      }
      return false;
    }

    return (
      <Fragment >
        <div>
          {this.renderActionResultDlg()}
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={keysetPage.keys || []}
                columns={KeysetSelectionColumns(this.selectedChanged, this.onSort)}
                bootstrap4
                search={{ customMatchFunc }}
              >
                {toolkitProps => (
                  <Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box mr-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Search.SearchBar
                              {...toolkitProps.searchProps}
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div>
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            remote
                            hover={true}
                            bordered={false}
                            striped={false}
                            classes={
                              "table table-centered table-nowrap"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={this.handleTableChange}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </div>
      </Fragment >
    );
  }
}

KeysetSelection.propTypes = {
  keysetType: PropTypes.any,
  onSelectedChanged: PropTypes.func,
  actionResult: PropTypes.object,
  keysetPage: PropTypes.object,
  onGetKeysets: PropTypes.func,
  onClearActionResult: PropTypes.func,
}

const mapStateToProps = ({ keysetsReducer }) => ({
  keysetPage: keysetsReducer.keysetPage,
  actionResult: keysetsReducer.actionResult,
})
const mapDispatchToProps = dispatch => ({
  onGetKeysets: (page, size, sortField, sortOrder, type, search) => dispatch(getKeysets(page, size, sortField, sortOrder, type, search)),
  onClearActionResult: () => dispatch(clearActionResult()),
})

export default connect(mapStateToProps, mapDispatchToProps)(KeysetSelection)