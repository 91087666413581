import { AvField, AvForm } from "availity-reactstrap-validation";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import {
  Alert, Col, Modal,
  ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";


class KeysetDetails extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
      keyset: props.keyset,
      onSubmit: props.onSubmit,
      onMakeDefault: props.onMakeDefault,
      onClosed: props.onClosed
    }
  }


  render() {
    const { keyset, isOpen, onSubmit, onMakeDefault, onClosed } = this.state

    let updating = keyset.id !== undefined && keyset.id > 0;

    return (
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => {
          this.setState({ isOpen: false });
          onClosed();
        }}
        centered={true}
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            this.setState({ isOpen: false });
            onClosed();
          }}
        >
          {(updating ? "UPDATE KEYSET" : "NEW KEYSET")}
        </ModalHeader>
        <AvForm
          className="form-horizontal"
        >
          <ModalBody>
            {keyset.usedForPrivisioning && (
              <Row>
                <Col lg={12}>
                  <Alert color="warning" role="alert">
                    This keyset have been used to provisioning so you cannot update it!
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="name"
                      id="name"
                      label="Name"
                      value={keyset.name}
                      className="form-control"
                      type="text"
                      placeholder="Enter Name"
                      errorMessage="Invalid Name"
                      required
                      minLength="1"
                      maxLength="255"
                      readOnly={updating}
                      onChange={(event) => {
                        keyset.name = event.target.value;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <AvField type="select"
                      name="type"
                      label="Type"
                      value={keyset.type}
                      disabled={true}
                    >
                    <option>SECURE_ELEMENT</option>
                    <option>SECURE_FIRMWARE</option>
                  </AvField>
                </div>
              </Col>
              {updating && (<Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="defaultKey"
                      id="defaultKey"
                      label="Is Default Key?"
                      value={keyset.defaultKey ? "YES" : "NO"}
                      className="form-control"
                      type="text"
                      minLength="1"
                      maxLength="255"
                      readOnly={true}
                  />
                </div>
              </Col>
              )}
            </Row>
            <Row>
              <Col lg={12}>
                <div className="form-group">
                  <AvField
                      name="description"
                      id="description"
                      label="Description"
                      value={keyset.description}
                      className="form-control"
                      type="text"
                      placeholder="Enter Description"
                      errorMessage="Invalid Description"
                      required
                      minLength="0"
                      maxLength="1024"
                      readOnly={keyset.usedForPrivisioning}
                      onChange={(event) => {
                        keyset.description = event.target.value;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="kekObjectId"
                      id="kekObjectId"
                      label="KEK Key Id"
                      value={keyset.kekObjectId}
                      className="form-control"
                      type="text"
                      placeholder="Enter Key Id"
                      errorMessage="Invalid Key Id (4 bytes HEX)"
                      required
                      minLength="8"
                      maxLength="8"
                      readOnly={keyset.usedForPrivisioning}
                      onChange={(event) => {
                        keyset.kekObjectId = event.target.value;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="kekLabel"
                      id="kekLabel"
                      label="KEK Key Label"
                      value={keyset.kekLabel}
                      className="form-control"
                      type="text"
                      placeholder="Enter Key Label"
                      errorMessage="Invalid Key Label"
                      required
                      minLength="1"
                      maxLength="255"
                      readOnly={keyset.usedForPrivisioning}
                      onChange={(event) => {
                        keyset.kekLabel = event.target.value;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="kekHandler"
                      id="kekHandler"
                      label="KEK Key Handler"
                      value={keyset.kekHandler + ""}
                      className="form-control"
                      type="number"
                      min="0"
                      max="4294967294"
                      readOnly={keyset.usedForPrivisioning}
                      placeholder="Enter Key Handler"
                      errorMessage="Invalid Key Handler"
                      onChange={(event) => {
                        keyset.kekHandler = event.target.valueAsNumber;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="identivObjectId"
                      id="identivObjectId"
                      label="Identiv Key Id"
                      value={keyset.identivObjectId}
                      className="form-control"
                      type="text"
                      placeholder="Enter Key Id"
                      errorMessage="Invalid Key Id (4 bytes HEX)"
                      required
                      minLength="8"
                      maxLength="8"
                      readOnly={keyset.usedForPrivisioning}
                      onChange={(event) => {
                        keyset.identivObjectId = event.target.value;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="identivPubKeyLabel"
                      id="identivPubKeyLabel"
                      label="Identiv Public Key Label"
                      value={keyset.identivPubKeyLabel}
                      className="form-control"
                      type="text"
                      placeholder="Enter Public Key Label"
                      errorMessage="Invalid Public Key Label"
                      required
                      minLength="1"
                      maxLength="255"
                      readOnly={keyset.usedForPrivisioning}
                      onChange={(event) => {
                        keyset.identivPubKeyLabel = event.target.value;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="identivPubKeyHandler"
                      id="identivPubKeyHandler"
                      label="Identiv Public Key Handler"
                      value={keyset.identivPubKeyHandler + ""}
                      className="form-control"
                      type="number"
                      min="0"
                      max="4294967294"
                      readOnly={keyset.usedForPrivisioning}
                      placeholder="Enter Public Key Handler"
                      errorMessage="Invalid Public Key Handler"
                      onChange={(event) => {
                        keyset.identivPubKeyHandler = event.target.valueAsNumber;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="identivPriKeyLabel"
                      id="identivPriKeyLabel"
                      label="Identiv Private Key Label"
                      value={keyset.identivPriKeyLabel}
                      className="form-control"
                      type="text"
                      placeholder="Enter Private Key Label"
                      errorMessage="Invalid Private Key Label"
                      required
                      minLength="1"
                      maxLength="255"
                      readOnly={keyset.usedForPrivisioning}
                      onChange={(event) => {
                        keyset.identivPriKeyLabel = event.target.value;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="identivPriKeyHandler"
                      id="identivPriKeyHandler"
                      label="Identiv Private Key Handler"
                      value={keyset.identivPriKeyHandler + ""}
                      className="form-control"
                      type="number"
                      min="0"
                      max="4294967294"
                      readOnly={keyset.usedForPrivisioning}
                      placeholder="Enter Private Key Handler"
                      errorMessage="Invalid Private Key Handler"
                      onChange={(event) => {
                        keyset.identivPriKeyHandler = event.target.valueAsNumber;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
            </Row>
            {keyset.type === "SECURE_ELEMENT" && (
              <Row>
                <Col lg={4}>
                  <div className="form-group">
                    <AvField
                        name="deviceObjectId"
                        id="deviceObjectId"
                        label="Device Key Id"
                        value={keyset.deviceObjectId}
                        className="form-control"
                        type="text"
                        placeholder="Enter Key Id"
                        errorMessage="Invalid Key Id (4 bytes HEX)"
                        required
                        minLength="8"
                        maxLength="8"
                        readOnly={keyset.usedForPrivisioning}
                        onChange={(event) => {
                          keyset.deviceObjectId = event.target.value;
                          this.setState({
                            keyset
                          })
                        }}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="form-group">
                    <AvField
                        name="devicePubKeyLabel"
                        id="devicePubKeyLabel"
                        label="Device Public Key Label"
                        value={keyset.devicePubKeyLabel}
                        className="form-control"
                        type="text"
                        placeholder="Enter Public Key Label"
                        errorMessage="Invalid Public Key Label"
                        required
                        minLength="1"
                        maxLength="255"
                        readOnly={keyset.usedForPrivisioning}
                        onChange={(event) => {
                          keyset.devicePubKeyLabel = event.target.value;
                          this.setState({
                            keyset
                          })
                        }}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="form-group">
                    <AvField
                        name="devicePubKeyHandler"
                        id="devicePubKeyHandler"
                        label="Device Public Key Handler"
                        value={keyset.devicePubKeyHandler + ""}
                        className="form-control"
                        type="number"
                        min="0"
                        max="4294967294"
                        readOnly={keyset.usedForPrivisioning}
                        placeholder="Enter Public Key Handler"
                        errorMessage="Invalid Public Key Handler"
                        onChange={(event) => {
                          keyset.devicePubKeyHandler = event.target.valueAsNumber;
                          this.setState({
                            keyset
                          })
                        }}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {keyset.type === "SECURE_ELEMENT" && (
            <Row>
              <Col lg={4}>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="devicePriKeyLabel"
                      id="devicePriKeyLabel"
                      label="Device Private Key Label"
                      value={keyset.devicePriKeyLabel}
                      className="form-control"
                      type="text"
                      placeholder="Enter Private Key Label"
                      errorMessage="Invalid Private Key Label"
                      required
                      minLength="1"
                      maxLength="255"
                      readOnly={keyset.usedForPrivisioning}
                      onChange={(event) => {
                        keyset.devicePriKeyLabel = event.target.value;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <AvField
                      name="devicePriKeyHandler"
                      id="devicePriKeyHandler"
                      label="Device Private Key Handler"
                      value={keyset.devicePriKeyHandler + ""}
                      className="form-control"
                      type="number"
                      min="0"
                      max="4294967294"
                      readOnly={keyset.usedForPrivisioning}
                      placeholder="Enter Private Key Handler"
                      errorMessage="Invalid Private Key Handler"
                      onChange={(event) => {
                        keyset.devicePriKeyHandler = event.target.valueAsNumber;
                        this.setState({
                          keyset
                        })
                      }}
                  />
                </div>
              </Col>
            </Row>
            )}

          </ModalBody>
          <ModalFooter>
            <Row>
              <Col lg={12}>
                {this.rederFooter(updating, keyset, onMakeDefault, onSubmit, onClosed)}
              </Col>
            </Row>
          </ModalFooter>
        </AvForm>

      </Modal>
    );
  }

  rederFooter(updating, keyset, onMakeDefault, onSubmit, onClosed) {
    return <div className="text-right">
      {updating && (
        <button
          type="button"
          className="btn btn-primary mr-1 "
          disabled={keyset.defaultKey}
          onClick={() => {
            this.setState({ isOpen: false });
            onMakeDefault(keyset);
          } }
        >
          Make Default
        </button>
      )}
      <button
        type="button"
        className="btn btn-primary mr-1 "
        disabled={keyset.usedForPrivisioning}
        onClick={() => {
          if (keyset.name === undefined || keyset.name === null || isEmpty(keyset.name)) {
            return;
          }
          if (keyset.kekLabel === undefined || keyset.kekLabel === null || isEmpty(keyset.kekLabel)) {
            return;
          }
          if (keyset.identivPubKeyLabel === undefined || keyset.identivPubKeyLabel === null || isEmpty(keyset.identivPubKeyLabel)) {
            return;
          }
          if (keyset.type === "SECURE_ELEMENT") {
            if (keyset.devicePubKeyLabel === undefined || keyset.devicePubKeyLabel === null || isEmpty(keyset.devicePubKeyLabel)) {
              return;
            }
            if (keyset.devicePriKeyLabel === undefined || keyset.devicePriKeyLabel === null || isEmpty(keyset.devicePriKeyLabel)) {
              return;
            }
          }
          this.setState({ isOpen: false });
          onSubmit(keyset);
        } }
      >
        Submit
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => {
          this.setState({ isOpen: false });
          onClosed();
        } }
      >
        Close
      </button>
    </div>;
  }
}

KeysetDetails.propTypes = {
  keyset: PropTypes.object,
  onClosed: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default KeysetDetails