import {
  GET_SECURE_BUILDS,
  GET_SECURE_BUILDS_SUCCESS,
  GET_SECURE_BUILDS_FAIL,
  GET_SECURE_BUILD_DETAILS_SUCCESS,
  GET_SECURE_BUILD_DETAILS_FAIL,
  CREATE_SECURE_BUILD_SUCCESS,
  CREATE_SECURE_BUILD_FAIL,
  UPDATE_SECURE_BUILD_SUCCESS,
  UPDATE_SECURE_BUILD_FAIL,
  CLEAR_ERROR,
  CLEAR_ACTION_RESULT,
  RESULT_OK,
  RESULT_ERROR,
  RESET
} from "./actionTypes"

const INIT_STATE = {
  secureBuildPage: {},
  secureBuildDetails: undefined,
  error: {},
  actionResult: undefined
}

const secureBuildReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CLEAR_ACTION_RESULT:
      {
        return {
          ...state,
          actionResult: undefined
        }
      }

    case GET_SECURE_BUILDS_SUCCESS:
      {
        return {
          ...state,
          secureBuildPage: action.payload,
          actionResult: { action: GET_SECURE_BUILDS, result: RESULT_OK, error: '' },
        }
      }
    case GET_SECURE_BUILDS_FAIL: {
      return {
        ...state,
        secureBuildPage: {},
        actionResult: { action: GET_SECURE_BUILDS, result: RESULT_ERROR, error: action.payload },
      }
    }

    case CREATE_SECURE_BUILD_FAIL:
    case UPDATE_SECURE_BUILD_FAIL:
    case GET_SECURE_BUILD_DETAILS_FAIL: {
      return {
        ...state,
        error: { message: action.payload },
      }
    }

    case GET_SECURE_BUILD_DETAILS_SUCCESS:
    case CREATE_SECURE_BUILD_SUCCESS:
    case UPDATE_SECURE_BUILD_SUCCESS:
      {
        return {
          ...state,
          secureBuildDetails: action.payload
        }
      }

    case CLEAR_ERROR:
      {
        return {
          ...state,
          error: {}
        }
      }

    case RESET:
      {
        return INIT_STATE
      }

    default:
      return state
  }
}

export default secureBuildReducer
