import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_SECURE_ELEMENT_SESSION,
  INIT_SECURE_ELEMENT_SESSION,
  EXCHANGE_SECURE_ELEMENT_SESSION,
  VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION
} from "./actionTypes"

import {
  getSecureElementSessionSuccess,
  getSecureElementSessionFail,
  initSecureElementSessionSuccess,
  initSecureElementSessionFail,
  exchangeSecureElementSessionSuccess,
  exchangeSecureElementSessionFail,
  verifyExchangeSecureElementSessionSuccess,
  verifyExchangeSecureElementSessionFail
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getSecureElementSession,
  initSecureElementSession,
  exchangeSecureElementSession,
  verifyExchangeSecureElementSession
} from "helpers/backend_helper"

function* fetchSecureElementSession({ sessionId}) {
  try {
    const response = yield call(getSecureElementSession, sessionId)
    yield put(getSecureElementSessionSuccess(response))
  } catch (error) {
    yield put(getSecureElementSessionFail(error))
  }
}

function* onInitSecureElementSession({ payload }) {
  try {
    const response = yield call(initSecureElementSession, payload)
    yield put(initSecureElementSessionSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(initSecureElementSessionFail(error.response.data))
    } else {
      yield put(initSecureElementSessionFail(error.message))
    }
  }
}

function* onExchangeSecureElementSession({ payload }) {
  try {
    const response = yield call(exchangeSecureElementSession, payload)
    yield put(exchangeSecureElementSessionSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(exchangeSecureElementSessionFail(error.response.data))
    } else {
      yield put(exchangeSecureElementSessionFail(error.message))
    }
  }
}

function* onVerifyExchangeSecureElementSession({ payload }) {
  try {
    const response = yield call(verifyExchangeSecureElementSession, payload)
    yield put(verifyExchangeSecureElementSessionSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(verifyExchangeSecureElementSessionFail(error.response.data))
    } else {
      yield put(verifyExchangeSecureElementSessionFail(error.message))
    }
  }
}

function* secureElementSessionSaga() {
  yield takeEvery(GET_SECURE_ELEMENT_SESSION, fetchSecureElementSession)
  yield takeEvery(INIT_SECURE_ELEMENT_SESSION, onInitSecureElementSession)
  yield takeEvery(EXCHANGE_SECURE_ELEMENT_SESSION, onExchangeSecureElementSession)
  yield takeEvery(VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION, onVerifyExchangeSecureElementSession)
}

export default secureElementSessionSaga