/* SECURE_BUILDS */

/* LIST EDGE DEVICES */
export const GET_SECURE_BUILDS = "GET_SECURE_BUILDS"
export const GET_SECURE_BUILDS_SUCCESS = "GET_SECURE_BUILDS_SUCCESS"
export const GET_SECURE_BUILDS_FAIL = "GET_SECURE_BUILDS_FAIL"

/* FETCH EDGE DEVICE DETAILS */
export const GET_SECURE_BUILD_DETAILS = "GET_SECURE_BUILD_DETAILS"
export const GET_SECURE_BUILD_DETAILS_SUCCESS = "GET_SECURE_BUILD_DETAILS_SUCCESS"
export const GET_SECURE_BUILD_DETAILS_FAIL = "GET_SECURE_BUILD_DETAILS_FAIL"

/* CREATE EDGE DEVICE */
export const CREATE_SECURE_BUILD = "CREATE_SECURE_BUILD"
export const CREATE_SECURE_BUILD_SUCCESS = "CREATE_SECURE_BUILD_SUCCESS"
export const CREATE_SECURE_BUILD_FAIL = "CREATE_SECURE_BUILD_FAIL"
/* UPDATE EDGE DEVICE */
export const UPDATE_SECURE_BUILD = "UPDATE_SECURE_BUILD"
export const UPDATE_SECURE_BUILD_SUCCESS = "UPDATE_SECURE_BUILD_SUCCESS"
export const UPDATE_SECURE_BUILD_FAIL = "UPDATE_SECURE_BUILD_FAIL"

/* CLEAR ERROR */
export const CLEAR_ERROR = "CLEAR_ERROR"
export const CLEAR_ACTION_RESULT = "CLEAR_ACTION_RESULT"

export const RESET = "RESET"

export const RESULT_OK = "RESULT_OK"
export const RESULT_ERROR = "RESULT_ERROR"
