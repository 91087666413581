import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postJwtRegister = (_url, data) => {
  return axios
    .post(_url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data)

const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)
// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_PASSWORD_FORGET, data)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// users
export const apiGetUsers = params => get(url.GET_USERS, params)
export const apiAddUser = data => post(url.ADD_USER, data)
export const apiEditUser = data => put(`${url.EDIT_USER}/${data.id}`, data)
export const apiDeleteUser = id => del(`${url.DELETE_USER}/${id}`)
export const apiResetPwd = id => del(`${url.RESET_PWD}/${id}/resetpassword`)
export const apiChangePasswordByAdmin = (id, data) => put(`${url.CHANGE_PWD}/${id}/password`, data)

// configurations
export const apiGetConfs = params => get(url.GET_CONFS, params)
export const apiAddConf = data => post(url.ADD_CONF, data)
export const apiEditConf = data => put(`${url.EDIT_CONF}/${data.id}`, data)
export const apiDeleteConf = id => del(`${url.DELETE_CONF}/${id}`)

// user profile
export const getUserProfile = () => get(url.GET_USER_PROFILE)
export const editUserProfile = data => put(url.GET_USER_PROFILE, data)
export const updatePassword = data => put(`${url.GET_USER_PROFILE}/password`, data)

// trns
export const apiGetTrns = (params) => get(`${url.GET_TRNS}`, params)
export const apiGetTrnsVersions = (id, params) => get(`${url.GET_TRNS}/${id}/vesions/`, params)
export const apiGetTrnAudits = (trnId, verId, params) => get(`${url.GET_TRNS}/${trnId}/${verId}/audits`, params)
export const apiSearchingTRNs = (params) => get(url.SEARCHING_TRNS, params)
export const apiGetRejectedTrns = (params) => get(`${url.GET_REJECTED_TRNS}`, params)
export const apiSelectForProvisioning = (id) => put(`${url.SELECT_FOR_PROVISIONING}/${id}`)

// reports
export const apiGetDeviceReport = params => get(`${url.GET_REPORTS}/device`, params)
export const apiGetTrnReport = params => get(`${url.GET_REPORTS}/trn`, params)
export const apiGetDashboardReport = () => get(`${url.GET_REPORTS}/dashboard`)

// logins
export const apiGetLogins = params => get(url.GET_LOGINS, params)

// Exceptions
export const apiGetExceptions = params => get(url.GET_EXCEPTIONS, params)

// audit
export const apiGetAudits = (params) => get(url.GET_AUDITS, params)

// secure build device discovery
export const getSecureBuildDiscoveryRequest = () => get(url.SECURE_BUILD_DISCOVERY)
export const getSecureBuildDiscoveryResponse = data => post(url.SECURE_BUILD_DISCOVERY, data)

// sam discovery
export const getSamDiscoveryRequest = () => get(url.SAM_DISCOVERY)
export const getSamDiscoveryResponse = data => post(url.SAM_DISCOVERY, data)

// hsm info
export const getHsmInfo = () => get(url.GET_HSM_INFO)
// sam key
export const getKeysets = (params) => get(url.KEYSET, params)
export const getKeysetDetails = id => get(`${url.KEYSET}/${id}`)
export const createKeyset = data => post(url.KEYSET, data)
export const updateKeyset = data => put(`${url.KEYSET}/${data.id}`, data)
export const makeDefaultKeyset = id => put(`${url.KEYSET}/${id}/makeDefault`)
export const deleteKeyset = id => del(`${url.KEYSET}/${id}`)

// secure element
export const getSecureElements = (params) => get(url.SECURE_ELEMENT, params)
export const getSecureElementDetails = id => get(`${url.SECURE_ELEMENT}/${id}`)
export const createSecureElement = data => post(url.SECURE_ELEMENT, data)
export const updateSecureElement = data => put(`${url.SECURE_ELEMENT}/${data.samSerial}`, data)
// secure element session
export const getSecureElementSession = id => get(`${url.SECURE_ELEMENT_SESSION}/${id}`)
export const initSecureElementSession = session => post(url.SECURE_ELEMENT_SESSION, session)
export const exchangeSecureElementSession = session => put(`${url.SECURE_ELEMENT_SESSION}/${session.id}`, session)
export const verifyExchangeSecureElementSession = session => put(`${url.SECURE_ELEMENT_SESSION}/verify`, session)

// secure build
export const getSecureBuilds = (params) => get(url.SECURE_BUILD, params)
export const getSecureBuildDetails = id => get(`${url.SECURE_BUILD}/${id}`)
export const createSecureBuild = data => post(url.SECURE_BUILD, data)
export const updateSecureBuild = data => put(`${url.SECURE_BUILD}/${data.samSerial}`, data)
// secure build session
export const getSecureBuildSession = id => get(`${url.SECURE_BUILD_SESSION}/${id}`)
export const initSecureBuildSession = session => post(url.SECURE_BUILD_SESSION, session)
export const exchangeSecureBuildSession = session => put(`${url.SECURE_BUILD_SESSION}/${session.id}`, session)

// secure build audit
export const getSecureBuildAudits = (secureBuildId, params) => get(`${url.SECURE_BUILD_AUDIT}/${secureBuildId}`, params)
export const createSecureBuildAudit = data => post(url.SECURE_BUILD_AUDIT, data)

// secure element audit
export const getSecureElementAudits = (secureElementId, params) => get(`${url.SECURE_ELEMENT_AUDIT}/${secureElementId}`, params)
export const createSecureElementAudit = data => post(url.SECURE_ELEMENT_AUDIT, data)

// TRN Upload
export const getTrnUploadActive = () => get(`${url.TRN_UPLOAD}`)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtRegister,
  postJwtProfile,
  postJwtLogin,
  postJwtForgetPwd,
}
