import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "components/Common/Breadcrumb";
import { isDate, isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { clearActionResult, clearState, getLogins } from "store/logins/actions";
import { RESULT_OK, GET_LOGINS } from "store/logins/actionTypes";
import LoginColumns from "./LoginColumns";


class LoginsList extends Component {

  constructor(props) {
    super(props)
    let today = new Date()
    this.state = {
      content: [],
      page: 1,
      size: 20,
      totalPages: 0,
      totalElements: -1,
      sortField: '',
      sortOrder: '',

      filterStartDate: today,
      filterEndDate: today,
      filterLogin: '',
      filterStatus: 'All',      

      actionResult: {},
      errorMsg: '',
    }
  }

  componentDidMount() {
    const { page, sortField, sortOrder  } = this.state
    this.onGetLoginsImpl(page, sortField, sortOrder)
  }

  componentWillUnmount() {
    this.props.onSendAction_ClearState()
  }

  componentDidUpdate(prevProps, prevState) {
    const { contentPage, actionResult } = this.props
    if (!isEmpty(contentPage)) {
      if (!isEqual(prevProps.contentPage, contentPage)) {
        this.setState({
          content: contentPage.content,
          page: contentPage.page + 1,  //SpringData: Page number start with 0
          size: contentPage.size,
          totalPages: contentPage.totalPages,
          totalElements: contentPage.totalElements,
          actionResult: actionResult,
        })
      }
    }
  }

  render() {
    return (
      <Fragment >
        <div className="page-content">
          {this.renderActionResultDlg()}
          {this.renderErrorMessage()}
          <Container fluid>
            <Breadcrumbs title="Maintenance" breadcrumbItem="Login History" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    { this.renderDataGrid() }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment >
    )
  }

  onFilterClick = () => {
    const { filterStartDate, filterEndDate, sortField, sortOrder } = this.state
    if (!isDate(filterStartDate) || !isDate(filterEndDate)) {
      this.setState({ content: [], totalElements: 0, page: 1, errorMsg: 'From Date and To Date are required' })
      return
    } else if (new Date(filterStartDate.toDateString()) > new Date(filterEndDate.toDateString())) {
      this.setState({ content: [], totalElements: 0, page: 1, errorMsg: 'From Date must be less than To Date' })
      return
    }
    this.onGetLoginsImpl(1, sortField, sortOrder)
  }

  onGetLoginsImpl = (page, sortField, sortOrder) => {
    const { size, filterStartDate, filterEndDate, filterLogin, filterStatus } = this.state
    let sdStr = ""
    let edStr = ""
    // Server Format Date "MM/dd/yyyy"
    if (isDate(filterStartDate)) {
      sdStr = filterStartDate.getMonth() + 1 + "/" + filterStartDate.getDate() + "/" + filterStartDate.getFullYear()
    }
    if (isDate(filterEndDate)) {
      edStr = filterEndDate.getMonth() + 1 + "/" + filterEndDate.getDate() + "/" + filterEndDate.getFullYear()
    }
    this.props.onSendAction_GetLogins(page, size, sortField, sortOrder, 
        { filterStartDate: sdStr, filterEndDate: edStr, filterLogin, filterStatus })
  }

  onFilterStartDateChange = date => {
    this.setState({
      filterStartDate: date,
    })
  }

  onFilterEndDateChange = date => {
    this.setState({
      filterEndDate: date,
    })
  }

  onFilterControlChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onPageChange = (page, sizePerPage) => {
    this.onGetLoginsImpl(page)
  }

  onSort = (field, order) => {
    this.onGetLoginsImpl(this.state.page, field, order)
  }

  // eslint-disable-next-line no-unused-vars
  onTableChange = (type, { page, searchText }) => {
  }

  onCloseErrorDlg = () => {
    this.setState({ errorMsg: '' })
  }

  renderErrorMessage = () => {
    const { errorMsg } = this.state
    if (!isEmpty(errorMsg)) {
      return <SweetAlert
        error={true}
        success={false}
        title="Error"
        onConfirm={this.onCloseErrorDlg}
      >
        {errorMsg}
      </SweetAlert>
    }
    return null
  }

  onCloseResultDlg = () => {
    this.setState({actionResult:{}})
  }

  renderActionResultDlg = () => {
    let { actionResult } = this.state
    if (!isEmpty(actionResult)) {
      let success = actionResult.result === RESULT_OK
      let title = ''
      let message = ''
      if (actionResult.action === GET_LOGINS) {
          if (actionResult.result === RESULT_OK) {
            return null
          } else {
            title = "Fetch Logins Fail"
            message = "Fetch Logins failed. " + actionResult.error
          }
      }
      return <SweetAlert
        error={!success}
        success={success}
        title={title}
        onConfirm={() => this.onCloseResultDlg()}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  renderDataGrid() {
    const { content, page, size, totalPages, totalElements, filterStatus } = this.state
    const pageOptions = {
      page: page,
      sizePerPage: size,
      totalSize: totalElements,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
    }
    return <PaginationProvider
      pagination={paginationFactory(pageOptions)}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="id"
          data={content || []}
          columns={LoginColumns(this.onSort, filterStatus)}
          bootstrap4
        >
          {toolkitProps => (
            <Fragment>
              <Row className="mb-2">
                <Col sm="12">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={this.onFilterClick}
                  >
                    <Row>
                      <FormGroup row={true}>
                        <div className="col-xl col-sm-4">
                          <AvGroup>
                            <Label>From Date</Label>
                            <DatePicker
                              selected={this.state.filterStartDate}
                              onChange={this.onFilterStartDateChange}
                              className="form-control"
                              placeholderText="Select date"
                              required={true} />
                          </AvGroup>
                        </div>
                        <div className="col-xl col-sm-4">
                          <AvGroup>
                            <Label>To Date</Label>
                            <DatePicker
                              selected={this.state.filterEndDate}
                              onChange={this.onFilterEndDateChange}
                              className="form-control"
                              placeholderText="Select date"
                              required={true} />
                          </AvGroup>
                        </div>
                        <div className="col-xl col-sm-4">
                          <AvGroup>
                            <AvField
                              name="filterLogin"
                              id="filterLogin"
                              label="Username"
                              value={this.state.filterLogin}
                              onChange={this.onFilterControlChange}
                              className="form-control"
                              placeholder="Enter Login"
                              type="text" />
                          </AvGroup>
                        </div>
                        <div className="col-xl col-sm-4">
                          <AvGroup>
                            <AvField type="select" name="select-multiple" label="Status" onChange={this.onFilterControlChange} id="filterStatus" name="filterStatus">
                              <option defaultValue>All</option>
                              <option>Success</option>
                              <option>Fail</option>
                            </AvField>
                          </AvGroup>
                        </div>
                        <div className="col-xl col-sm-4 align-self-end">
                          <AvGroup>
                            <button
                              type="submit"
                              className="btn btn-primary mr-1 "
                            >
                              Filter
                            </button>
                          </AvGroup>
                        </div>
                      </FormGroup>
                    </Row>
                  </AvForm>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl="12">
                  <div>
                    <BootstrapTable
                      keyField={"id"}
                      responsive
                      remote
                      hover={true}
                      bordered={false}
                      striped={false}
                      classes={"table table-centered table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      onTableChange={this.onTableChange}
                      {...paginationTableProps} />
                  </div>
                </Col>
              </Row>
              { totalPages > 1 ?
                <Row className="align-items-md-center mt-30">
                  <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                    <PaginationListStandalone
                      {...paginationProps} />
                  </Col>
                </Row> : null
              }
            </Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>;
  }
}

LoginsList.propTypes = {
  contentPage: PropTypes.object,
  actionResult: PropTypes.object,
  onGetLogins: PropTypes.func,
  onClearActionResult: PropTypes.func,
}

const mapStateToProps = ({ loginsReducer }) => ({
  contentPage: loginsReducer.contentPage,
  actionResult: loginsReducer.actionResult,
})

const mapDispatchToProps = dispatch => ({
  //SpringData: Page number start with 0.
  onSendAction_GetLogins: (page, size, sortField, sortOrder, search) => {
    dispatch(getLogins(page - 1, size, sortField, sortOrder, search))
  },
  onSendAction_ClearActionResult: () => dispatch(clearActionResult()),
  onSendAction_ClearState: () => dispatch(clearState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginsList)
