//Include Both Helper File with needed methods
import { apiGetRejectedTrns, apiGetTrnAudits, apiGetTrns, apiGetTrnsVersions, 
  apiSearchingTRNs, apiSelectForProvisioning } from "helpers/backend_helper"
import { isEmpty } from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import { selectForProvisioning } from "store/actions"
import {
  getRejectedTrnsFail, getRejectedTrnsSuccess, getTrnAuditsFail, getTrnAuditsSuccess, getTrnsFail, getTrnsSuccess,
  getTrnsVersionsFail, getTrnsVersionsSuccess, searchingTRNsFail, searchingTRNsSuccess, selectForProvisioningSuccess, selectForProvisioningFail
} from "./actions"
import { GET_REJECTED_TRNS, GET_TRNS, GET_TRNS_VERSIONS, GET_TRN_AUDITS, SEARCHING_TRNS, SELECT_FOR_PROVISIONING } from "./actionTypes"


function* onGetTrns({ payload }) {
  try {
    const response = yield call(apiGetTrns, {
      params: {
        page: payload.page,
        size: payload.size,
      }
    })
    yield put(getTrnsSuccess({
      content: response.content,
      page: response.number,
      size: response.size,
      totalPages: response.totalPages,
      totalElements: response.totalElements
    }))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getTrnsFail(error.response.data.message))
    } else {
      yield put(getTrnsFail(error.message))
    }
  }
}

function* onGetTrnAudits({payload}) {
  try {
    const response = yield call(apiGetTrnAudits, payload.trnId, payload.verId, {
        params: {
          page: payload.page, 
          size: payload.size
        }
      })
    yield put(getTrnAuditsSuccess({
      content: response.content,
      page: response.number,
      size: response.size,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
    }))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getTrnAuditsFail(error.response.data.message))
    } else {
      yield put(getTrnAuditsFail(error.message))
    }
  }
}

function* onGetRejectedTrns({payload}) {
  try {
    const response = yield call(apiGetRejectedTrns,{
      params: {
        page: payload.page,
        size: payload.size,
        sortField: payload.sortField, 
        sortOrder: payload.sortOrder,
      }
    })
    yield put(getRejectedTrnsSuccess({
      content: response.content,
      page: response.number,
      size: response.size,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
    }))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getRejectedTrnsFail(error.response.data.message))
    } else {
      yield put(getRejectedTrnsFail(error.message))
    }
  }
}

function* onSelectForVisioning({payload}) {
  try {
    const response = yield call(apiSelectForProvisioning, payload)
    yield put(selectForProvisioningSuccess(payload))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(selectForProvisioningFail(error.response.data.message))
    } else {
      yield put(selectForProvisioningFail(error.message))
    }
  }
}

function* onGetTrnsVersions({ payload }) {
  try {
    const response = yield call(apiGetTrnsVersions, {
      params: {
        page: payload.page, size: payload.size,
        sortField: payload.sortField, sortOrder: payload.sortOrder,
        search: payload.search,
      }
    })
    yield put(getTrnsVersionsSuccess({
      content: response.content,
      page: response.number,
      size: response.size,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
      sortField: payload.sortField,
      sortOrder: payload.sortOrder,
    }))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getTrnsVersionsFail(error.response.data.message))
    } else {
      yield put(getTrnsVersionsFail(error.message))
    }
  }
}

function* onSearchingTRNs({ payload }) {
  try {
    const response = yield call(apiSearchingTRNs, {
      params: {
        page: payload.page,
        size: payload.size,
        sortField: payload.sortField,
        sortOrder: payload.sortOrder,
        search: payload.search,
        selectedProvisioning: true
      }
    })
    yield put(searchingTRNsSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(searchingTRNsFail(error.response.data.message))
    } else {
      yield put(searchingTRNsFail(error.message))
    }
  }
}


function* trnsSaga() {
  yield takeEvery(GET_TRNS, onGetTrns)
  yield takeEvery(GET_TRNS_VERSIONS, onGetTrnsVersions)
  yield takeEvery(SEARCHING_TRNS, onSearchingTRNs)
  yield takeEvery(GET_TRN_AUDITS, onGetTrnAudits)
  yield takeEvery(GET_REJECTED_TRNS, onGetRejectedTrns)
  yield takeEvery(SELECT_FOR_PROVISIONING, onSelectForVisioning)
}

export default trnsSaga
