import { Link } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";

const ConfigurationColumns = (onDelete, onEdit, onSort) => [
  {
    text: "Module",
    dataField: "moduleName",
    sort: true,
    onSort: onSort,    
    headerStyle: (colum, colIndex) => {
      return { width: '150px' };
    },
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '270px' };
    },
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.name.length >40 ? row.name.substring(0, 39) + '...' : row.name}</p>
      </>
    ),
  },
  {
    text: "Description",
    dataField: "description",
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.description.length >42 ? row.description.substring(0, 41) + '...' : row.description}</p>
      </>
    ),
  },
  {
    text: "Status",
    dataField: "enabled",
    headerAlign: "center",
    align: "center",
    formatter: (cellContent, row) => (
      <Badge
        className={"font-size-12 badge-soft-" + (row.enabled === true ? "true" : "false")}
        color={row.enabled === true ? "success" : "secondary"}
        pill
      >
        { row.enabled === true ? "Enabled" : "Disabled"}
      </Badge >
    ),
    headerStyle: (colum, colIndex) => {
      return { width: '100px' };
    },
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    headerAlign: "center",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '100px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <Link to="#" className="text-primary">
          <i className="mdi mdi-pencil font-size-18 mr-2" id="edittooltip" onClick={() => onEdit(row)} />
          <UncontrolledTooltip placement="top" target="edittooltip">
            Edit
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
]

export default ConfigurationColumns
