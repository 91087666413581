import { Link } from "react-router-dom"
import {
  Badge, UncontrolledTooltip
} from "reactstrap"
import { getImage } from "./UserFieldControls"

const UserColumns = (onDelete, onEdit, onSort, onReset) => [
  {
    dataField: "avatarBase64",
    text: "#",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '50px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        {!row.avatarBase64 ? (
          <div className="avatar-xs">
            <img
                className="rounded-circle avatar-xs"
                src={getImage(null)}
                alt={row.firstName + " " + row.lastName}
                title={row.firstName + " " + row.lastName}
              />
          </div>
        ) : (
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={"data:image/png;base64," + row.avatarBase64}
                alt={row.firstName + " " + row.lastName}
                title={row.firstName + " " + row.lastName}
              />
            </div>
          )}
      </>
    ),
  },
  {
    dataField: "login",
    text: "Username",
    sort: true,
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '200px'};
    },
  },
  {
    text: "Email",
    dataField: "email",
    sort: true,
    onSort: onSort,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.email}</p>
      </>
    ),
  },
  {
    text: "Role",
    dataField: "role",
    align: "center",
    sort: false,
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '200px', textAlign: 'center'};
    },
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{ getRoleDisplay(row.role) }</p>
      </>
    ),
  },
  {
    dataField: "activated",
    text: "Status",
    sort: true,
    align: "center",
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '100px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <Badge
        className={"font-size-12 badge-soft-" + (row.activated === true ? "true" : "false")}
        color={row.activated === true ? "success" : "secondary"}
        pill
      >
        { row.activated === true ? "Active" : "In-Active"}
      </Badge >
    ),
  },
  {
    dataField: "failAttempts",
    text: "Locked",
    sort: true,
    align: "center",
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '100px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <Badge
        className={"font-size-12 badge-soft-" + (row.failAttempts >= 3 ? "false" : "true")}
        color={row.failAttempts >= 3 ? "secondary" : "success"}
        pill
      >
        { row.failAttempts >= 3 ? "Yes" : "No"}
      </Badge >
    ),
  },  
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    headerStyle: (colum, colIndex) => {
      return { width: '100px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <Link to="#" className="text-primary">
          <i className="mdi mdi-pencil font-size-18 mr-2" id="edittooltip" onClick={() => onEdit(row)} />
          <UncontrolledTooltip placement="top" target="edittooltip">
            Edit
          </UncontrolledTooltip>
        </Link>
        <Link to="#" className={`${row.userLogin?"text-muted":"text-danger"}`}>
          <i className="mdi mdi-delete font-size-18 mr-2" id="deletetooltip" onClick={() => row.userLogin ? '' : onDelete(row)} />
          <UncontrolledTooltip placement="top" target="deletetooltip">
            Delete
          </UncontrolledTooltip>
        </Link>
        <Link to="#" className="text-secondary">
          <i className="mdi mdi-lock-reset font-size-18 mr-2" id="resettooltip" onClick={() => onReset(row)} />
          <UncontrolledTooltip placement="top" target="resettooltip">
            Reset Password
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
]

export default UserColumns

export const getRoleDisplay = (role) => {
  if (role === 'ROLE_SUPER_ADMIN') {
    return 'Super Admin'
  } else if (role === 'ROLE_ADMIN') {
    return 'Admin'
  } else if (role === 'ROLE_USER') {
    return 'User'
  } else if (role === 'ROLE_VENDOR') {
    return 'Vendor'
  }
} 
