import {
  CLEAR_STATE,
  GET_AUDITS,
  GET_AUDITS_FAIL,
  GET_AUDITS_SUCCESS
} from "./actionTypes"

export const clearState = () => ({
  type: CLEAR_STATE
})

export const getAudits = (page, size) => ({
  type: GET_AUDITS,
  payload: { page, size }
})

export const getAuditsSuccess = contentPage => ({
  type: GET_AUDITS_SUCCESS,
  payload: { contentPage },
})

export const getAuditsFail = error => ({
  type: GET_AUDITS_FAIL,
  payload: error,
})
