import axios from "axios"
import { isEmpty } from "lodash"
import store from "../store"


let serverURL = window.Configs.REACT_APP_SERVER_URL;
const axiosApi = axios.create({
  baseURL: serverURL
  //baseURL: process.env.REACT_APP_SERVER_URL,
})

// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )

export function setToken() {
  let authUser = store.getState().Login.authUser
  if (!isEmpty(authUser)) {
    const obj = JSON.parse(authUser)
    axiosApi.defaults.headers.common["Authorization"] = "Bearer " + obj.token
  } else {
    delete axiosApi.defaults.headers.common["Authorization"];
  }
}

export async function get(url, config = {}) {
  setToken()
  return await axiosApi.get(url, config)
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  setToken()
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  setToken()
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  setToken()
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export function upload(url, formData, onUploadProgress) {
  setToken()
  return axiosApi.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
  });
}

export function setupInterceptors(errorHandler) {
  axiosApi.interceptors.response.use(
    response => { return response; },
    errorHandler
  );
}