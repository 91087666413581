import { Fragment, Component } from "react"
import { compose } from 'redux';
import { connect } from "react-redux"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { withRouter, Link, Redirect } from "react-router-dom"
import { withCookies } from 'react-cookie';

//i18n
import { withTranslation } from "react-i18next"

import { isEmpty, isEqual } from 'lodash'
import { getUserProfile, logoutUser } from "store/actions";

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      userProfile: {},
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  componentDidMount() {
    const { userProfile } = this.props
    this.setState({ userProfile })
    if (isEmpty(userProfile)) {
      console.log("componentDidMount==(userProfile=null)==>onGetUserProfile")
      this.props.onGetUserProfile()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userProfile } = this.props
    if (!isEqual(prevProps.userProfile, userProfile)) {
      console.log("componentDidUpdate==>userProfile changed")
      this.setState({ userProfile })
    }
  }


  renderAvatar = () => {
    if (!isEmpty(this.state.userProfile.avatarBase64)) {
      return (<img
        className="rounded-circle header-profile-user"
        src={"data:image/png;base64," + this.state.userProfile.avatarBase64}
        alt="Header Avatar"
      />)
    } {
      return null
    }
  }

  logoutUser = () => {
    this.props.onLogoutUser(this.props.history, this.props.cookies)
  }

  render() {
    if (isEmpty(this.props.userProfile)) {
      return <Redirect to="/login" />
    }
    return (
      <Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            {this.renderAvatar()}
            <span className="d-none d-xl-inline-block ml-2 mr-1">
              {this.state.userProfile.login}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag={Link} to="/profile">
              <i className="bx bx-user font-size-16 align-middle mr-1" />
              {this.props.t("Profile")}
            </DropdownItem>
            {/* <DropdownItem tag="a" href="#">
              <span className="badge badge-success float-right mt-1">5</span>
              <i className="bx bx-wrench font-size-17 align-middle mr-1" />
              {this.props.t("Settings")}
            </DropdownItem>
            <DropdownItem tag="a" href="auth-lock-screen">
              <i className="bx bx-lock-open font-size-16 align-middle mr-1" />
              {this.props.t("Lock screen")}
            </DropdownItem> */}
            <div className="dropdown-divider" />
            <Link to="/login" onClick={() => this.logoutUser()} className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
              <span>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </Fragment>
    );
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any
}

const mapStateToProps = ({ Login }) => ({
  userProfile: Login.userProfile,
})

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
  onLogoutUser: (history, cookies) =>dispatch(logoutUser(history, cookies))
})

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(withCookies(ProfileMenu)))
