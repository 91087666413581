import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SECURE_ELEMENTS,
  GET_SECURE_ELEMENT_DETAILS,
  CREATE_SECURE_ELEMENT,
  UPDATE_SECURE_ELEMENT
} from "./actionTypes"

import {
  getSecureElementsSuccess,
  getSecureElementsFail,
  getSecureElementDetailsSuccess,
  getSecureElementDetailsFail,
  createSecureElementSuccess,
  createSecureElementFail,
  updateSecureElementSuccess,
  updateSecureElementFail
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getSecureElements,
  getSecureElementDetails,
  createSecureElement,
  updateSecureElement
} from "helpers/backend_helper"

function* fetchSecureElements({ payload }) {
  try {
    const response = yield call(getSecureElements, {
      params: {
        page: payload.page,
        size: payload.size,
        sortField: payload.sortField,
        sortOrder: payload.sortOrder,
        search: payload.search
      }
    })
    yield put(getSecureElementsSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getSecureElementsFail(error.response.data.message))
    } else {
      yield put(getSecureElementsFail(error.message))
    }
  }
}

function* fetchSecureElementDetail({ deviceId }) {
  try {
    const response = yield call(getSecureElementDetails, deviceId)
    yield put(getSecureElementDetailsSuccess(response))
  } catch (error) {
    yield put(getSecureElementDetailsFail(error))
  }
}

function* onCreateSecureElement({ payload }) {
  try {
    const response = yield call(createSecureElement, payload)
    yield put(createSecureElementSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(createSecureElementFail(error.response.data))
    } else {
      yield put(createSecureElementFail(error.message))
    }
  }
}

function* onUpdateSecureElement({ payload }) {
  try {
    const response = yield call(updateSecureElement, payload)
    yield put(updateSecureElementSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(updateSecureElementFail(error.response.data))
    } else {
      yield put(updateSecureElementFail(error.message))
    }
  }
}

function* secureElementSaga() {
  yield takeEvery(GET_SECURE_ELEMENTS, fetchSecureElements)
  yield takeEvery(GET_SECURE_ELEMENT_DETAILS, fetchSecureElementDetail)
  yield takeEvery(CREATE_SECURE_ELEMENT, onCreateSecureElement)
  yield takeEvery(UPDATE_SECURE_ELEMENT, onUpdateSecureElement)
}

export default secureElementSaga