import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Col, Row } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { getSecureBuilds, clearActionResult } from "store/secureBuild/actions"
import SecureBuildColumns from "./SecureBuildColumns"
import SecureBuildDetails from "./SecureBuildDetails"
import SecureBuildAuditListing from "./SecureBuildAuditListing"
import { GET_SECURE_BUILDS } from "store/secureBuild/actionTypes"
import { RESULT_OK, RESULT_ERROR } from "store/baseActionTypes"

class SecureBuildListing extends Component {

  constructor(props) {
    super(props)
    this.state = {
      secureBuildPage: {
        currentPage: 0,
        totalPages: 0,
        pageSize: 10,
        totalItems: 0,
        devices: []
      },
      sortField: '',
      sortOrder: '',
      search: '',
      actionResult: undefined,
      forceReload: false,
      deviceDetails: undefined,
      deviceAudit: undefined
    }
  }

  componentDidMount() {
    const { onGetSecureBuilds } = this.props
    onGetSecureBuilds(this.state.secureBuildPage.currentPage, this.state.secureBuildPage.pageSize, this.state.sortField, this.state.sortOrder, this.state.search)
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGetSecureBuilds } = this.props
    const { secureBuildPage, sortField, sortOrder, search, forceReload } = this.state

    if (secureBuildPage.currentPage !== prevState.secureBuildPage.currentPage
        || secureBuildPage.pageSize !== prevState.secureBuildPage.pageSize
        || sortField !== prevState.sortField
        || sortOrder !== prevState.sortOrder
        || search !== prevState.search) {
      onGetSecureBuilds(secureBuildPage.currentPage, secureBuildPage.pageSize, sortField, sortOrder, search);
      return;
    }
    if (forceReload === true) {
      secureBuildPage.currentPage = 0; // reset to first page
      onGetSecureBuilds(secureBuildPage.currentPage, secureBuildPage.pageSize, sortField, sortOrder, search);
      this.setState({
        forceReload: false,
        secureBuildPage
      })
      return;
    }

    if (this.props.secureBuildPage !== undefined && this.props.secureBuildPage.devices !== undefined && this.props.secureBuildPage !== prevProps.secureBuildPage) {
      this.setState({
        secureBuildPage: this.props.secureBuildPage
      })
      return;
    }
    if (this.props.actionResult !== prevProps.actionResult) {
      this.setState({
        actionResult: this.props.actionResult
      })
      return;
    }
  }

  onPageChange = (page, sizePerPage) => {
    let secureBuildPage = Object.assign({}, this.state.secureBuildPage);
    secureBuildPage.currentPage = page - 1;
    this.setState({
      secureBuildPage
    })
  }

  onSizePerPageChange = (page, sizePerPage) => {
    let secureBuildPage = Object.assign({}, this.state.secureBuildPage);
    secureBuildPage.currentPage = 0;
    secureBuildPage.pageSize = sizePerPage;
    this.setState({
      secureBuildPage
    })
  }

  onSort = (field, order) => {
    let secureBuildPage = Object.assign({}, this.state.secureBuildPage);
    secureBuildPage.currentPage = 0;
    this.setState({
      sortField: field,
      sortOrder: order,
      secureBuildPage
    })
  }

  handleTableChange = (type, { page, searchText }) => {
    if (type === 'search') {
      let secureBuildPage = Object.assign({}, this.state.secureBuildPage);
      secureBuildPage.currentPage = 0;
      this.setState({
        search: searchText,
        secureBuildPage
      })
    }
  }

  showConfirmDeleteDevice = (row) => {
  }

  showDeviceDetails = (row) => {
    this.setState({
      deviceDetails: row
    });
  }

  showAudits = (row) => {
    this.setState({
      deviceAudit: row
    });
  }

  onCloseResultDlg = (reloadPage) => {
    this.props.onClearActionResult();
    if (reloadPage === true) {
      this.setState({
        forceReload: true
      })
    }
  }

  renderActionResultDlg = () => {
    let { actionResult } = this.state
    let reloadPage = false
    if (actionResult !== undefined) {
      let error = false
      let title = ''
      let message = ''
      if (actionResult.action === GET_SECURE_BUILDS) {
          if (actionResult.result === RESULT_OK) {
            return null
          } else if (actionResult.result === RESULT_ERROR) {
            error = true
            title = "Failed to fetch devices"
            message = actionResult.error
          }
      }
      return <SweetAlert
        error={error}
        success={!error}
        title={title}
        onConfirm={() => this.onCloseResultDlg(reloadPage)}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  renderShowDeviceDetails = () => {
    const { deviceDetails } = this.state;
    if (deviceDetails !== undefined) {
      return <SecureBuildDetails
        device={deviceDetails}
        onClosed={() => {
          this.setState({
            deviceDetails: undefined
          });
        }}
      >
      </SecureBuildDetails>
    }
    return null;
  }

  renderShowDeviceAudits = () => {
    const { deviceAudit } = this.state;
    if (deviceAudit !== undefined) {
      return <SecureBuildAuditListing
        secureBuild={deviceAudit}
        onClosed={() => {
          this.setState({
            deviceAudit: undefined
          });
        }}
      >
      </SecureBuildAuditListing>
    }
    return null;
  }

  render() {
    const { secureBuildPage } = this.state
    const pageOptions = {
      page: secureBuildPage.currentPage + 1, //SpringData: Page number values start with 0.
      sizePerPage: secureBuildPage.pageSize,
      totalSize: secureBuildPage.totalItems,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
      onSizePerPageChange: this.onSizePerPageChange,
    }
    function customMatchFunc({
      searchText,
      value,
    }) {
      if (typeof value !== 'undefined') {
        return value.startsWith(searchText);
      }
      return false;
    }

    return (
      <Fragment >
        <div>
          {this.renderActionResultDlg()}
          {this.renderShowDeviceDetails()}
          {this.renderShowDeviceAudits()}
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={secureBuildPage.devices || []}
                columns={SecureBuildColumns(this.showDeviceDetails, this.showAudits, this.onSort)}
                bootstrap4
                search={{ customMatchFunc }}
              >
                {toolkitProps => (
                  <Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box mr-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Search.SearchBar
                              {...toolkitProps.searchProps}
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div>
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            remote
                            hover={true}
                            bordered={false}
                            striped={false}
                            classes={
                              "table table-centered table-nowrap"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={this.handleTableChange}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </div>
      </Fragment >
    );
  }
}

SecureBuildListing.propTypes = {
  actionResult: PropTypes.object,
  secureBuildPage: PropTypes.object,
  onGetSecureBuilds: PropTypes.func,
  onClearActionResult: PropTypes.func,
}

const mapStateToProps = ({ secureBuildReducer }) => ({
  secureBuildPage: secureBuildReducer.secureBuildPage,
  actionResult: secureBuildReducer.actionResult,
})
const mapDispatchToProps = dispatch => ({
  onGetSecureBuilds: (page, size, sortField, sortOrder, search) => dispatch(getSecureBuilds(page, size, sortField, sortOrder, search)),
  onClearActionResult: () => dispatch(clearActionResult()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecureBuildListing)