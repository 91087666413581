import { AvField } from "availity-reactstrap-validation"

export const ModuleNameField = (value, onChange) => {
    return <div className="form-group">
        <AvField 
            type="select" 
            name="moduleName" 
            label="Module" 
            value={value}
            onChange={onChange}>
          <option>General</option>
          <option>Secure Build</option>
          <option>Key provisioning</option>
        </AvField>
    </div>
} 

export const NameField = (value, onChange) => {
    return <div className="form-group">
        <AvField
            name="name"
            id="name"
            label="Name"
            value={value}
            onChange={onChange}
            className="form-control"
            placeholder="Enter Name"
            type="text"
            errorMessage="Invalid Name"
            validate={{
                required: { value: true },
                pattern: { value: '^(?![0-9])[a-zA-Z0-9$_.]+$' },
                minLength: { value: 1 },
                maxLength: { value: 50 }
            }} />
    </div>
}
export const DescField = (value, onChange) => {
    return <div className="form-group">
        <AvField
            name="description"
            id="description"
            label="Description"
            value={value}
            onChange={onChange}
            className="form-control"
            placeholder="Enter Description"
            type="text"
            errorMessage="Max length is 64 characters"
            validate={{
                maxLength: { value: 64 }
            }} />
    </div>
}
export const ValueField = (value, onChange) => {
    return <div className="form-group">
        <AvField
            name="value"
            id="value"
            label="Value"
            value={value}
            onChange={onChange}
            className="form-control"
            placeholder="Enter Value"
            type="text"
            errorMessage="Min length is 1 character. Max length is 64 characters"
            validate={{
                required: { value: true },
                minLength: { value: 1 },
                maxLength: { value: 250 }
            }} />
    </div>
}
export const ModifiedByField = (value) => {
    return <div className="form-group">
        <AvField
            name="modifiedby"
            id="modifiedby"
            label="Modified By"
            value={value}
            className="form-control"
            type="text"
            readOnly={true}
            />
    </div>
}
export const ModifiedDateField = (value) => {
    return <div className="form-group">
        <AvField
            name="modifieddate"
            id="modifieddate"
            label="Modified Date"
            value={value}
            className="form-control"
            type="text"
            readOnly={true}
            />
    </div>
}
export const OldValueField = (value) => {
    return <div className="form-group">
        <AvField
            name="oldvalue"
            id="oldvalue"
            label="Old Value"
            value={value}
            className="form-control-plaintext"
            type="text"
            readOnly={true}
            />
    </div>
}