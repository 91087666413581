import { TabContent, TabPane, Nav, NavItem, NavLink, CardBody, Button, Row, Col, CardFooter } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from "prop-types";
import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import { renderVersionDetails } from "./trn-helper"
import TrnAuditPopup from "./TrnAuditPopup"
import { selectForProvisioning } from 'store/actions';
import SweetAlert from "react-bootstrap-sweetalert";
import {isEmpty} from "lodash"
import {SELECT_FOR_PROVISIONING, RESULT_OK} from 'store/trns/actionTypes'

class TrnDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: "tab-1",
            versionClicked: {},

            showViewAuditPopup: false,
            trnViewAudit: {},
            trnVerViewAudit: {},
            trn: props.trn,
            selectedVerIdForProvisioning: -1,
            reverse: false,
        }
    }

    toggle = tab => {
        const { activeTab } = this.state
        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    renderNavItem = (ver, tab) => {
        const { activeTab } = this.state
        return (
            <NavItem key={"ni-" + tab}>
                <NavLink key={"nl-" + tab}
                    className={classnames({ active: activeTab === tab })}
                    onClick={() => { this.toggle(tab); }}
                >
                    <div>{ver.trnVersion} {ver.selectedProvisioning?(<div className="fc-daygrid-event-dot-green"/>):(<div className="fc-daygrid-event-dot-gray"/>)}</div>
                </NavLink>
            </NavItem >
        )
    }

    onViewAudit = (trn, ver) => {
        this.setState({showViewAuditPopup: true, trnViewAudit: trn, trnVerViewAudit: ver})
    }

    selectForProvisioning = (selectedVerIdForProvisioning, reverse) => {
        this.setState({selectedVerIdForProvisioning, reverse, show_confirm_selectForProv: true})
    }

    renderConfirmSelectForProvisioning = () => {
        if (this.state.show_confirm_selectForProv) {
            let title = this.state.reverse ? 'Are you sure you want to unselect this version from provisioning' : ' Are you sure you want to select this version for provisioning?'
            return <SweetAlert
              title={title}
              warning
              showCancel
              confirmButtonText="Yes"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              closeOnClickOutside={false}
              onConfirm={() => {
                this.props.onSendAction_SelectForProvisioning(this.state.selectedVerIdForProvisioning)
                this.setState({
                    show_confirm_selectForProv: false,
                    selectedVerIdForProvisioning: -1
                })
              }
              }
              onCancel={() =>
                this.setState({
                    show_confirm_selectForProv: false,
                    selectedVerIdForProvisioning: -1
                })
              }
            >
            </SweetAlert>;
          }
          return null;
    }

    renderTabPane = (trn, ver, tab, onGoBack) => {
        return (
            <TabPane key={"tp-" + tab} tabId={tab} >
                <Row>
                    <Col sm="12">
                        <CardBody>
                            { renderVersionDetails(trn, ver, this.onViewAudit, this.selectForProvisioning) }
                            { this.renderConfirmSelectForProvisioning() }
                            <CardFooter align="right">
                                <Button className="btn btn-secondary mr-2" onClick={() => onGoBack()}>Cancel</Button>
                            </CardFooter>
                        </CardBody>
                    </Col>
                </Row>
            </TabPane >
        )
    }

    onToggleViewAudit = () => {
        this.setState(prevState => ({
            showViewAuditPopup: !prevState.showViewAuditPopup,
        }))
    }

    renderViewAuditDlg() {
        if (this.state.showViewAuditPopup === true) {
            return <TrnAuditPopup 
                        modal = {this.state.showViewAuditPopup}
                        onToggle = {this.onToggleViewAudit} 
                        trnVerViewAudit = {this.state.trnVerViewAudit}
                        trnViewAudit = {this.state.trnViewAudit}
                         />
        } else return null
    }

    componentDidUpdate(prevProps, prevState) {
        const { trn } = this.state
        const { actionResult, selectedVerId } = this.props;
        if (prevProps != this.props && !isEmpty(actionResult)) {
            if (actionResult.action === SELECT_FOR_PROVISIONING && actionResult.result === RESULT_OK) {
                trn.ProdTrnsList = trn.prodVersions.map(
                    (ver) => {
                      if (ver.id === selectedVerId) {
                        ver.selectedProvisioning = !ver.selectedProvisioning
                      }
                      return ver;
                    }
                  )
                // update cached TRN
                this.setState({trn: trn})
            }
        }
    }

    render() {
        const { onGoBack } = this.props
        const { trn } = this.state
        let versions = trn.prodVersions
        const { activeTab } = this.state
        return (
            <Fragment >
                { this.renderViewAuditDlg() }
                < Col >
                    <div>
                        <Nav tabs>
                            {
                                versions.map((ver, index) => {
                                    return this.renderNavItem(ver, "tab-" + (index + 1))
                                })
                            }
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {
                                versions.map((ver, index) => {
                                    return this.renderTabPane(trn, ver, "tab-" + (index + 1), onGoBack)
                                })
                            }
                        </TabContent>
                    </div>
                </ Col>
            </Fragment>
        )
    }
}

TrnDetails.propTypes = {
    trn: PropTypes.object,
    onGoBack: PropTypes.func,
    onSelectForProvisioning: PropTypes.func
}

const mapStateToProps = ({ trnVersionReducer }) => ({
    actionResult: trnVersionReducer.actionResult,
    selectedVerId: trnVersionReducer.contentPage,
  })

const mapDispatchToProps = dispatch => ({
    onSendAction_SelectForProvisioning: (id) => dispatch(selectForProvisioning(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrnDetails)
  