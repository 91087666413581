import { AvField } from "availity-reactstrap-validation"
import { isEmpty } from "lodash"
import noimage from "../../../assets/images" 
import { ValidationPatterns } from "../../../common/ValidationPatterns"

export const emailControl = (value, onChange, readOnly) => {
    return <div className="form-group">
        <AvField
            name="email"
            id="email"
            label="Email"
            type="email"
            readOnly={readOnly}
            className="form-control"
            placeholder="Enter Email"
            errorMessage="Invalid Email"
            required
            minLength="5"
            maxLength="30"
            value={value}
            onChange={onChange} />
    </div>
}

export const firstNameControl = (value, onChange) => {
    return <div className="form-group">
        <AvField
            name="firstName"
            id="firstName"
            label="First Name"
            value={value}
            onChange={onChange}
            className="form-control"
            placeholder="Enter First Name"
            type="text"
            errorMessage="Invalid First Name"
            validate={{
                required: { value: true },
                pattern: { value: '^[A-Za-z0-9]+$' },
                minLength: { value: 1 },
                maxLength: { value: 25 }
            }} />
    </div>
}

export const lastNameControl = (value, onChange) => {
    return <div className="form-group">
        <AvField
            name="lastName"
            id="lastName"
            label="Last Name"
            value={value}
            onChange={onChange}
            className="form-control"
            placeholder="Enter Last Name"
            type="text"
            errorMessage="Invalid Last Name"
            validate={{
                pattern: { value: '^[A-Za-z0-9]+$' },
                maxLength: { value: 25 }
            }} />
    </div>
}

export const usernameControl = (value, onChange, readOnly) => {
    return <div className="form-group">
        <AvField
            name="username"
            id="username"
            label="Username"
            value={value}
            onChange={onChange}
            className="form-control"
            placeholder="Enter Username"
            type="text"
            readOnly={readOnly}
            errorMessage="Invalid Username"
            autoComplete="username"
            validate={{
                required: { value: true },
                pattern: {value: '^[A-Za-z0-9-]+$'},
                minLength: { value: 1 },
                maxLength: { value: 25 }
            }} />
    </div>
}

export const passwordControl = (id, label, value, onChange) => {
    return <div className="form-group">
        <AvField
            name={id}
            id={id}
            label={label}
            type="password"
            className="form-control"
            placeholder="Enter Password"
            errorMessage="Invalid Password"
            autoComplete="new-password"
            required
            value={value}
            onChange={onChange}
            validate={{
                pattern: {value: ValidationPatterns.password,
                          errorMessage: ValidationPatterns.passwordErrMsg}
                        }}/>
    </div>
}

export const strongPasswordControl = (id, label, value, onChange) => {
    return <div className="form-group">
        <AvField
            name={id}
            id={id}
            label={label}
            type="password"
            className="form-control"
            placeholder="Enter Password"
            errorMessage="Invalid Password"
            autoComplete="new-password"
            required
            value={value}
            onChange={onChange}
            validate={{
                pattern: {value: ValidationPatterns.passwordStrong,
                         errorMessage: ValidationPatterns.passwordStrongErrMsg},
            }} />
    </div>
}

export const passwordConfirmControl = (label) => {
    return <div className="form-group">
        <AvField
            name="passwordConfirm"
            id="passwordConfirm"
            label={label}
            type="password"
            className="form-control"
            placeholder="Enter Password"
            errorMessage="Invalid Password"
            autoComplete="new-password"
            required
            validate={{ match: { value: 'password' } }} />
    </div>
}

export const getImage = (avatarBase64) => {
    return (avatarBase64 !== null && avatarBase64 !== '')?("data:image/png;base64," + avatarBase64):noimage
  }

export const previewAvatarControl = (avatarBase64) => {
    return <div className="form-group">
        <label htmlFor="avatar">&nbsp;</label>
        <div className="avatar-md profile-user-wid mb-4">
            <span className="avatar-title rounded-circle bg-light">
                <img
                    id="AvatarPreview" src={getImage(avatarBase64)}
                    alt=""
                    className="avatar-md rounded-circle img-thumbnail"
                    height="60" />
            </span>
        </div>
    </div>
}

export const avatarControl = (onChange) => {
    return <div className="form-group">
        <label htmlFor="avatar">Avatar</label>
        <div className="custom-file">
            <input
                type="file"
                className="custom-file-input"
                id="avatar"
                onChange={onChange} />
            <label className="custom-file-label" htmlFor="avatar">
                Choose photo
        </label>
        </div>
    </div>
}

export const roleControl = (value, onChange, userLoginRole, readOnly) => {
    if (userLoginRole === "ROLE_SUPER_ADMIN") {
        return <div className="form-group">
            <AvField type="select" name="role" id="role" label="Role"
                required
                value={isEmpty(value) ? "" : value}
                onChange={onChange}
                disabled={readOnly}
                errorMessage="Invalid Role">
                <option value="">&lt;Select&gt;</option>
                <option value="ROLE_SUPER_ADMIN">Super Admin</option>
                <option value="ROLE_ADMIN">Admin</option>
                <option value="ROLE_USER">User</option>
                <option value="ROLE_VENDOR">Vendor</option>
            </AvField>
        </div>
    } else {
        return <div className="form-group">
            <AvField type="select" name="role" id="role" label="Role"
                required
                value={isEmpty(value) ? "" : value}
                onChange={onChange}
                disabled={readOnly}
                errorMessage="Invalid Role">
                <option value="">&lt;Select&gt;</option>
                <option value="ROLE_ADMIN">Admin</option>
                <option value="ROLE_USER">User</option>
                <option value="ROLE_VENDOR">Vendor</option>
            </AvField>
        </div>
    }
}
