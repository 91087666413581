
export const POST_JWT_LOGIN = "/authenticate"
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"

export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//USERS
export const GET_USERS = "/users"
export const ADD_USER = "/users"
export const EDIT_USER = "/users"
export const DELETE_USER = "/users"
export const RESET_PWD = "/users"
export const CHANGE_PWD = "/users"
export const GET_USER_PROFILE = "/profile"
export const GET_TRNS = "/trns"
export const GET_REJECTED_TRNS = "/rejectedtrns"
export const SEARCHING_TRNS = "/trns/searching"
export const MOVE_TRN_VERSION = "/trns/versions"
export const SELECT_FOR_PROVISIONING = "/trns/versions"
export const GET_LOGINS = "/logins"
export const GET_EXCEPTIONS = "/exceptions"
export const POST_PASSWORD_FORGET = "/forgotpassword"

//CONFIGURATIONS
export const GET_CONFS = "/configurations"
export const ADD_CONF = "/configurations"
export const EDIT_CONF = "/configurations"
export const DELETE_CONF = "/configurations"

// AUDITS
export const GET_AUDITS = "/audits"

// REPORTS
export const GET_REPORTS = "/reports"

// EDGE DEVICE
export const GET_EDGE_DEVICES = "/EdgeDevice"
export const GET_EDGE_DEVICE_DETAILS = "/EdgeDevice"
export const CREATE_EDGE_DEVICE = "/EdgeDevice"

// EDGE DEVICE BETA
export const GET_BETA_EDGE_DEVICES = "/EdgeDeviceBeta"
export const GET_BETA_EDGE_DEVICE_DETAILS = "/EdgeDeviceBeta"
export const CREATE_BETA_EDGE_DEVICE = "/EdgeDeviceBeta"

// EDGE DEVICE SESSION
export const GET_EDGE_DEVICE_SESSION = "/EdgeDeviceSession"
export const INIT_EDGE_DEVICE_SESSION = "/EdgeDeviceSession"
export const EXCHANGE_EDGE_DEVICE_SESSION = "/EdgeDeviceSession"

// SECURE BUILD DISCOVERY
export const SECURE_BUILD_DISCOVERY = "/SecureBuildDiscovery"

// SAM DISCOVERY
export const SAM_DISCOVERY = "/SamDiscovery"

// HSM INFO
export const GET_HSM_INFO = "/HsmInfo"

// KEYSET
export const KEYSET = "/Keyset"

// SECURE ELEMENT
export const SECURE_ELEMENT = "/SecureElement"

// SECURE ELEMENT SESSION
export const SECURE_ELEMENT_SESSION = "/SecureElementSession"

// SECURE BUILD
export const SECURE_BUILD = "/SecureBuild"

// SECURE BUILD SESSION
export const SECURE_BUILD_SESSION = "/SecureBuildSession"

// SECURE BUILD AUDIT
export const SECURE_BUILD_AUDIT = "/SecureBuildAudit"

// SECURE ELEMENT AUDIT
export const SECURE_ELEMENT_AUDIT = "/SecureElementAudit"

// TRN UPLOAD
export const TRN_UPLOAD = "/TrnUpload"
