import { toDatetimeString } from "../../../helpers/app_helper"

const ReportColumns = (trnTypeMode) => {
  return [
    {
      text: "Device ID",
      dataField: "deviceId",
      headerStyle: (colum, colIndex) => {
        return { width: '120px'};
      },
    },
    {
      text: "SAM Serial",
      dataField: "samSerial",
      headerStyle: (colum, colIndex) => {
        return { width: '300px', textAlign: 'center' };
      },
    },
    {
      text: "TRN/Version",
      dataField: "trn",
      formatter: (cellContent, row) => (
        <>
          <span className="ml-2 mr-1" style={ { "overflow-wrap": "break-word", "white-space": "normal" }}>
          {row.trn} / {row.version}
          </span>
        </>
      ),
    },
    {
      text: "Status",
      dataField: "status",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { width: '150px', textAlign: 'center' };
      },
      formatter: (cellContent, row) => (
        <>
          <div>
            <span className={"ml-2 badge " + (row.samProvisioned ? "badge-soft-success" : "badge-soft-danger")}>
              {row.samStatus}
            </span>
            <br/>
            <span className={"ml-2 badge " + (row.firmwareProvisioned ? "badge-soft-success" : "badge-soft-danger")}>
              {row.firmwareStatus}
            </span>
          </div>
        </>
      )
    },
    {
      text: "Datetime",
      dataField: "datetime",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { width: '180px', textAlign: 'center' };
      },
      formatter: (cellContent, row) => (
        <>
          <span className="ml-2 mr-1">
            {toDatetimeString(new Date(row.datetime))}
          </span>
        </>
      ),
    },
    {
      text: "Enroller",
      dataField: "enroller",
      headerStyle: (colum, colIndex) => {
        return { width: '100px'};
      },
    },
  ]
}
export default ReportColumns
