import {
  CLEAR_STATE, CLEAR_ACTION_RESULT, GET_LOGINS, GET_LOGINS_FAIL, GET_LOGINS_SUCCESS, RESULT_ERROR, RESULT_OK
} from "./actionTypes"

const INIT_STATE = {
  contentPage: {},
  actionResult: {},
}

const loginsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_LOGINS_SUCCESS:
      {
        return {
          ...state,
          contentPage: action.payload.contentPage,
          actionResult: { action: GET_LOGINS, result: RESULT_OK, error: '' },
        }
      }
    case GET_LOGINS_FAIL: {
      return {
        ...state,
        contentPage: {},
        actionResult: { action: GET_LOGINS, result: RESULT_ERROR, error: action.payload },
      }
    }
    case CLEAR_STATE:
      {
        return {
          ...INIT_STATE,
        }
      }
    case CLEAR_ACTION_RESULT:
      {
        return {
          ...state,
          actionResult: {},
        }
      }

    default:
      return state
  }
}

export default loginsReducer
