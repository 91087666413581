import {
  CLEAR_STATE,
  GET_CONFS,
  GET_CONFS_FAIL,
  GET_CONFS_SUCCESS,
  ADD_CONF,
  ADD_CONF_SUCCESS,
  ADD_CONF_FAIL,
  EDIT_CONF,
  EDIT_CONF_SUCCESS,
  EDIT_CONF_FAIL,
  DELETE_CONF,
  DELETE_CONF_SUCCESS,
  DELETE_CONF_FAIL,
} from "./actionTypes"


export const clearState = () => ({
  type: CLEAR_STATE
})

export const getConfs = (page, size, sortField, sortOrder, search) => ({
  type: GET_CONFS,
  payload: { page, size, sortField, sortOrder, search }
})

export const getConfsSuccess = (contentPage) => ({
  type: GET_CONFS_SUCCESS,
  payload: { contentPage }
})

export const getConfsFail = error => ({
  type: GET_CONFS_FAIL,
  payload: error,
})

export const addConf = (conf) => ({
  type: ADD_CONF,
  payload: conf,
})

export const addConfSuccess = conf => ({
  type: ADD_CONF_SUCCESS,
  payload: conf,
})

export const addConfFail = error => ({
  type: ADD_CONF_FAIL,
  payload: error,
})

export const editConf = (conf) => ({
  type: EDIT_CONF,
  payload: conf,
})

export const editConfSuccess = (conf) => ({
  type: EDIT_CONF_SUCCESS,
  payload: conf,
})

export const editConfFail = (error) => ({
  type: EDIT_CONF_FAIL,
  payload: error,
})

export const deleteConf = (id) => ({
  type: DELETE_CONF,
  payload: id,
})

export const deleteConfSuccess = (id) => ({
  type: DELETE_CONF_SUCCESS,
  payload: id,
})

export const deleteConfFail = (error) => ({
  type: DELETE_CONF_FAIL,
  payload: error,
})