import {
  GET_KEYSETS,
  GET_KEYSETS_FAIL,
  GET_KEYSETS_SUCCESS,
  GET_KEYSET_DETAILS,
  GET_KEYSET_DETAILS_FAIL,
  GET_KEYSET_DETAILS_SUCCESS,
  CREATE_KEYSET,
  CREATE_KEYSET_FAIL,
  CREATE_KEYSET_SUCCESS,
  UPDATE_KEYSET,
  UPDATE_KEYSET_FAIL,
  UPDATE_KEYSET_SUCCESS,
  DELETE_KEYSET,
  DELETE_KEYSET_FAIL,
  DELETE_KEYSET_SUCCESS,
  MAKE_DEFAULT_KEYSET,
  MAKE_DEFAULT_KEYSET_FAIL,
  MAKE_DEFAULT_KEYSET_SUCCESS,
  CLEAR_ACTION_RESULT
} from "./actionTypes"

export const clearActionResult = () => ({
  type: CLEAR_ACTION_RESULT,
})

export const getKeysets = (page, size, sortField, sortOrder, type, search) => ({
  type: GET_KEYSETS,
  payload: { page, size, sortField, sortOrder, type, search }
})

export const getKeysetsSuccess = (keysetPage) => ({
  type: GET_KEYSETS_SUCCESS,
  payload: keysetPage
})

export const getKeysetsFail = error => ({
  type: GET_KEYSETS_FAIL,
  payload: error,
})

export const getKeysetDetails = () => ({
  type: GET_KEYSET_DETAILS,
})

export const getKeysetDetailsSuccess = keyset => ({
  type: GET_KEYSET_DETAILS_SUCCESS,
  payload: keyset,
})

export const getKeysetDetailsFail = error => ({
  type: GET_KEYSET_DETAILS_FAIL,
  payload: error,
})

export const createKeyset = (keyset) => ({
  type: CREATE_KEYSET,
  payload: keyset,
})

export const createKeysetSuccess = keyset => ({
  type: CREATE_KEYSET_SUCCESS,
  payload: keyset,
})

export const createKeysetFail = error => ({
  type: CREATE_KEYSET_FAIL,
  payload: error,
})

export const updateKeyset = (keyset) => ({
  type: UPDATE_KEYSET,
  payload: keyset,
})

export const updateKeysetSuccess = keyset => ({
  type: UPDATE_KEYSET_SUCCESS,
  payload: keyset,
})

export const updateKeysetFail = error => ({
  type: UPDATE_KEYSET_FAIL,
  payload: error,
})

export const makeDefaultKeyset = (id) => ({
  type: MAKE_DEFAULT_KEYSET,
  payload: id,
})

export const makeDefaultKeysetSuccess = keyset => ({
  type: MAKE_DEFAULT_KEYSET_SUCCESS,
  payload: keyset,
})

export const makeDefaultKeysetFail = error => ({
  type: MAKE_DEFAULT_KEYSET_FAIL,
  payload: error,
})

export const deleteKeyset = (id) => ({
  type: DELETE_KEYSET,
  payload: id,
})

export const deleteKeysetSuccess = () => ({
  type: DELETE_KEYSET_SUCCESS,
  payload: undefined,
})

export const deleteKeysetFail = error => ({
  type: DELETE_KEYSET_FAIL,
  payload: error,
})
