import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types"
import {
    Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, Card,
} from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { renderVersionDetails } from "./trn-helper"
import { selectForProvisioning, clearState } from 'store/actions';
import SweetAlert from "react-bootstrap-sweetalert";
import {isEmpty} from "lodash"
import {SELECT_FOR_PROVISIONING, RESULT_OK} from 'store/trns/actionTypes'

class TrnVersionDetailsPopup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedVerIdForProvisioning: -1,
            show_confirm_selectForProv: false,
            versionClicked: props.versionClicked,
            reverse: false,
        }
    }

    selectForProvisioning = (selectedVerIdForProvisioning, reverse) => {
        this.setState({selectedVerIdForProvisioning, reverse, show_confirm_selectForProv: true})
    }

    renderConfirmSelectForProvisioning = () => {
        if (this.state.show_confirm_selectForProv) {
            let title = this.state.reverse ? 'Are you sure you want to unselect this version from provisioning' : ' Are you sure you want to select this version for provisioning?'
            return <SweetAlert
              title={title}
              warning
              showCancel
              confirmButtonText="Yes"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              closeOnClickOutside={false}
              onConfirm={() => {
                this.props.onSendAction_SelectForProvisioning(this.state.selectedVerIdForProvisioning)
                this.setState({
                    show_confirm_selectForProv: false,
                    selectedVerIdForProvisioning: -1,
                })
              }
              }
              onCancel={() =>
                this.setState({
                    show_confirm_selectForProv: false,
                    selectedVerIdForProvisionin: -1
                })
              }
            >
            </SweetAlert>;
          }
          return null;
    }

    componentDidUpdate(prevProps, prevState) {
        const { versionClicked } = this.state
        const { actionResult, selectedVerId } = this.props;
        if (prevProps != this.props) {
            if (versionClicked != this.props.versionClicked) {
                this.setState({versionClicked: this.props.versionClicked})
            }
            
            if (!isEmpty(actionResult) && actionResult.action === SELECT_FOR_PROVISIONING && actionResult.result === RESULT_OK
                    && selectedVerId === versionClicked.id) {
                versionClicked.selectedProvisioning = !versionClicked.selectedProvisioning
                this.setState({versionClicked: versionClicked})
                this.props.onUpdateVersionClicked(versionClicked)
                this.props.onSendAction_ClearState()
            }
        }
    }

    render() {
        const { trnClicked, modal, onToggle } = this.props
        const { versionClicked } = this.state
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    centered={true}
                    backdrop="static"
                    size="lg"
                >
                    <AvForm>
                        <ModalHeader toggle={onToggle} tag="h4">
                            Version details
                        </ModalHeader>
                        <ModalBody>
                            <Card body>
                                { renderVersionDetails(trnClicked, versionClicked, null, this.selectForProvisioning) }
                                { this.renderConfirmSelectForProvisioning() }
                            </Card>
                        </ModalBody>
                        <ModalFooter>
                            <Row>
                                <Col>
                                    <div className="text-right">
                                        <button
                                            type="button"
                                            className="btn btn-light mr-2"
                                            onClick={onToggle}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </Fragment>
        )
    }
}

TrnVersionDetailsPopup.propTypes = {
    modal: PropTypes.bool.isRequired,
    trnClicked: PropTypes.object.isRequired,
    versionClicked: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    onHandleValidEventSubmit: PropTypes.func.isRequired,
    onUpdateVersionClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ trnVersionReducer }) => ({
    actionResult: trnVersionReducer.actionResult,
    selectedVerId: trnVersionReducer.contentPage,
  })

const mapDispatchToProps = dispatch => ({
    onSendAction_SelectForProvisioning: (id) => dispatch(selectForProvisioning(id)),
    onSendAction_ClearState: ()=>dispatch(clearState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrnVersionDetailsPopup)