/*global chrome */
// No delete comment above, it will use for ESLint, else you cannot use 'chrome.runtime'
// https://eslint.org/docs/rules/no-undef
// Disallow Undeclared Variables (no-undef)

const edgeExtensionId = "ekniabknfgaehaokijpefjdfepemokbl"
const defaultConnectorKey = "DefaultEdge"
const extensionConnections = new Map()
const connectors = new Map()

const randomID = () => {
    const crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    crypto.getRandomValues(array);
    return '_' + array[0].toString(36).substr(2, 9);
}

const getConnector = (key) => {
    if (key === undefined || key === '') {
        key = defaultConnectorKey;
    }
    if (connectors.has(key)) {
        return connectors.get(key);
    }
    let id = randomID();
    connectors.set(key, createConnector(id));
    console.log("Opened connection by key: " + key);
    return connectors.get(key);
}

const disposeConnector = (key) => {
    if (key === undefined || key === '') {
        key = defaultConnectorKey;
    }
    if (connectors.has(key)) {
        connectors.get(key).closeConnection();
        connectors.delete(key);
        console.log("Disposed connection by key: " + key);
    }
}

const openConnection = (extensionId, id) => {
    let connection = undefined;
    if (extensionConnections.has(id)) {
        connection = extensionConnections.get(id);
        if (connection && connection.connected) {
            throw new Error("Duplicate id to connect extension: " + extensionId);
        }
        extensionConnections.delete(id);
    }
    connection = {};
    /* eslint-disable-rule no-undef */
    let port = chrome.runtime.connect(extensionId, { 'name': id });
    /* eslint-enable-rule no-undef */
    if (port) {
        console.debug("Extension Connected: " + id);

        connection.id = id;
        connection.port = port;
        connection.connected = true;
        connection.received = false;
        connection.currentMethod = '';
        
        connection.failure = function (connectionArg, error) {
            console.error("<- " + connectionArg.currentMethod);
            if (connectionArg.onFailure !== undefined) {
                connectionArg.onFailure(error);
            }
        }
        connection.success = function () {
            let connectionArg = arguments[0];
            console.debug("From native app: " + JSON.stringify(arguments[1]));
            if (connectionArg.onSuccess !== undefined) {
                connectionArg.onSuccess(arguments[1]);
            }
        }
        connection.invokeAsync = function() {
            let argsArray = Array.prototype.slice.call(arguments);
            this.currentMethod = argsArray[0];
            this.onSuccess = argsArray[1];
            this.onFailure = argsArray[2];
            let portArs = {"method": argsArray[0], "parameters": argsArray.slice(3)};
            console.debug("Send to native app: " + JSON.stringify(portArs));
            this.port.postMessage(portArs);
        }
        connection.closeConnection = function () {
            this.port.disconnect();
            extensionConnections.delete(this.id);
        }

        port.onDisconnect.addListener(function () {
            // arguments[0]: port sender
            let portId = arguments[0].name;
            let connectionById = extensionConnections.get(portId);
            if (connectionById.received) {
                connectionById.failure(connectionById, "Lost connection with extension");
            } else {
                connectionById.failure(connectionById, "Extension is not installed or is disabled");
            }
        });

        port.onMessage.addListener(function () {
            // arguments[0]: { method: "method", success: true/false, error="", data= [response data array]}
            // arguments[1]: port sender
            let portId = arguments[1].name;
            let connectionById = extensionConnections.get(portId);
            connectionById.received = true;
            let response = arguments[0];
            if (response && response.success) {
                connectionById.success(connectionById, response.data);
            } else {
                connectionById.failure(connectionById, response.error);
            }
        });
        
        extensionConnections.set(id, connection);
    } else {
        throw new Error("Cannot connect to extension.");
    }
    return connection;
}
const createConnector = (id) => {
    let edgeConnection = openConnection(edgeExtensionId , id);

    edgeConnection.connectTCP = function (serverAddress, port) {
        return new Promise((resolve, reject) => {
            this.invokeAsync('connectTCP', (data) => { resolve(data[0]); }, reject, serverAddress, port);
        });
    }
    edgeConnection.disconnectTCP = function () {
        return new Promise((resolve, reject) => {
            this.invokeAsync('disconnectTCP', (data) => { resolve(data[0]); }, reject);
        });
    }
    edgeConnection.exchangeTCP = function (msg) {
        return new Promise((resolve, reject) => {
            if (msg === null || msg === undefined || msg.length == 0) {
                reject("Required message for exchangeTCP");
            } else {
                let EXCHANGE_EOM = String.fromCharCode([255]);// EOM is FF byte
                let max = 256;
                if (msg.length <= max) {
                    // send a single message
                    this.invokeAsync('exchangeTCP', (data) => { resolve(data[0]); }, reject, msg + EXCHANGE_EOM);
                } else {
                    // need send a chain messages
                    var invokeNextMsg = function (connection, nextMsg, nextCallback) {
                        if (nextMsg.length > max) {
                            let partMsg = nextMsg.substring(0, max);
                            nextMsg = nextMsg.substring(max, nextMsg.length);
                            connection.invokeAsync('exchangeTCP', (data) => {
                                if (data[0] === true) {
                                    nextCallback(connection, nextMsg, nextCallback);
                                } else {
                                    reject("Required message for exchangeTCP");
                                }
                            }, reject, partMsg);
                        } else {
                            connection.invokeAsync('exchangeTCP', (data) => { resolve(data[0]); }, reject, nextMsg + EXCHANGE_EOM);
                        }
                    };
                    invokeNextMsg(this, msg, invokeNextMsg);
                }
            }
        });
    }
    edgeConnection.uploadTCP = function (link, packageSize, name, version, hash) {
        return new Promise((resolve, reject) => {
            this.invokeAsync('uploadTCP', (data) => { resolve(data[0]); }, reject, link, packageSize, name, version, hash);
        });
    }
    edgeConnection.uploadStatusTCP = function () {
        return new Promise((resolve, reject) => {
            this.invokeAsync('uploadStatusTCP', resolve, reject);
        });
    }
    edgeConnection.discoverySecureBuilds = function (broadcastAddress, port, message, waitingTime) {
        return new Promise((resolve, reject) => {
            this.invokeAsync('discoveryDeviceIP', resolve, reject, broadcastAddress, port, message, waitingTime);
        });
    }
    edgeConnection.discoveryUDPSams = function (broadcastAddress, port, message, waitingTime) {
        return new Promise((resolve, reject) => {
            this.invokeAsync('discoveryDeviceIP', resolve, reject, broadcastAddress, port, message, waitingTime);
        });
    }
    edgeConnection.discoveryMDNSSams = function (protocol, portFilter, serviceFilter, scanTime) {
        return new Promise((resolve, reject) => {
            this.invokeAsync('discoveryDeviceByMDNS', resolve, reject, protocol, portFilter, serviceFilter, scanTime);
        });
    }

    return edgeConnection;
}

// This function will validate a discovery device info text that received from native app host
// It only validate the format, it cannot decrypt the data
const validateDiscoveryDeviceText = (discoveryDeviceText, ipExpected) => {
    try {
        let discoveryDeviceJson = JSON.parse(discoveryDeviceText);
        // compare IP if expected an IP
        if (ipExpected !== undefined && ipExpected !== null && ipExpected.length > 0) {
            if (discoveryDeviceJson.Sender !== ipExpected) {
                return false;
            }
        }
        // validate Message
        // [PREFIX_CHAR] ['04'] [data_encrypted] [SUFFIX_CHAR]
        let message = discoveryDeviceJson.Message;
        if (message === undefined || message === null || (message.length % 2) !== 0 || message.length < 7) {
            return false;
        }
        // check PREFIX_CHAR
        let prefix = message.substring(0, 1);
        if (prefix !== String.fromCharCode([0x01])) {
            return false;
        }
        // check SUFFIX_CHAR
        let suffix = message.substring(message.length - 1);
        if (suffix !== String.fromCharCode([0x1F])) {
            return false;
        }
        // check COMM
        let comm = message.substring(1, 3);
        if (comm !== '04') {
            return false;
        }
        return true;
    } catch (error) {
        console.error("Cannot parse the discovery device info", error);
        return false;
    }
}
  

export {
  getConnector,
  disposeConnector,
  validateDiscoveryDeviceText
}