import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SECURE_BUILDS,
  GET_SECURE_BUILD_DETAILS,
  CREATE_SECURE_BUILD,
  UPDATE_SECURE_BUILD
} from "./actionTypes"

import {
  getSecureBuildsSuccess,
  getSecureBuildsFail,
  getSecureBuildDetailsSuccess,
  getSecureBuildDetailsFail,
  createSecureBuildSuccess,
  createSecureBuildFail,
  updateSecureBuildSuccess,
  updateSecureBuildFail
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getSecureBuilds,
  getSecureBuildDetails,
  createSecureBuild,
  updateSecureBuild
} from "helpers/backend_helper"

function* fetchSecureBuilds({ payload }) {
  try {
    const response = yield call(getSecureBuilds, {
      params: {
        page: payload.page,
        size: payload.size,
        sortField: payload.sortField,
        sortOrder: payload.sortOrder,
        search: payload.search
      }
    })
    yield put(getSecureBuildsSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getSecureBuildsFail(error.response.data.message))
    } else {
      yield put(getSecureBuildsFail(error.message))
    }
  }
}

function* fetchSecureBuildDetail({ deviceId }) {
  try {
    const response = yield call(getSecureBuildDetails, deviceId)
    yield put(getSecureBuildDetailsSuccess(response))
  } catch (error) {
    yield put(getSecureBuildDetailsFail(error))
  }
}

function* onCreateSecureBuild({ payload }) {
  try {
    const response = yield call(createSecureBuild, payload)
    yield put(createSecureBuildSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(createSecureBuildFail(error.response.data))
    } else {
      yield put(createSecureBuildFail(error.message))
    }
  }
}

function* onUpdateSecureBuild({ payload }) {
  try {
    const response = yield call(updateSecureBuild, payload)
    yield put(updateSecureBuildSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(updateSecureBuildFail(error.response.data))
    } else {
      yield put(updateSecureBuildFail(error.message))
    }
  }
}

function* secureBuildSaga() {
  yield takeEvery(GET_SECURE_BUILDS, fetchSecureBuilds)
  yield takeEvery(GET_SECURE_BUILD_DETAILS, fetchSecureBuildDetail)
  yield takeEvery(CREATE_SECURE_BUILD, onCreateSecureBuild)
  yield takeEvery(UPDATE_SECURE_BUILD, onUpdateSecureBuild)
}

export default secureBuildSaga