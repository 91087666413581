import { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
} from "reactstrap"
import { getDashboardReport } from "store/reports/actions";
import { RESULT_ERROR, RESULT_OK, GET_EXCEPTIONS } from "store/reports/actionTypes";


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content: []
    }
  }

  componentDidMount() {
    const { onSendAction_GetDashboardReport } = this.props
    onSendAction_GetDashboardReport()
  }

  componentWillUnmount() {
    this.setState({content: {}})
  }

  componentDidUpdate(prevProps, prevState) {
    const { contentPage } = this.props
    const { content } = this.state

    if (!isEmpty(contentPage)) {
      if (!isEqual(prevProps.contentPage, contentPage) || !isEqual(prevProps.contentPage.content, content)) {
        this.setState({
          content: contentPage.content,
        })
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div className="page-content">
          <Container fluid>
            <Fragment>
              <Row>
                <Col xs="12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">Dashboard</h4>
                  </div>
                </Col>
              </Row>
            </Fragment>
            <Row>
              <Col xl="12">
                <Row>
                  {this.state.content.map((report, key) => (
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted font-weight-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={this.getIconClass(report)}
                                />
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }

  getIconClass(report) {
    if (report.type === 'TRN') {
      return "bx bx-layer font-size-24"
    } else if (report.type === 'SECURE_ELEMENT') {
      return "bx bx-chip font-size-24"
    } else {
      return "bx bx-devices font-size-24"
    }
  }

}

Dashboard.propTypes = {
  contentPage: PropTypes.object,
  actionResult: PropTypes.object,
  onSendAction_GetDashboardReport: PropTypes.func,
}

const mapStateToProps = ({ reportsReducer }) => ({
  contentPage: reportsReducer.contentPage,
  actionResult: reportsReducer.actionResult,
})

const mapDispatchToProps = dispatch => ({
  onSendAction_GetDashboardReport: () => {
    dispatch(getDashboardReport())
  },
})

export default  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
