/* COMMON */
export const CLEAR_STATE = "CLEAR_STATE"
export const CLEAR_ACTION_RESULT = "CLEAR_ACTION_RESULT"
export const RESULT_OK = "RESULT_OK"
export const RESULT_ERROR = "RESULT_ERROR"

/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"
export const ADD_USER = "ADD_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL"
export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL"
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
export const RESET_PWD = "RESET_PWD"
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS"
export const RESET_PWD_FAIL = "RESET_PWD_FAIL"
export const CHANGE_PASSWORD_BY_ADMIN = "CHANGE_PASSWORD_BY_ADMIN"
export const CHANGE_PASSWORD_BY_ADMIN_SUCCESS = "CHANGE_PASSWORD_BY_ADMIN_SUCCESS"
export const CHANGE_PASSWORD_BY_ADMIN_FAIL = "CHANGE_PASSWORD_BY_ADMIN_FAIL"
