import {
  GET_CONFS,
  GET_CONFS_FAIL,
  GET_CONFS_SUCCESS,
  ADD_CONF,
  ADD_CONF_SUCCESS,
  ADD_CONF_FAIL,
  EDIT_CONF,
  EDIT_CONF_SUCCESS,
  EDIT_CONF_FAIL,
  DELETE_CONF,
  DELETE_CONF_SUCCESS,
  DELETE_CONF_FAIL,
  RESULT_OK,
  RESULT_ERROR,
  CLEAR_STATE,
} from "./actionTypes"


const INIT_STATE = {
  contentPage: {},
  actionResult: {},
}

const confsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CLEAR_STATE:
      {
        return {
          ...INIT_STATE
        }
      }
    case GET_CONFS_SUCCESS:
      {
        return {
          ...state,
          contentPage: action.payload.contentPage,
          actionResult: { action: GET_CONFS, result: RESULT_OK, error: '' },
        }
      }
    case GET_CONFS_FAIL: {
      return {
        ...state,
        contentPage: {},
        actionResult: { action: GET_CONFS, result: RESULT_ERROR, error: action.payload },
      }
    }
    case ADD_CONF_SUCCESS:
      {
        return {
          ...state,
          actionResult: { action: ADD_CONF, result: RESULT_OK, error: '' },
        }
      }
    case ADD_CONF_FAIL:
      return {
        ...state,
        actionResult: { action: ADD_CONF, result: RESULT_ERROR, error: action.payload },
      }
    case EDIT_CONF_SUCCESS:
      {
        return {
          ...state,
          actionResult: { action: EDIT_CONF, result: RESULT_OK, error: '' },
        }
      }
    case EDIT_CONF_FAIL:
      return {
        ...state,
        actionResult: { action: EDIT_CONF, result: RESULT_ERROR, error: action.payload },
      }
    case DELETE_CONF_FAIL:
      return {
        ...state,
        actionResult: { action: DELETE_CONF, result: RESULT_ERROR, error: action.payload },
      }
    case DELETE_CONF_SUCCESS:
      return {
        ...state,
        actionResult: { action: DELETE_CONF, result: RESULT_OK, error: '' },
      }
    default:
      return state
  }
}

export default confsReducer
