import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
} from "./actionTypes"

export const loginUser = (user, history, cookies) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, cookies },
  }
}

export const loginSuccess = authUser => {
  return {
    type: LOGIN_SUCCESS,
    payload: authUser,
  }
}

export const logoutUser = (history, cookies) => {
  return {
    type: LOGOUT_USER,
    payload: { history, cookies },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const getUserProfile = () => {
  return {
    type: GET_USER_PROFILE
  }
}

export const getUserProfileSuccess = user => {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    payload: user,
  }
}

export const getUserProfileFail = error => {
  return {
    type: GET_USER_PROFILE_FAIL,
    payload: error,
  }
}
