import {
  CLEAR_STATE,
  GET_TRN_REPORT,
  GET_TRN_REPORT_FAIL,
  GET_TRN_REPORT_SUCCESS,
  GET_DEVICE_REPORT,
  GET_DEVICE_REPORT_FAIL,
  GET_DEVICE_REPORT_SUCCESS, 
  GET_DASHBOARD_REPORT,
  GET_DASHBOARD_REPORT_FAIL,
  GET_DASHBOARD_REPORT_SUCCESS,
} from "./actionTypes"

export const clearState = () => ({
  type: CLEAR_STATE
})

export const getTrnReport = (page, size, search) => ({
  type: GET_TRN_REPORT,
  payload: { page, size, search }
})

export const getTrnReportSuccess = contentPage => ({
  type: GET_TRN_REPORT_SUCCESS,
  payload: { contentPage }
})

export const getTrnReportFail = error => ({
  type: GET_TRN_REPORT_FAIL,
  payload: error,
})

export const getDeviceReport = (page, size, search) => ({
  type: GET_DEVICE_REPORT,
  payload: { page, size, search }
})

export const getDeviceReportSuccess = contentPage => ({
  type: GET_DEVICE_REPORT_SUCCESS,
  payload: { contentPage }
})

export const getDeviceReportFail = error => ({
  type: GET_DEVICE_REPORT_FAIL,
  payload: error,
})

export const getDashboardReport = (page, size, search) => ({
  type: GET_DASHBOARD_REPORT,
  payload: {}
})

export const getDashboardReportSuccess = contentPage => ({
  type: GET_DASHBOARD_REPORT_SUCCESS,
  payload: { contentPage }
})

export const getDashboardReportFail = error => ({
  type: GET_DASHBOARD_REPORT_FAIL,
  payload: error,
})