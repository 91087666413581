import PropTypes from "prop-types"
import { Fragment, Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
    }
  }

  render() {

    let userRoles = {
      superAdmin: false,
      admin: false,
      user: false,
      vendor: false,
    }
    if (localStorage.getItem("userRoles")) {
      let savedUserRoles = JSON.parse(localStorage.getItem("userRoles"));
      if (savedUserRoles !== undefined) {
        userRoles = savedUserRoles;
      }
    }

    return (
      <Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* Dashboards */}
            {(userRoles.superAdmin || userRoles.admin) && (
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="bx bx-home-circle" />
                <span>{this.props.t("Dashboard")}</span>
              </Link>
            </li>
            )}
            {/* EG2 Operations */}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-aperture" />
                <span>{this.props.t("EG-2 Devices")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/provisioning-eg2">
                    {this.props.t("Provisioning")}
                  </Link>
                </li>
                <li>
                  <Link to="/devices-eg2">
                    {this.props.t("Device List")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* Operations */}
            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-aperture" />
                <span>{this.props.t("Devices")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/production-devices">
                    {this.props.t("Device List")}
                  </Link>
                </li>
                <li>
                  <Link to="/production-secure-elements">
                    {this.props.t("Secure Element List")}
                  </Link>
                </li>
                <li>
                  <Link to="/provisioning-secure-element">
                    {this.props.t("Secure Element Provisioning")}
                  </Link>
                </li>
                <li>
                  <Link to="/provisioning-smartbridge">
                    {this.props.t("Smart Bridge Provisioning")}
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* Device Discovery */}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-transfer" />
                <span>{this.props.t("Device Discovery")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/discovery-eg2">
                    {this.props.t("EG-2 Device")}
                  </Link>
                </li>
                <li>
                  <Link to="/discovery-secure-element">
                    {this.props.t("Secure Element")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* Reports */}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bxs-report" />
                <span>{this.props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/device-report">{this.props.t("Device Report")}</Link>
                </li>
                {(userRoles.superAdmin || userRoles.admin) && (
                <li>
                  <Link to="/trn-report">{this.props.t("TRN Report")}</Link>
                </li>
                )}
              </ul>
            </li>
            {/* TRN Management */}
            {(userRoles.superAdmin || userRoles.admin) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-memory-card" />
                  <span>{this.props.t("TRN Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {/* <li>
                    <Link to="/limit-trns">{this.props.t("Limit TRNs")}</Link>
                  </li> */}
                  <li>
                    <Link to="/production-trns">{this.props.t("TRNs")}</Link>
                  </li>
                  <li>
                    <Link to="/rejected-trns">{this.props.t("Rejected TRNs")}</Link>
                  </li>
                  <li>
                    <Link to="/upload-trn">{this.props.t("Upload TRN")}</Link>
                  </li>
                  {/*(userRoles.superAdmin) && (
                    <li>
                      <Link to="/generate-trns">{this.props.t("Generate TRNs")}</Link>
                    </li>
                  )*/}
                </ul>
              </li>
            )}
            {/* Key Management */}
            {(userRoles.superAdmin || userRoles.admin) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-key" />
                  <span>{this.props.t("Key Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/keysetList">{this.props.t("Keyset List")}</Link>
                  </li>
                  {(userRoles.superAdmin || userRoles.admin) && (
                    <li>
                      <Link to="/hsm-info">{this.props.t("HSM Info")}</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {/* User Management */}
            {(userRoles.superAdmin || userRoles.admin) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-user" />
                  <span>{this.props.t("User Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/users">{this.props.t("Manage Users")}</Link>
                  </li>
                </ul>
              </li>
            )}
            {/* Maintenance */}
            {(userRoles.superAdmin || userRoles.admin) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-chevron-left-circle" />
                  <span>{this.props.t("Maintenance")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {/* <li>
                    <Link to="/troubleshooting">{this.props.t("Troubleshooting")}</Link>
                  </li>
                  <li>
                    <Link to="/notification-messages">{this.props.t("Notification Messages")}</Link>
                  </li> */}
                  <li>
                    <Link to="/audit">{this.props.t("Audit")}</Link>
                  </li>
                  <li>
                    <Link to="/exception">{this.props.t("Exception")}</Link>
                  </li>
                  <li>
                    <Link to="/login-history">{this.props.t("Login History")}</Link>
                  </li>
                  <li>
                    <Link to="/application-configuration">{this.props.t("Configuration")}</Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
