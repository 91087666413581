import {
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import { toDatetimeString } from "../../helpers/app_helper"

const KeysetListingColumns = (onEdit, onDelete, onSort, readOnly) => [
  {
    dataField: "name",
    text: "Name",
    sort: true,
    onSort: onSort,
  },
  {
    dataField: "type",
    text: "Type"
  },
  {
    dataField: "defaultKey",
    text: "Default Key",
    formatter: (cellContent, row) => {
      return (
        <>
          {row.defaultKey === true && (<span className="badge badge-success">DEFAULT</span>)}
          {row.defaultKey === false && (<span></span>)}
        </>
      )
    }
  },
  {
    dataField: "createdDate",
    text: "Created Date",
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{toDatetimeString(new Date(row.createdDate))}</p>
      </>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    headerStyle: (colum, colIndex) => {
      return { width: '150px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => {
      if (readOnly === true) return (<></>);
      return (
        <>
          <Link to="#" className="text-primary">
            <i className="mdi mdi-pencil font-size-18 mr-2" id="edittooltip" onClick={() => onEdit(row)} />
            <UncontrolledTooltip placement="top" target="edittooltip">
              Edit
            </UncontrolledTooltip>
          </Link>
          {!row.defaultKey && !row.usedForPrivisioning && (
            <Link to="#" className="text-danger">
              <i className="mdi mdi-delete font-size-18 mr-2" id="deletetooltip" onClick={() => onDelete(row)} />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          )}
        </>
      )
    }
  },
]

export default KeysetListingColumns
