import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Button,
  Col,
  Row,
  Container,
  Card,
  CardBody,
 } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { getKeysets, createKeyset, updateKeyset, deleteKeyset, makeDefaultKeyset, clearActionResult } from "store/keysets/actions"
import KeysetListingColumns from "./KeysetListingColumns"
import KeysetDetails from "./KeysetDetails"
import {
  GET_KEYSETS_SUCCESS,
  GET_KEYSETS_FAIL,
  CREATE_KEYSET_SUCCESS,
  CREATE_KEYSET_FAIL,
  UPDATE_KEYSET_SUCCESS,
  UPDATE_KEYSET_FAIL,
  DELETE_KEYSET_SUCCESS,
  DELETE_KEYSET_FAIL,
  MAKE_DEFAULT_KEYSET_SUCCESS,
  MAKE_DEFAULT_KEYSET_FAIL
} from "store/keysets/actionTypes"

class KeysetListing extends Component {

  constructor(props) {
    super(props)
    this.state = {
      keysetPage: {
        currentPage: 0,
        totalPages: 0,
        pageSize: 3,
        totalItems: 0,
        keys: []
      },
      sortField: '',
      sortOrder: '',
      search: '',
      actionResult: undefined,
      forceReload: false,
      keysetDetails: undefined,
      keysetDeleting: undefined,
      readOnly: false
    }
  }

  componentDidMount() {
    const { onGetKeysets } = this.props
    onGetKeysets(this.state.keysetPage.currentPage, this.state.keysetPage.pageSize, this.state.sortField, this.state.sortOrder, this.state.search)

    if (localStorage.getItem("userRoles")) {
      let savedUserRoles = JSON.parse(localStorage.getItem("userRoles"));
      if (savedUserRoles !== undefined) {
        this.setState({
          readOnly: savedUserRoles.vendor === true
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGetKeysets } = this.props
    const { keysetPage, sortField, sortOrder, search, forceReload } = this.state

    if (keysetPage.currentPage !== prevState.keysetPage.currentPage
        || keysetPage.pageSize !== prevState.keysetPage.pageSize
        || sortField !== prevState.sortField
        || sortOrder !== prevState.sortOrder
        || search !== prevState.search) {
      onGetKeysets(keysetPage.currentPage, keysetPage.pageSize, sortField, sortOrder, search);
      return;
    }
    if (forceReload === true) {
      keysetPage.currentPage = 0; // reset to first page
      onGetKeysets(keysetPage.currentPage, keysetPage.pageSize, sortField, sortOrder, search);
      this.setState({
        forceReload: false,
        keysetPage,
      })
      return;
    }

    if (this.props.keysetPage !== undefined && this.props.keysetPage.keys !== undefined && this.props.keysetPage !== prevProps.keysetPage) {
      this.setState({
        keysetPage: this.props.keysetPage,
      })
      return;
    }

    if (this.props.actionResult !== prevProps.actionResult) {
      this.setState({
        actionResult: this.props.actionResult
      })
    }
  }

  onPageChange = (page, sizePerPage) => {
    let keysetPage = _.cloneDeep(this.state.keysetPage);
    keysetPage.currentPage = page - 1;
    this.setState({
      keysetPage
    })
  }

  onSizePerPageChange = (page, sizePerPage) => {
    let keysetPage = _.cloneDeep(this.state.keysetPage);
    keysetPage.currentPage = 0;
    keysetPage.pageSize = sizePerPage;
    this.setState({
      keysetPage
    })
  }

  onSort = (field, order) => {
    let keysetPage = _.cloneDeep(this.state.keysetPage);
    keysetPage.currentPage = 0;
    this.setState({
      sortField: field,
      sortOrder: order,
      keysetPage
    })
  }

  handleTableChange = (type, { page, searchText }) => {
    if (type === 'search') {
      let keysetPage = _.cloneDeep(this.state.keysetPage);
      keysetPage.currentPage = 0;
      this.setState({
        search: searchText,
        keysetPage
      })
    }
  }

  onCloseResultDlg = (reloadPage) => {
    if (reloadPage === true) {
      this.setState({
        forceReload: true,
        actionResult: undefined
      })
    }
    this.props.onClearActionResult();
  }

  renderActionResultDlg = () => {
    let { actionResult } = this.state
    let reloadPage = false
    if (actionResult !== undefined) {
      let error = false
      let title = ''
      let message = ''
      switch (actionResult.action) {
        case GET_KEYSETS_SUCCESS:
          return null
        case GET_KEYSETS_FAIL:
        case CREATE_KEYSET_FAIL:
        case DELETE_KEYSET_FAIL:
        case MAKE_DEFAULT_KEYSET_FAIL:
            error = true
            title = "ERROR"
            message = actionResult.error
            break;
        case UPDATE_KEYSET_FAIL:
            error = true
            title = "ERROR"
            message = actionResult.error
            // we need reload to ignore all changes of current row
            reloadPage = true
            break;
        case CREATE_KEYSET_SUCCESS:
            title = "SUCCESSFUL"
            message = "New Keyset was submitted"
            reloadPage = true
          break;
        case UPDATE_KEYSET_SUCCESS:
            title = "SUCCESSFUL"
            message = "Updating Keyset was submitted"
            reloadPage = true
          break;
        case DELETE_KEYSET_SUCCESS:
            title = "SUCCESSFUL"
            message = "Keyset was deleted"
            reloadPage = true
          break;
        case MAKE_DEFAULT_KEYSET_SUCCESS:
            title = "SUCCESSFUL"
            message = "Make as Default Key"
            reloadPage = true
          break;
      }
      return <SweetAlert
        error={error}
        success={!error}
        title={title}
        onConfirm={() => this.onCloseResultDlg(reloadPage)}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  onCreateForSecureElement = () => {
    this.setState({
      keysetDetails: {
        type: "SECURE_ELEMENT"
      }
    });
  }

  onCreateForSecureFirmware = () => {
    this.setState({
      keysetDetails: {
        type: "SECURE_FIRMWARE"
      }
    });
  }

  onEdit = (row) => {
    this.setState({
      keysetDetails: row
    });
  }

  onDelete = (row) => {
    this.setState({
      keysetDeleting: row
    });
  }

  renderKeyDetails = () => {
    const { keysetDetails } = this.state;
    const { onCreateKeyset, onUpdateKeyset, onMakeDefaultKeyset } = this.props
    if (keysetDetails !== undefined) {
      return <KeysetDetails
        keyset={keysetDetails}
        onClosed={() => {
          this.setState({
            keysetDetails: undefined
          });
        }}
        onSubmit={(key) => {
          if (key.id !== undefined && key.id > 0) {
            onUpdateKeyset(key);
          } else {
            onCreateKeyset(key);
          }
          this.setState({
            keysetDetails: undefined
          });
        }}
        onMakeDefault={(key) => {
          if (key.id !== undefined && key.id > 0) {
            onMakeDefaultKeyset(key.id);
          }
          this.setState({
            keysetDetails: undefined
          });
        }}
      >
      </KeysetDetails>
    }
    return null;
  }

  renderConfirmDelete = () => {
    const { keysetDeleting } = this.state;
    if (keysetDeleting !== undefined) {
      return <SweetAlert
        title={`Are you sure you want to delete key "${keysetDeleting.name}"?`}
        warning
        showCancel
        confirmButtonText="Yes, delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        closeOnClickOutside={false}
        onConfirm={() => {
          this.props.onDeleteKeyset(keysetDeleting.id)
          this.setState({
            keysetDeleting: undefined
          })
        }}
        onCancel={() =>
          this.setState({
            keysetDeleting: undefined
          })
        }
      >
        You won't be able to revert this!
      </SweetAlert>;
    }
    return null;
  }

  render() {
    const { keysetPage, readOnly } = this.state
    const pageOptions = {
      page: keysetPage.currentPage + 1, //SpringData: Page number values start with 0.
      sizePerPage: keysetPage.pageSize,
      totalSize: keysetPage.totalItems,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
      onSizePerPageChange: this.onSizePerPageChange,
    }
    function customMatchFunc({
      searchText,
      value,
    }) {
      if (typeof value !== 'undefined') {
        return value.startsWith(searchText);
      }
      return false;
    }

    return (
      <Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Key Management" breadcrumbItem="Keyset List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {this.renderActionResultDlg()}
                    {this.renderKeyDetails()}
                    {this.renderConfirmDelete()}
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={keysetPage.keys || []}
                          columns={KeysetListingColumns(this.onEdit, this.onDelete, this.onSort, readOnly)}
                          bootstrap4
                          search={{ customMatchFunc }}
                        >
                          {toolkitProps => (
                            <Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box mr-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <Search.SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                {!readOnly && (
                                  <Col sm="8">
                                    <div className="text-sm-right">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                        onClick={() => {
                                          this.onCreateForSecureElement()
                                        }}
                                      >
                                        <i className="mdi mdi-plus mr-1" />New Keyset
                                      </Button>

                                      {/* <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                        onClick={() => {
                                          this.onCreateForSecureFirmware()
                                        }}
                                      >
                                        <i className="mdi mdi-plus mr-1" />Create for SECURE FIRMWARE
                                      </Button> */}
                                    </div>
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div>
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      remote
                                      hover={true}
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={this.handleTableChange}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

KeysetListing.propTypes = {
  actionResult: PropTypes.object,
  keysetPage: PropTypes.object,
  onGetKeysets: PropTypes.func,
  onCreateKeyset: PropTypes.func,
  onUpdateKeyset: PropTypes.func,
  onDeleteKeyset: PropTypes.func,
  onMakeDefaultKeyset: PropTypes.func,
  onClearActionResult: PropTypes.func,
}

const mapStateToProps = ({ keysetsReducer }) => ({
  keysetPage: keysetsReducer.keysetPage,
  actionResult: keysetsReducer.actionResult,
})
const mapDispatchToProps = dispatch => ({
  onGetKeysets: (page, size, sortField, sortOrder, search) => dispatch(getKeysets(page, size, sortField, sortOrder, null, search)),
  onCreateKeyset: (keyset) => dispatch(createKeyset(keyset)),
  onUpdateKeyset: (keyset) => dispatch(updateKeyset(keyset)),
  onDeleteKeyset: (id) => dispatch(deleteKeyset(id)),
  onMakeDefaultKeyset: (id) => dispatch(makeDefaultKeyset(id)),
  onClearActionResult: () => dispatch(clearActionResult()),
})

export default connect(mapStateToProps, mapDispatchToProps)(KeysetListing)