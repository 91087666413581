
/* COMMON */
export const CLEAR_STATE = "CLEAR_STATE"
export const CLEAR_ACTION_RESULT = "CLEAR_ACTION_RESULT"
export const RESULT_OK = "RESULT_OK"
export const RESULT_ERROR = "RESULT_ERROR"

/* Logins */
export const GET_LOGINS = "GET_LOGINS"
export const GET_LOGINS_SUCCESS = "GET_LOGINS_SUCCESS"
export const GET_LOGINS_FAIL = "GET_LOGINS_FAIL"
