import {
  Badge
} from "reactstrap";
import { toDatetimeString } from "../../helpers/app_helper"

const LoginColumns = (onSort, statusMode) => {
  let showFailColumn = statusMode === 'Fail'
  return [
  {
    dataField: "login",
    text: "Username",
    sort: true,
    align: "center",
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '110px', textAlign: 'center' };
    },
  },
  {
    dataField: "userId",
    text: "Full Name",
    sort: false,
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{(row.user && row.user !== "null" && row.user !== "undefined") ? (row.user.firstName + " " + row.user.lastName) : "[Unknown]"}</p>
      </>
    ),
  },
  {
    text: "Status",
    dataField: "status",
    sort: true,
    onSort: onSort,
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <Badge
        className={"font-size-12 badge-soft-" + (row.status === "SUCCESS" ? "true" : "false")}
        color={row.status === "SUCCESS" ? "success" : "secondary"}
        pill
      >
        { row.status === "SUCCESS" ? "Success" : "Fail"}
      </Badge >
    ),
  },
  {
    text: "Fail Reason",
    dataField: "failReason",
    align: "center",
    sort: false,
    hidden: !showFailColumn,
    headerStyle: (colum, colIndex) => {
      return { width: '120px'};
    },
  },  
  {
    text: "IP Address",
    dataField: "ipAddress",
    align: "center",
    sort: false,
    headerStyle: (colum, colIndex) => {
      return { width: '100px', textAlign: 'center' };
    },
  },
  {
    text: "Browser",
    dataField: "browserName",
    sort: false,
    align: "center",
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.browserName.length > 20 ? (row.browserName.substr(0, 17) + "...") : row.browserName}</p>
      </>
    ),
    headerStyle: (colum, colIndex) => {
      return { width: '190px', textAlign: 'center' };
    },
  },
  {
    text: "Date Time",
    dataField: "dateTime",
    align: "center",
    sort: true,
    onSort: onSort,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{toDatetimeString(new Date(row.dateTime))}</p>
      </>
    ),
    headerStyle: (colum, colIndex) => {
      return { width: '160px', textAlign: 'center' };
    },
  },
  ]
}
export default LoginColumns
