import { AvField, AvForm } from "availity-reactstrap-validation";
import { map, orderBy } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import {
  Col, Modal,
  ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";

import { toDatetimeString } from "../../helpers/app_helper"

class SecureBuildDetails extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
      device: props.device,
      onClosed: props.onClosed
    }
  }


  render() {
    const { device, isOpen, onClosed } = this.state

    return (
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => {
          this.setState({ isOpen: false });
          onClosed();
        }}
        centered={true}
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            this.setState({ isOpen: false });
            onClosed();
          }}
        >
          { "EG-2 Device Details" }
        </ModalHeader>
        <AvForm
          className="form-horizontal"
        >
          <ModalBody>
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <AvField
                      name="deviceId"
                      id="deviceId"
                      label="Device ID"
                      value={device.deviceId}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <AvField
                      name="serial"
                      id="serial"
                      label="Secure Element Serial"
                      value={device.serial}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="doorProcDeviceID"
                      id="doorProcDeviceID"
                      label="Door Serial"
                      value={device.doorProcDeviceID}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="readerProcDeviceID"
                      id="readerProcDeviceID"
                      label="Reader Serial"
                      value={device.readerProcDeviceID}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="cm4Serial"
                      id="cm4Serial"
                      label="CM4 Serial"
                      value={device.cm4Serial}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="macAddress"
                      id="macAddress"
                      label="MAC Address"
                      value={device.macAddress}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="model"
                      id="model"
                      label="Model"
                      value={device.model}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="ip"
                      id="ip"
                      label="Device IP"
                      value={device.ip}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="connectionMode"
                      id="connectionMode"
                      label="Connection Mode"
                      value={device.connectionMode === 4 ? "Secure" : "Plain"}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="imageVersion"
                      id="imageVersion"
                      label="OS Version"
                      value={device.imageVersion}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="engineVersion"
                      id="engineVersion"
                      label="APP Version"
                      value={device.engineVersion}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="deviceModuleLibVersion"
                      id="deviceModuleLibVersion"
                      label="Device Module Version"
                      value={device.deviceModuleLibVersion}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="readerProcFWVersion"
                      id="readerProcFWVersion"
                      label="Reader Proc Version"
                      value={device.readerProcFWVersion}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <AvField
                      name="doorProcFWVersion"
                      id="doorProcFWVersion"
                      label="Door Proc Version"
                      value={device.doorProcFWVersion}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <h4 className="card-title mb-4">TRN Versions</h4>
              </Col>
            </Row>
            {map(orderBy(device.trnVersions, ['id'], ['desc']), (k, ki) => (
              <Row>
                <Col lg={12}>
                    <p>
                      <em>
                        {toDatetimeString(new Date(k.provisioningDate))}  {" : "} <strong>{k.trnVersionSummary}</strong> by {k.enroller.firstName + " " + k.enroller.lastName} {" - "}
                      </em>
                      <span className={k.status === "COMPLETED" ? "badge badge-soft-success" : (k.status === "FAILED" ? "badge badge-soft-danger" : "badge badge-soft-primary")}>
                        {k.status}
                      </span>
                      {k.status === "FAILED" && (
                        <em>
                          {" (" + k.lastError + ")"}
                        </em>
                      )}
                    </p>
                </Col>
              </Row>
            ))}
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col lg={12}>
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.setState({ isOpen: false });
                      onClosed();
                    }}
                  >
                    Close
                  </button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </AvForm>

      </Modal>
    );
  }
}

SecureBuildDetails.propTypes = {
  device: PropTypes.object,
  onClosed: PropTypes.func,
}

const mapStateToProps = ({ secureBuildReducer }) => ({
})
const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SecureBuildDetails)