//Include Both Helper File with needed methods
import { apiAddConf, apiDeleteConf, apiEditConf, apiGetConfs } from "helpers/backend_helper"
import { isEmpty } from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addConfFail, addConfSuccess, deleteConfFail, deleteConfSuccess, editConfFail, editConfSuccess, getConfsFail, getConfsSuccess
} from "./actions"
import { ADD_CONF, DELETE_CONF, EDIT_CONF, GET_CONFS } from "./actionTypes"


function* onGetConfs({ payload }) {
  try {
    const response = yield call(apiGetConfs, {
      params: {
        page: payload.page, size: payload.size,
        sortField: payload.sortField, sortOrder: payload.sortOrder,
        search: payload.search,
      }
    })
    yield put(getConfsSuccess({
      content: response.content,
      page: response.number,
      size: response.size,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
      sortField: payload.sortField,
      sortOrder: payload.sortOrder,
    }))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getConfsFail(error.response.data.message))
    } else {
      yield put(getConfsFail(error.message))
    }
  }
}

function* onAddConf({ payload }) {
  try {
    yield call(apiAddConf, payload)
    yield put(addConfSuccess(payload))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(addConfFail(error.response.data))
    } else {
      yield put(addConfFail(error.message))
    }
  }
}

function* onEditConf({ payload }) {
  try {
    const response = yield call(apiEditConf, payload)
    yield put(editConfSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(editConfFail(error.response.data))
    } else {
      yield put(editConfFail(error.message))
    }
  }
}

function* onDeleteConf({ payload }) {
  try {
    yield call(apiDeleteConf, payload)
    yield put(deleteConfSuccess(payload))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(deleteConfFail(error.response.data))
    } else {
      yield put(deleteConfFail(error.message))
    }
  }
}

function* confsSaga() {
  yield takeEvery(GET_CONFS, onGetConfs)
  yield takeEvery(ADD_CONF, onAddConf)
  yield takeEvery(EDIT_CONF, onEditConf)
  yield takeEvery(DELETE_CONF, onDeleteConf)
}

export default confsSaga
