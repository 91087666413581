import PropTypes from 'prop-types';
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toggleRightSidebar } from "../../store/actions";


class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { isSearch: false }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleRightbar = this.toggleRightbar.bind(this)
    this.toggleFullscreen = this.toggleFullscreen.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch })
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack()
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar()
  }

  // toggleFullscreen() {
  // if (
  //   !document.fullscreenElement &&
  //   /* alternative standard method */ !document.mozFullScreenElement &&
  //   !document.webkitFullscreenElement
  // ) {
  //   // current working methods
  //   if (document.documentElement.requestFullscreen) {
  //     document.documentElement.requestFullscreen()
  //   } else if (document.documentElement.mozRequestFullScreen) {
  //     document.documentElement.mozRequestFullScreen()
  //   } else if (document.documentElement.webkitRequestFullscreen) {
  //     document.documentElement.webkitRequestFullscreen(
  //       Element.ALLOW_KEYBOARD_INPUT
  //     )
  //   }
  // } else {
  //   if (document.cancelFullScreen) {
  //     document.cancelFullScreen()
  //   } else if (document.mozCancelFullScreen) {
  //     document.mozCancelFullScreen()
  //   } else if (document.webkitCancelFullScreen) {
  //     document.webkitCancelFullScreen()
  //   }
  // }
  // }

  render() {
    return this.renderContent();
  }

  renderContent() {
    return null;
    //   return (
    //     <Fragment>
    //       <header id="page-topbar">
    //         <div className="navbar-header">
    //           <div className="d-flex">
    //             <div className="navbar-brand-box">
    //               <Link to="/" className="logo logo-dark">
    //                 <span className="logo-sm">
    //                   <img src={logo} alt="" height="22" />
    //                 </span>
    //                 <span className="logo-lg">
    //                   <img src={logoDark} alt="" height="17" />
    //                 </span>
    //               </Link>

    //               <Link to="/" className="logo logo-light">
    //                 <span className="logo-sm">
    //                   <img src={logoLightSvg} alt="" height="22" />
    //                 </span>
    //                 <span className="logo-lg">
    //                   <img src={logoLight} alt="" height="19" />
    //                 </span>
    //               </Link>
    //             </div>

    //             <button
    //               type="button"
    //               className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
    //               data-toggle="collapse"
    //               onClick={this.toggleMenu}
    //               data-target="#topnav-menu-content"
    //             >
    //               <i className="fa fa-fw fa-bars" />
    //             </button>

    //             <form className="app-search d-none d-lg-block">
    //               <div className="position-relative">
    //                 <input
    //                   type="text"
    //                   className="form-control"
    //                   placeholder="Search..." />
    //                 <span className="bx bx-search-alt" />
    //               </div>
    //             </form>

    //             <Dropdown
    //               className="dropdown-mega d-none d-lg-block ml-2"
    //               isOpen={this.state.megaMenuDrp}
    //               toggle={() => {
    //                 this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
    //               } }
    //             >
    //               <DropdownToggle
    //                 className="btn header-item waves-effect"
    //                 caret
    //                 tag="button"
    //               >
    //                 {this.props.t("Mega Menu")}{" "}
    //                 <i className="mdi mdi-chevron-down" />
    //               </DropdownToggle>
    //               <DropdownMenu className="identiv-dropdown-megamenu">
    //                 <Row>
    //                   <Col sm={12}>
    //                     <Row>
    //                       <Col md={3}>
    //                         <h5 className="font-size-14 mt-0">
    //                           {this.props.t("Configurations")}
    //                         </h5>
    //                         <ul className="list-unstyled megamenu-list">
    //                           <li>
    //                             <Link to="/trns">{this.props.t("TRNs")}</Link>
    //                           </li>
    //                           <li>
    //                             <Link to="/keysetList">{this.props.t("Keyset List")}</Link>
    //                           </li>
    //                           <li>
    //                             <Link to="/edgeconfigurations">{this.props.t("Edge Configurations")}</Link>
    //                           </li>
    //                         </ul>
    //                       </Col>

    //                       <Col md={3}>
    //                         <h5 className="font-size-14 mt-0">
    //                           {this.props.t("Devices")}
    //                         </h5>
    //                         <ul className="list-unstyled megamenu-list">
    //                           <li>
    //                             <Link to="/edgedevices">{this.props.t("Edge Devices")}</Link>
    //                           </li>
    //                         </ul>
    //                       </Col>

    //                       <Col md={3}>
    //                         <h5 className="font-size-14 mt-0">
    //                           {this.props.t("Provisioning")}
    //                         </h5>
    //                         <ul className="list-unstyled megamenu-list">
    //                           <li>
    //                             <Link to="/edge">
    //                               {this.props.t("Edge")}
    //                             </Link>
    //                           </li>
    //                         </ul>
    //                       </Col>
    //                       <Col sm={3}>
    //                         <h5 className="font-size-14 mt-0">
    //                           {this.props.t("Admin")}
    //                         </h5>
    //                         <ul className="list-unstyled megamenu-list">
    //                           <li>
    //                             <Link to="/users">{this.props.t("Users")}</Link>
    //                           </li>
    //                           <li>
    //                             <Link to="/maintenance">{this.props.t("Maintenance")}</Link>
    //                           </li>
    //                           <li>
    //                             <Link to="/audit">{this.props.t("Audit")}</Link>
    //                           </li>
    //                           <li>
    //                             <Link to="/exceptions">{this.props.t("Exception")}</Link>
    //                           </li>
    //                         </ul>
    //                       </Col>
    //                     </Row>
    //                   </Col>
    //                 </Row>
    //               </DropdownMenu>
    //             </Dropdown>
    //           </div>

    //           <div className="d-flex">
    //             <div className="dropdown d-inline-block d-lg-none ml-2">
    //               <button
    //                 type="button"
    //                 className="btn header-item noti-icon waves-effect"
    //                 id="page-header-search-dropdown"
    //                 onClick={() => {
    //                   this.setState({ isSearch: !this.state.isSearch });
    //                 } }
    //               >
    //                 <i className="mdi mdi-magnify" />
    //               </button>
    //               <div
    //                 className={this.state.isSearch
    //                   ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
    //                   : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
    //                 aria-labelledby="page-header-search-dropdown"
    //               >
    //                 <form className="p-3">
    //                   <div className="form-group m-0">
    //                     <div className="input-group">
    //                       <input
    //                         type="text"
    //                         className="form-control"
    //                         placeholder={this.props.t("Search") + "..."}
    //                         aria-label="Recipient's username" />
    //                       <div className="input-group-append">
    //                         <button className="btn btn-primary" type="submit">
    //                           <i className="mdi mdi-magnify" />
    //                         </button>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </form>
    //               </div>
    //             </div>

    //             <LanguageDropdown />

    //             <div className="dropdown d-none d-lg-inline-block ml-1">
    //               <button
    //                 type="button"
    //                 className="btn header-item noti-icon waves-effect"
    //                 onClick={this.toggleFullscreen}
    //                 data-toggle="fullscreen"
    //               >
    //                 <i className="bx bx-fullscreen" />
    //               </button>
    //             </div>

    //             <NotificationDropdown />

    //             <ProfileMenu />

    //             <div className="dropdown d-inline-block">
    //               <button
    //                 onClick={this.toggleRightbar}
    //                 type="button"
    //                 className="btn header-item noti-icon right-bar-toggle waves-effect"
    //               >
    //                 <i className="bx bx-cog bx-spin" />
    //               </button>
    //             </div>
    //           </div>
    //         </div>
    //       </header>
    //     </Fragment>
    //   );
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
  toggleRightSidebar: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout
  return { layoutType }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
)
