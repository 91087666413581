import PropTypes from 'prop-types'
import { Fragment, Component } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Media,
  Row,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Breadcrumb from "../../components/Common/Breadcrumb"
import { isEmpty, isEqual } from "lodash"

// actions
import { RESULT_OK, RESULT_ERROR } from "../../store/baseActionTypes"
import { EDIT_PROFILE, UPDATE_PASSWORD } from "../../store/auth/profile/actionTypes"
import { editProfile, updatePassword, clearActionResult } from "../../store/auth/profile/actions"
import { getUserProfile } from "../../store/auth/login/actions"
import noimage from "../../assets/images" 

import {
  firstNameControl, lastNameControl, passwordControl, passwordConfirmControl,
  strongPasswordControl, avatarControl
} from "../Users/UserList/UserFieldControls"

class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_confirm_save_change: false,

      error_dlg: false,
      dynamic_title: 'Error',
      dynamic_description: '',

      login: '',//read only
      email: '',//read only
      currentPassword: '',
      password: '',
      firstName: '',
      lastName: '',
      avatarBase64: '',
      avatarType: '',
      activated: '',//read only
      role: '',//read only

      userProfile: {},
      actionResultChanged: false,
      actionResult: {},
    }
  }

  componentDidMount() {
    const { userProfile } = this.props
    if (!isEmpty(userProfile)) {
      const { login, email, firstName, lastName, avatarBase64, avatarType, activated, role } = userProfile
      this.setState({
        userProfile,
        login,
        email,
        firstName,
        lastName,
        avatarBase64,
        avatarType,
        activated,
        role,
      })
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    const { actionResult, userProfile } = this.props
    if ((!isEmpty(actionResult) && !isEqual(prevProps.actionResult, actionResult))
      || (!isEqual(prevProps.userProfile, userProfile))) {
      console.log("componentDidUpdate->actionResultChanged");
      const { login, email, firstName, lastName, avatarBase64, avatarType, activated, role } = userProfile
      this.setState({
        actionResult,
        actionResultChanged: true,
        userProfile,

        login,
        email,
        firstName,
        lastName,
        avatarBase64,
        avatarType,
        activated,
        role,
      })//trigger to show Popup
    }
  }

  handlePopupChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  selectFile = (event) => {
    let file = event.target.files[0]
    if (file) {
      this.setState({
        avatarType: file.type
      });
      const reader = new FileReader()
      reader.onload = this.handleReaderLoaded.bind(this)
      reader.readAsBinaryString(file)
    }
  }

  handleReaderLoaded = (readerEvt) => {
    let binStr = readerEvt.target.result
    let base64 = btoa(binStr)
    document.getElementById("AvatarPreview").src = "data:image/png;base64," + base64;
    this.setState({
      avatarBase64: base64,
    });
  }

  getDisplayRoles = () => {
    const { role } = this.state.userProfile
    if (role === 'ROLE_SUPER_ADMIN') {
      return "Super Administrator"
    } else if (role === 'ROLE_ADMIN') {
      return "Administrator"
    } else if (role === 'ROLE_USER') {
      return "User"
    } else if (role === 'ROLE_VENDOR') {
      return "Vendor"
    }
    return null
  }

  doSave = () => {
    const { onEditProfile, onUpdatePassword } = this.props
    const { currentPassword, password, firstName, lastName, avatarType, avatarBase64 } = this.state
    if (this.state.passwordChanging === true) {
      console.log("onUpdatePassword");
      onUpdatePassword({
        currentPassword,
        newPassword: password,
      })
    } else {
      console.log("onEditProfile");
      onEditProfile({
        firstName,
        lastName,
        avatarType,
        avatarBase64
      })
    }
  }

  showConfirmSaveChange = (passwordChanging) => {
    this.setState({
      show_confirm_save_change: true,
      passwordChanging,
    })
  }

  renderConfirmSaveChangeDlg = () => {
    if (this.state.show_confirm_save_change) {
      return <SweetAlert
        title="Are you sure you want to save this change?"
        warning
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        closeOnClickOutside={false}
        onConfirm={() => {
          this.doSave()
          this.setState({
            show_confirm_save_change: false,
          })
        }
        }
        onCancel={() =>
          this.setState({
            show_confirm_save_change: false,
          })
        }
      >
      </SweetAlert>;
    }
    return null;
  }

  renderActionResultDlg = () => {
    let { actionResult, actionResultChanged } = this.state
    let reloadProfile = false
    if (actionResultChanged === true && !isEmpty(actionResult)) {
      console.log("renderActionResultDlg->actionResultChanged");
      let success = false
      let title = ''
      let message = ''
      switch (actionResult.action) {
        case EDIT_PROFILE:
          if (actionResult.result === RESULT_OK) {
            success = true
            title = "Edit Profile Success"
            message = "User Profile has been updated"
            reloadProfile = true
          } else if (actionResult.result === RESULT_ERROR) {
            title = "Edit Profile Fail"
            message = "Edit Profile failed. " + actionResult.error
          }
          break
        case UPDATE_PASSWORD:
          if (actionResult.result === RESULT_OK) {
            success = true
            title = "Update Password Success"
            message = "Password has been updated"
          } else if (actionResult.result === RESULT_ERROR) {
            title = "Update Password Fail"
            message = "Update Password failed. " + actionResult.error
          }
          break
      }
      return <SweetAlert
        error={!success}
        success={success}
        title={title}
        onConfirm={() => this.onCloseResultDlg(reloadProfile)}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  onCloseResultDlg = (reloadProfile) => {
    this.setState({ actionResultChanged: false })
    this.props.onClearActionResult()
    if (reloadProfile) {
      console.log("onNotifyProfileChanged");
      this.props.onNotifyProfileChanged()
    }
  }

  renderErrorDlg = () => {
    if (this.state.error_dlg === true) {
      return (<SweetAlert
        error
        title={this.state.dynamic_title}
        onConfirm={() => this.setState({ error_dlg: false })}
      >
        {this.state.dynamic_description}
      </SweetAlert>)
    }
    return null
  }

  getImage(avatarBase64) {
    return (avatarBase64 !== null && avatarBase64 !== '')?("data:image/png;base64," + avatarBase64):noimage
  }

  render() {
    if (isEmpty(this.state.userProfile)) {
      return null
    }

    const { login, email, firstName, lastName, avatarBase64, activated } = this.state
    return (
      <Fragment>
        <div className="page-content">
          {this.renderActionResultDlg()}
          {this.renderConfirmSaveChangeDlg()}
          {this.renderErrorDlg()}
          <Container fluid>
            <Breadcrumb title="SmartBridge" breadcrumbItem="Profile" />
            <Row>
              <Col lg="12">
                {this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}

                <Card>
                  <CardBody>
                    <Media>
                      <div className="mr-3">
                        <img
                          id="AvatarPreview"
                          name="AvatarPreview"
                          src={this.getImage(avatarBase64)}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <Media body className="align-self-center">
                        <div className="text-muted">
                          <h5>{login}</h5>
                          <p className="mb-1">{email}</p>
                          <p className="mb-0">{this.getDisplayRoles()}</p>
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change User</h4>

            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                this.showConfirmSaveChange(false)
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>{firstNameControl(firstName, this.handlePopupChange)}</Col>
                    <Col lg={6}>{lastNameControl(lastName, this.handlePopupChange)}</Col>
                  </Row>
                  <Row>
                    <Col lg={6}>{avatarControl(this.selectFile)}</Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div
                        className="custom-control custom-switch mb-3"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          disabled={true}
                          className="custom-control-input"
                          id="customSwitch1"
                          defaultChecked={activated} />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch1"
                        // onClick={this.handleActiveChange}
                        >
                          Active
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-danger mr-1 "
                        >
                          Edit User
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </AvForm>
            <h4 className="card-title mb-4">Change Password</h4>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                this.showConfirmSaveChange(true)
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>{passwordControl("currentPassword", "Current Password", this.state.currentPassword, this.handlePopupChange)}</Col>
                  </Row>
                  <Row>
                    <Col lg={6}>{strongPasswordControl("password", "New Password", this.state.password, this.handlePopupChange)}</Col>
                    <Col lg={6}>{passwordConfirmControl("Confirm New Password")}</Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-danger mr-1"
                        >
                          Edit Password
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </AvForm>
          </Container>
        </div>
      </Fragment>
    );
  }
}

UserProfile.propTypes = {
  onEditProfile: PropTypes.func,
  updatePassword: PropTypes.func,
  actionResult: PropTypes.object,
  onClearActionResult: PropTypes.func,
}

const mapStateToProps = ({ Login, Profile }) => ({
  actionResult: Profile.actionResult,
  userProfile: Login.userProfile,
})

const mapDispatchToProps = dispatch => ({
  onEditProfile: (user) => dispatch(editProfile(user)),
  onUpdatePassword: (password) => dispatch(updatePassword(password)),
  onNotifyProfileChanged: () => dispatch(getUserProfile()),
  onClearActionResult: () => dispatch(clearActionResult()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
)
