import PropTypes from 'prop-types';
import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { default as logoDark, default as logoLightPng } from "../../assets/images/identiv-logo.png";
import { default as logo, default as logoLightSvg } from "../../assets/images/identiv-logo.svg";
// Redux Store
import { toggleRightSidebar } from "../../store/actions";
// Import menuDropdown
import DownloadDropdown from "../CommonForBoth/TopbarDropdown/DownloadDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";


class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleRightbar = this.toggleRightbar.bind(this)
    this.toggleFullscreen = this.toggleFullscreen.bind(this)
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback()
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar()
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  render() {
    let userRoles = {
      superAdmin: false,
      admin: false,
      user: false,
      vendor: false,
    }
    if (localStorage.getItem("userRoles")) {
      let savedUserRoles = JSON.parse(localStorage.getItem("userRoles"));
      if (savedUserRoles !== undefined) {
        userRoles = savedUserRoles;
      }
    }
    return (
      <Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="19" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>

              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t("Search") + "..."}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> */}

              <Dropdown
                className="dropdown-mega d-none d-lg-block ml-2"
                isOpen={this.state.megaMenuDrp}
                toggle={() => {
                  this.setState({ megaMenuDrp: !this.state.megaMenuDrp })
                }}
              >
                <DropdownToggle
                  className="btn header-item waves-effect"
                  caret
                  tag="button"
                >
                  {" "}
                  {this.props.t("Operations")}{" "}
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu className="identiv-dropdown-megamenu">
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col md={2}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("EG-2 Devices")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/provisioning-eg2">
                                {this.props.t("Provisioning")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/devices-eg2">
                                {this.props.t("Device List")}
                              </Link>
                            </li>
                          </ul>

                          {/* <h5 className="font-size-14 mt-0">
                            {this.props.t("Devices")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/production-devices">
                                {this.props.t("Device List")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/production-secure-elements">
                                {this.props.t("Secure Element List")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/provisioning-secure-element">
                                {this.props.t("Secure Element Provisioning")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/provisioning-smartbridge">
                                {this.props.t("Smart Bridge Provisioning")}
                              </Link>
                            </li>
                          </ul> */}

                          <h5 className="font-size-14 mt-0">
                            {this.props.t("Discovery")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/discovery-eg2">
                                {this.props.t("EG-2 Device")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/discovery-secure-element">
                                {this.props.t("Secure Element")}
                              </Link>
                            </li>
                          </ul>
                        </Col>

                        <Col md={2}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("Reports")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="/device-report">{this.props.t("Device Report")}</Link>
                            </li>
                            {(userRoles.superAdmin || userRoles.admin) && (
                            <li>
                              <Link to="/trn-report">{this.props.t("TRN Report")}</Link>
                            </li>
                            )}
                          </ul>
                        </Col>

                        {(userRoles.superAdmin || userRoles.admin) && (
                        <Col md={2}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("TRN Management")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            {/* <li>
                              <Link to="/limit-trns">{this.props.t("Limit TRNs")}</Link>
                            </li> */}
                            <li>
                              <Link to="/production-trns">{this.props.t("TRNs")}</Link>
                            </li>
                            <li>
                              <Link to="/rejected-trns">{this.props.t("Rejected TRNs")}</Link>
                            </li>
                            <li>
                              <Link to="/upload-trn">{this.props.t("Upload TRN")}</Link>
                            </li>
                          </ul>
                        </Col>
                        )}
                        {(userRoles.superAdmin || userRoles.admin) && (
                          <Col sm={2}>
                            <h5 className="font-size-14 mt-0">
                              {this.props.t("Key Management")}
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/keysetList">{this.props.t("Keyset List")}</Link>
                              </li>
                              <li>
                                <Link to="/hsm-info">{this.props.t("HSM Info")}</Link>
                              </li>
                            </ul>
                          </Col>
                        )}
                        {(userRoles.superAdmin || userRoles.admin) && (
                          <Col sm={2}>
                            <h5 className="font-size-14 mt-0">
                              {this.props.t("User Management")}
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/users">{this.props.t("Manage Users")}</Link>
                              </li>
                            </ul>
                          </Col>
                        )}
                        {(userRoles.superAdmin || userRoles.admin) && (
                          <Col sm={2}>
                            <h5 className="font-size-14 mt-0">
                              {this.props.t("Maintenance")}
                            </h5>
                            <ul className="list-unstyled megamenu-list">
{/*                               <li>
                                <Link to="/troubleshooting">{this.props.t("Troubleshooting")}</Link>
                              </li>
                              <li>
                                <Link to="/notification-messages">{this.props.t("Notification Messages")}</Link>
                              </li> */}
                              <li>
                                <Link to="/audit">{this.props.t("Audit")}</Link>
                              </li>
                              <li>
                                <Link to="/exception">{this.props.t("Exception")}</Link>
                              </li>
                              <li>
                                <Link to="/login-history">{this.props.t("Login History")}</Link>
                              </li>
                              <li>
                                <Link to="/application-configuration">{this.props.t("Configuration")}</Link>
                              </li>
                            </ul>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                <button
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch })
                  }}
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

             <DownloadDropdown /> 

            {/*
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  onClick={this.toggleFullscreen}
                  className="btn header-item noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div> 

             <NotificationDropdown />*/}
              <ProfileMenu />

              {/* <div
                onClick={this.toggleRightbar}
                className="dropdown d-inline-block"
              >
                <button
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout
  return { layoutType }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
)

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  toggleRightSidebar: PropTypes.func
}