import {
  GET_SECURE_BUILD_SESSION_SUCCESS,
  GET_SECURE_BUILD_SESSION_FAIL,
  INIT_SECURE_BUILD_SESSION_FAIL,
  INIT_SECURE_BUILD_SESSION_SUCCESS,
  EXCHANGE_SECURE_BUILD_SESSION_FAIL,
  EXCHANGE_SECURE_BUILD_SESSION_SUCCESS,
  CLEAR_ERROR,
  RESET
} from "./actionTypes"

const INIT_STATE = {
  secureBuildSession: {},
  error: {}
}

const secureBuildSessionReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_SECURE_BUILD_SESSION_SUCCESS:
    case INIT_SECURE_BUILD_SESSION_SUCCESS:
    case EXCHANGE_SECURE_BUILD_SESSION_SUCCESS:
      {
        return {
          ...state,
          secureBuildSession: action.payload
        }
      }

    case GET_SECURE_BUILD_SESSION_FAIL:
    case INIT_SECURE_BUILD_SESSION_FAIL:
    case EXCHANGE_SECURE_BUILD_SESSION_FAIL: {
      return {
        ...state,
        error: { message: action.payload },
      }
    }

    case CLEAR_ERROR:
      {
        return {
          ...state,
          error: {}
        }
      }

    case RESET:
      {
        return INIT_STATE
      }

    default:
      return state
  }
}

export default secureBuildSessionReducer
