import {
  CLEAR_ACTION_RESULT,
  RESULT_OK,
  RESULT_ERROR,
  GET_TRNS,
  GET_TRNS_SUCCESS,
  GET_TRNS_FAIL,
  GET_TRNS_VERSIONS,
  GET_TRNS_VERSIONS_SUCCESS,
  GET_TRNS_VERSIONS_FAIL,
  SEARCHING_TRNS,
  SEARCHING_TRNS_SUCCESS,
  SEARCHING_TRNS_FAIL,
} from "./actionTypes"


const INIT_STATE = {
  contentPage: {},
  actionResult: {},
  searchingPage: {}
}

const trnsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_TRNS_SUCCESS:
      {
        return {
          ...state,
          contentPage: action.payload.contentPage,
          actionResult: { action: GET_TRNS, result: RESULT_OK, error: '' },
        }
      }

    case GET_TRNS_FAIL: {
      return {
        ...state,
        contentPage: {},
        actionResult: { action: GET_TRNS, result: RESULT_ERROR, error: action.payload },
      }
    }
    case GET_TRNS_VERSIONS_SUCCESS:
      {
        return {
          ...state,
          contentPage: action.payload.contentPage,
          actionResult: { action: GET_TRNS_VERSIONS, result: RESULT_OK, error: '' },
        }
      }

    case GET_TRNS_VERSIONS_FAIL: {
      return {
        ...state,
        contentPage: {},
        actionResult: { action: GET_TRNS_VERSIONS, result: RESULT_ERROR, error: action.payload },
      }
    }

    case CLEAR_ACTION_RESULT:
      {
        return {
          ...state,
          actionResult: {},
        }
      }

    case SEARCHING_TRNS_SUCCESS:
      {
        return {
          ...state,
          searchingPage: action.payload,
          actionResult: { action: SEARCHING_TRNS, result: RESULT_OK, error: '' },
        }
      }

    case SEARCHING_TRNS_FAIL: {
      return {
        ...state,
        searchingPage: {},
        actionResult: { action: SEARCHING_TRNS, result: RESULT_ERROR, error: action.payload },
      }
    }

    default:
      return state
  }
}

export default trnsReducer
