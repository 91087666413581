import Breadcrumbs from "components/Common/Breadcrumb"
import { isEmpty, isEqual } from 'lodash'
import React, { Component, Fragment } from 'react'
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { connect } from 'react-redux'
import { Card, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import PropTypes from "prop-types";
import { getTrnAudits } from "store/trns/actions"
import TrnAuditColumns from "./TrnAuditColumns"

class TrnAuditPopup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            content: [],
            page: 1,
            size: 5,
            totalPages: 0,
            totalElements: -1,
        }
    }

    componentDidMount() {
        const { trnViewAudit, trnVerViewAudit, onSendAction_GetTrnAudits } = this.props
        const {page, size} = this.state
        onSendAction_GetTrnAudits(trnViewAudit.id, trnVerViewAudit.id, page, size)
    }

    componentDidUpdate(prevProps, prevState) {
        const { contentPage } = this.props
        const { content } = this.state
        if (!isEmpty(contentPage)) {
            if (!isEqual(prevProps.contentPage, contentPage) 
                    || !isEqual(content, contentPage.content)) { // for popup require this check
                this.setState({
                    content: contentPage.content,
                    page: contentPage.page + 1,
                    size: contentPage.size,
                    totalPages: contentPage.totalPages,
                    totalElements: contentPage.totalElements
                })
            }
        }
      }    

    render() {
        const { modal, onToggle } = this.props
        const { trnViewAudit, trnVerViewAudit } = this.props    
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    centered={true}
                    backdrop="static"
                    size="xl"
                >
                    <ModalHeader toggle={onToggle} tag="h4">
                        TRN Audit
                    </ModalHeader>
                    <ModalBody>
                    <Container fluid>
                        <Breadcrumbs title={trnViewAudit.name} breadcrumbItem={trnVerViewAudit.trnVersion} />
                        <Row>
                        <Col xs="12">
                            <Card body>
                                { this.renderDataGrid() }
                            </Card>
                        </Col>
                        </Row>
                    </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col>
                                <div className="text-right">
                                    <button
                                        type="button"
                                        className="btn btn-light mr-2"
                                        onClick={onToggle}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }

    onTableChange = () => {}

    onPageChange = (page, sizePerPage) => {
        const { trnViewAudit, trnVerViewAudit, onSendAction_GetTrnAudits } = this.props
        const {size} = this.state
        onSendAction_GetTrnAudits(trnViewAudit.id, trnVerViewAudit.id, page, size)
    }

    renderDataGrid() {
        const { content, page, size, totalPages, totalElements } = this.state
        const pageOptions = {
          page: page,
          sizePerPage: size,
          totalSize: totalElements,
          showTotal: true,
          custom: true,
          onPageChange: this.onPageChange,
        }    
        return <PaginationProvider
            pagination={paginationFactory(pageOptions)}
        >
            {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                    keyField="id"
                    data={content || []}
                    columns={TrnAuditColumns()}
                    bootstrap4
                >
                    {toolkitProps => (
                        <Fragment>
                            <Row>
                                <Col xl="12">
                                    <div>
                                        <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            remote
                                            hover={true}
                                            bordered={false}
                                            striped={false}
                                            classes={"table table-centered table-nowrap"}
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            onTableChange={this.onTableChange}
                                            {...paginationTableProps} />
                                    </div>
                                </Col>
                            </Row>
                            { totalPages > 1 ? 
                                <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                        {...paginationProps} />
                                    </Col>
                                </Row> : null }
                        </Fragment>
                    )}
                </ToolkitProvider>
            )}
        </PaginationProvider>
    }
}

TrnAuditPopup.propTypes = {
    contentPage:  PropTypes.object,
    actionResult: PropTypes.object,
    trnViewAudit: PropTypes.object,
    trnVerViewAudit: PropTypes.object,
    onSendAction_GetTrnAudits: PropTypes.func,
}

const mapStateToProps = ({ trnAuditReducer }) => ({
    contentPage: trnAuditReducer.contentPage,
    actionResult: trnAuditReducer.actionResult,
})
  
const mapDispatchToProps = dispatch => ({
    onSendAction_GetTrnAudits: (trnId, verId, page, size) => dispatch(getTrnAudits(trnId, verId, page - 1, size)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrnAuditPopup)