import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"

class Troubleshooting extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <Fragment>
        <div className="page-content">
        </div>
      </Fragment>
    )
  }
}

export default Troubleshooting
