import { Fragment, Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//import clientPlugin from "../../../assets/downloads/client-plugin.zip"

class DownloadDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
    }
  }

  downloadClientPlugin = () => {
    const fn = window.Configs.PLUGIN_NAME
    let a = document.createElement('a');
    a.href = "/"+fn;
    a.download = fn;
    a.click();
  }

  toggle = () => {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  render() {
    const { menu } = this.state
    return (
      <Fragment>
        <Dropdown isOpen={menu} toggle={this.toggle} className="d-inline-block">
          <DropdownToggle className="btn header-item waves-effect" tag="button">
            <span className="align-middle">
            <i className="fa fa-fw fa-download"></i>
            </span>
          </DropdownToggle>
          <DropdownMenu className="language-switch" right>
            <DropdownItem
                onClick={() => this.downloadClientPlugin()}
              >
                <i className="fa fa-fw fa-file-archive"></i>
                <span className="align-middle">{ window.Configs.PLUGIN_NAME }</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Fragment>
    );
  }
}

export default DownloadDropdown
