import {
  SECURE_BUILD_DISCOVERY_REQUEST_SUCCESS,
  SECURE_BUILD_DISCOVERY_REQUEST_FAIL,
  SECURE_BUILD_DISCOVERY_RESPONSE_FAIL,
  SECURE_BUILD_DISCOVERY_RESPONSE_SUCCESS,
  CLEAR_ERROR
} from "./actionTypes"

const INIT_STATE = {
  secureBuildDiscoveryRequest: {},
  secureBuildDiscoveryResponse: {},
  error: {}
}

const secureBuildDiscoveryReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case SECURE_BUILD_DISCOVERY_REQUEST_SUCCESS:
      {
        return {
          ...state,
          secureBuildDiscoveryRequest: action.payload
        }
      }
    case SECURE_BUILD_DISCOVERY_RESPONSE_SUCCESS:
      {
        return {
          ...state,
          secureBuildDiscoveryResponse: action.payload
        }
      }

    case SECURE_BUILD_DISCOVERY_REQUEST_FAIL:
    case SECURE_BUILD_DISCOVERY_RESPONSE_FAIL: {
      return {
        ...state,
        error: { message: action.payload },
      }
    }

    case CLEAR_ERROR:
      {
        return {
          ...state,
          error: {}
        }
      }

    default:
      return state
  }
}

export default secureBuildDiscoveryReducer
