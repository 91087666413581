import {
  GET_SECURE_BUILDS,
  GET_SECURE_BUILDS_FAIL,
  GET_SECURE_BUILDS_SUCCESS,
  GET_SECURE_BUILD_DETAILS,
  GET_SECURE_BUILD_DETAILS_FAIL,
  GET_SECURE_BUILD_DETAILS_SUCCESS,
  CREATE_SECURE_BUILD,
  CREATE_SECURE_BUILD_FAIL,
  CREATE_SECURE_BUILD_SUCCESS,
  UPDATE_SECURE_BUILD,
  UPDATE_SECURE_BUILD_FAIL,
  UPDATE_SECURE_BUILD_SUCCESS,
  CLEAR_ERROR,
  CLEAR_ACTION_RESULT,
  RESET
} from "./actionTypes"

export const clearActionResult = () => ({
  type: CLEAR_ACTION_RESULT,
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})

export const reset = () => ({
  type: RESET
})

export const getSecureBuilds = (page, size, sortField, sortOrder, search) => ({
  type: GET_SECURE_BUILDS,
  payload: { page, size, sortField, sortOrder, search }
})

export const getSecureBuildsSuccess = (devicePage) => ({
  type: GET_SECURE_BUILDS_SUCCESS,
  payload: devicePage
})

export const getSecureBuildsFail = error => ({
  type: GET_SECURE_BUILDS_FAIL,
  payload: error,
})

export const getSecureBuildDetails = () => ({
  type: GET_SECURE_BUILD_DETAILS,
})

export const getSecureBuildDetailsSuccess = device => ({
  type: GET_SECURE_BUILD_DETAILS_SUCCESS,
  payload: device,
})

export const getSecureBuildDetailsFail = error => ({
  type: GET_SECURE_BUILD_DETAILS_FAIL,
  payload: error,
})

export const createSecureBuild = (device) => ({
  type: CREATE_SECURE_BUILD,
  payload: device,
})

export const createSecureBuildSuccess = device => ({
  type: CREATE_SECURE_BUILD_SUCCESS,
  payload: device,
})

export const createSecureBuildFail = error => ({
  type: CREATE_SECURE_BUILD_FAIL,
  payload: error,
})

export const updateSecureBuild = (device) => ({
  type: UPDATE_SECURE_BUILD,
  payload: device,
})

export const updateSecureBuildSuccess = device => ({
  type: UPDATE_SECURE_BUILD_SUCCESS,
  payload: device,
})

export const updateSecureBuildFail = error => ({
  type: UPDATE_SECURE_BUILD_FAIL,
  payload: error,
})
