import {
  GET_SECURE_ELEMENT_SESSION,
  GET_SECURE_ELEMENT_SESSION_FAIL,
  GET_SECURE_ELEMENT_SESSION_SUCCESS,
  INIT_SECURE_ELEMENT_SESSION,
  INIT_SECURE_ELEMENT_SESSION_FAIL,
  INIT_SECURE_ELEMENT_SESSION_SUCCESS,
  EXCHANGE_SECURE_ELEMENT_SESSION,
  EXCHANGE_SECURE_ELEMENT_SESSION_FAIL,
  EXCHANGE_SECURE_ELEMENT_SESSION_SUCCESS,
  VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION,
  VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION_FAIL,
  VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION_SUCCESS,
  CLEAR_ERROR,
  RESET
} from "./actionTypes"

export const clearError = () => ({
  type: CLEAR_ERROR,
})

export const reset = () => ({
  type: RESET
})

export const getSecureElementSession = () => ({
  type: GET_SECURE_ELEMENT_SESSION,
})

export const getSecureElementSessionSuccess = session => ({
  type: GET_SECURE_ELEMENT_SESSION_SUCCESS,
  payload: session,
})

export const getSecureElementSessionFail = error => ({
  type: GET_SECURE_ELEMENT_SESSION_FAIL,
  payload: error,
})

export const initSecureElementSession = (session) => ({
  type: INIT_SECURE_ELEMENT_SESSION,
  payload: session,
})

export const initSecureElementSessionSuccess = session => ({
  type: INIT_SECURE_ELEMENT_SESSION_SUCCESS,
  payload: session,
})

export const initSecureElementSessionFail = error => ({
  type: INIT_SECURE_ELEMENT_SESSION_FAIL,
  payload: error,
})

export const exchangeSecureElementSession = (session) => ({
  type: EXCHANGE_SECURE_ELEMENT_SESSION,
  payload: session,
})

export const exchangeSecureElementSessionSuccess = session => ({
  type: EXCHANGE_SECURE_ELEMENT_SESSION_SUCCESS,
  payload: session,
})

export const exchangeSecureElementSessionFail = error => ({
  type: EXCHANGE_SECURE_ELEMENT_SESSION_FAIL,
  payload: error,
})

export const verifyExchangeSecureElementSession = (session) => ({
  type: VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION,
  payload: session,
})

export const verifyExchangeSecureElementSessionSuccess = session => ({
  type: VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION_SUCCESS,
  payload: session,
})

export const verifyExchangeSecureElementSessionFail = error => ({
  type: VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION_FAIL,
  payload: error,
})
