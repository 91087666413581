import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import UserProfile from "../pages/Authentication/UserProfile"
import LockScreen from "../pages/Authentication/auth-lock-screen"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// EG-2 Devices
import EG2ListingPage from "../pages/Provisioning/EG2ListingPage"
import ProvisioningEG2 from "../pages/Provisioning/ProvisioningEG2"


//Devices
import SecureElementListingPage from "../pages/Provisioning/SecureElementListingPage"
import SecureBuildListingPage from "../pages/Provisioning/SecureBuildListingPage"
import ProvisioningEdge from "../pages/Provisioning/ProvisioningEdge"
import ProvisioningSam from "../pages/Provisioning/ProvisioningSam"

//Device Discovery
import DiscoverySecureBuild from "../pages/Provisioning/DiscoverySecureBuild"
import DiscoverySAM from "../pages/Provisioning/DiscoverySAM"

//Reports
import DeviceReport from "../pages/Reports/DeviceReport"
import TrnReport from "../pages/Reports/TrnReport"

//User Management
import UsersList from "../pages/Users/UserList"

//Key Management
import HsmInfo from "../pages/KeyManagement/HsmInfo"
import KeysetList from "../pages/KeyManagement/KeysetList"

//TRN Management
import ProdTrnsList from "../pages/Trns/ProdTrnList"
import RejectedTrns from "../pages/Trns/RejectedTrns"
import TrnsDetails from "../pages/Trns/TrnDetails"
import TrnUpload from "../pages/Trns/TrnUpload"

//Maintenance
import LoginHistory from "../pages/Logins"
import ExceptionList from "../pages/Exceptions"
import AuditsList from "../pages/Audit"

import PageNotImplemented from "../pages/PageNotImplemented"

// Maintenance
import Troubleshooting from "../pages/Maintenance/Troubleshooting"
import ConfigurationsList from "../pages/Configuration"

// Error handler
import Pages404 from "../pages/pages-404"
import Pages500 from "../pages/pages-500"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // EG-2 Devices
  { path: "/devices-eg2", component: EG2ListingPage },
  { path: "/provisioning-eg2", component: ProvisioningEG2 },
  
  //Devices
  // { path: "/production-devices", component: SecureBuildListingPage },
  // { path: "/production-secure-elements", component: SecureElementListingPage },
  // { path: "/provisioning-secure-element", component: ProvisioningSam },
  // { path: "/provisioning-smartbridge", component: ProvisioningEdge },

  // Device Discovery
  { path: "/discovery-eg2", component: DiscoverySecureBuild },
  { path: "/discovery-secure-element", component: DiscoverySAM },

  //Reports
  { path: "/device-report", component: DeviceReport },
  { path: "/trn-report", component: TrnReport },

  //TRN Management
  // { path: "/limit-trns", component: PageNotImplemented },
  { path: "/production-trns", component: ProdTrnsList },
  { path: "/rejected-trns", component: RejectedTrns },
  { path: "/upload-trn", component: TrnUpload },

  //Key Management
  { path: "/keysetList", component: KeysetList },
  { path: "/hsm-info", component: HsmInfo },

  //User Management
  { path: "/users", component: UsersList },

  //Maintenance
  { path: "/troubleshooting", component: Troubleshooting },
  { path: "/notification-messages", component: PageNotImplemented },
  { path: "/audit", component: AuditsList },
  { path: "/exception", component: ExceptionList },
  { path: "/login-history", component: LoginHistory },
  { path: "/application-configuration", component: ConfigurationsList },

  //profile
  { path: "/profile", component: UserProfile },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  // { path: "/", exact: true, component: () => <Redirect to="/production-devices" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { authProtectedRoutes, publicRoutes }
