import { call, put, takeEvery } from "redux-saga/effects"

import { GET_AUDITS } from "./actionTypes"
import {
  getAuditsSuccess,
  getAuditsFail,
} from "./actions"


import { apiGetAudits } from "helpers/backend_helper"

function* fetchAudits({ payload }) {
  try {
    const response = yield call(apiGetAudits, {
      params: {
        page: payload.page,
        size: payload.size,
      }
    })
    yield put(getAuditsSuccess({
        content: response.content,
        page: response.number,
        size: response.size,
        totalPages: response.totalPages,
        totalElements: response.totalElements,
      }))
  } catch (error) {
    yield put(getAuditsFail(error))
  }
}

function* auditsSaga() {
  yield takeEvery(GET_AUDITS, fetchAudits)
}

export default auditsSaga