import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import {
  Card, CardBody, Col, Container, Row
} from "reactstrap";
import { getRejectedTrns, clearState } from "store/trns/actions";
import RejectedTrnColumns from "./RejectedTrnColumns";
import RejectedTrnDetailsPopup from "./RejectedTrnDetailsPopup";


class RejectedTrns extends Component {

  constructor(props) {
    super(props)
    this.state = {
      content: [],
      page: 1,
      size: 5,
      sortField: '',
      sortOrder: '',
      totalPages: 0,  
      totalElements: -1,

      actionResult: {},
      isShowingDetailsDlg: false,
      currentRow: {},
    }
  }

  componentDidMount() {
    const { onSendAction_GetRejectedTrns } = this.props
    const { page, size, sortField, sortOrder } = this.state
    onSendAction_GetRejectedTrns(page, size, sortField, sortOrder)
  }

  componentWillUnmount() {
    this.props.onSendAction_ClearState()
  }

  componentDidUpdate(prevProps, prevState) {
    const { contentPage, actionResult } = this.props

    var serverResponseStates = {}
    var gridViewStates = {}

    if (!isEmpty(actionResult) && !isEqual(prevProps.actionResult, actionResult)) {
      serverResponseStates= { actionResult}
    }
    if (!isEmpty(contentPage)) {
      if (!isEqual(prevProps.contentPage, contentPage) ) {
        gridViewStates= {
          content: contentPage.content,
          page: contentPage.page + 1,
          size: contentPage.size,
          totalPages: contentPage.totalPages,
          totalElements: contentPage.totalElements,
          sortField: contentPage.sortField,
          sortOrder: contentPage.sortOrder,
        }
      }
    }

    if (!isEmpty(serverResponseStates) || !isEmpty(gridViewStates)) {
      this.setState({...serverResponseStates, ...gridViewStates})
    }
  }

  onPageChange = (page, sizePerPage) => {
    const { onSendAction_GetRejectedTrns } = this.props
    const { size, sortField, sortOrder } = this.state
    onSendAction_GetRejectedTrns(page, size, sortField, sortOrder)
  }

  onSort = (field, order) => {
    const { onSendAction_GetRejectedTrns } = this.props
    const { page, size } = this.state
    onSendAction_GetRejectedTrns(page, size, field, order);
  }

  onToggle = (currentRow) => {
    let mode = !this.state.isShowingDetailsPopup
    this.setState({currentRow, isShowingDetailsPopup: mode})
  }

  renderDetailsPopup = () => {
    const { currentRow } = this.state
    if (currentRow === undefined || currentRow === null) return null

    let data = {
      name: currentRow.trnName, 
      trnVersion:currentRow.trnVersion, 
      firmwareMetadata:currentRow.firmwareMetaDataInfo,
      rejectedReason: currentRow.rejectedReason}
    return  this.state.isShowingDetailsPopup ? <RejectedTrnDetailsPopup 
              data={data}
              modal={this.state.isShowingDetailsPopup}
              onToggle={this.onToggle}
              readOnly={true} /> : null
  }

  render() {
    return (
      <Fragment >
        <div className="page-content">
          {this.renderDetailsPopup()}
          <Container fluid>
            <Breadcrumbs title="TRN Management" breadcrumbItem="Rejected TRNS" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {this.renderDataGrid()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment >
    );
  }

  onTableChange = () => {

  }

  renderDataGrid() {
    const { content, page, size, totalPages, totalElements } = this.state
    const pageOptions = {
      page: page,
      sizePerPage: size,
      totalSize: totalElements,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
    }
    return <PaginationProvider
      pagination={paginationFactory(pageOptions)}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="id"
          data={content || []}
          columns={RejectedTrnColumns(this.onToggle, this.onSort)}
          bootstrap4
        >
          {toolkitProps => (
            <Fragment>
              <Row>
                <Col xl="12">
                  <div>
                    <BootstrapTable
                      keyField={"id"}
                      responsive
                      remote
                      hover={true}
                      bordered={false}
                      striped={false}
                      classes={"table table-centered table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      onTableChange={this.onTableChange}
                      {...paginationTableProps} />
                  </div>
                </Col>
              </Row>
              { totalPages > 1 ?
                <Row className="align-items-md-center mt-30">
                  <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                    <PaginationListStandalone
                      {...paginationProps} />
                  </Col>
                </Row> : null
              }
            </Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>;
  }
}

RejectedTrns.propTypes = {
  contentPage: PropTypes.object,
  actionResult: PropTypes.object,
  onSendAction_GetRejectedTrns: PropTypes.func,
  onSendAction_ClearState: PropTypes.func,
}

const mapStateToProps = ({ reducerRejectedTrn }) => ({
  contentPage: reducerRejectedTrn.contentPage,
  actionResult: reducerRejectedTrn.actionResult,
})

const mapDispatchToProps = dispatch => ({
  onSendAction_GetRejectedTrns: (page, size, sortField, sortOrder) => dispatch(getRejectedTrns(page - 1, size, sortField, sortOrder)),
  onSendAction_ClearState: () => dispatch(clearState())
})

export default connect(mapStateToProps, mapDispatchToProps)(RejectedTrns)
