import { toDatetimeString } from "../../helpers/app_helper"

const TrnAuditColumns = () => [
  {
    dataField: "action",
    text: "Action",
    sort: false,
    headerStyle: (colum, colIndex) => {
      return { width: '200px' };
    },
  },  
  {
    dataField: "description",
    text: "Description",
    sort: false,
  },
  {
    dataField: "status",
    text: "Status",
    sort: false,
    headerStyle: (colum, colIndex) => {
      return { width: '70px' };
    },
  },
  {
    text: "Date Time",
    dataField: "createdDate",
    align: "center",
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{toDatetimeString(new Date(row.createdDate))}</p>
      </>
    ),
    headerStyle: (colum, colIndex) => {
      return { width: '160px', textAlign: 'center' };
    },
  },
]
export default TrnAuditColumns
