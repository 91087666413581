import {
  CLEAR_ACTION_RESULT,
  CLEAR_STATE,
  GET_TRNS,
  GET_TRNS_SUCCESS,
  GET_TRNS_FAIL,
  GET_TRNS_VERSIONS,
  GET_TRNS_VERSIONS_SUCCESS,
  GET_TRNS_VERSIONS_FAIL,
  SEARCHING_TRNS,
  SEARCHING_TRNS_SUCCESS,
  SEARCHING_TRNS_FAIL,
  GET_TRN_AUDITS,
  GET_TRN_AUDITS_SUCCESS,
  GET_TRN_AUDITS_FAIL,
  GET_REJECTED_TRNS,
  GET_REJECTED_TRNS_SUCCESS,
  GET_REJECTED_TRNS_FAIL,
  SELECT_FOR_PROVISIONING,
  SELECT_FOR_PROVISIONING_SUCCESS,
  SELECT_FOR_PROVISIONING_FAIL
} from "./actionTypes"


export const clearState = () => ({
  type: CLEAR_STATE,
})

export const clearActionResult = () => ({
  type: CLEAR_ACTION_RESULT,
})

export const getTrns = (page, size) => ({
  type: GET_TRNS,
  payload: { page, size }
})

export const getTrnsSuccess = (contentPage) => ({
  type: GET_TRNS_SUCCESS,
  payload: { contentPage },
})

export const getTrnsFail = error => ({
  type: GET_TRNS_FAIL,
  payload: error,
})

export const getRejectedTrns = (page, size, sortField, sortOrder) => ({
  type: GET_REJECTED_TRNS,
  payload: { page, size, sortField, sortOrder }
})

export const getRejectedTrnsSuccess = (contentPage) => ({
  type: GET_REJECTED_TRNS_SUCCESS,
  payload: { contentPage },
})

export const getRejectedTrnsFail = error => ({
  type: GET_REJECTED_TRNS_FAIL,
  payload: error,
})

export const getTrnsVersions = (page, size, sortField, sortOrder, search) => ({
  type: GET_TRNS_VERSIONS,
  payload: { page, size, sortField, sortOrder, search }
})

export const getTrnsVersionsSuccess = (contentPage) => ({
  type: GET_TRNS_VERSIONS_SUCCESS,
  payload: { contentPage },
})

export const getTrnsVersionsFail = error => ({
  type: GET_TRNS_VERSIONS_FAIL,
  payload: error,
})

export const searchingTRNs = (page, size, sortField, sortOrder, search) => ({
  type: SEARCHING_TRNS,
  payload: { page, size, sortField, sortOrder, search }
})

export const searchingTRNsSuccess = (page) => ({
  type: SEARCHING_TRNS_SUCCESS,
  payload: page
})

export const searchingTRNsFail = error => ({
  type: SEARCHING_TRNS_FAIL,
  payload: error,
})

export const getTrnAudits = (trnId, verId, page, size) => ({
  type: GET_TRN_AUDITS,
  payload: { trnId, verId, page, size }
})

export const getTrnAuditsSuccess = contentPage => ({
  type: GET_TRN_AUDITS_SUCCESS,
  payload: { contentPage },
})

export const getTrnAuditsFail = error => ({
  type: GET_TRN_AUDITS_FAIL,
  payload: error,
})

export const selectForProvisioning = (id) => ({
  type: SELECT_FOR_PROVISIONING,
  payload: id
})

export const selectForProvisioningSuccess = (id) => ({
  type: SELECT_FOR_PROVISIONING_SUCCESS,
  payload: id
})

export const selectForProvisioningFail = error => ({
  type: SELECT_FOR_PROVISIONING_FAIL,
  payload: error
})