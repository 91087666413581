import {
  GET_TRN_UPLOAD,
  GET_TRN_UPLOAD_FAIL,
  GET_TRN_UPLOAD_SUCCESS
} from "./actionTypes"

export const getTrnUpload = () => ({
  type: GET_TRN_UPLOAD,
})

export const getTrnUploadSuccess = hsmInfo => ({
  type: GET_TRN_UPLOAD_SUCCESS,
  payload: hsmInfo,
})

export const getTrnUploadFail = error => ({
  type: GET_TRN_UPLOAD_FAIL,
  payload: error,
})
