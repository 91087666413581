import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
  Tooltip
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

import { isEmpty, map, size } from "lodash"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import KeysetSelection from "../../components/Keysets/KeysetSelection"
import TRNVersionSelection from "../../components/TRN/TRNVersionSelection"
import DiscoverySecureBuildView from "../../components/Discovery/DiscoverySecureBuildView"

//Import edge devices helper
import { getConnector, disposeConnector, validateDiscoveryDeviceText } from "../../helpers/edge_helper"

import { getSecureBuildDetails, createSecureBuild, clearError as clearSecureBuildError, reset as deviceStoreReset } from "store/secureBuild/actions"
import { initSecureBuildSession, exchangeSecureBuildSession, clearError as clearSecureBuildSessionError, reset as sessionStoreReset } from "store/secureBuildSession/actions"
import { createSecureBuildAudit } from "store/secureBuildAudit/actions"

import { getSecureElementDetails, createSecureElement, clearError as clearSecureElementError, reset as secureElementStoreReset } from "store/secureElement/actions"
import { initSecureElementSession, exchangeSecureElementSession, verifyExchangeSecureElementSession, clearError as clearSecureElementSessionError, reset as secureElementSessionStoreReset } from "store/secureElementSession/actions"
import { createSecureElementAudit } from "store/secureElementAudit/actions"

import { getSamDiscoveryRequest, getSamDiscoveryResponse, clearError as clearSamDiscoveryError } from "store/samDiscovery/actions"

export const ProvisioningEG2InitState = () => {
  return {
      activeTab: 1,
      activeTabProgress: 1,
      progressInterval: 20, // 5 steps, each step is 20%
      progressValue: 20,

      deviceSelected: [],
      toggleResetDiscovery: false,
      keysetSelected: undefined,
      trnSelected: undefined,

      deviceProvisioning: [],
      deviceProvisioned: [],
      successDevices: [],
      failureDevices: [],
      confirm_provisioning: false,
      provisioning_starting: false,
      provisioning_started: false,

      // secure build
      current_sb_native: undefined,
      current_sb: undefined,
      current_sb_session: undefined,

      // secure element
      current_se_native: undefined,
      current_se: undefined,
      current_se_session: undefined,
      current_verify_se_session: undefined,

      // secure element discovery
      samDiscoveryRequest: undefined,
      samDiscoveryRequired: false,
      samDiscoveryResponse: undefined,
      rawSams: [],
      samDiscoveryDevices: [],

      connector: undefined,
      autoDiscoveried: false,
      showLogForDevice: undefined,
      tooltipId: undefined
  };
}

class ProvisioningEG2 extends Component {
  constructor(props) {
    super(props)
    this.state = ProvisioningEG2InitState()
    this.toggle_tab_progress.bind(this)
    this.start_provisioning.bind(this)
  }

  componentDidMount() {
    this.cleanup_data();
    const { onSamDiscoveryRequest } = this.props
    onSamDiscoveryRequest()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { current_sb, current_sb_session, error_sb, error_sb_session } = this.props
    const { current_se, current_se_session, current_verify_se_session, error_se, error_se_session } = this.props
    const { provisioning_starting, current_sb_native, current_se_native } = this.state;
    if (provisioning_starting !== prevState.provisioning_starting) {
      console.log("Start produce next device");
      this.produce_next_sb();
      return;
    }
    // Change to Discovery Devices Tab
    if (this.state.activeTabProgress !== prevState.activeTabProgress && this.state.activeTabProgress === 2) {
      if (!this.state.autoDiscoveried) {
        //this.handleDiscovery();
        //return;
      }
    }

    // SecureElement Discovery
    const { onSamDiscoveryResponse } = this.props
    const { rawSams } = this.state
    if (rawSams !== undefined && rawSams.length > 0 && rawSams !== prevState.rawSams) {
      // found SAM devices, send them to service for parsing
      let response = {};
      response.encryptedDevices = rawSams;
      onSamDiscoveryResponse(response);
      return;
    }
    // got samDiscoveryRequest from service, update state before call discovery SAM devices
    if (this.props.samDiscoveryRequest !== undefined && this.props.samDiscoveryRequest !== prevProps.samDiscoveryRequest) {
      this.setState({
        samDiscoveryRequest: this.props.samDiscoveryRequest
      });
      return;
    }
    // got samDiscoveryResponse from service
    if (this.props.samDiscoveryResponse !== undefined && this.props.samDiscoveryResponse !== prevProps.samDiscoveryResponse) {
      let devices = [...this.props.samDiscoveryResponse.devices];
      let samDiscoveried = [];
      for (let d of devices) {
        d.allowSelect = (d.error === undefined || d.error === null || d.error === "")
                         && d.serial !== undefined && d.serial !== null && d.serial !== "";
        if (d.allowSelect) {
          d.requiredVerification = true;
          samDiscoveried.push(d);
        }
      }
      // discoveried all SAM Device, start provision first SB
      this.setState({
        samDiscoveryResponse: this.props.samDiscoveryResponse,
        rawSams: [],
        samDiscoveryDevices: samDiscoveried
      }, this.produce_next_sb);
      return;
    }

    // SecureElement
    if (prevProps.error_se !== error_se && error_se !== undefined && error_se.message !== undefined) {
      this.append_se_status(current_se_native, error_se.message);
      releaseExtensionConnection(() => {
        // device cannot created on service, so we must create an instance as service instance
        let failedDevice = this.to_se_service(current_se_native);
        this.add_se_failure_and_continue(failedDevice);
      });
      return;
    }

    if (prevProps.error_se_session !== error_se_session && error_se_session !== undefined && error_se_session.message !== undefined) {
      this.append_se_status(current_se_native, error_se_session.message);
      releaseExtensionConnection(() => {
        this.add_se_failure_and_continue(current_se_session.secureElement);
      });
      return;
    }

    if (prevProps.current_se !== current_se && typeof(current_se) != "undefined" && typeof(current_se.id) != "undefined") {
      this.setState({ current_se: current_se });
      // new device requested
      console.log("[" + current_se.serial + "]: Requested on service");
      // request new session
      this.request_init_se_session(current_se);
      return;
    }
    if (prevProps.current_se_session !== current_se_session && typeof(current_se_session) != "undefined" && typeof(current_se_session.id) != "undefined") {
      this.setState({ current_se_session: current_se_session });
      if (typeof(current_se_session) != "undefined" && typeof(current_se_session.secureElement) != "undefined") {
        this.setState({ current_se: current_se_session.secureElement });
      }
      if (typeof(current_se_session) != "undefined"
          && (typeof(prevProps.current_se_session) == "undefined"
            || typeof(prevProps.current_se_session.id) == "undefined"
            || prevProps.current_se_session.id != current_se_session.id
            || typeof(prevProps.current_se_session.requestKey) == "undefined"
            || prevProps.current_se_session.requestKey != current_se_session.requestKey)) {
        console.log("[" + current_se_session.secureElement.serial + "]: Session changed -> " + current_se_session.requestKey);
        this.request_exchange_se_session(current_se_session);
        return;
      }
    }
    if (prevProps.current_verify_se_session !== current_verify_se_session && typeof(current_verify_se_session) != "undefined") {
      this.setState({ current_verify_se_session: current_verify_se_session });
      if (typeof(current_verify_se_session) != "undefined"
          && (typeof(prevProps.current_verify_se_session) == "undefined"
            || typeof(prevProps.current_verify_se_session.requestKey) == "undefined"
            || prevProps.current_verify_se_session.requestKey != current_verify_se_session.requestKey)) {
        console.log("[" + current_verify_se_session.currentKeyId + "]: Session changed -> " + current_verify_se_session.requestKey);
        this.request_verify_exchange_sb_session(current_verify_se_session);
        return;
      }
    }

    // SecureBuild
    if (prevProps.error_sb !== error_sb && error_sb !== undefined && error_sb.message !== undefined) {
      this.append_sb_status(current_sb_native, error_sb.message);
      releaseExtensionConnection(() => {
        // device cannot created on service, so we must create an instance as service instance
        let failedDevice = this.to_sb_service(current_sb_native);
        this.add_sb_failure_and_continue(failedDevice);
      });
      return;
    }

    if (prevProps.error_sb_session !== error_sb_session && error_sb_session !== undefined && error_sb_session.message !== undefined) {
      this.append_sb_status(current_sb_native, error_sb_session.message);
      releaseExtensionConnection(() => {
        this.add_sb_failure_and_continue(current_sb_session.secureBuild);
      });
      return;
    }

    if (prevProps.current_sb !== current_sb) {
      this.setState({ current_sb: current_sb });
      // new device requested
      if (current_sb !== undefined) {
        console.log("[" + current_sb.deviceId + "]: Requested on service");
        // request new session
        this.request_init_sb_session(current_sb);
        return;
      }
    }

    if (prevProps.current_sb_session !== current_sb_session && current_sb_session !== undefined
          && current_sb_session.id !== undefined && current_sb_session.secureBuild !== undefined) {
      this.setState({
        current_sb_session: current_sb_session,
        current_sb: current_sb_session.secureBuild
      });
      if (prevProps.current_sb_session === undefined || prevProps.current_sb_session.id === undefined) {
        console.log("[" + current_sb_session.secureBuild.deviceId + "]: Session created on service");
      }
      if (prevProps.current_sb_session === undefined
            || prevProps.current_sb_session.id === undefined
            || prevProps.current_sb_session.id !== current_sb_session.id
            || prevProps.current_sb_session.requestKey === undefined
            || prevProps.current_sb_session.requestKey !== current_sb_session.requestKey
            || current_sb_session.requestKey === "Validation" // Validation allow to exchange many times
            ) {
        console.log("[" + current_sb_session.secureBuild.serial + "]: Session changed -> " + current_sb_session.requestKey);
        this.request_exchange_sb_session(current_sb_session);
        return;
      }
    }
  }

  releaseExtensionConnection = (callback) => {
    disposeConnector();
    this.setState({connector: undefined}, callback);
  }

  add_sb_failure_and_continue = (failedDevice) => {
    failedDevice.completed = false;
    let provisionedDevices = [...this.state.deviceProvisioned];
    let failureDevicesCopy = [...this.state.failureDevices];
    provisionedDevices.push(failedDevice);
    failureDevicesCopy.push(failedDevice);
    this.setState({
      deviceProvisioned: provisionedDevices,
      failureDevices: failureDevicesCopy
    }, this.produce_next_sb);
  }

  add_se_failure_and_continue = (se) => {
    se.completed = false;
    let sbNative = this.state.deviceProvisioning.find(x => x.serial === se.serial);
    sbNative.samStatus = "FAILED";
    this.update_sb_on_local(sbNative);
    let sb = this.to_sb_service(sbNative);
    this.add_sb_failure_and_continue(sb);
  }

  change_se_status = (serial, status) => {
    let sbNative = this.state.deviceProvisioning.find(x => x.serial === serial);
    sbNative.samStatus = status;
    this.update_sb_on_local(sbNative);
  }

  cleanup_data = () => {
    // cleanup data of ScureBuild device and session store
    this.props.onDeviceStoreReset();
    this.props.onSessionStoreReset();
    // cleanup data of ScureElement device and session store
    this.props.onSecureElementStoreReset();
    this.props.onSecureElementSessionStoreReset();
  }

  keyset_changed = (keyset) => {
    this.setState({
      keysetSelected: keyset
    });
  }

  trn_changed = (trns) => {
    if (trns === undefined || trns.length === 0) {
      this.setState({
        trnSelected: undefined
      });
      return;
    }
    this.setState({
      trnSelected: trns[0]
    });
  }

  discovery_sb_changed = (devices) => {
    // if any SB required provision SE, we need to turn on samDiscoveryRequired flag
    let samDiscoveryRequired = false;
    for (let d of devices) {
      if (d.secureElementProvisioned !== true) {
        samDiscoveryRequired = true;
        break;
      }
    }
    this.setState({
      deviceSelected: devices,
      samDiscoveryRequired: samDiscoveryRequired
    });
  }

  start_provisioning () {
    if (isEmpty(this.state.deviceSelected)) {
      return;
    }
    if (this.state.keysetSelected === undefined) {
      return;
    }
    if (this.state.trnSelected === undefined) {
      return;
    }
    console.log("Start Provisioning...");
    let deviceProvisioning = [...this.state.deviceSelected];
    this.setState({
      deviceSelected: [],
      deviceProvisioning: deviceProvisioning,
      provisioning_starting: true,
      provisioning_started: true
    });
  }

  discovery_se = () => {
    const { samDiscoveryRequest } = this.state;
    // reset old discoveried SAM before starting discovery again
    this.setState({
      samDiscoveryResponse: undefined,
      rawSams: [],
      samDiscoveryDevices: [],
      samDiscoveryRequired: false,
    });
    let discovery_connector = getConnector();
    var that = this;
    // handle success callback
    let successHandler = function (devices) {
      if (devices === undefined || size(devices) === 0) {
        that.produce_next_sb();
      } else {
        that.setState({
          rawSams: devices
        });
      }
    };
    let failureHandler = function (error) {
      that.produce_next_sb();
    };
    if (samDiscoveryRequest.useMDNS) {
      // Using mDNS to discovery Secure Element Devices
      discovery_connector.discoveryMDNSSams(
        samDiscoveryRequest.address,
        samDiscoveryRequest.port,
        samDiscoveryRequest.message,
        samDiscoveryRequest.waitingTime
      ).then(successHandler)
       .catch(failureHandler)
       .finally(() => {
         disposeConnector();
      });
    } else if (samDiscoveryRequest.useUDP) {
      // Using UDP Ping request to discovery Secure Element Devices
      // address, port, message, waitingTime
      discovery_connector.discoveryUDPSams(
        samDiscoveryRequest.address,
        samDiscoveryRequest.port,
        samDiscoveryRequest.message,
        samDiscoveryRequest.waitingTime
      ).then(successHandler)
       .catch(failureHandler)
       .finally(() => {
         disposeConnector();
      });
    } else {
      disposeConnector();
      failureHandler("Cannot detect the discovery mode from service. Please contact administrator");
    }
  };

  produce_next_sb = () => {
    // reset variables for se and sb
    this.setState({
      connector: undefined,
      current_sb_native: undefined,
      current_sb: undefined,
      current_sb_session: undefined,
      current_se_native: undefined,
      current_se: undefined,
      current_se_session: undefined
    });
    let nextDevice = undefined;
    const { deviceProvisioning, deviceProvisioned } = this.state;
    for (let i = 0; i < deviceProvisioning.length; i++) {
      let found = false;
      for (let j = 0; j < deviceProvisioned.length; j++) {
        if (deviceProvisioning[i].deviceId === deviceProvisioned[j].deviceId) {
          found = true;
          break;
        }
      }
      if (!found) {
        nextDevice = deviceProvisioning[i];
        break;
      }
    }
    if (nextDevice === undefined) {
      this.setState({
        provisioning_starting: false
      });
    } else {
      // only discovery all current SAM device onetime
      if (this.state.samDiscoveryRequired === true) {
        this.append_sb_status(nextDevice, "Initializing...");
        this.discovery_se();
        return;
      }
      if (nextDevice.secureElementProvisioned === true) {
        console.log("Produce SB " + nextDevice.deviceId + "/" + nextDevice.serial);
        this.request_new_sb(nextDevice);
      } else {
        console.log("Produce SE " + nextDevice.deviceId + "/" + nextDevice.serial);
        this.request_new_se(nextDevice);
      }
    }
  }

  to_sb_service  = (nativeDevice) => {
    return {
      imageVersion: nativeDevice.imageVersion,
      engineVersion: nativeDevice.engineVersion,
      deviceModuleLibVersion: nativeDevice.deviceModuleLibVersion,
      doorProcFWVersion: nativeDevice.doorProcFWVersion,
      readerProcFWVersion: nativeDevice.readerProcFWVersion,
      ip: nativeDevice.ip,
      connectionMode: nativeDevice.connectionMode,
      model: nativeDevice.model,
      deviceId: nativeDevice.deviceId,
      serial: nativeDevice.serial,
      hostName: nativeDevice.hostName,
      dbReady: nativeDevice.dbReady,
      trnVersionId: this.state.trnSelected.selectedVersion.id,
      samStatus: nativeDevice.samStatus,
      doorProcDeviceID: nativeDevice.doorProcDeviceID,
      readerProcDeviceID: nativeDevice.readerProcDeviceID,
      cm4Serial: nativeDevice.cm4Serial,
      macAddress: nativeDevice.macAddress,
    };
  }

  update_sb_on_local = (d) => {
    let provisioningItems = [...this.state.deviceProvisioning];
    for (let i = 0; i < provisioningItems.length; i++) {
      if (provisioningItems[i].deviceId === d.deviceId) {
        provisioningItems[i] = d;
      }
    }
    this.setState({
      deviceProvisioning: provisioningItems
    });
  };

  append_sb_status = (d, msg) => {
    if (msg === undefined || msg === null || msg === "") {
      return;
    }
    if (d.status === undefined) {
      d.status = [];
    }
    if (msg.includes("Download") && d.status.length > 0) {
      // add or replace last Download message
      let lastIndex = d.status.length - 1;
      let lastMsg = d.status[lastIndex];
      if (lastMsg !== undefined && lastMsg.includes("Download")) {
        d.status[lastIndex] = msg;
      } else {
        d.status.push(msg);
      }
    } else {
      // add more msg
      d.status.push(msg);
    }

    // set last log message
    d.lastStatus = msg;
    console.log("[" + d.deviceId + "]: " + msg);
    this.update_sb_on_local(d);
  };

  request_new_sb = (nativeDevice) => {
    this.setState({
      current_sb_native: nativeDevice
    });
    console.log("[" + nativeDevice.deviceId + "]: Requesting new device");
    this.props.onCreateSecureBuild(this.to_sb_service(nativeDevice));
  };

  request_init_sb_session = (current_sb) => {
    console.log("[" + current_sb.deviceId + "]: Requesting new session");
    let session = {
      secureBuildId: current_sb.id
    };
    this.props.onInitSecureBuildSession(session);
  };

  request_create_sb_audit = (current_sb, current_sb_session, action, description, statusResult) => {
    console.log("[" + current_sb.deviceId + "]: Requesting audit creation");
    let audit = {
      secureBuildId: current_sb.id,
      secureBuildSessionId: current_sb_session.id,
      action: action,
      description: description,
      status: statusResult ? "Success" : "Failure"
    };
    this.props.onCreateSecureBuildAudit(audit);
  };

  request_exchange_sb_session = async (current_sb_session) => {
    const { current_sb_native } = this.state;
    try {
      let current_sb = current_sb_session.secureBuild;
      // transfer versions from service to client device
      current_sb_native.lastTrnVersion = current_sb.lastTrnVersion;
      current_sb_native.engineVersion = current_sb.engineVersion;
      current_sb_native.imageVersion = current_sb.imageVersion;
      current_sb_native.deviceModuleLibVersion = current_sb.deviceModuleLibVersion;
      current_sb_native.doorProcFWVersion = current_sb.doorProcFWVersion;
      current_sb_native.readerProcFWVersion = current_sb.readerProcFWVersion;
      if (this.state.connector === undefined) {
        let conn = getConnector();
        this.setState({
          connector: conn
        });
        let connectAction = "Connecting Firmware Device [" + current_sb_native.ip + "]";
        this.append_sb_status(current_sb_native, connectAction);
        let connected = await conn.connectTCP(
          current_sb_native.ip,
          6679
        );
        if (!connected) {
          this.append_sb_status(current_sb_native, "Unconnected");
          this.request_create_sb_audit(current_sb, current_sb_session, "Connect", connectAction, false);
          this.releaseExtensionConnection(this.produce_next_sb);
          return;
        } else {
          this.append_sb_status(current_sb_native, "Connected");
          this.request_create_sb_audit(current_sb, current_sb_session, "Connect", connectAction, true);
        }
      }
      const { connector } = this.state;
      // logging prev response
      if (current_sb_session.response !== "" && current_sb_session.response !== null && current_sb_session.response !== undefined) {
        this.append_sb_status(current_sb_native, "-> " + current_sb_session.response);
      }

      if (current_sb.lastTrnVersion === undefined
            || current_sb.lastTrnVersion === null
            || current_sb.lastTrnVersion.status !== "PROVISIONING") {
        this.releaseExtensionConnection();

        let provisionedDevices = [...this.state.deviceProvisioned];
        provisionedDevices.push(current_sb);
        let successDevicesCopy = [...this.state.successDevices];
        let failureDevicesCopy = [...this.state.failureDevices];
        if (current_sb.lastTrnVersion !== null) {
          if (current_sb.lastTrnVersion.status == "COMPLETED") {
            successDevicesCopy.push(current_sb);
            this.append_sb_status(current_sb_native, "Firmware Installed");
            this.append_sb_status(current_sb_native, "- OS Version: " + current_sb.imageVersion);
            this.append_sb_status(current_sb_native, "- APP Version: " + current_sb.engineVersion);
            this.append_sb_status(current_sb_native, "- Device Module Version : " + current_sb.deviceModuleLibVersion);
            this.append_sb_status(current_sb_native, "- Reader Proc Version : " + current_sb.readerProcFWVersion);
            this.append_sb_status(current_sb_native, "- Door Proc Version : " + current_sb.doorProcFWVersion);
            this.append_sb_status(current_sb_native, "Provisioning Successful");
          } else if (current_sb.lastTrnVersion.status == "FAILED") {
            failureDevicesCopy.push(current_sb);
            this.append_sb_status(current_sb_native, "Provisioning Failed");
          }
        }
        this.setState({
          deviceProvisioned: provisionedDevices,
          successDevices: successDevicesCopy,
          failureDevices: failureDevicesCopy
        }, this.produce_next_sb);
        return;
      }
      if (current_sb_session.request !== "" && current_sb_session.request !== null && current_sb_session.request !== undefined) {
        if (current_sb_session.requestKey == "Download") {
          let downloadRequest = JSON.parse(current_sb_session.request);
          this.append_sb_status(current_sb_native, "Downloading Firmware into Device");
          let uploadStarted = await connector.uploadTCP(
              downloadRequest.Link,
              downloadRequest.PackageSize,
              downloadRequest.Name,
              downloadRequest.Version,
              downloadRequest.Hash
          );
          let downloadResponse = {
            Id: downloadRequest.Id,
            Completed: false,
            PackageIndex: 0,
            PackageCount: 0,
            ProgressPercent: 0.0,
            Uploading: false
          };
          if (uploadStarted == true) {
            this.append_sb_status(current_sb_native, "Downloading Firmware into Device [0.00 % completed]");
            do {
              let report = await connector.uploadStatusTCP();
              downloadResponse.Completed = report[0];
              downloadResponse.Uploading = report[1];
              downloadResponse.PackageIndex = report[2];
              downloadResponse.PackageCount = report[3];
              downloadResponse.ProgressPercent = downloadResponse.PackageIndex * 100 / downloadResponse.PackageCount;
              this.append_sb_status(current_sb_native, "Downloading Firmware into Device [" + downloadResponse.ProgressPercent.toFixed(2) + " % completed]");
              // delay 2s
              await new Promise(r => setTimeout(r, 2000));
            } while (downloadResponse.Uploading);
          } else {
            this.append_sb_status(current_sb_native, "Download Firmware into Device Failed");
          }
          current_sb_session.response = JSON.stringify(downloadResponse);
        } else if (current_sb_session.requestKey == "Validation") {
          if (current_sb_native.validationCount === undefined) {
            current_sb_native.validationCount = 1;
          } else {
            current_sb_native.validationCount += 1;
          }
          this.update_sb_on_local(current_sb_native);

          // TRN copied to device, disconnect TCP connection
          //this.append_sb_status(current_sb_native, "Disconnecting");
          let discoveryRequest = JSON.parse(current_sb_session.request);

          let startPollingDevice = false;
          if (current_sb_native.validationCount == 1) {
            await connector.disconnectTCP();
            this.append_sb_status(current_sb_native, "Disconnected");
            let auditDesc = "Disconnected [" + current_sb_native.ip + "]";
            this.request_create_sb_audit(current_sb, current_sb_session, "Disconnect", auditDesc, true);

            this.append_sb_status(current_sb_native, "Rebooting device [waits for " + (discoveryRequest.deplayTime / 1000) + "s]");
            // waiting to make sure that device rebooted and starting installation new TRN
            await new Promise(r => setTimeout(r, discoveryRequest.deplayTime));
            startPollingDevice = true;
          } else if (current_sb_native.validationCount > discoveryRequest.retry) {
            // max retry for serivce validation
            current_sb_session.response = "";
            startPollingDevice = false;
          } else {
            startPollingDevice = true;
          }
          if (startPollingDevice === true) {
            let discoveryRetry = discoveryRequest.retry;
            let count = 1;
            do {
              this.append_sb_status(
                current_sb_native,
                "Verifying firmware installation [timeout=" + (discoveryRequest.pollingTime / 1000) + "s, retryClient=" + count + ", retryService=" + current_sb_native.validationCount + "]");
              try {
                let discoveryDevices = await connector.discoverySecureBuilds(
                  discoveryRequest.address,
                  discoveryRequest.port,
                  discoveryRequest.message,
                  discoveryRequest.pollingTime
                );
                if (discoveryDevices === undefined || size(discoveryDevices) === 0) {
                  // not found the device by mac address after copied TRN
                  current_sb_session.response = "";
                } else {
                  current_sb_session.response = "";
                  for (let discoveryDeviceText of discoveryDevices) {
                    if (validateDiscoveryDeviceText(discoveryDeviceText, current_sb_native.ip)) {
                      current_sb_session.response = discoveryDeviceText;
                      discoveryRetry = 0; // stop discovery
                      break;
                    }
                  }
                }
              } catch (pollingError) {
                // ignore polling error
              } finally {
                --discoveryRetry;
                count++;
              }
            } while (discoveryRetry > 0);
          }
        } else {
          //++this.append_sb_status(current_sb_native, current_sb_session.requestDescription);
          current_sb_session.response = await connector.exchangeTCP(current_sb_session.request);
        }
      }
      this.props.onExchangeSecureBuildSession(current_sb_session);
    } catch (error) {
      console.error(error);
      // got any failure -> disconnect current connection -> show failure error on record -> produce next device
      this.releaseExtensionConnection(() => {
        if (error !== undefined) {
          this.append_sb_status(current_sb_native, error.message === undefined ? error : error.message);
        }
        this.add_sb_failure_and_continue(current_sb_session.secureBuild);
      });
    }
  };

  // Secure Element Methods
  to_se_service  = (seNative) => {
    return {
      ip: seNative.ip,
      connectionMode: seNative.connectionMode,
      serial: seNative.serial,
      cplc: seNative.cplc,
      identifyData: seNative.identifyData,
      appVersion: seNative.appVersion,
      keysetId: this.state.keysetSelected.id
    };
  }

  append_se_status = (se, msg) => {
    if (se == undefined || msg === undefined || msg === null || msg === "") {
      return;
    }
    let sb = this.state.deviceProvisioning.find(x => x.serial == se.serial);
    if (sb === undefined) {
      return;
    }
    this.append_sb_status(sb, msg);
  };

  request_new_se = (sbNative) => {
    let seNative = this.state.samDiscoveryDevices.find(x => x.serial === sbNative.serial);
    if (seNative === undefined) {
      // device cannot created on service, so we must create an instance as service instance
      sbNative.samStatus = "FAILED";
      this.append_sb_status(sbNative, "Not found SAM device");
      let sb = this.to_sb_service(sbNative);
      this.add_sb_failure_and_continue(sb);
    } else {
      this.setState({
        current_sb_native: sbNative,
        current_se_native: seNative
      });
      console.log("[" + sbNative.serial + "]: Requesting new SecureElement");
      this.props.onCreateSecureElement(this.to_se_service(seNative));
    }
  };

  request_init_se_session = (se) => {
    console.log("[" + se.serial + "]: Requesting new session");
    let session = {
      secureElementId: se.id,
      request: "",
      response: ""
    };
    this.props.onInitSecureElementSession(session);
  };

  request_create_se_audit = (se, se_session, action, description, statusResult) => {
    console.log("[" + se.serial + "]: Requesting audit creation");
    let audit = {
      secureElementId: se.id,
      secureElementSessionId: se_session.id,
      action: action,
      description: description,
      status: statusResult ? "Success" : "Failure"
    };
    this.props.onCreateSecureElementAudit(audit);
  };

  request_exchange_se_session = async (session) => {
    const { current_se_native } = this.state;
    try {
      let se = session.secureElement;
      // forward keys from service device to native device
      current_se_native.keys = [...se.keys];
      current_se_native.distinctKeys = [...se.distinctKeys];
      current_se_native.completed = se.completed;
      if (this.state.connector === undefined) {
        let conn = getConnector();
        this.setState({
          connector: conn
        });
        let actionDesc = "Connecting SAM [" + current_se_native.ip + "]";
        this.append_se_status(current_se_native, actionDesc);
        let connected = await conn.connectTCP(
          current_se_native.ip,
          8767
        );
        if (!connected) {
          this.append_se_status(current_se_native, "Unconnected");
          this.request_create_se_audit(se, session, "Connect", actionDesc, false);
          this.releaseExtensionConnection(this.produce_next_sb);
          return;
        } else {
          this.change_se_status(current_se_native.serial, "PROVISIONING");
          this.append_se_status(current_se_native, "Connected");
          this.request_create_se_audit(se, session, "Connect", actionDesc, true);
        }
      }
      const { connector } = this.state;
      // logging prev response
      if (session.response !== "" && session.response !== null && session.response !== undefined) {
        this.append_se_status(current_se_native, "-> " + session.response);
      }

      let processAllKeys = true;
      for (let k of se.keys) {
        if (k.status !== "COMPLETED" && k.status !== "FAILED") {
          processAllKeys = false;
          break;
        }
      }
      let noRequest = session.request === "" || session.request === null || session.request === undefined;
      if (processAllKeys && noRequest) {
        //this.append_se_status(current_se_native, "-------------------");
        //this.append_se_status(current_se_native, "Disconnecting");
        await connector.disconnectTCP();
        this.releaseExtensionConnection(() => {
          this.append_se_status(current_se_native, "Disconnected");
          let auditDesc = "Disconnected [" + current_se_native.ip + "]";
          this.request_create_se_audit(se, session, "Disconnect", auditDesc, true);

          // only change to verify process after all keys provisioned
          if (se.completed && current_se_native.requiredVerification) {
            this.request_verify_exchange_sb_session({
              secureElementId: se.id,
              requestKey: ""
            });
            return;
          }

          let sbNative = this.state.deviceProvisioning.find(x => x.serial === se.serial);
          if (se.completed) {
            this.change_se_status(current_se_native.serial, "COMPLETED");
            // provision SE completed -> continue provision SB
            this.request_new_sb(sbNative);
          } else {
            // provision SE failed -> make this SB failed -> go to next SB
            this.add_se_failure_and_continue(se);
          }
        });
        return;
      }

      if (session.request !== "" && session.request !== null && session.request !== undefined) {
        //this.append_se_status(current_se_native, "-------------------");
        this.append_se_status(current_se_native, session.requestDescription);

        session.response = await connector.exchangeTCP(session.request);

        //this.append_se_status(current_se_native, "Received response for [" + session.requestKey + "]");
      }
      this.props.onExchangeSecureElementSession(session);
    } catch (error) {
      console.error(error);
      // got any failure -> disconnect current connection -> show failure error on record -> produce next device
      this.releaseExtensionConnection(() => {
        if (error !== undefined) {
          this.append_se_status(current_se_native, error.message === undefined ? error : error.message);
        }
        this.add_se_failure_and_continue(session.secureElement);
      });
    }
  };

  request_verify_exchange_sb_session = async (se_session) => {
    const { current_se_native } = this.state;
    try {
      console.log("Verify Key: " + se_session.requestKey);
      let se = se_session.secureElement;
      // uptodate native device from service device
      if (se !== undefined && se !== null && se.keys !== undefined) {
        current_se_native.keys = [...se.keys];
        current_se_native.distinctKeys = [...se.distinctKeys];
        current_se_native.completed = se.completed;
      }
      if (this.state.connector === undefined) {
        let conn = getConnector();
        this.setState({
          connector: conn
        });
        let connectAction = "Connecting SAM [" + current_se_native.ip + "]";
        this.append_se_status(current_se_native, connectAction);
        let connected = await conn.connectTCP(
          current_se_native.ip,
          8767
        );
        if (!connected) {
          this.append_se_status(current_se_native, "Unconnected");
          this.releaseExtensionConnection(this.produce_next_sb);
          return;
        } else {
          this.change_se_status(current_se_native.serial, "VERIFYING");
          this.append_se_status(current_se_native, "Connected");
        }
      }
      const { connector } = this.state;
      // logging prev response
      if (se_session.response !== "" && se_session.response !== null && se_session.response !== undefined) {
        this.append_se_status(current_se_native, "-> " + se_session.response);
      }

      let completed = se_session.secureElement !== undefined && se_session.requestKey === "";
      if (completed) {
        console.log("Completed Verification");
        //this.append_se_status(current_se_native, "-------------------");
        //this.append_se_status(current_se_native, "Disconnecting");
        await connector.disconnectTCP();
        this.releaseExtensionConnection(() => {
          this.append_se_status(current_se_native, "Disconnected");
          let sbNative = this.state.deviceProvisioning.find(x => x.serial === se.serial);
          if (se.completed) {
            this.change_se_status(current_se_native.serial, "COMPLETED");
            // provision SE completed -> continue provision SB
            this.request_new_sb(sbNative);
          } else {
            // provision SE failed -> make this SB failed -> go to next SB
            this.add_se_failure_and_continue(se);
          }
        });
        return;
      }

      if (se_session.request !== "" && se_session.request !== null && se_session.request !== undefined) {
        //this.append_se_status(current_se_native, "-------------------");
        this.append_se_status(current_se_native, se_session.requestDescription);

        se_session.response = await connector.exchangeTCP(se_session.request);

        //this.append_se_status(current_se_native, "Received verification response for [" + se_session.requestKey + "]");
      }
      this.props.onVerifyExchangeSecureElementSession(se_session);
    } catch (error) {
      console.error(error);
      // got any failure -> disconnect current connection -> show failure error on record -> produce next device
      this.releaseExtensionConnection(() => {
        if (error !== undefined) {
          this.append_se_status(current_se_native, error.message === undefined ? error : error.message);
        }
        if (se_session.secureElement !== undefined) {
          this.add_se_failure_and_continue(se_session.secureElement);
        }
      });
    }
  };

  toggle_tab_progress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 5) {
        this.setState({
          activeTabProgress: tab,
        })
        let progressValue = tab * this.state.progressInterval
        this.setState({ progressValue })
      }
    }
  }

  toggle_tooltip (currentId, targetId) {
    if (currentId === targetId) {
      this.setState({ tooltipId: undefined });
    } else {
      this.setState({ tooltipId: targetId });
    }
  };

  render() {
    const { deviceSelected, deviceProvisioning, keysetSelected, trnSelected, tooltipId } = this.state;
    return (
      <Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="EG-2 Devices" breadcrumbItem="Provisioning" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 1
                            })}
                            onClick={() => {
                              if (!this.state.provisioning_started) {
                                this.toggle_tab_progress(1)
                              }
                            }}
                          >
                            <span className="step-number mr-2">01</span>
                            <span>Choose KEYSET</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 2
                            })}
                            onClick={() => {
                              if (!this.state.provisioning_started && this.state.keysetSelected !== undefined) {
                                this.toggle_tab_progress(2)
                              }
                            }}
                          >
                            <span className="step-number mr-2">02</span>
                            <span>Choose TRN</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 3
                            })}
                            onClick={() => {
                              if (!this.state.provisioning_started && this.state.keysetSelected !== undefined && this.state.trnSelected !== undefined) {
                                this.toggle_tab_progress(3)
                              }
                            }}
                          >
                            <span className="step-number mr-2">03</span>
                            Discovery Devices
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 4,
                            })}
                            onClick={() => {
                              if (this.state.keysetSelected !== undefined && this.state.trnSelected !== undefined
                                    && this.state.deviceSelected !== undefined
                                    && this.state.deviceSelected.length > 0) {
                                this.toggle_tab_progress(4)
                              }
                            }}
                          >
                            <span className="step-number mr-2">04</span>
                            Provisioning
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 5,
                            })}
                            onClick={() => {
                              if (this.state.provisioning_started) {
                                this.toggle_tab_progress(5)
                              }
                            }}
                          >
                            <span className="step-number mr-2">05</span>
                            Summary
                          </NavLink>
                        </NavItem>
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={this.state.progressValue}
                        />
                        <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"/>
                      </div>
                      <TabContent
                        activeTab={this.state.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <KeysetSelection onSelectedChanged={key => this.keyset_changed(key)} keysetType={"SECURE_ELEMENT"}/>
                        </TabPane>

                        <TabPane tabId={2}>
                          <TRNVersionSelection multiple={false} onSelectedChanged={trn => this.trn_changed(trn)}/>
                        </TabPane>

                        <TabPane tabId={3}>
                          <DiscoverySecureBuildView selectionMode={true} allowProvisionSAM={true} toggleReset={this.state.toggleResetDiscovery} onSelectedChanged={devices => this.discovery_sb_changed(devices)}/>
                        </TabPane>

                        <TabPane tabId={4}>
                          <div>
                            { !this.state.provisioning_starting && !isEmpty(deviceSelected) && (
                              <div className="button-items">
                                <Button
                                  color="success"
                                  className="btn btn-success btn-lg waves-effect"
                                  onClick={() => {
                                    this.setState({
                                      confirm_provisioning: true,
                                    })
                                  }}
                                >
                                  Start Provisioning
                                </Button>
                              </div>
                              )}
                            <br/>

                            {this.state.provisioning_starting ? (
                              <SweetAlert
                                title="EG-2 device provisioning..."
                                customClass="swal2-progressProvisioning"
                                showConfirm={false}
                                onConfirm={() => {}}
                              >
                                <div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </SweetAlert>
                            ) : null}

                            {keysetSelected === undefined && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please select a KEYSET!
                                  </Alert>
                                </div>
                            )}
                            {trnSelected === undefined && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please select a TRN!
                                  </Alert>
                                </div>
                            )}
                            {keysetSelected !== undefined && trnSelected !== undefined && (
                                <div>
                                  <Alert color="success" role="alert">
                                    Selected KEYSET: {keysetSelected.name}, TRN: {trnSelected.name} ({trnSelected.selectedVersion.trnVer})
                                  </Alert>
                                </div>
                            )}

                            {isEmpty(deviceSelected) && isEmpty(deviceProvisioning) && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please select one or more devices!
                                  </Alert>
                                </div>
                            )}
                            { this.state.provisioning_starting && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please do not close this page during provisioning
                                  </Alert>
                                </div>
                            )}

                            { this.state.confirm_provisioning ? (
                              <SweetAlert
                                title="Are you sure you want to start provisioning?"
                                warning
                                showCancel
                                focusCancelBtn
                                cancelBtnBsStyle="light"
                                confirmBtnBsStyle="danger"
                                confirmBtnText="Start"
                                reverseButtons={true}
                                onConfirm={() => {
                                  this.setState({
                                    confirm_provisioning: false,
                                  });
                                  this.start_provisioning()
                                }}
                                onCancel={() =>
                                  this.setState({
                                    confirm_provisioning: false,
                                  })
                                }
                              >
                                Please do not close this page during provisioning
                              </SweetAlert>
                            ) : null}

                            { this.state.showLogForDevice ? (
                              <SweetAlert
                                title="Provisioning Output"
                                info
                                confirmBtnBsStyle="success"
                                onConfirm={() => {
                                  this.setState({
                                    showLogForDevice: undefined,
                                  });
                                }}
                              >
                                <div style={{ whiteSpace: "unset", width: "400px", textAlign: "start" }}>
                                  <p className="mb-0">
                                    <em>Device Address: <b>{this.state.showLogForDevice.ip}</b></em>
                                  </p>
                                  <p className="mb-0">
                                    <em>Device ID: <b>{this.state.showLogForDevice.deviceId}</b></em>
                                  </p>
                                  <p className="mb-0">
                                    <em>SAM: <b>{this.state.showLogForDevice.serial}</b></em>
                                  </p>
                                  <p className="mb-0">
                                    <em>Output:</em>
                                  </p>
                                  {map(this.state.showLogForDevice.status, (s, si) => (
                                    <p className="mb-0" key={si}>
                                      <em>- {s}</em>
                                    </p>
                                  ))}
                                </div>

                              </SweetAlert>
                            ) : null}

                            {!isEmpty(deviceSelected) && (
                              <div className="table-responsive">
                                <Table className="table table-nowrap table-centered">
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Device Address</th>
                                      <th scope="col">Device ID</th>
                                      <th scope="col">SAM Serial</th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedOSHeader"} target="selectedOSHeader"
                                              toggle={() => this.toggle_tooltip(tooltipId, "selectedOSHeader")}>OS Version</Tooltip>
                                          <div id="selectedOSHeader">OS</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedAppHeader"} target="selectedAppHeader"
                                              toggle={() => this.toggle_tooltip(tooltipId, "selectedAppHeader")}>APP Version</Tooltip>
                                          <div id="selectedAppHeader">APP</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedDeviceHeader"} target="selectedDeviceHeader"
                                              toggle={() => this.toggle_tooltip(tooltipId, "selectedDeviceHeader")}>Device Module Version</Tooltip>
                                          <div id="selectedDeviceHeader">Device</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedReaderHeader"} target="selectedReaderHeader"
                                              toggle={() => this.toggle_tooltip(tooltipId, "selectedReaderHeader")}>Reader Proc Version</Tooltip>
                                          <div id="selectedReaderHeader">Reader</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedDoorHeader"} target="selectedDoorHeader"
                                            toggle={() => this.toggle_tooltip(tooltipId, "selectedDoorHeader")}>Door Proc Version</Tooltip>
                                          <div id="selectedDoorHeader">Door</div>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {map(deviceSelected, (item, index) => (
                                      <tr key={index}>
                                        <td>{item.ip}</td>
                                        <td>{item.deviceId}</td>
                                        <td><div style={{ width: "110px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{item.serial}</div></td>
                                        <td>{item.imageVersion}</td>
                                        <td>{item.engineVersion}</td>
                                        <td>{item.deviceModuleLibVersion}</td>
                                        <td>{item.readerProcFWVersion}</td>
                                        <td>{item.doorProcFWVersion}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                            {!isEmpty(deviceProvisioning) && (
                              <div className="table-responsive">
                                <Table className="table table-nowrap table-centered">
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Device Address</th>
                                      <th scope="col">Device ID</th>
                                      <th scope="col" style={{ width: "120px" }}>SAM Serial</th>
                                      {/* <th scope="col">Loading TRN Version</th> */}
                                      <th scope="col" style={{ width: "250px" }}>Process</th>
                                      <th scope="col" style={{ width: "100px" }}>Status</th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningOSHeader"} target="provisioningOSHeader"
                                              toggle={() => this.toggle_tooltip(tooltipId, "provisioningOSHeader")}>OS Version</Tooltip>
                                          <div id="provisioningOSHeader">OS</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningAppHeader"} target="provisioningAppHeader"
                                              toggle={() => this.toggle_tooltip(tooltipId, "provisioningAppHeader")}>APP Version</Tooltip>
                                          <div id="provisioningAppHeader">APP</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningDeviceHeader"} target="provisioningDeviceHeader"
                                              toggle={() => this.toggle_tooltip(tooltipId, "provisioningDeviceHeader")}>Device Module Version</Tooltip>
                                          <div id="provisioningDeviceHeader">Device</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningReaderHeader"} target="provisioningReaderHeader"
                                              toggle={() => this.toggle_tooltip(tooltipId, "provisioningReaderHeader")}>Reader Proc Version</Tooltip>
                                          <div id="provisioningReaderHeader">Reader</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningDoorHeader"} target="provisioningDoorHeader"
                                            toggle={() => this.toggle_tooltip(tooltipId, "provisioningDoorHeader")}>Door Proc Version</Tooltip>
                                          <div id="provisioningDoorHeader">Door</div>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {map(deviceProvisioning, (item, index) => (
                                      <tr key={index}>
                                        <td>{item.ip}</td>
                                        <td>{item.deviceId}</td>
                                        <td><div style={{ width: "110px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{item.serial}</div></td>
                                        {/* <td>{item.lastTrnVersion === undefined ? "" : item.lastTrnVersion.trnVersionSummary}</td> */}
                                        <td style={{ whiteSpace: "unset", width: "250px" }}>
                                          {this.state.provisioning_starting
                                            ? item.lastStatus
                                            : (<Link to="#" onClick={() => { this.setState({ showLogForDevice: item }) }}>View</Link>)
                                          }
                                        </td>
                                        <td style={{ width: "100px" }}>
                                          <div>
                                            {item.samStatus !== undefined && (
                                              <div>
                                                <span className={item.samStatus === "COMPLETED" ? "badge badge-success" : (item.samStatus === "FAILED" ? "badge badge-danger" : "badge badge-info")}>
                                                  {"SAM " + item.samStatus}
                                                </span>
                                                <br/>
                                              </div>
                                            )}
                                            {item.lastTrnVersion !== undefined && item.lastTrnVersion.status !== undefined && (
                                              <span className={item.lastTrnVersion.status === "COMPLETED" ? "badge badge-success" : (item.lastTrnVersion.status === "FAILED" ? "badge badge-danger" : "badge badge-info")}>
                                                {"FIRMWARE " + item.lastTrnVersion.status}
                                              </span>
                                            )}
                                          </div>
                                        </td>
                                        <td>{item.imageVersion}</td>
                                        <td>{item.engineVersion}</td>
                                        <td>{item.deviceModuleLibVersion}</td>
                                        <td>{item.readerProcFWVersion}</td>
                                        <td>{item.doorProcFWVersion}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId={5}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div>
                                  <h5 className="mt-4">Provisioning Detail</h5>
                                  <p className="text-muted font-size-16 mt-4">
                                    <span className="badge badge-success font-size-16 mr-3">
                                      {size(this.state.successDevices)}
                                    </span>
                                    Successful Provisioning
                                    <span className="badge badge-danger font-size-16 mr-3 ml-3">
                                      {size(this.state.failureDevices)}
                                    </span>
                                    Failed Provisioning
                                  </p>
                                  { size(this.state.deviceProvisioned) > 0 && (
                                    <div className="button-items mt-4">
                                      <Button
                                        color="success"
                                        className="btn btn-success btn-lg waves-effect"
                                        onClick={() => {
                                          // reset as new page
                                          let newState = ProvisioningEG2InitState();
                                          newState.keysetSelected = keysetSelected;
                                          newState.trnSelected = trnSelected;
                                          newState.activeTabProgress = 3;
                                          newState.progressValue = 40;
                                          newState.toggleResetDiscovery = !this.state.toggleResetDiscovery;
                                          newState.samDiscoveryRequest = this.state.samDiscoveryRequest;
                                          this.cleanup_data();
                                          this.setState(newState);
                                        }}
                                      >
                                        Start New Provisioning
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          className={
                            this.state.activeTabProgress === 1 || this.state.provisioning_starting
                            || (this.state.activeTabProgress === 4 && this.state.provisioning_started)
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (this.state.activeTabProgress === 1
                                  || this.state.provisioning_starting
                                  || (this.state.activeTabProgress === 4 && this.state.provisioning_started)) {
                                // nothing
                              } else {
                                this.toggle_tab_progress(
                                  this.state.activeTabProgress - 1
                                )
                              }
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={
                            this.state.activeTabProgress === 5 || this.state.provisioning_starting ||
                            (this.state.activeTabProgress === 4 && !this.state.provisioning_started) ||
                            (this.state.activeTabProgress === 1 && this.state.keysetSelected === undefined) ||
                            (this.state.activeTabProgress === 2 && this.state.trnSelected === undefined) ||
                            (this.state.activeTabProgress === 3 && (this.state.deviceSelected === undefined || this.state.deviceSelected.length === 0))
                              ? "next disabled"
                              : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (this.state.activeTabProgress === 5 || this.state.provisioning_starting ||
                                (this.state.activeTabProgress === 4 && !this.state.provisioning_started) ||
                                (this.state.activeTabProgress === 1 && this.state.keysetSelected === undefined) ||
                                (this.state.activeTabProgress === 2 && this.state.trnSelected === undefined) ||
                                (this.state.activeTabProgress === 3 && (this.state.deviceSelected === undefined || this.state.deviceSelected.length === 0))) {
                                // nothing
                              } else {
                                this.toggle_tab_progress(
                                  this.state.activeTabProgress + 1
                                )
                              }
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

ProvisioningEG2.propTypes = {
  // SecureBuild properties
  error_sb: PropTypes.any,
  error_sb_session: PropTypes.any,
  current_sb: PropTypes.object,
  current_sb_session: PropTypes.object,
  onCreateSecureBuild: PropTypes.func,
  onGetSecureBuildDetails: PropTypes.func,
  onInitSecureBuildSession: PropTypes.func,
  onExchangeSecureBuildSession: PropTypes.func,
  onClearSecureBuildError: PropTypes.func,
  onClearSecureBuildSessionError: PropTypes.func,
  onDeviceStoreReset: PropTypes.func,
  onSessionStoreReset: PropTypes.func,
  onCreateSecureBuildAudit: PropTypes.func,
  // SecureElement properties
  error_se: PropTypes.any,
  error_se_session: PropTypes.any,
  current_se: PropTypes.object,
  current_se_session: PropTypes.object,
  onCreateSecureElement: PropTypes.func,
  onGetSecureElementDetails: PropTypes.func,
  onInitSecureElementSession: PropTypes.func,
  onExchangeSecureElementSession: PropTypes.func,
  onVerifyExchangeSecureElementSession: PropTypes.func,
  onClearSecureElementError: PropTypes.func,
  onClearSecureElementSessionError: PropTypes.func,
  onSecureElementStoreReset: PropTypes.func,
  onSecureElementSessionStoreReset: PropTypes.func,
  onCreateSecureElementAudit: PropTypes.func,
  // SAM Discovery
  error_sam_discovery: PropTypes.any,
  samDiscoveryRequest: PropTypes.object,
  samDiscoveryResponse: PropTypes.object,
  onSamDiscoveryRequest: PropTypes.func,
  onSamDiscoveryResponse: PropTypes.func,
  onClearSamDiscoveryError: PropTypes.func,
}

const mapStateToProps = ({ secureBuildReducer, secureBuildSessionReducer, secureElementReducer, secureElementSessionReducer, samDiscoveryReducer }) => ({
  // SecureBuild
  current_sb: secureBuildReducer.secureBuildDetails,
  current_sb_session: secureBuildSessionReducer.secureBuildSession,
  error_sb: secureBuildReducer.error,
  error_sb_session: secureBuildSessionReducer.error,
  // SecureElement
  current_se: secureElementReducer.secureElementDetails,
  error_se: secureElementReducer.error,
  current_se_session: secureElementSessionReducer.secureElementSession,
  current_verify_se_session: secureElementSessionReducer.verifySecureElementSession,
  error_se_session: secureElementSessionReducer.error,
  // SAM Discovery
  samDiscoveryRequest: samDiscoveryReducer.samDiscoveryRequest,
  samDiscoveryResponse: samDiscoveryReducer.samDiscoveryResponse,
  error_sam_discovery: samDiscoveryReducer.error,
})

const mapDispatchToProps = dispatch => ({
  // SecureBuild
  onCreateSecureBuild: device => dispatch(createSecureBuild(device)),
  onGetSecureBuildDetails: id => dispatch(getSecureBuildDetails(id)),
  onInitSecureBuildSession: session => dispatch(initSecureBuildSession(session)),
  onExchangeSecureBuildSession: session => dispatch(exchangeSecureBuildSession(session)),
  onClearSecureBuildError: () => dispatch(clearSecureBuildError()),
  onClearSecureBuildSessionError: () => dispatch(clearSecureBuildSessionError()),
  onDeviceStoreReset: () => dispatch(deviceStoreReset()),
  onSessionStoreReset: () => dispatch(sessionStoreReset()),
  onCreateSecureBuildAudit: audit => dispatch(createSecureBuildAudit(audit)),
  // SecureElement
  onCreateSecureElement: device => dispatch(createSecureElement(device)),
  onGetSecureElementDetails: id => dispatch(getSecureElementDetails(id)),
  onInitSecureElementSession: session => dispatch(initSecureElementSession(session)),
  onExchangeSecureElementSession: session => dispatch(exchangeSecureElementSession(session)),
  onVerifyExchangeSecureElementSession: session => dispatch(verifyExchangeSecureElementSession(session)),
  onClearSecureElementError: () => dispatch(clearSecureElementError()),
  onClearSecureElementSessionError: () => dispatch(clearSecureElementSessionError()),
  onSecureElementStoreReset: () => dispatch(secureElementStoreReset()),
  onSecureElementSessionStoreReset: () => dispatch(secureElementSessionStoreReset()),
  onCreateSecureElementAudit: audit => dispatch(createSecureElementAudit(audit)),
  // SAM Discovery
  onSamDiscoveryRequest: () => dispatch(getSamDiscoveryRequest()),
  onSamDiscoveryResponse: data => dispatch(getSamDiscoveryResponse(data)),
  onClearSamDiscoveryError: () => dispatch(clearSamDiscoveryError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProvisioningEG2)