import { Fragment } from "react"
import { Row, Col, Table, Card, CardBody, CardTitle, Badge, CardLink } from "reactstrap"
import Moment from 'react-moment';

function parseJsonValue(jsonData, key, key2) {
    if (jsonData === undefined || jsonData === null || jsonData === "") {
        return ""
    }
    let json = JSON.parse(jsonData);
    if (json === undefined || json === null) {
        return ""
    }
    if (key2 === undefined) {
        if ( json.hasOwnProperty(key)
            && (typeof json[key] == 'string') )
                return json[key]
        return ""
    } else {
        if (json.hasOwnProperty(key)
            && json[key].hasOwnProperty(key2)
            && (typeof json[key][key2] == 'string'))
                return json[key][key2]
        return ""
    }
}

function Timestamp(props) {
    return <Moment format="MM/DD/YYYY hh:mm:ss:xxx">{new Date(parseInt(props.value))}</Moment>
}

function renderCheckboxChangeStatus(ver, selectForProvisioning) {
    return (
        <tr>
            <td width="300"></td>
            <td>
                <input id='chk' type='checkbox' 
                    onClick={(e)=>{e.preventDefault();selectForProvisioning(ver.id, ver.selectedProvisioning)}} checked={ver.selectedProvisioning}/>&nbsp;<label htmlFor='chk'>Select for Provisioning</label>
            </td>
        </tr>)
}

export const renderVersionDetails = (trn, ver, onViewAudit, selectForProvisioning) => {
    return (<Fragment>
            {/*
                <Card>
                    <CardBody>
                        <CardTitle className="mb-3">
                            <Row><Col>General</Col><Col align="right">
                                {onViewAudit != null ? <h6>
                                    <CardLink href="#" onClick={(event) => {
                                        event.preventDefault()
                                        if (onViewAudit != null)
                                            onViewAudit(trn, ver)
                                        }
                                    }>View Audit</CardLink>
                                </h6> : null}
                            </Col></Row>
                        </CardTitle>
                        <div className="table-responsive">
                            <Table className="table mb-0">
                            <tbody>
                                <RowWrapper label="TRN Name:" value={trn.name} />
                                <RowWrapper label="TRN Description:" value={trn.description} />
                                <RowWrapper label="TRN Version:" value={ver.trnVersion} />
                                <RowWrapper label="Digest:" value={ver.digest} />
                                <RowWrapper label="Status" value={
                                    <h4><Badge color={ver.selectedProvisioning?'badge badge-success':'badge badge-secondary'}>{ver.selectedProvisioning?"Selected for Provisioning":"Not Selected for Provisioning"}</Badge></h4>
                                } />
                                { renderCheckboxChangeStatus(ver, selectForProvisioning) }
                            </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            */}
                <Card>
                    <CardBody>
                        <CardTitle className="mb-3">Firmware Meta Data</CardTitle>
                        <div className="table-responsive">
                            <Table className="table mb-0">
                            <tbody>
                                <RowWrapper label="Name:" value={parseJsonValue(ver.firmwareMetadata, "displayName")} />
                                <RowWrapper label="Type:" value={parseJsonValue(ver.firmwareMetadata, "fwtype")} />
                                <RowWrapper label="Version:" value={parseJsonValue(ver.firmwareMetadata, "fwVersion")} />
                                <RowWrapper label="Description:" value={parseJsonValue(ver.firmwareMetadata, "fwDescription")} />
                                <RowWrapper label="TRN Name:" value={parseJsonValue(ver.firmwareMetadata, "trnName")} />
                                <RowWrapper label="Status" value={
                                    <h4><Badge color={ver.selectedProvisioning?'badge badge-success':'badge badge-secondary'}>{ver.selectedProvisioning?"Selected for Provisioning":"Not Selected for Provisioning"}</Badge></h4>
                                } />
                                { renderCheckboxChangeStatus(ver, selectForProvisioning) }
                            </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-3">Header</CardTitle>
                        <div className="table-responsive">
                            <Table className="table mb-0">
                            <tbody>
                                <RowWrapper label="Signature:" value={parseJsonValue(ver.header, "signature")} />
                                <RowWrapper label="Version:" value={parseJsonValue(ver.header, "version")} />
                            </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-3">Manifest</CardTitle>
                        <div className="table-responsive">
                            <Table className="table mb-0">
                            <tbody>
                                <RowWrapper label="Timestamp:" value={<Timestamp value = {parseJsonValue(ver.manifest, "timestamp")} />} />
                                <RowWrapper label="VendorID:" value={parseJsonValue(ver.manifest, "vendorID")} />
                                <RowWrapper label="ClassID:" value={parseJsonValue(ver.manifest, "classID")} />
                                <RowWrapper label="Supported Device:" value={parseJsonValue(ver.manifest, "supportedDevice")} />
                                <RowWrapper label="Firmware Name:" value={parseJsonValue(ver.manifest, "firmwareName")} />
                            </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-3">Payload Descriptor</CardTitle>
                        <div className="table-responsive">
                            <Table className="table mb-0">
                            <tbody>
                                <RowWrapper label="Version:" value={parseJsonValue(ver.payloadDescriptor, "version")} />
                                <RowWrapper label="Format:" value={parseJsonValue(ver.payloadDescriptor, "format")} />
                                <RowWrapper label="Size:" value={parseJsonValue(ver.payloadDescriptor, "size")} />
                                <RowWrapper label="Content Type:" value={parseJsonValue(ver.payloadDescriptor, "contentType")} />
                                <RowWrapper label="Payload Encryption:" value={parseJsonValue(ver.payloadDescriptor, "payloadEncryption")} />
                                <RowWrapper label="Payload Digest:" value={parseJsonValue(ver.payloadDescriptor, "payloadDigest")} />
                            </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-3">Secure Header</CardTitle>
                        <div className="table-responsive">
                            <Table className="table mb-0">
                            <tbody>
                                <RowWrapper label="Session Key Algorithm:" value={parseJsonValue(ver.secureHeader, "sessionkeyAlgorithm")} />
                                <RowWrapper label="Encrypted Session Key:" value={parseJsonValue(ver.secureHeader, "encryptedSessionkey").substr(0, 50)+"..."} />
                                <RowWrapper label="Session Key IV:" value={parseJsonValue(ver.secureHeader, "sessionkeyIV")} />
                                <RowWrapper label="KEK IV:" value={parseJsonValue(ver.secureHeader, "kekIv")} />
                                <RowWrapper label="Signature Algorithm:" value={parseJsonValue(ver.secureHeader, "signature", "signatureAlgorithm")} />
                                <RowWrapper label="Secure Build Hash:" value={parseJsonValue(ver.secureHeader, "signature", "secureBuildHash")} />
                                <RowWrapper label="Secure Build Signature:" value={parseJsonValue(ver.secureHeader, "signature", "secureBuildSignature").substr(0, 50)+"..."} />
                            </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>

    </Fragment>
    )
}

const RowWrapper = ({label, value}) => {
    return (
        <tr>
            <td width="300">{label}</td>
            <td>{value}</td>
        </tr>
    )
}

export const renderRejectedTrnDetails = (data) => {
    return (<Fragment>
                <Card>
                    <CardBody>
                        <div className="table-responsive">
                            <Table className="table mb-0">
                            <tbody>
                                <RowWrapper label="Rejected Reason:" value={data.rejectedReason} />
                                <RowWrapper label="TRN Name:" value={data.name} />
                                <RowWrapper label="TRN Version:" value={data.trnVersion} />
                            </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-3">Firmware Meta Data</CardTitle>
                        <div className="table-responsive">
                            <Table className="table mb-0">
                            <tbody>
                                <RowWrapper label="Name:" value={parseJsonValue(data.firmwareMetadata, "displayName")} />
                                <RowWrapper label="Type:" value={parseJsonValue(data.firmwareMetadata, "fwtype")} />
                                <RowWrapper label="Version:" value={parseJsonValue(data.firmwareMetadata, "fwVersion")} />
                                <RowWrapper label="Description:" value={parseJsonValue(data.firmwareMetadata, "fwDescription")} />
                                <RowWrapper label="TRN Name:" value={parseJsonValue(data.firmwareMetadata, "trnName")} />
                            </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
    </Fragment>
    )
}