import {
  SECURE_BUILD_DISCOVERY_REQUEST,
  SECURE_BUILD_DISCOVERY_REQUEST_FAIL,
  SECURE_BUILD_DISCOVERY_REQUEST_SUCCESS,
  SECURE_BUILD_DISCOVERY_RESPONSE,
  SECURE_BUILD_DISCOVERY_RESPONSE_FAIL,
  SECURE_BUILD_DISCOVERY_RESPONSE_SUCCESS,
  CLEAR_ERROR
} from "./actionTypes"

export const clearError = () => ({
  type: CLEAR_ERROR,
})

export const getSecureBuildDiscoveryRequest = () => ({
  type: SECURE_BUILD_DISCOVERY_REQUEST,
})

export const getSecureBuildDiscoveryRequestSuccess = session => ({
  type: SECURE_BUILD_DISCOVERY_REQUEST_SUCCESS,
  payload: session,
})

export const getSecureBuildDiscoveryRequestFail = error => ({
  type: SECURE_BUILD_DISCOVERY_REQUEST_FAIL,
  payload: error,
})

export const getSecureBuildDiscoveryResponse = (session) => ({
  type: SECURE_BUILD_DISCOVERY_RESPONSE,
  payload: session,
})

export const getSecureBuildDiscoveryResponseSuccess = session => ({
  type: SECURE_BUILD_DISCOVERY_RESPONSE_SUCCESS,
  payload: session,
})

export const getSecureBuildDiscoveryResponseFail = error => ({
  type: SECURE_BUILD_DISCOVERY_RESPONSE_FAIL,
  payload: error,
})