import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import usersSaga from "./users/saga"
import auditsSaga from "./audits/saga"
import trnsSaga from "./trns/saga"
import reportsSaga from "./reports/reportsSaga"
import loginsSaga from "./logins/saga"
import secureBuildDiscoverySaga from "./secureBuildDiscovery/secureBuildDiscoverySaga"
import samDiscoverySaga from "./samDiscovery/samDiscoverySaga"
import hsmInfoSaga from "./hsmInfo/saga"
import keysetsSaga from "./keysets/keysetsSaga"
import exceptionsSaga from "./exceptions/saga"
import confsSaga from "./configurations/confsSaga"
import secureElementSaga from "./secureElement/secureElementSaga"
import secureElementSessionSaga from "./secureElementSession/secureElementSessionSaga"
import secureBuildSaga from "./secureBuild/secureBuildSaga"
import secureBuildSessionSaga from "./secureBuildSession/secureBuildSessionSaga"
import secureBuildAuditSaga from "./secureBuildAudit/secureBuildAuditSaga"
import secureElementAuditSaga from "./secureElementAudit/secureElementAuditSaga"
import trnUploadSaga from "./trnUpload/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(usersSaga),
    fork(auditsSaga),
    fork(trnsSaga),
    fork(reportsSaga),
    fork(loginsSaga),
    fork(secureBuildDiscoverySaga),
    fork(samDiscoverySaga),
    fork(hsmInfoSaga),
    fork(keysetsSaga),
    fork(exceptionsSaga),
    fork(confsSaga),
    fork(secureElementSaga),
    fork(secureElementSessionSaga),
    fork(secureBuildSaga),
    fork(secureBuildSessionSaga),
    fork(secureBuildAuditSaga),
    fork(secureElementAuditSaga),
    fork(trnUploadSaga)
  ])
}
