import {
  GET_HSM_INFO_SUCCESS,
  GET_HSM_INFO_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  hsmInfo: {},
  error: {}
}

const hsmInfoReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_HSM_INFO_SUCCESS:
      {
        return {
          ...state,
          hsmInfo: action.payload
        }
      }

    case GET_HSM_INFO_FAIL:
      {
        return {
          ...state,
          error: { message: action.payload.message },
        }
      }

    default:
      return state
  }
}

export default hsmInfoReducer
