import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
  Tooltip
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

import { isEmpty, map, size } from "lodash"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TRNVersionSelection from "../../components/TRN/TRNVersionSelection"
import DiscoverySecureBuildView from "../../components/Discovery/DiscoverySecureBuildView"

//Import edge devices helper
import { getConnector, disposeConnector } from "../../helpers/edge_helper"

import { getSecureBuildDetails, createSecureBuild, clearError as clearSecureBuildError, reset as deviceStoreReset } from "store/secureBuild/actions"
import { initSecureBuildSession, exchangeSecureBuildSession, clearError as clearSecureBuildSessionError, reset as sessionStoreReset } from "store/secureBuildSession/actions"
import { createSecureBuildAudit } from "store/secureBuildAudit/actions"

export const ProvisioningEdgeInitState = () => {
  return {
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      deviceSelected: [],
      toggleResetDiscovery: false,
      trnSelected: undefined,
      deviceProvisioning: [],
      deviceProvisioned: [],
      successDevices: [],
      failureDevices: [],
      confirm_provisioning: false,
      provisioning_starting: false,
      provisioning_started: false,
      current_native_device: undefined,
      current_device: undefined,
      current_session: undefined,
      connector: undefined,
      autoDiscoveried: false,
      showLogForDevice: undefined,
      tooltipId: undefined
  };
}

class ProvisioningEdge extends Component {
  constructor(props) {
    super(props)
    this.state = ProvisioningEdgeInitState()
    this.toggleTabProgress.bind(this)
    this.handleStartProvisioning.bind(this)
  }

  componentDidMount() {
    this.cleanupData();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { current_device, current_session, errorOfDevice, errorOfSession } = this.props
    const { provisioning_starting, current_native_device } = this.state;
    if (provisioning_starting !== prevState.provisioning_starting) {
      console.log("Start produce next device");
      this.produceNextDevice();
      return;
    }
    // Change to Discovery Devices Tab
    if (this.state.activeTabProgress !== prevState.activeTabProgress && this.state.activeTabProgress === 2) {
      if (!this.state.autoDiscoveried) {
        //this.handleDiscovery();
        //return;
      }
    }

    if (prevProps.errorOfDevice !== errorOfDevice && errorOfDevice !== undefined && errorOfDevice.message !== undefined) {
      this.appendDeviceProvisioningStatus(current_native_device, errorOfDevice.message);
      disposeConnector();
      // device cannot created on service, so we must create an instance as service instance
      let failedDevice = this.toServiceDevice(current_native_device);
      this.addFailureDeviceAndContinue(failedDevice);
      return;
    }

    if (prevProps.errorOfSession !== errorOfSession && errorOfSession !== undefined && errorOfSession.message !== undefined) {
      this.appendDeviceProvisioningStatus(current_native_device, errorOfSession.message);
      disposeConnector();
      this.addFailureDeviceAndContinue(current_session.secureBuild);
      return;
    }

    if (prevProps.current_device !== current_device) {
      this.setState({ current_device: current_device });
      // new device requested
      if (current_device !== undefined) {
        console.log("[" + current_device.deviceId + "]: Requested on service");
        // request new session
        this.requestInitDeviceSession(current_device);
        return;
      }
    }

    if (prevProps.current_session !== current_session && current_session !== undefined
          && current_session.id !== undefined && current_session.secureBuild !== undefined) {
      this.setState({
        current_session: current_session,
        current_device: current_session.secureBuild
      });
      if (prevProps.current_session === undefined || prevProps.current_session.id === undefined) {
        console.log("[" + current_session.secureBuild.deviceId + "]: Session created on service");
      }
      if (prevProps.current_session === undefined
            || prevProps.current_session.id === undefined
            || prevProps.current_session.id !== current_session.id
            || prevProps.current_session.requestKey === undefined
            || prevProps.current_session.requestKey !== current_session.requestKey) {
        console.log("[" + current_session.secureBuild.serial + "]: Session changed -> " + current_session.requestKey);
        this.requestExchangeDeviceSession(current_session);
        return;
      }
    }
  }

  addFailureDeviceAndContinue = (failedDevice) => {
    failedDevice.completed = false;
    let provisionedDevices = [...this.state.deviceProvisioned];
    let failureDevicesCopy = [...this.state.failureDevices];
    provisionedDevices.push(failedDevice);
    failureDevicesCopy.push(failedDevice);
    this.setState({
      deviceProvisioned: provisionedDevices,
      failureDevices: failureDevicesCopy
    }, this.produceNextDevice);
  }

  cleanupData = () => {
    // cleanup data of device and session store
    this.props.onDeviceStoreReset();
    this.props.onSessionStoreReset();
  }

  handleTRNSelectedChanged = (trns) => {
    if (trns === undefined || trns.length === 0) {
      this.setState({
        trnSelected: undefined
      });
      return;
    }
    this.setState({
      trnSelected: trns[0]
    });
  }

  handleSelectedDevicesChanged = (devices) => {
    this.setState({
      deviceSelected: devices
    });
  }

  handleStartProvisioning () {
    if (isEmpty(this.state.deviceSelected)) {
      return;
    }
    if (this.state.trnSelected === undefined) {
      return;
    }
    console.log("Start Provisioning...");
    let deviceProvisioning = [...this.state.deviceSelected];
    this.setState({
      deviceSelected: [],
      deviceProvisioning: deviceProvisioning,
      provisioning_starting: true,
      provisioning_started: true
    });
  }

  produceNextDevice = () => {
    this.setState({
      connector: undefined,
      current_device: undefined,
      current_session: undefined
    });
    let nextDevice = undefined;
    const { deviceProvisioning, deviceProvisioned } = this.state;
    for (let i = 0; i < deviceProvisioning.length; i++) {
      let found = false;
      for (let j = 0; j < deviceProvisioned.length; j++) {
        if (deviceProvisioning[i].deviceId === deviceProvisioned[j].deviceId) {
          found = true;
          break;
        }
      }
      if (!found) {
        nextDevice = deviceProvisioning[i];
        break;
      }
    }
    if (nextDevice === undefined) {
      this.setState({
        provisioning_starting: false
      });
    } else {
      console.log("Produce device " + nextDevice.deviceId);
      this.requestNewDevice(nextDevice);
    }
  }

  toServiceDevice  = (nativeDevice) => {
    return {
      imageVersion: nativeDevice.imageVersion,
      engineVersion: nativeDevice.engineVersion,
      deviceModuleLibVersion: nativeDevice.deviceModuleLibVersion,
      doorProcFWVersion: nativeDevice.doorProcFWVersion,
      readerProcFWVersion: nativeDevice.readerProcFWVersion,
      ip: nativeDevice.ip,
      connectionMode: nativeDevice.connectionMode,
      model: nativeDevice.model,
      deviceId: nativeDevice.deviceId,
      serial: nativeDevice.serial,
      hostName: nativeDevice.hostName,
      dbReady: nativeDevice.dbReady,
      doorProcDeviceID: nativeDevice.doorProcDeviceID,
      readerProcDeviceID: nativeDevice.readerProcDeviceID,
      cm4Serial: nativeDevice.cm4Serial,
      macAddress: nativeDevice.macAddress,
      trnVersionId: this.state.trnSelected.selectedVersion.id
    };
  }

  uptodateDeviceProvisioning = (d) => {
    let provisioningItems = [...this.state.deviceProvisioning];
    for (let i = 0; i < provisioningItems.length; i++) {
      if (provisioningItems[i].deviceId === d.deviceId) {
        provisioningItems[i] = d;
      }
    }
    this.setState({
      deviceProvisioning: provisioningItems
    });
  };

  appendDeviceProvisioningStatus = (d, msg) => {
    if (msg === undefined || msg === null || msg === "") {
      return;
    }
    if (d.status === undefined) {
      d.status = [];
    }
    if (msg.includes("Download") && d.status.length > 0) {
      // add or replace last Download message
      let lastIndex = d.status.length - 1;
      let lastMsg = d.status[lastIndex];
      if (lastMsg !== undefined && lastMsg.includes("Download")) {
        d.status[lastIndex] = msg;
      } else {
        d.status.push(msg);
      }
    } else {
      // add more msg
      d.status.push(msg);
    }

    // set last log message
    d.lastStatus = msg;
    console.log("[" + d.deviceId + "]: " + msg);
    this.uptodateDeviceProvisioning(d);
  };

  requestNewDevice = (nativeDevice) => {
    this.setState({
      current_native_device: nativeDevice
    });
    console.log("[" + nativeDevice.deviceId + "]: Requesting new device");
    this.props.onCreateSecureBuild(this.toServiceDevice(nativeDevice));
  };

  requestInitDeviceSession = (current_device) => {
    console.log("[" + current_device.deviceId + "]: Requesting new session");
    let session = {
      secureBuildId: current_device.id
    };
    this.props.onInitSecureBuildSession(session);
  };

  requestCreateAuditAction = (current_device, current_session, action, description, statusResult) => {
    console.log("[" + current_device.deviceId + "]: Requesting audit creation");
    let audit = {
      secureBuildId: current_device.id,
      secureBuildSessionId: current_session.id,
      action: action,
      description: description,
      status: statusResult ? "Success" : "Failure"
    };
    this.props.onCreateSecureBuildAudit(audit);
  };

  requestExchangeDeviceSession = async (current_session) => {
    const { current_native_device } = this.state;
    try {
      let current_device = current_session.secureBuild;
      // transfer versions from service to client device
      current_native_device.lastTrnVersion = current_device.lastTrnVersion;
      current_native_device.engineVersion = current_device.engineVersion;
      current_native_device.imageVersion = current_device.imageVersion;
      current_native_device.deviceModuleLibVersion = current_device.deviceModuleLibVersion;
      current_native_device.doorProcFWVersion = current_device.doorProcFWVersion;
      current_native_device.readerProcFWVersion = current_device.readerProcFWVersion;
      if (this.state.connector === undefined) {
        let conn = getConnector();
        this.setState({
          connector: conn
        });
        let connected = await conn.connectTCP(
          current_native_device.ip,
          6679
        );
        let auditDesc = "Device Connected with Portal [" + current_native_device.ip + "]";
        if (!connected) {
          this.appendDeviceProvisioningStatus(current_native_device, "Cannot connected");
          this.requestCreateAuditAction(current_device, current_session, "Connect", auditDesc, false);
          disposeConnector();
          this.produceNextDevice();
          return;
        } else {
          this.appendDeviceProvisioningStatus(current_native_device, "Connected");
          this.requestCreateAuditAction(current_device, current_session, "Connect", auditDesc, true);
        }
      }
      const { connector } = this.state;
      // logging prev response
      if (current_session.response !== "" && current_session.response !== null && current_session.response !== undefined) {
        this.appendDeviceProvisioningStatus(current_native_device, "ERROR: " + current_session.response);
      }

      if (current_device.lastTrnVersion === undefined
            || current_device.lastTrnVersion === null
            || current_device.lastTrnVersion.status !== "PROVISIONING") {
        disposeConnector();

        let provisionedDevices = [...this.state.deviceProvisioned];
        provisionedDevices.push(current_device);
        let successDevicesCopy = [...this.state.successDevices];
        let failureDevicesCopy = [...this.state.failureDevices];
        if (current_device.lastTrnVersion !== null) {
          if (current_device.lastTrnVersion.status == "COMPLETED") {
            successDevicesCopy.push(current_device);
            this.appendDeviceProvisioningStatus(current_native_device, "TRN Installed");
            this.appendDeviceProvisioningStatus(current_native_device, "- OS Version: " + current_device.imageVersion);
            this.appendDeviceProvisioningStatus(current_native_device, "- APP Version: " + current_device.engineVersion);
            this.appendDeviceProvisioningStatus(current_native_device, "- Device Module Version : " + current_device.deviceModuleLibVersion);
            this.appendDeviceProvisioningStatus(current_native_device, "- Reader Proc Version : " + current_device.readerProcFWVersion);
            this.appendDeviceProvisioningStatus(current_native_device, "- Door Proc Version : " + current_device.doorProcFWVersion);
            this.appendDeviceProvisioningStatus(current_native_device, "Provisioning Successful");
          } else if (current_device.lastTrnVersion.status == "FAILED") {
            failureDevicesCopy.push(current_device);
            this.appendDeviceProvisioningStatus(current_native_device, "Provisioning Failed");
          }
        }
        this.setState({
          deviceProvisioned: provisionedDevices,
          successDevices: successDevicesCopy,
          failureDevices: failureDevicesCopy
        }, this.produceNextDevice);
        return;
      }
      if (current_session.request !== "" && current_session.request !== null && current_session.request !== undefined) {
        if (current_session.requestKey == "Download") {
          let downloadRequest = JSON.parse(current_session.request);
          this.appendDeviceProvisioningStatus(current_native_device, "Download Requesting");
          let uploadStarted = await connector.uploadTCP(
              downloadRequest.Link,
              downloadRequest.PackageSize,
              downloadRequest.Name,
              downloadRequest.Version,
              downloadRequest.Hash
          );
          let downloadResponse = {
            Id: downloadRequest.Id,
            Completed: false,
            PackageIndex: 0,
            PackageCount: 0,
            ProgressPercent: 0.0,
            Uploading: false
          };
          if (uploadStarted == true) {
            this.appendDeviceProvisioningStatus(current_native_device, "Download 0.00 %");
            do {
              let report = await connector.uploadStatusTCP();
              downloadResponse.Completed = report[0];
              downloadResponse.Uploading = report[1];
              downloadResponse.PackageIndex = report[2];
              downloadResponse.PackageCount = report[3];
              downloadResponse.ProgressPercent = downloadResponse.PackageIndex * 100 / downloadResponse.PackageCount;
              this.appendDeviceProvisioningStatus(current_native_device, "Download " + downloadResponse.ProgressPercent.toFixed(2) + " %");
              // delay 2s
              await new Promise(r => setTimeout(r, 2000));
            } while (downloadResponse.Uploading);
          } else {
            this.appendDeviceProvisioningStatus(current_native_device, "Download Progress Failed");
          }
          current_session.response = JSON.stringify(downloadResponse);
        } else if (current_session.requestKey == "Validation") {
          // TRN copied to device, disconnect TCP connection
          await connector.disconnectTCP();
          this.appendDeviceProvisioningStatus(current_native_device, "Disconnected");
          let auditDesc = "Disconnected Portal connection [" + current_native_device.ip + "]";
          this.requestCreateAuditAction(current_device, current_session, "Disconnect", auditDesc, true);

          let discoveryRequest = JSON.parse(current_session.request);
          this.appendDeviceProvisioningStatus(current_native_device, "Delay " + (discoveryRequest.deplayTime / 1000) + "s before installation");
          // waiting to make sure that device rebooted and starting installation new TRN
          await new Promise(r => setTimeout(r, discoveryRequest.deplayTime));
          let discoveryRetry = discoveryRequest.retry;
          let count = 1;
          do {
            this.appendDeviceProvisioningStatus(
              current_native_device,
              "Validating installation (timeout=" + (discoveryRequest.pollingTime / 1000) + "s, retry=" + count + ")");
            try {
              let discoveryDevices = await connector.discoverySecureBuilds(
                discoveryRequest.address,
                discoveryRequest.port,
                discoveryRequest.message,
                discoveryRequest.pollingTime
              );
              if (discoveryDevices === undefined || size(discoveryDevices) === 0) {
                // not found the device by mac address after copied TRN
                current_session.response = "";
              } else {
                // validate first device on service
                current_session.response = discoveryDevices[0];
                discoveryRetry = 0; // stop discovery
              }
            } catch (pollingError) {
              // ignore polling error
            } finally {
              --discoveryRetry;
              count++;
            }
          } while (discoveryRetry > 0);
        } else {
          this.appendDeviceProvisioningStatus(current_native_device, current_session.requestKey);
          current_session.response = await connector.exchangeTCP(current_session.request);
        }
      }
      this.props.onExchangeSecureBuildSession(current_session);
    } catch (error) {
      // got any failure -> disconnect current connection -> show failure error on record -> produce next device
      disposeConnector();
      this.appendDeviceProvisioningStatus(current_native_device, error);
      this.addFailureDeviceAndContinue(current_session.secureBuild);
    }
  };

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTabProgress: tab,
        })
        let progressValue = tab * 25
        this.setState({ progressValue })
      }
    }
  }

  toggleTooltip (currentId, targetId) {
    if (currentId === targetId) {
      this.setState({ tooltipId: undefined });
    } else {
      this.setState({ tooltipId: targetId });
    }
  };

  render() {
    const { discoveryError } = this.props
    console.log(discoveryError);
    if (!isEmpty(discoveryError) && JSON.stringify(discoveryError).includes("status code 500")) {
      console.log("discoveryError");
      return (
        <Fragment>
            <div className="page-content">
              <Container fluid={true}>
                <Breadcrumbs title="Devices" breadcrumbItem="Smart Bridge Provisioning" />
                <Row>
                  <Col lg="12">
                    <Alert color="danger">
                      Something went wrong with the portal, please try later
                    </Alert>
                </Col>
              </Row>
            </Container>
          </div>
        </Fragment>
      );
    }

    const { deviceSelected, deviceProvisioning, trnSelected, tooltipId } = this.state;
    return (
      <Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Devices" breadcrumbItem="Smart Bridge Provisioning" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Provisioning Smart Bridge Devices</h4> */}

                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 1
                            })}
                            onClick={() => {
                              if (!this.state.provisioning_started) {
                                this.toggleTabProgress(1)
                              }
                            }}
                          >
                            <span className="step-number mr-2">01</span>
                            <span>Choose TRN</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 2
                            })}
                            onClick={() => {
                              if (!this.state.provisioning_started) {
                                this.toggleTabProgress(2)
                              }
                            }}
                          >
                            <span className="step-number mr-2">02</span>
                            Discovery Devices
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 3,
                            })}
                            onClick={() => {
                              this.toggleTabProgress(3)
                            }}
                          >
                            <span className="step-number mr-2">03</span>
                            Provisioning
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 4,
                            })}
                            onClick={() => {
                              if (this.state.provisioning_started) {
                                this.toggleTabProgress(4)
                              }
                            }}
                          >
                            <span className="step-number mr-2">04</span>
                            Summary
                          </NavLink>
                        </NavItem>
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={this.state.progressValue}
                        />
                        <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"/>
                      </div>
                      <TabContent
                        activeTab={this.state.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <TRNVersionSelection multiple={false} onSelectedChanged={trn => this.handleTRNSelectedChanged(trn)}/>
                        </TabPane>

                        <TabPane tabId={2}>
                          <DiscoverySecureBuildView selectionMode={true} toggleReset={this.state.toggleResetDiscovery} onSelectedChanged={devices => this.handleSelectedDevicesChanged(devices)}/>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            { !this.state.provisioning_starting && !isEmpty(deviceSelected) && (
                              <div className="button-items">
                                <Button
                                  color="success"
                                  className="btn btn-success btn-lg waves-effect"
                                  onClick={() => {
                                    this.setState({
                                      confirm_provisioning: true,
                                    })
                                  }}
                                >
                                  Start Provisioning
                                </Button>
                              </div>
                              )}
                            <br/>

                            {this.state.provisioning_starting ? (
                              <SweetAlert
                                title="Smartbridge device provisioning..."
                                customClass="swal2-progressProvisioning"
                                showConfirm={false}
                                onConfirm={() => {}}
                              >
                                <div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </SweetAlert>
                            ) : null}

                            {trnSelected === undefined && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please select a TRN!
                                  </Alert>
                                </div>
                            )}
                            {trnSelected !== undefined && (
                                <div>
                                  <Alert color="success" role="alert">
                                    Selected TRN: {trnSelected.name} ({trnSelected.selectedVersion.trnVer})
                                  </Alert>
                                </div>
                            )}

                            {isEmpty(deviceSelected) && isEmpty(deviceProvisioning) && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please select one or more devices!
                                  </Alert>
                                </div>
                            )}
                            { this.state.provisioning_starting && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please do not close this page during provisioning, otherwise the device may be damaged
                                  </Alert>
                                </div>
                            )}

                            { this.state.confirm_provisioning ? (
                              <SweetAlert
                                title="Are you sure you want to start provisioning?"
                                warning
                                showCancel
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                onConfirm={() => {
                                  this.setState({
                                    confirm_provisioning: false,
                                  });
                                  this.handleStartProvisioning()
                                }}
                                onCancel={() =>
                                  this.setState({
                                    confirm_provisioning: false,
                                  })
                                }
                              >
                                Please do not close this page during provisioning, otherwise the device may be damaged
                              </SweetAlert>
                            ) : null}

                            { this.state.showLogForDevice ? (
                              <SweetAlert
                                title="Provisioning Output"
                                info
                                confirmBtnBsStyle="success"
                                onConfirm={() => {
                                  this.setState({
                                    showLogForDevice: undefined,
                                  });
                                }}
                              >
                                <div style={{ whiteSpace: "unset", width: "400px", textAlign: "start" }}>
                                  <p className="mb-0">
                                    <em>Device Address: <b>{this.state.showLogForDevice.ip}</b></em>
                                  </p>
                                  <p className="mb-0">
                                    <em>Device ID: <b>{this.state.showLogForDevice.deviceId}</b></em>
                                  </p>
                                  <p className="mb-0">
                                    <em>Output:</em>
                                  </p>
                                  {map(this.state.showLogForDevice.status, (s, si) => (
                                    <p className="mb-0" key={si}>
                                      <em>- {s}</em>
                                    </p>
                                  ))}
                                </div>

                              </SweetAlert>
                            ) : null}

                            {!isEmpty(deviceSelected) && (
                              <div className="table-responsive">
                                <Table className="table table-nowrap table-centered">
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Device Address</th>
                                      <th scope="col">Device ID</th>
                                      <th scope="col">SAM Serial</th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedOSHeader"} target="selectedOSHeader"
                                              toggle={() => this.toggleTooltip(tooltipId, "selectedOSHeader")}>OS Version</Tooltip>
                                          <div id="selectedOSHeader">OS</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedAppHeader"} target="selectedAppHeader"
                                              toggle={() => this.toggleTooltip(tooltipId, "selectedAppHeader")}>APP Version</Tooltip>
                                          <div id="selectedAppHeader">APP</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedDeviceHeader"} target="selectedDeviceHeader"
                                              toggle={() => this.toggleTooltip(tooltipId, "selectedDeviceHeader")}>Device Module Version</Tooltip>
                                          <div id="selectedDeviceHeader">Device</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedReaderHeader"} target="selectedReaderHeader"
                                              toggle={() => this.toggleTooltip(tooltipId, "selectedReaderHeader")}>Reader Proc Version</Tooltip>
                                          <div id="selectedReaderHeader">Reader</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "selectedDoorHeader"} target="selectedDoorHeader"
                                            toggle={() => this.toggleTooltip(tooltipId, "selectedDoorHeader")}>Door Proc Version</Tooltip>
                                          <div id="selectedDoorHeader">Door</div>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {map(deviceSelected, (item, index) => (
                                      <tr key={index}>
                                        <td>{item.ip}</td>
                                        <td>{item.deviceId}</td>
                                        <td><div style={{ width: "110px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{item.serial}</div></td>
                                        <td>{item.imageVersion}</td>
                                        <td>{item.engineVersion}</td>
                                        <td>{item.deviceModuleLibVersion}</td>
                                        <td>{item.readerProcFWVersion}</td>
                                        <td>{item.doorProcFWVersion}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                            {!isEmpty(deviceProvisioning) && (
                              <div className="table-responsive">
                                <Table className="table table-nowrap table-centered">
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Device Address</th>
                                      <th scope="col">Device ID</th>
                                      <th scope="col" style={{ width: "120px" }}>SAM Serial</th>
                                      {/* <th scope="col">Loading TRN Version</th> */}
                                      <th scope="col" style={{ width: "250px" }}>Process</th>
                                      <th scope="col" style={{ width: "100px" }}>Status</th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningOSHeader"} target="provisioningOSHeader"
                                              toggle={() => this.toggleTooltip(tooltipId, "provisioningOSHeader")}>OS Version</Tooltip>
                                          <div id="provisioningOSHeader">OS</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningAppHeader"} target="provisioningAppHeader"
                                              toggle={() => this.toggleTooltip(tooltipId, "provisioningAppHeader")}>APP Version</Tooltip>
                                          <div id="provisioningAppHeader">APP</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningDeviceHeader"} target="provisioningDeviceHeader"
                                              toggle={() => this.toggleTooltip(tooltipId, "provisioningDeviceHeader")}>Device Module Version</Tooltip>
                                          <div id="provisioningDeviceHeader">Device</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningReaderHeader"} target="provisioningReaderHeader"
                                              toggle={() => this.toggleTooltip(tooltipId, "provisioningReaderHeader")}>Reader Proc Version</Tooltip>
                                          <div id="provisioningReaderHeader">Reader</div>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div>
                                          <Tooltip placement="right" isOpen={tooltipId === "provisioningDoorHeader"} target="provisioningDoorHeader"
                                            toggle={() => this.toggleTooltip(tooltipId, "provisioningDoorHeader")}>Door Proc Version</Tooltip>
                                          <div id="provisioningDoorHeader">Door</div>
                                        </div>
                                      </th>                                    </tr>
                                  </thead>
                                  <tbody>
                                    {map(deviceProvisioning, (item, index) => (
                                      <tr key={index}>
                                        <td>{item.ip}</td>
                                        <td>{item.deviceId}</td>
                                        <td><div style={{ width: "110px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{item.serial}</div></td>
                                        {/* <td>{item.lastTrnVersion === undefined ? "" : item.lastTrnVersion.trnVersionSummary}</td> */}
                                        <td style={{ whiteSpace: "unset", width: "250px" }}>
                                          {this.state.provisioning_starting
                                            ? item.lastStatus
                                            : (<Link to="#" onClick={() => { this.setState({ showLogForDevice: item }) }}>View</Link>)
                                          }
                                        </td>
                                        <td style={{ width: "100px" }}>
                                          <span className={item.lastTrnVersion !== undefined && item.lastTrnVersion.status === "COMPLETED" ? "badge badge-success" : (item.lastTrnVersion !== undefined && item.lastTrnVersion.status === "FAILED" ? "badge badge-danger" : "badge badge-info")}>
                                            {item.lastTrnVersion !== undefined ? item.lastTrnVersion.status : ""}
                                          </span>
                                        </td>
                                        <td>{item.imageVersion}</td>
                                        <td>{item.engineVersion}</td>
                                        <td>{item.deviceModuleLibVersion}</td>
                                        <td>{item.readerProcFWVersion}</td>
                                        <td>{item.doorProcFWVersion}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div>
                                  <h5 className="mt-4">Provisioning Detail</h5>
                                  <p className="text-muted font-size-16 mt-4">
                                    <span className="badge badge-success font-size-16 mr-3">
                                      {size(this.state.successDevices)}
                                    </span>
                                    Successful Provisioning
                                    <span className="badge badge-danger font-size-16 mr-3 ml-3">
                                      {size(this.state.failureDevices)}
                                    </span>
                                    Failed Provisioning
                                  </p>
                                  { size(this.state.deviceProvisioned) > 0 && (
                                    <div className="button-items mt-4">
                                      <Button
                                        color="success"
                                        className="btn btn-success btn-lg waves-effect"
                                        onClick={() => {
                                          // reset as new page
                                          let newState = ProvisioningEdgeInitState();
                                          newState.trnSelected = trnSelected;
                                          newState.activeTabProgress = 2;
                                          newState.progressValue = 50;
                                          newState.toggleResetDiscovery = !this.state.toggleResetDiscovery;
                                          this.cleanupData();
                                          this.setState(newState);
                                        }}
                                      >
                                        Start New Provisioning
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          className={
                            this.state.activeTabProgress === 1 || this.state.provisioning_starting
                            || (this.state.activeTabProgress === 3 && this.state.provisioning_started)
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (this.state.activeTabProgress === 1
                                  || this.state.provisioning_starting
                                  || (this.state.activeTabProgress === 3 && this.state.provisioning_started)) {
                                // nothing
                              } else {
                                this.toggleTabProgress(
                                  this.state.activeTabProgress - 1
                                )
                              }
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={
                            this.state.activeTabProgress === 4 || this.state.provisioning_starting ||
                            (this.state.activeTabProgress === 3 && !this.state.provisioning_started)
                              ? "next disabled"
                              : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (this.state.activeTabProgress === 4 || this.state.provisioning_starting ||
                                (this.state.activeTabProgress === 3 && !this.state.provisioning_started)) {
                                // nothing
                              } else {
                                this.toggleTabProgress(
                                  this.state.activeTabProgress + 1
                                )
                              }
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

ProvisioningEdge.propTypes = {
  errorOfDevice: PropTypes.any,
  errorOfSession: PropTypes.any,
  current_device: PropTypes.object,
  current_session: PropTypes.object,
  onCreateSecureBuild: PropTypes.func,
  onGetSecureBuildDetails: PropTypes.func,
  onInitSecureBuildSession: PropTypes.func,
  onExchangeSecureBuildSession: PropTypes.func,
  onClearSecureBuildError: PropTypes.func,
  onClearSecureBuildSessionError: PropTypes.func,
  onDeviceStoreReset: PropTypes.func,
  onSessionStoreReset: PropTypes.func,
  onCreateSecureBuildAudit: PropTypes.func,
}

const mapStateToProps = ({ secureBuildReducer, secureBuildSessionReducer, secureBuildDiscoveryReducer }) => ({
  current_device: secureBuildReducer.secureBuildDetails,
  current_session: secureBuildSessionReducer.secureBuildSession,
  errorOfDevice: secureBuildReducer.error,
  errorOfSession: secureBuildSessionReducer.error,
  discoveryError: secureBuildDiscoveryReducer.error,
})

const mapDispatchToProps = dispatch => ({
  onCreateSecureBuild: device => dispatch(createSecureBuild(device)),
  onGetSecureBuildDetails: id => dispatch(getSecureBuildDetails(id)),
  onInitSecureBuildSession: session => dispatch(initSecureBuildSession(session)),
  onExchangeSecureBuildSession: session => dispatch(exchangeSecureBuildSession(session)),
  onClearSecureBuildError: () => dispatch(clearSecureBuildError()),
  onClearSecureBuildSessionError: () => dispatch(clearSecureBuildSessionError()),
  onDeviceStoreReset: () => dispatch(deviceStoreReset()),
  onSessionStoreReset: () => dispatch(sessionStoreReset()),
  onCreateSecureBuildAudit: audit => dispatch(createSecureBuildAudit(audit)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProvisioningEdge)