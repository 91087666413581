import { Link } from "react-router-dom"

const EG2Columns = (onViewDetails, onViewAudits, onViewSamDetails, onViewSamAudits, onSort) => [
  {
    dataField: "deviceId",
    text: "Device ID",
    sort: true,
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '120px', textAlign: 'center' };
    },
  },
  {
    dataField: "serial",
    text: "SAM Serial",
    sort: true,
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '300px', textAlign: 'center' };
    }
  },
  {
    dataField: "lastTrnVersion",
    text: "TRN/Version",
    align: "center",
    headerAlign: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '200px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <span className="ml-2 mr-1" style={ { "overflow-wrap": "break-word", "white-space": "normal" }}>
          {(row.lastTrnVersion !== undefined && row.lastTrnVersion !== null) ? row.lastTrnVersion.trnVersionSummary : ""}
        </span>
      </>
    ),
  },
  {
    dataField: "lastTrnVersion.status",
    text: "Status",
    headerAlign: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '150px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <div>
          <span className={"ml-2 badge " + (row.secureElement.completed ? "badge-soft-success" : "badge-soft-danger")}>
            {row.secureElement.completed ? "SAM COMPLETED" : "SAM FAILED"}
          </span>
          <br/>
          <span className={"ml-2 badge " + (row.lastTrnVersion.status === "COMPLETED" ? "badge-soft-success" : (row.lastTrnVersion.status === "FAILED" ? "badge-soft-danger" : "badge-soft-primary"))}>
            {"FIRMWARE " + row.lastTrnVersion.status}
          </span>
        </div>
      </>
    )
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    headerStyle: (colum, colIndex) => {
      return { width: '100px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
      <ul className="list-unstyled">
        <li>
          <Link to="#" id={"firmwareDetails" + row.id} onClick={() => onViewDetails(row)}>
            <span className="ml-2 badge badge-soft-info">Firmware Details</span>
          </Link>
        </li>
        <li>
          <Link to="#" id={"firmwareAudit" + row.id} onClick={() => onViewAudits(row)}>
            <span className="ml-2 badge badge-soft-info">Firmware Audit</span>
          </Link>
        </li>
        <li>
          <Link to="#" id={"samDetails" + row.id} onClick={() => onViewSamDetails(row)}>
            <span className="ml-2 badge badge-soft-info">SAM Details</span>
          </Link>
        </li>
        <li>
          <Link to="#" id={"samAudit" + row.id} onClick={() => onViewSamAudits(row)}>
            <span className="ml-2 badge badge-soft-info">SAM Audit</span>
          </Link>
        </li>
      </ul>
      </>
    ),
  },
  {
    dataField: "lastTrnVersion.enroller.login",
    text: "Enroller",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '100px', textAlign: 'center' };
    }
  },

]

export default EG2Columns
