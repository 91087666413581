import {
  GET_SECURE_BUILD_AUDITS,
  GET_SECURE_BUILD_AUDITS_FAIL,
  GET_SECURE_BUILD_AUDITS_SUCCESS,
  CREATE_SECURE_BUILD_AUDIT,
  CREATE_SECURE_BUILD_AUDIT_FAIL,
  CREATE_SECURE_BUILD_AUDIT_SUCCESS,
  CLEAR_ERROR,
  CLEAR_ACTION_RESULT,
  RESET
} from "./actionTypes"

export const clearActionResult = () => ({
  type: CLEAR_ACTION_RESULT,
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})

export const reset = () => ({
  type: RESET
})

export const getSecureBuildAudits = (secureBuildId, page, size, sortField, sortOrder, search) => ({
  type: GET_SECURE_BUILD_AUDITS,
  payload: { secureBuildId, page, size, sortField, sortOrder, search }
})

export const getSecureBuildAuditsSuccess = (devicePage) => ({
  type: GET_SECURE_BUILD_AUDITS_SUCCESS,
  payload: devicePage
})

export const getSecureBuildAuditsFail = error => ({
  type: GET_SECURE_BUILD_AUDITS_FAIL,
  payload: error,
})

export const createSecureBuildAudit = (device) => ({
  type: CREATE_SECURE_BUILD_AUDIT,
  payload: device,
})

export const createSecureBuildAuditSuccess = device => ({
  type: CREATE_SECURE_BUILD_AUDIT_SUCCESS,
  payload: device,
})

export const createSecureBuildAuditFail = error => ({
  type: CREATE_SECURE_BUILD_AUDIT_FAIL,
  payload: error,
})
