import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Col, Row } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { getSecureElements, clearActionResult } from "store/secureElement/actions"
import SecureElementColumns from "./SecureElementColumns"
import SecureElementDetails from "./SecureElementDetails"
import SecureElementAuditListing from "./SecureElementAuditListing"
import { GET_SECURE_ELEMENTS } from "store/secureElement/actionTypes"
import { RESULT_OK, RESULT_ERROR } from "store/baseActionTypes"

class SecureElementListing extends Component {

  constructor(props) {
    super(props)
    this.state = {
      secureElementPage: {
        currentPage: 0,
        totalPages: 0,
        pageSize: 10,
        totalItems: 0,
        devices: []
      },
      sortField: '',
      sortOrder: '',
      search: '',
      actionResult: undefined,
      forceReload: false,
      deviceDetails: undefined,
      deviceAudit: undefined
    }
  }

  componentDidMount() {
    const { onGetSecureElements } = this.props
    onGetSecureElements(this.state.secureElementPage.currentPage, this.state.secureElementPage.pageSize, this.state.sortField, this.state.sortOrder, this.state.search)
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGetSecureElements } = this.props
    const { secureElementPage, sortField, sortOrder, search, forceReload } = this.state

    if (secureElementPage.currentPage !== prevState.secureElementPage.currentPage
        || secureElementPage.pageSize !== prevState.secureElementPage.pageSize
        || sortField !== prevState.sortField
        || sortOrder !== prevState.sortOrder
        || search !== prevState.search) {
      onGetSecureElements(secureElementPage.currentPage, secureElementPage.pageSize, sortField, sortOrder, search);
      return;
    }
    if (forceReload === true) {
      secureElementPage.currentPage = 0; // reset to first page
      onGetSecureElements(secureElementPage.currentPage, secureElementPage.pageSize, sortField, sortOrder, search);
      this.setState({
        forceReload: false,
        secureElementPage
      })
      return;
    }

    if (this.props.secureElementPage !== undefined && this.props.secureElementPage.devices !== undefined && this.props.secureElementPage !== prevProps.secureElementPage) {
      this.setState({
        secureElementPage: this.props.secureElementPage
      })
      return;
    }
    if (this.props.actionResult !== prevProps.actionResult) {
      this.setState({
        actionResult: this.props.actionResult
      })
      return;
    }
  }

  onPageChange = (page, sizePerPage) => {
    let secureElementPage = Object.assign({}, this.state.secureElementPage);
    secureElementPage.currentPage = page - 1;
    this.setState({
      secureElementPage
    })
  }

  onSizePerPageChange = (page, sizePerPage) => {
    let secureElementPage = Object.assign({}, this.state.secureElementPage);
    secureElementPage.currentPage = 0;
    secureElementPage.pageSize = sizePerPage;
    this.setState({
      secureElementPage
    })
  }

  onSort = (field, order) => {
    let secureElementPage = Object.assign({}, this.state.secureElementPage);
    secureElementPage.currentPage = 0;
    this.setState({
      sortField: field,
      sortOrder: order,
      secureElementPage
    })
  }

  handleTableChange = (type, { page, searchText }) => {
    if (type === 'search') {
      let secureElementPage = Object.assign({}, this.state.secureElementPage);
      secureElementPage.currentPage = 0;
      this.setState({
        search: searchText,
        secureElementPage
      })
    }
  }

  showDeviceDetails = (row) => {
    this.setState({
      deviceDetails: row
    });
  }

  showAudits = (row) => {
    this.setState({
      deviceAudit: row
    });
  }

  onCloseResultDlg = (reloadPage) => {
    this.props.onClearActionResult();
    if (reloadPage === true) {
      this.setState({
        forceReload: true
      })
    }
  }

  renderActionResultDlg = () => {
    let { actionResult } = this.state
    let reloadPage = false
    if (actionResult !== undefined) {
      let error = false
      let title = ''
      let message = ''
      if (actionResult.action === GET_SECURE_ELEMENTS) {
          if (actionResult.result === RESULT_OK) {
            return null
          } else if (actionResult.result === RESULT_ERROR) {
            error = true
            title = "Failed to fetch devices"
            message = actionResult.error
          }
      }
      return <SweetAlert
        error={error}
        success={!error}
        title={title}
        onConfirm={() => this.onCloseResultDlg(reloadPage)}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  renderShowDeviceDetails = () => {
    const { deviceDetails } = this.state;
    if (deviceDetails !== undefined) {
      return <SecureElementDetails
        device={deviceDetails}
        onClosed={() => {
          this.setState({
            deviceDetails: undefined
          });
        }}
      >
      </SecureElementDetails>
    }
    return null;
  }

  renderShowDeviceAudits = () => {
    const { deviceAudit } = this.state;
    if (deviceAudit !== undefined) {
      return <SecureElementAuditListing
        secureElement={deviceAudit}
        onClosed={() => {
          this.setState({
            deviceAudit: undefined
          });
        }}
      >
      </SecureElementAuditListing>
    }
    return null;
  }

  render() {
    const { secureElementPage } = this.state
    const pageOptions = {
      page: secureElementPage.currentPage + 1, //SpringData: Page number values start with 0.
      sizePerPage: secureElementPage.pageSize,
      totalSize: secureElementPage.totalItems,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
      onSizePerPageChange: this.onSizePerPageChange,
    }
    function customMatchFunc({
      searchText,
      value,
    }) {
      if (typeof value !== 'undefined') {
        return value.startsWith(searchText);
      }
      return false;
    }

    return (
      <Fragment >
        <div>
          {this.renderActionResultDlg()}
          {this.renderShowDeviceDetails()}
          {this.renderShowDeviceAudits()}
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={secureElementPage.devices || []}
                columns={SecureElementColumns(this.showDeviceDetails, this.showAudits, this.onSort)}
                bootstrap4
                search={{ customMatchFunc }}
              >
                {toolkitProps => (
                  <Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box mr-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Search.SearchBar
                              {...toolkitProps.searchProps}
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div>
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            remote
                            hover={true}
                            bordered={false}
                            striped={false}
                            classes={
                              "table table-centered table-nowrap"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={this.handleTableChange}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </div>
      </Fragment >
    );
  }
}

SecureElementListing.propTypes = {
  actionResult: PropTypes.object,
  secureElementPage: PropTypes.object,
  onGetSecureElements: PropTypes.func,
  onClearActionResult: PropTypes.func,
}

const mapStateToProps = ({ secureElementReducer }) => ({
  secureElementPage: secureElementReducer.secureElementPage,
  actionResult: secureElementReducer.actionResult,
})
const mapDispatchToProps = dispatch => ({
  onGetSecureElements: (page, size, sortField, sortOrder, search) => dispatch(getSecureElements(page, size, sortField, sortOrder, search)),
  onClearActionResult: () => dispatch(clearActionResult()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecureElementListing)