import {
  GET_SECURE_BUILD_AUDITS,
  GET_SECURE_BUILD_AUDITS_SUCCESS,
  GET_SECURE_BUILD_AUDITS_FAIL,
  CREATE_SECURE_BUILD_AUDIT_SUCCESS,
  CREATE_SECURE_BUILD_AUDIT_FAIL,
  CLEAR_ERROR,
  CLEAR_ACTION_RESULT,
  RESULT_OK,
  RESULT_ERROR,
  RESET
} from "./actionTypes"

const INIT_STATE = {
  secureBuildAuditPage: {},
  secureBuildAuditDetails: undefined,
  error: {},
  actionResult: undefined
}

const secureBuildAuditReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CLEAR_ACTION_RESULT:
      {
        return {
          ...state,
          actionResult: undefined
        }
      }

    case GET_SECURE_BUILD_AUDITS_SUCCESS:
      {
        return {
          ...state,
          secureBuildAuditPage: action.payload,
          actionResult: { action: GET_SECURE_BUILD_AUDITS, result: RESULT_OK, error: '' },
        }
      }
    case GET_SECURE_BUILD_AUDITS_FAIL: {
      return {
        ...state,
        secureBuildAuditPage: {},
        actionResult: { action: GET_SECURE_BUILD_AUDITS, result: RESULT_ERROR, error: action.payload },
      }
    }

    case CREATE_SECURE_BUILD_AUDIT_FAIL: {
      return {
        ...state,
        error: { message: action.payload },
      }
    }

    case CREATE_SECURE_BUILD_AUDIT_SUCCESS:
      {
        return {
          ...state,
          secureBuildAuditDetails: action.payload
        }
      }

    case CLEAR_ERROR:
      {
        return {
          ...state,
          error: {}
        }
      }

    case RESET:
      {
        return INIT_STATE
      }

    default:
      return state
  }
}

export default secureBuildAuditReducer
