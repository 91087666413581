import { AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React, { Component, Fragment } from 'react'
import { Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap"
import { toDatetimeString } from "../../helpers/app_helper"

class AuditDetailsPopup extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { data, modal, onToggle } = this.props
        return (
            <Fragment>
                <Modal
                    isOpen={modal}
                    centered={true}
                    backdrop="static"
                    size="lg"
                >
                    <AvForm>
                        <ModalHeader toggle={onToggle} tag="h4">
                            Audit details
                        </ModalHeader>
                        <ModalBody>
                            <Card body>
                            <div className="table-borderless">
                                <Table className="table mb-0">
                                    <tbody>
                                        <RowWrapper label="Action:" value={data.action} />
                                        <RowWrapper label="Service Class:" value={data.serviceClass} />
                                        <RowWrapper label="Service Method:" value={data.serviceMethod} />
                                        <RowWrapper label="Asset Class:" value={data.assetClass} />
                                        <RowWrapper label="IP Address:" value={data.ipAddress} />
                                        <RowWrapper label="Datetime:" value={toDatetimeString(new Date(data.createdDate))} />
                                    </tbody>
                                </Table>
                            </div>
                            </Card>
                        </ModalBody>
                        <ModalFooter>
                            <Row>
                                <Col>
                                    <div className="text-right">
                                        <button
                                            type="button"
                                            className="btn btn-light mr-2"
                                            onClick={onToggle}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </Fragment>
        )
    }
}

const RowWrapper = ({label, value}) => {
    return (
        <tr>
            <td width="200">{label}</td>
            <td>{value}</td>
        </tr>
    )
}

AuditDetailsPopup.propTypes = {
    modal: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
};

export default AuditDetailsPopup;