import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { RESULT_OK } from "store/baseActionTypes";
import { DELETE_CONF, ADD_CONF, EDIT_CONF,GET_CONFS } 
    from "store/configurations/actionTypes";
import { isEmpty } from "lodash";


export const ActionResultBox = ({actionResult, onCloseResultDlg}) => {
    if (!isEmpty(actionResult)) {      
      if (actionResult.action === DELETE_CONF) {
        return renderDeleteConf(actionResult, onCloseResultDlg)
      }
      else if (actionResult.action === ADD_CONF) {
        return renderAddConf(actionResult, onCloseResultDlg)
      }
      else if (actionResult.action === EDIT_CONF) {
        return renderEditConf(actionResult, onCloseResultDlg)
      }
      else if (actionResult.action === GET_CONFS) {
        return renderGetConfs(actionResult, onCloseResultDlg)
      }
    }
    return null;
}

const renderDeleteConf = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      title = "Delete Configuration Success"
      message = "Configuration has been deleted"
      reloadPage = true
    } else {
      title = "Delete Configuration Fail"
      message = "Delete Configuration failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

  const renderAddConf = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      title = "Add Configuration Success"
      message = "Configuration has been created"
      reloadPage = true
    } else {
      title = "Add Configuration Fail"
      message = "Add Configuration failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

  const renderEditConf = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      title = "Edit Configuration Success"
      message = "Configuration has been edited"
      reloadPage = true
    } else {
      title = "Edit Configuration Fail"
      message = "Edit Configuration failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

  const renderGetConfs = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      return null
    } else {
      title = "Fetch Configurations Fail"
      message = "Fetch Configurations failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

  const renderActionResultDlgImpl = (success, title, message, reloadPage, onCloseResultDlg) => {
    return <SweetAlert
      error={!success}
      success={success}
      title={title}
      onConfirm={()=>onCloseResultDlg(reloadPage)}
    >
      {message}
    </SweetAlert>;
  }