import { toDatetimeString } from "../../helpers/app_helper"

const KeysetSelectionColumns = (onSelectedChanged, onSort) => [
  {
    dataField: "checked",
    text: "#",
    headerStyle: (colum, colIndex) => {
      return { width: '60px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => {
      return (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={row.id}
            onChange={e => onSelectedChanged(e.target.checked, row)}
            checked={row.checked}
          />
          <label
            className="custom-control-label"
            htmlFor={row.id}
          />
        </div>
    )},
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    onSort: onSort,
  },
  {
    dataField: "type",
    text: "Type"
  },
  {
    dataField: "createdDate",
    text: "Created Date",
    align: "center",
    formatter: (cellContent, row) => (
      <>
        <span className="ml-2 mr-1">
          {toDatetimeString(new Date(row.createdDate))}
        </span>
      </>
    ),
    headerStyle: (colum, colIndex) => {
      return { width: '160px', textAlign: 'center' };
    },
  },
  {
    dataField: "defaultKey",
    text: "Default Key?",
    align: "center",
    formatter: (cellContent, row) => {
      return (
        <>
          {row.defaultKey === true && (<span className="badge badge-success">DEFAULT</span>)}
          {row.defaultKey === false && (<span></span>)}
        </>
      )
    },
    headerStyle: (colum, colIndex) => {
      return { textAlign: 'center' };
    },
  }
]

export default KeysetSelectionColumns
