import { apiGetLogins } from "helpers/backend_helper"
import { isEmpty } from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  getLoginsFail, getLoginsSuccess
} from "./actions"
import {
  GET_LOGINS
} from "./actionTypes"


function* onGetLogins({ payload }) {
  try {
    const response = yield call(apiGetLogins, {
      params: {
        page: payload.page,
        size: payload.size,
        sortField: payload.sortField,
        sortOrder: payload.sortOrder,
        filterStartDate: payload.search.filterStartDate,
        filterEndDate: payload.search.filterEndDate,
        filterStatus: payload.search.filterStatus,
        filterLogin: payload.search.filterLogin,
      }
    })
    yield put(getLoginsSuccess({
      content: response.content,
      page: response.number,
      size: response.size,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
      search: payload.search,
    }))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getLoginsFail(error.response.data.message))
    } else {
      yield put(getLoginsFail(error.message))
    }
  }
}

function* loginsSaga() {
  yield takeEvery(GET_LOGINS, onGetLogins)
}

export default loginsSaga
