/* KEYSETS */

/* LIST KEYSETS */
export const GET_KEYSETS = "GET_KEYSETS"
export const GET_KEYSETS_SUCCESS = "GET_KEYSETS_SUCCESS"
export const GET_KEYSETS_FAIL = "GET_KEYSETS_FAIL"

/* FETCH KEYSET DETAILS */
export const GET_KEYSET_DETAILS = "GET_KEYSET_DETAILS"
export const GET_KEYSET_DETAILS_SUCCESS = "GET_KEYSET_DETAILS_SUCCESS"
export const GET_KEYSET_DETAILS_FAIL = "GET_KEYSET_DETAILS_FAIL"

/* CREATE KEYSET */
export const CREATE_KEYSET = "CREATE_KEYSET"
export const CREATE_KEYSET_SUCCESS = "CREATE_KEYSET_SUCCESS"
export const CREATE_KEYSET_FAIL = "CREATE_KEYSET_FAIL"

/* UPDATE KEYSET */
export const UPDATE_KEYSET = "UPDATE_KEYSET"
export const UPDATE_KEYSET_SUCCESS = "UPDATE_KEYSET_SUCCESS"
export const UPDATE_KEYSET_FAIL = "UPDATE_KEYSET_FAIL"

/* MAKE_DEFAULT KEYSET */
export const MAKE_DEFAULT_KEYSET = "MAKE_DEFAULT_KEYSET"
export const MAKE_DEFAULT_KEYSET_SUCCESS = "MAKE_DEFAULT_KEYSET_SUCCESS"
export const MAKE_DEFAULT_KEYSET_FAIL = "MAKE_DEFAULT_KEYSET_FAIL"

/* DELETE KEYSET */
export const DELETE_KEYSET = "DELETE_KEYSET"
export const DELETE_KEYSET_SUCCESS = "DELETE_KEYSET_SUCCESS"
export const DELETE_KEYSET_FAIL = "DELETE_KEYSET_FAIL"

/* CLEAR ERROR */
export const CLEAR_ACTION_RESULT = "CLEAR_ACTION_RESULT"
export const RESULT_OK = "RESULT_OK"
export const RESULT_ERROR = "RESULT_ERROR"
