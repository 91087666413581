import { Link } from "react-router-dom"
import { map, isEmpty } from "lodash"
import {
    Badge,
    Card,
    CardBody,
    Col,
    Media,
    Tooltip,
} from "reactstrap"
import React, { Component } from 'react';
import { toDatetimeString } from "../../helpers/app_helper"

class CardTrn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isComponentLoaded: true
        }
    }

    componentDidMount() {

    }

    renderBadge(ver, key) {
        let blueDot = <div className="fc-daygrid-event-dot-green" />
        let grayDot = <div className="fc-daygrid-event-dot-gray" />
        return <h5><Badge color="warning" style={{ width: "100px" }} key={"badge" + key}> 
                    {ver.selectedProvisioning === true ? blueDot : grayDot} {ver.trnVersion}
               </Badge></h5>
    }

    renderVersion = (trn, ver, key, onVersionClick) => {
        return (
            <span key={"span-"+key}>
                <Link
                    to="#"
                    onClick={(event) => {
                        event.preventDefault()
                        if (onVersionClick != null)
                            onVersionClick(trn, ver)
                    }
                    }
                    className="d-inline-block"
                    id={'member-' + ver.id}
                    key={"_team_" + key}
                >
                {this.renderBadge(ver, key)}
                <Tooltip
                    placement="top"
                    target={"member-" + ver.id}
                    isOpen={this.isToolTipOpen('member-' + ver.id)}
                    toggle={() => this.onToggleToolTip('member-' + ver.id)}
                >
                    {ver.trnVersion}<br/>{ver.description}<br/>{toDatetimeString(new Date(ver.createdDate))}
                </Tooltip>
                </Link>
                {" "}
            </span>         
        )
    }

    onToggleToolTip = targetName => {
        if (!this.state[targetName]) {
            this.setState({
                [targetName]: {
                    tooltipOpen: true
                }
            });
        } else {
            this.setState({
                [targetName]: {
                    tooltipOpen: !this.state[targetName].tooltipOpen
                }
            });
        }
    }

    isToolTipOpen = targetName => {
        if (isEmpty(this.state) || this.state.isComponentLoaded === false) {
            return false
        }
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    }

    renderFwTypeIcon = (type, small) => {
        let css_type
        switch(type) {
            case "Smart Bridge Application":
                css_type = small?"bx bx-chip":"fwicon bx bx-chip"
              break;
            case "Smart Bridge OS":
                css_type = small?"bx bx-analyse":"fwicon bx bx-analyse"
              break;
            case "Smart Bridge Reader Firmware":
                css_type = small?"bx bx-devices":"fwicon bx bx-devices"
              break;
            case "Smart Bridge Door Firmware":
                css_type = small?"bx bx-door-open":"fwicon bx bx-door-open"
              break;
            case "Smart Bridge Secure Keys":
                css_type = small?"bx bxs-key":"fwicon bx bxs-key"
              break;
          }
        return <i className={css_type}></i>
    }

    render() {
        const { trns, onVersionClick, onTrnClick } = this.props
        return (
            <React.Fragment>
                {map(trns, (trn, key) => (
                    <Col xl="6" sm="6" key={"_trn_" + key}>
                        <Card key={"card-" + key}>
                            <CardBody>
                                <Media>
                                    <Link to="#" onClick={() => { onTrnClick(trn) }}>
                                        <div className="avatar-md mr-4" >
                                            <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                                { this.renderFwTypeIcon(trn.type) }
                                            </span>
                                        </div>
                                    </Link>
                                    <Media className="overflow-hidden" body>
                                        <h1 className="text-truncate font-size-15">
                                            <Link
                                                to="#"
                                                onClick={() => { onTrnClick(trn) }}
                                                className="text-dark"
                                                id={'trn-' + trn.id}
                                                key={'trn-' + trn.id}
                                            >
                                                {trn.name}
                                            </Link>
                                        </h1>
                                        <Tooltip
                                            placement="top"
                                            target={"trn-" + trn.id}
                                            isOpen={this.isToolTipOpen('trn-' + trn.id)}
                                            toggle={() => this.onToggleToolTip('trn-' + trn.id)}
                                        >Click to view TRN details</Tooltip>
                                        <p className="text-muted mb-4">
                                            {
                                                this.getFirstVersion(trn) !== null 
                                                    ? this.getFirstVersion(trn).description
                                                    : ""
                                            }
                                        </p>
                                        <div>
                                            <div>
                                            {(
                                                trn.prodVersions.slice(0, 8).map((ver, key2) =>
                                                    this.renderVersion(trn, ver, key2, onVersionClick))
                                            )}
                                            </div>
                                        </div>
                                    </Media>
                                </Media>
                            </CardBody>
                            <div className="px-4 py-3 border-top">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mr-2">
                                        <h3><Badge color="badge badge-primary">{trn.type}</Badge></h3>
                                    </li>
                                    <li className="list-inline-item mr-2" id={`createdDate-${key}`}>
                                        <i className="bx bx-calendar mr-1" /> 
                                        {
                                            this.getFirstVersion(trn) !== null 
                                                ? toDatetimeString(new Date(this.getFirstVersion(trn).createdDate))
                                                : ""
                                        }
                                        <Tooltip placement="top"
                                            target={`createdDate-${key}`}
                                            isOpen={this.isToolTipOpen(`createdDate-${key}`)}
                                            toggle={() => this.onToggleToolTip(`createdDate-${key}`)}
                                        >
                                            Created date
                                        </Tooltip>
                                    </li>
                                    <li className="list-inline-item mr-2" id={`versionLen-${key}`}>
                                    { this.renderFwTypeIcon(trn.type, true) }{" "}
                                        {trn.prodVersions.length}
                                        <Tooltip placement="top"
                                            target={`versionLen-${key}`}
                                            isOpen={this.isToolTipOpen(`versionLen-${key}`)}
                                            toggle={() => this.onToggleToolTip(`versionLen-${key}`)}
                                        >
                                            Total of current version
                                        </Tooltip>
                                    </li>
                                </ul>
                            </div>
                        </Card>
                    </Col>
                ))
                }
            </React.Fragment >
        )
    }

    getFirstVersion = (trn) => {
        if (trn.prodVersions.length > 0) {
            return trn.prodVersions[0]    
        }
        return null
    }
}

export default CardTrn
