import {
  CLEAR_STATE,
  CLEAR_ACTION_RESULT,
  GET_EXCEPTIONS,
  GET_EXCEPTIONS_SUCCESS,
  GET_EXCEPTIONS_FAIL,
} from "./actionTypes"


export const clearState = () => ({
  type: CLEAR_STATE,
})

export const clearActionResult = () => ({
  type: CLEAR_ACTION_RESULT,
})

export const getExceptions = (page, size, sortField, sortOrder) => ({
  type: GET_EXCEPTIONS,
  payload: { page, size, sortField, sortOrder }
})

export const getExceptionsSuccess = (contentPage) => ({
  type: GET_EXCEPTIONS_SUCCESS,
  payload: { contentPage },
})

export const getExceptionsFail = error => ({
  type: GET_EXCEPTIONS_FAIL,
  payload: error,
})





