import { call, put, takeEvery } from "redux-saga/effects"

import {
  SECURE_BUILD_DISCOVERY_REQUEST,
  SECURE_BUILD_DISCOVERY_RESPONSE
} from "./actionTypes"

import {
  getSecureBuildDiscoveryRequestSuccess,
  getSecureBuildDiscoveryRequestFail,
  getSecureBuildDiscoveryResponseSuccess,
  getSecureBuildDiscoveryResponseFail
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getSecureBuildDiscoveryRequest,
  getSecureBuildDiscoveryResponse
} from "helpers/backend_helper"

function* fetchSecureBuildDiscoveryRequest() {
  try {
    const response = yield call(getSecureBuildDiscoveryRequest)
    yield put(getSecureBuildDiscoveryRequestSuccess(response))
  } catch (error) {
    yield put(getSecureBuildDiscoveryRequestFail(error))
  }
}

function* parseSecureBuildDiscoveryResponse({ payload }) {
  try {
    const response = yield call(getSecureBuildDiscoveryResponse, payload)
    yield put(getSecureBuildDiscoveryResponseSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getSecureBuildDiscoveryResponseFail(error.response.data))
    } else {
      yield put(getSecureBuildDiscoveryResponseFail(error.message))
    }
  }
}

function* secureBuildDiscoverySaga() {
  yield takeEvery(SECURE_BUILD_DISCOVERY_REQUEST, fetchSecureBuildDiscoveryRequest)
  yield takeEvery(SECURE_BUILD_DISCOVERY_RESPONSE, parseSecureBuildDiscoveryResponse)
}

export default secureBuildDiscoverySaga