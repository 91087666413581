import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { GET_USER_PROFILE, LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"
import { obfuscatedValue } from "../../../helpers/app_helper"

import {
  postJwtLogin,
  getUserProfile,
} from "../../../helpers/backend_helper"
import { getUserProfileSuccess, getUserProfileFail } from "./actions"

function* loginUser({ payload: { user, history, cookies } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      let response = null
      if (cookies.get("token")) {
        try {
          response = JSON.parse(Buffer.from(cookies.get('token'), 'base64').toString('ascii'))
          response.token = response.token.replace(obfuscatedValue, '')
        } catch (error) {
        }
      }
      if (user) {
        response = yield call(postJwtLogin, {
          username: user.username,
          password: user.password,
        })
      }
      yield put(loginSuccess(JSON.stringify(response)))
      const userProfile = yield call(getUserProfile)
      yield put(getUserProfileSuccess(userProfile))
      let superAdmin = false;
      let admin = false;
      let isUser = false;
      let vendor = false;
      if (userProfile !== undefined && userProfile.role !== undefined) {
        if (userProfile.role === 'ROLE_SUPER_ADMIN') {
          superAdmin = true;
        } else if (userProfile.role === 'ROLE_ADMIN') {
          admin = true;
        } else if (userProfile.role === 'ROLE_USER') {
          isUser = true;
        } else if (userProfile.role === 'ROLE_VENDOR') {
          vendor = true;
        }
      }

      // Store cookie or reset expire for cookie
      if ((user && user.rememberme === true) || cookies.get("token")) {
        var now = new Date();
        var expireTime = now.getTime() +  24 * 3600 * 1000; //1 day
        now.setTime(expireTime);
        let abfuscated = response
        abfuscated.token = abfuscated.token + obfuscatedValue 
        cookies.set('token', Buffer.from(JSON.stringify(abfuscated)).toString('base64'), { path: '/', expires:  now});
      }

      localStorage.setItem("userRoles", JSON.stringify({
        superAdmin,
        admin,
        user: isUser,
        vendor
      }));

      if (isUser || vendor) {
        history.push("/provisioning-eg2")
      } else {
        history.push("/dashboard")
      }
    } else {
      history.push("/provisioning-eg2")
    }
  } catch (error) {
    cookies.remove('token')
    const errMsg = typeof error.response.data === 'object' ? error.response.data.error : error.response.data
    yield put(apiError(errMsg))
  }
}

function* logoutUser({ payload: { history, cookies } }) {
  try {
    localStorage.clear();
    if (cookies.get('token')) {
      cookies.remove('token')
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* onGetUserProfile() {
  try {
    const userProfile = yield call(getUserProfile)
    yield put(getUserProfileSuccess(userProfile))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getUserProfileFail(error.response.data))
    } else {
      yield put(getUserProfileFail(error.message))
    }
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(GET_USER_PROFILE, onGetUserProfile)
}

export default authSaga
