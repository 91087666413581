import {
  CLEAR_ACTION_RESULT, CLEAR_STATE, GET_LOGINS, GET_LOGINS_FAIL, GET_LOGINS_SUCCESS
} from "./actionTypes"

export const clearActionResult = () => ({
  type: CLEAR_ACTION_RESULT,
})

export const clearState = () => ({
  type: CLEAR_STATE,
})

export const getLogins = (page, size, sortField, sortOrder, search) => ({
  type: GET_LOGINS,
  payload: { page, size, sortField, sortOrder, search }
})

export const getLoginsSuccess = (contentPage) => ({
  type: GET_LOGINS_SUCCESS,
  payload: { contentPage },
})

export const getLoginsFail = error => ({
  type: GET_LOGINS_FAIL,
  payload: error,
})





