import {
  EDIT_PROFILE, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAIL, UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL
} from "./actionTypes"
import { CLEAR_ACTION_RESULT, RESULT_OK, RESULT_ERROR } from "../../baseActionTypes"

const initialState = {
  actionResult: {},
}

const profile = (state = initialState, action = {}) => {
  switch (action.type) {
    case CLEAR_ACTION_RESULT:
      state = {
        ...state,
        actionResult: {},
      }
      break
    case EDIT_PROFILE_SUCCESS:
      state = {
        ...state,
        actionResult: { action: EDIT_PROFILE, result: RESULT_OK, error: '' }
      }
      break
    case EDIT_PROFILE_FAIL:
      state = {
        ...state,
        actionResult: { action: EDIT_PROFILE, result: RESULT_ERROR, error: action.payload }
      }
      break
    case UPDATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        actionResult: { action: UPDATE_PASSWORD, result: RESULT_OK, error: '' }
      }
      break
    case UPDATE_PASSWORD_FAIL:
      state = {
        ...state,
        actionResult: { action: UPDATE_PASSWORD, result: RESULT_ERROR, error: action.payload }
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
