import {
  CLEAR_STATE,
  RESULT_OK,
  RESULT_ERROR,
  SELECT_FOR_PROVISIONING,
  SELECT_FOR_PROVISIONING_SUCCESS,
  SELECT_FOR_PROVISIONING_FAIL
} from "./actionTypes"


const INIT_STATE = {
  contentPage: {},
  actionResult: {},
}

const trnVersionReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CLEAR_STATE:
      {
        return {
          ...INIT_STATE
        }
      }

    case SELECT_FOR_PROVISIONING_SUCCESS:
      {
        return {
          ...state,
          actionResult: { action: SELECT_FOR_PROVISIONING, result: RESULT_OK, error: '' },
          contentPage: action.payload
        }
      }

    case SELECT_FOR_PROVISIONING_FAIL: {
      return {
        ...state,
        actionResult: { action: SELECT_FOR_PROVISIONING, result: RESULT_ERROR, error: action.payload },
      }
    }

    default:
      return state
  }
}

export default trnVersionReducer
