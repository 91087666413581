import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_HSM_INFO,
} from "./actionTypes"

import {
  getHsmInfoSuccess,
  getHsmInfoFail,
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getHsmInfo,
} from "helpers/backend_helper"

function* fetchHsmInfo() {
  try {
    const response = yield call(getHsmInfo)
    yield put(getHsmInfoSuccess(response))
  } catch (error) {
    yield put(getHsmInfoFail(error))
  }
}

function* hsmInfoSaga() {
  yield takeEvery(GET_HSM_INFO, fetchHsmInfo)
}

export default hsmInfoSaga