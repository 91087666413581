import {
  GET_KEYSETS_SUCCESS,
  GET_KEYSETS_FAIL,
  GET_KEYSET_DETAILS_SUCCESS,
  GET_KEYSET_DETAILS_FAIL,
  CREATE_KEYSET_SUCCESS,
  CREATE_KEYSET_FAIL,
  UPDATE_KEYSET_SUCCESS,
  UPDATE_KEYSET_FAIL,
  DELETE_KEYSET_SUCCESS,
  DELETE_KEYSET_FAIL,
  MAKE_DEFAULT_KEYSET_SUCCESS,
  MAKE_DEFAULT_KEYSET_FAIL,
  CLEAR_ACTION_RESULT
} from "./actionTypes"

const INIT_STATE = {
  keysetPage: {},
  keysetDetails: undefined,
  actionResult: undefined
}

const keysetsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CLEAR_ACTION_RESULT:
      {
        return {
          ...state,
          actionResult: undefined
        }
      }

    case GET_KEYSETS_SUCCESS:
      {
        return {
          ...state,
          keysetPage: action.payload,
          actionResult: { action:  action.type, error: '' },
        }
      }
    case GET_KEYSETS_FAIL: {
      return {
        ...state,
        keysetPage: {},
        actionResult: { action:  action.type, error: action.payload },
      }
    }

    case CREATE_KEYSET_FAIL:
    case UPDATE_KEYSET_FAIL:
    case MAKE_DEFAULT_KEYSET_FAIL:
    case DELETE_KEYSET_FAIL:
    case GET_KEYSET_DETAILS_FAIL: {
      return {
        ...state,
        actionResult: { action: action.type, error: action.payload },
      }
    }

    case GET_KEYSET_DETAILS_SUCCESS:
    case CREATE_KEYSET_SUCCESS:
    case UPDATE_KEYSET_SUCCESS:
    case MAKE_DEFAULT_KEYSET_SUCCESS:
      {
        return {
          ...state,
          keysetDetails: action.payload,
          actionResult: { action: action.type },
        }
      }
    case DELETE_KEYSET_SUCCESS:
      {
        return {
          ...state,
          actionResult: { action: action.type },
        }
      }

    default:
      return state
  }
}

export default keysetsReducer
