import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Tooltip
} from "reactstrap"

import { map, size  } from "lodash"

import SweetAlert from "react-bootstrap-sweetalert"

import { getConnector, disposeConnector, validateDiscoveryDeviceText } from "../../helpers/edge_helper"
import { getSecureBuildDiscoveryRequest, getSecureBuildDiscoveryResponse, clearError } from "store/secureBuildDiscovery/actions"


class DiscoverySecureBuildView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      secureBuildDiscoveryRequest: undefined,
      secureBuildDiscoveryResponse: undefined,
      rawSecureBuilds: [],
      deviceFiltered: [],
      deviceSelected: [],
      discoverying: false,
      showError: undefined,
      tooltipId: undefined
    }
    this.handleDiscovery.bind(this)
    this.handleDeviceToggle.bind(this)
  }

  componentDidMount() {
    const { onSecureBuildDiscoveryRequest } = this.props
    onSecureBuildDiscoveryRequest()
  }

  componentDidUpdate(prevProps, prevState) {
    const { onSecureBuildDiscoveryResponse, selectionMode, allowProvisionSAM } = this.props
    const { rawSecureBuilds } = this.state

    if (this.props.toggleReset !== prevProps.toggleReset) {
      this.setState({
        secureBuildDiscoveryResponse: undefined,
        rawSecureBuilds: [],
        deviceFiltered: [],
        deviceSelected: [],
        discoverying: false
      })
      return;
    }

    if (rawSecureBuilds !== undefined && rawSecureBuilds.length > 0 && rawSecureBuilds !== prevState.rawSecureBuilds) {
      let response = {};
      response.encryptedDevices = rawSecureBuilds;
      response.allowProvisionSecureElement = allowProvisionSAM;
      onSecureBuildDiscoveryResponse(response);
      return;
    }

    if (this.props.secureBuildDiscoveryRequest !== undefined && this.props.secureBuildDiscoveryRequest !== prevProps.secureBuildDiscoveryRequest) {
      this.setState({
        secureBuildDiscoveryRequest: this.props.secureBuildDiscoveryRequest
      })
    }

    if (this.props.secureBuildDiscoveryResponse !== undefined && this.props.secureBuildDiscoveryResponse !== prevProps.secureBuildDiscoveryResponse) {
      let devices = [...this.props.secureBuildDiscoveryResponse.devices];
      let deviceSelected = [];
      for (let d of devices) {
        d.allowSelect = d.allowProvisioningProduction === true;
        if (selectionMode === true && d.allowSelect) {
          // defauult checked if mode selection
          d.checked = true;
          deviceSelected.push(d);
        }
      }
      this.setState({
        secureBuildDiscoveryResponse: this.props.secureBuildDiscoveryResponse,
        rawSecureBuilds: [],
        deviceFiltered: devices,
        deviceSelected: deviceSelected,
        discoverying: false
      })
      if (this.props.onSelectedChanged !== undefined) {
        this.props.onSelectedChanged(deviceSelected);
      }
    }

    if (this.props.error !== prevProps.error) {
      this.setState({
        showError: {
          title: "API ERROR",
          message: this.props.error
        }
      })
    }
  }

  handleDiscovery () {
    const { secureBuildDiscoveryRequest } = this.state;
    this.setState({
      secureBuildDiscoveryResponse: undefined,
      rawSecureBuilds: [],
      deviceFiltered: [],
      deviceSelected: [],
      discoverying: true
    });
    let connector = getConnector();
    // address, port, message, waitingTime
    connector.discoverySecureBuilds(
      secureBuildDiscoveryRequest.address,
      secureBuildDiscoveryRequest.port,
      secureBuildDiscoveryRequest.message,
      secureBuildDiscoveryRequest.waitingTime
    ).then(devices => {
      if (devices === undefined || size(devices) === 0) {
        this.setState({
          showError: {
            title: "Discovery",
            message: "No Devices Found"
          },
          discoverying: false
        });
      } else {
        let validatedDevices = [];
        for (let deviceText of devices) {
          if (validateDiscoveryDeviceText(deviceText)) {
            validatedDevices.push(deviceText);
          }
        }
        this.setState({
          rawSecureBuilds: validatedDevices
        });
      }
    }).catch(error => {
      this.setState({
        showError: {
          title: "Discovery Error",
          message: error
        },
        discoverying: false
      });
    }).finally(() => {
      disposeConnector();
    });
  }

  handleDeviceToggle (checked, ip, deviceIndex) {
    let devices = null;
    if (checked) {
      if (this.state.deviceSelected.findIndex(ds => ds.ip === ip) === -1) {
        let d = this.state.deviceFiltered[deviceIndex];
        if (d !== undefined) {
          let deviceSelected = [...this.state.deviceSelected];
          deviceSelected.push(d);

          d.checked = true;
          let deviceFiltered = [...this.state.deviceFiltered];
          deviceFiltered[deviceIndex] = d;

          this.setState({
            deviceFiltered,
            deviceSelected: deviceSelected,
            deviceProvisioning: []
          });
          devices = [...deviceSelected];
        }
      }
    } else {
      let d = this.state.deviceFiltered[deviceIndex];
      if (d !== undefined) {
        let filtered = this.state.deviceSelected.filter(function(value, index, arr){ 
            return value.ip !== ip;
        });
        d.checked = false;
        let deviceFiltered = [...this.state.deviceFiltered];
        deviceFiltered[deviceIndex] = d;
        this.setState({
          deviceFiltered,
          deviceSelected: filtered,
          deviceProvisioning: []
        });
        devices = [...filtered];
      }
    }
    if (this.props.onSelectedChanged !== undefined) {
      if (typeof(devices) == "undefined" || devices === null || devices.length === 0) {
        this.props.onSelectedChanged(undefined);
      } else {
        this.props.onSelectedChanged(devices);
      }
    }
  }

  renderShowError = () => {
    let { showError } = this.state
    if (showError !== undefined) {
      return <SweetAlert
        error={true}
        title={showError.title}
        onConfirm={() => {
          this.setState({
            showError: undefined
          })
        }}
      >
        {showError.message}
      </SweetAlert>
    }
    return null;
  }

  toggleTooltip (currentId, targetId) {
    if (currentId === targetId) {
      this.setState({ tooltipId: undefined });
    } else {
      this.setState({ tooltipId: targetId });
    }
  };

  render() {
    const { deviceFiltered, secureBuildDiscoveryRequest, discoverying, tooltipId } = this.state;
    const { selectionMode } = this.props;
    return (
      <Fragment>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {this.renderShowError()}

                  <div>
                    { secureBuildDiscoveryRequest !== undefined && (
                      <div className="button-items">
                        <Button
                          color="success"
                          className="btn btn-success btn-lg waves-effect"
                          disabled={discoverying}
                          onClick={() => {
                            this.handleDiscovery()
                          }}
                        >
                          {discoverying ? "Scanning..." : "Discovery"}
                        </Button>
                      </div>
                    )}
                    <br/>

                    <div className="table-responsive">
                      <Table className="table table-nowrap table-centered">
                        <thead className="thead-light">
                          <tr>
                            { selectionMode === true && (
                                <th scope="col" style={{ width: "100px" }}>Select</th>
                            )}
                            <th scope="col">Device Address</th>
                            <th scope="col">Device ID</th>
                            <th scope="col">SAM Serial</th>
                            <th scope="col">SAM Provisioned</th>
                            <th scope="col">Model</th>
                            <th scope="col">
                              <div>
                                <Tooltip placement="right" isOpen={tooltipId === "discoveryOSHeader"} target="discoveryOSHeader"
                                    toggle={() => this.toggleTooltip(tooltipId, "discoveryOSHeader")}>OS Version</Tooltip>
                                <div id="discoveryOSHeader">OS</div>
                              </div>
                            </th>
                            <th scope="col">
                              <div>
                                <Tooltip placement="right" isOpen={tooltipId === "discoveryAppHeader"} target="discoveryAppHeader"
                                    toggle={() => this.toggleTooltip(tooltipId, "discoveryAppHeader")}>APP Version</Tooltip>
                                <div id="discoveryAppHeader">APP</div>
                              </div>
                            </th>
                            <th scope="col">
                              <div>
                                <Tooltip placement="right" isOpen={tooltipId === "discoveryDeviceHeader"} target="discoveryDeviceHeader"
                                    toggle={() => this.toggleTooltip(tooltipId, "discoveryDeviceHeader")}>Device Module Version</Tooltip>
                                <div id="discoveryDeviceHeader">Device</div>
                              </div>
                            </th>
                            <th scope="col">
                              <div>
                                <Tooltip placement="right" isOpen={tooltipId === "discoveryReaderHeader"} target="discoveryReaderHeader"
                                    toggle={() => this.toggleTooltip(tooltipId, "discoveryReaderHeader")}>Reader Proc Version</Tooltip>
                                <div id="discoveryReaderHeader">Reader</div>
                              </div>
                            </th>
                            <th scope="col">
                              <div>
                                <Tooltip placement="right" isOpen={tooltipId === "discoveryDoorHeader"} target="discoveryDoorHeader"
                                  toggle={() => this.toggleTooltip(tooltipId, "discoveryDoorHeader")}>Door Proc Version</Tooltip>
                                <div id="discoveryDoorHeader">Door</div>
                              </div>
                            </th>
                            <th scope="col" style={{ width: "250px" }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(deviceFiltered, (item, index) => (
                            <tr key={index}>
                              { selectionMode === true && (
                                <td>
                                  { item.allowSelect === true && (
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={item.ip}
                                        checked={item.checked}
                                        onChange={e => this.handleDeviceToggle(e.target.checked, item.ip, index)}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={item.ip}
                                      />
                                    </div>
                                  )}
                                </td>
                              )}
                              <td>{item.ip} ({item.discoveryTime} ms)</td>
                              <td>{item.deviceId}</td>
                              <td><div style={{ width: "110px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{item.serial}</div></td>
                              <td>
                                <div>
                                  <span className={"ml-2"}>
                                    {(item.secureElementProvisioned ? "Provisioned" : "Not Provisioned") + " - Portal"}
                                  </span>
                                  <br/>
                                  <span className={"ml-2"}>
                                    {(item.secureElementProvisionedFromDevice ? "Provisioned" : "Not Provisioned") + " - Device"}
                                  </span>
                                </div>
                              </td>
                              <td>{item.model}</td>
                              <td>{item.imageVersion}</td>
                              <td>{item.engineVersion}</td>
                              <td>{item.deviceModuleLibVersion}</td>
                              <td>{item.readerProcFWVersion}</td>
                              <td>{item.doorProcFWVersion}</td>
                              <td style={item.allowSelect === true ? { whiteSpace: "unset", width: "250px" } : { whiteSpace: "unset", color: "red", width: "250px"}}>
                                {item.productionStatus}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
      </Fragment>
    );
  }
}

DiscoverySecureBuildView.propTypes = {
  // private
  error: PropTypes.any,
  secureBuildDiscoveryRequest: PropTypes.object,
  secureBuildDiscoveryResponse: PropTypes.object,
  onSecureBuildDiscoveryRequest: PropTypes.func,
  onSecureBuildDiscoveryResponse: PropTypes.func,
  onClearError: PropTypes.func,
  // public
  selectionMode: PropTypes.any,
  onSelectedChanged: PropTypes.func,
  allowProvisionSAM: PropTypes.any,
  toggleReset: PropTypes.any
}

const mapStateToProps = ({ secureBuildDiscoveryReducer }) => ({
  secureBuildDiscoveryRequest: secureBuildDiscoveryReducer.secureBuildDiscoveryRequest,
  secureBuildDiscoveryResponse: secureBuildDiscoveryReducer.secureBuildDiscoveryResponse,
  error: secureBuildDiscoveryReducer.error
})

const mapDispatchToProps = dispatch => ({
  onSecureBuildDiscoveryRequest: () => dispatch(getSecureBuildDiscoveryRequest()),
  onSecureBuildDiscoveryResponse: data => dispatch(getSecureBuildDiscoveryResponse(data)),
  onClearError: () => dispatch(clearError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverySecureBuildView)