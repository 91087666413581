import {
  SAM_DISCOVERY_REQUEST,
  SAM_DISCOVERY_REQUEST_FAIL,
  SAM_DISCOVERY_REQUEST_SUCCESS,
  SAM_DISCOVERY_RESPONSE,
  SAM_DISCOVERY_RESPONSE_FAIL,
  SAM_DISCOVERY_RESPONSE_SUCCESS,
  CLEAR_ERROR
} from "./actionTypes"

export const clearError = () => ({
  type: CLEAR_ERROR,
})

export const getSamDiscoveryRequest = () => ({
  type: SAM_DISCOVERY_REQUEST,
})

export const getSamDiscoveryRequestSuccess = session => ({
  type: SAM_DISCOVERY_REQUEST_SUCCESS,
  payload: session,
})

export const getSamDiscoveryRequestFail = error => ({
  type: SAM_DISCOVERY_REQUEST_FAIL,
  payload: error,
})

export const getSamDiscoveryResponse = (session) => ({
  type: SAM_DISCOVERY_RESPONSE,
  payload: session,
})

export const getSamDiscoveryResponseSuccess = session => ({
  type: SAM_DISCOVERY_RESPONSE_SUCCESS,
  payload: session,
})

export const getSamDiscoveryResponseFail = error => ({
  type: SAM_DISCOVERY_RESPONSE_FAIL,
  payload: error,
})