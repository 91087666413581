import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

// layouts
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/custom.scss"
import { isEmpty } from "lodash";

import { createBrowserHistory } from 'history';
import { setupInterceptors } from './helpers/api_helper';
import store from "./store"
import { logoutUser } from "./store/actions"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getLayout = this.getLayout.bind(this)
    const history = createBrowserHistory();
    setupInterceptors(error => {
      if (error.response.status === 401) {
        store.dispatch(logoutUser());
      }
      if (error.response.status === 404) {
        history.push('/pages-404');
      }
      if (error.response.status === 500) {
        history.push('/pages-500');
      }
      return Promise.reject(error);
    });
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout
    if (this.props.layout.layoutType === "horizontal") {
      layoutCls = HorizontalLayout
    }
    return layoutCls
  }

  render() {
    const Layout = this.getLayout()
    let login = !isEmpty(this.props.authUser)

    return (
      <Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                login={login}
                exact
              />
            ))}
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    authUser: state.Login.authUser
  }
}

App.propTypes = {
  layout: PropTypes.object,
}

export default connect(mapStateToProps, null)(App)
