import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

import { isEmpty, map, size, orderBy } from "lodash"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import KeysetSelection from "../../components/Keysets/KeysetSelection"
import DiscoverySAMView from "../../components/Discovery/DiscoverySAMView"

//Import edge devices helper
import { getConnector, disposeConnector } from "../../helpers/edge_helper"

import { getSecureElementDetails, createSecureElement, clearError as clearSecureElementError, reset as deviceStoreReset } from "store/secureElement/actions"
import { initSecureElementSession, exchangeSecureElementSession, verifyExchangeSecureElementSession, clearError as clearSecureElementSessionError, reset as sessionStoreReset } from "store/secureElementSession/actions"
import { createSecureElementAudit } from "store/secureElementAudit/actions"

export const ProvisioningSamInitState = () => {
  return {
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      deviceSelected: [],
      toggleResetDiscovery: false,
      keysetSelected: undefined,
      deviceProvisioning: [],
      deviceProvisioned: [],
      successDevices: [],
      failureDevices: [],
      confirm_provisioning: false,
      provisioning_starting: false,
      provisioning_started: false,
      current_native_device: undefined,
      current_device: undefined,
      current_session: undefined,
      current_verify_session: undefined,
      connector: undefined,
      showError: undefined,
      autoDiscoveried: false
  };
}

class ProvisioningSam extends Component {
  constructor(props) {
    super(props)
    this.state = ProvisioningSamInitState()
    this.toggleTabProgress.bind(this)
    this.handleStartProvisioning.bind(this)
  }

  componentDidMount() {
    this.cleanupData();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { current_device, current_session, current_verify_session, errorOfDevice, errorOfSession } = this.props
    const { provisioning_starting, current_native_device, failureDevices } = this.state;
    if (provisioning_starting !== prevState.provisioning_starting && provisioning_starting === true) {
      console.log("Start produce next device");
      this.produceNextDevice();
      return;
    }
    // Change to Discovery Devices Tab
    if (this.state.activeTabProgress !== prevState.activeTabProgress && this.state.activeTabProgress === 2) {
      if (!this.state.autoDiscoveried) {
        //this.handleDiscovery();
        //return;
      }
    }

    if (prevProps.errorOfDevice !== errorOfDevice && errorOfDevice !== undefined && errorOfDevice.message !== undefined) {
      this.appendDeviceProvisioningStatus(current_native_device, errorOfDevice.message);
      disposeConnector();
      // device cannot created on service, so we must create an instance as service instance
      let failedDevice = this.toServiceDevice(current_native_device);
      this.addFailureDeviceAndContinue(failedDevice);
      return;
    }

    if (prevProps.errorOfSession !== errorOfSession && errorOfSession !== undefined && errorOfSession.message !== undefined) {
      this.appendDeviceProvisioningStatus(current_native_device, errorOfSession.message);
      disposeConnector();
      this.addFailureDeviceAndContinue(current_session.secureElement);
      return;
    }

    if (prevProps.current_device !== current_device && typeof(current_device) != "undefined" && typeof(current_device.id) != "undefined") {
      this.setState({ current_device: current_device });
      // new device requested
      console.log("[" + current_device.serial + "]: Requested on service");
      // request new session
      this.requestInitDeviceSession(current_device);
      return;
    }
    if (prevProps.current_session !== current_session && typeof(current_session) != "undefined" && typeof(current_session.id) != "undefined") {
      this.setState({ current_session: current_session });
      if (typeof(current_session) != "undefined" && typeof(current_session.secureElement) != "undefined") {
        this.setState({ current_device: current_session.secureElement });
      }
      if (typeof(current_session) != "undefined"
          && (typeof(prevProps.current_session) == "undefined"
            || typeof(prevProps.current_session.id) == "undefined"
            || prevProps.current_session.id != current_session.id
            || typeof(prevProps.current_session.requestKey) == "undefined"
            || prevProps.current_session.requestKey != current_session.requestKey)) {
        console.log("[" + current_session.secureElement.serial + "]: Session changed -> " + current_session.requestKey);
        this.requestExchangeDeviceSession(current_session);
        return;
      }
    }
    if (prevProps.current_verify_session !== current_verify_session && typeof(current_verify_session) != "undefined") {
      this.setState({ current_verify_session: current_verify_session });
      if (typeof(current_verify_session) != "undefined"
          && (typeof(prevProps.current_verify_session) == "undefined"
            || typeof(prevProps.current_verify_session.requestKey) == "undefined"
            || prevProps.current_verify_session.requestKey != current_verify_session.requestKey)) {
        console.log("[" + current_verify_session.currentKeyId + "]: Session changed -> " + current_verify_session.requestKey);
        this.requestVerifyExchangeDeviceSession(current_verify_session);
        return;
      }
    }
  }

  addFailureDeviceAndContinue = (failedDevice) => {
    failedDevice.completed = false;
    let provisionedDevices = [...this.state.deviceProvisioned];
    let failureDevicesCopy = [...this.state.failureDevices];
    provisionedDevices.push(failedDevice);
    failureDevicesCopy.push(failedDevice);
    this.setState({
      deviceProvisioned: provisionedDevices,
      failureDevices: failureDevicesCopy
    }, this.produceNextDevice);
  }

  cleanupData = () => {
    // cleanup data of device and session store
    this.props.onDeviceStoreReset();
    this.props.onSessionStoreReset();
  }

  handleKeysetChanged = (keyset) => {
    this.setState({
      keysetSelected: keyset
    });
  }

  handleSelectedDevicesChanged = (devices) => {
    this.setState({
      deviceSelected: devices
    });
  }

  handleStartProvisioning () {
    if (isEmpty(this.state.deviceSelected)) {
      return;
    }
    if (this.state.keysetSelected === undefined) {
      return;
    }
    console.log("Start Provisioning...");
    let deviceProvisioning = [...this.state.deviceSelected];
    this.setState({
      deviceSelected: [],
      deviceProvisioning: deviceProvisioning,
      provisioning_starting: true,
      provisioning_started: true
    });
  }

  produceNextDevice = () => {
    this.setState({
      connector: undefined,
      current_device: undefined,
      current_session: undefined
    });
    let nextDevice = undefined;
    const { deviceProvisioning, deviceProvisioned } = this.state;
    for (let i = 0; i < deviceProvisioning.length; i++) {
      let found = false;
      for (let j = 0; j < deviceProvisioned.length; j++) {
        if (deviceProvisioning[i].serial === deviceProvisioned[j].serial) {
          found = true;
          break;
        }
      }
      if (!found) {
        nextDevice = deviceProvisioning[i];
        break;
      }
    }
    if (nextDevice === undefined) {
      this.setState({
        provisioning_starting: false
      });
    } else {
      console.log("Produce device " + nextDevice.serial);
      this.requestNewDevice(nextDevice);
    }
  }

  toServiceDevice  = (nativeDevice) => {
    return {
      ip: nativeDevice.ip,
      connectionMode: nativeDevice.connectionMode,
      serial: nativeDevice.serial,
      cplc: nativeDevice.cplc,
      identifyData: nativeDevice.identifyData,
      appVersion: nativeDevice.appVersion,
      keysetId: this.state.keysetSelected.id
    };
  }

  appendDeviceProvisioningStatus = (d, msg) => {
    if (d == undefined) return;
    if (d.status === undefined) {
      d.status = [];
    }
    d.status.push(msg);
    let provisioningItems = [...this.state.deviceProvisioning];
    for (let i = 0; i < provisioningItems.length; i++) {
      if (provisioningItems[i].serial === d.serial) {
        provisioningItems[i] = d;
      }
    }
    console.log("[" + d.serial + "]: " + msg);
    this.setState({
      deviceProvisioning: provisioningItems
    });
  };

  requestNewDevice = (nativeDevice) => {
    this.setState({
      current_native_device: nativeDevice
    });
    console.log("[" + nativeDevice.serial + "]: Requesting new device");
    this.props.onCreateSecureElement(this.toServiceDevice(nativeDevice));
  };

  requestInitDeviceSession = (current_device) => {
    console.log("[" + current_device.serial + "]: Requesting new session");
    let session = {
      secureElementId: current_device.id,
      request: "",
      response: ""
    };
    this.props.onInitSecureElementSession(session);
  };

  requestCreateAuditAction = (current_device, current_session, action, description, statusResult) => {
    console.log("[" + current_device.deviceId + "]: Requesting audit creation");
    let audit = {
      secureElementId: current_device.id,
      secureElementSessionId: current_session.id,
      action: action,
      description: description,
      status: statusResult ? "Success" : "Failure"
    };
    this.props.onCreateSecureElementAudit(audit);
  };

  requestExchangeDeviceSession = async (current_session) => {
    const { current_native_device } = this.state;
    try {
      let current_device = current_session.secureElement;
      // forward keys from service device to native device
      current_native_device.keys = [...current_device.keys];
      current_native_device.distinctKeys = [...current_device.distinctKeys];
      current_native_device.completed = current_device.completed;
      if (this.state.connector === undefined) {
        let conn = getConnector();
        this.setState({
          connector: conn
        });
        let connected = await conn.connectTCP(
          current_native_device.ip,
          8767
        );
        let auditDesc = "Device Connected with Portal [" + current_native_device.ip + "]";
        if (!connected) {
          this.appendDeviceProvisioningStatus(current_native_device, "Cannot connected");
          this.requestCreateAuditAction(current_device, current_session, "Connect", auditDesc, false);
          disposeConnector();
          this.produceNextDevice();
          return;
        } else {
          this.appendDeviceProvisioningStatus(current_native_device, "Connected");
          this.requestCreateAuditAction(current_device, current_session, "Connect", auditDesc, true);
        }
      }
      const { connector } = this.state;
      // logging prev response
      if (current_session.response !== "" && current_session.response !== null && current_session.response !== undefined) {
        this.appendDeviceProvisioningStatus(current_native_device, current_session.response);
      }

      let completed = true;
      for (let k of current_device.keys) {
        if (k.status === "NEW" || k.status === "ISSUE_KEY" || k.status === "CERTIFICATE_SIGNING_REQUEST" || k.status === "ISSUE_CERTIFICATE") {
          completed = false;
          break;
        }
      }
      let noRequest = current_session.request === "" || current_session.request === null || current_session.request === undefined;
      if (completed && noRequest) {
        await connector.disconnectTCP();
        disposeConnector();
        this.setState({
          connector: undefined
        }, () => {
          this.appendDeviceProvisioningStatus(current_native_device, "-------------------");
          this.appendDeviceProvisioningStatus(current_native_device, "Disconnected");
          let auditDesc = "Disconnected Portal connection [" + current_native_device.ip + "]";
          this.requestCreateAuditAction(current_device, current_session, "Disconnect", auditDesc, true);

          if (current_native_device.requiredVerification) {
            this.requestVerifyExchangeDeviceSession({
              secureElementId: current_device.id,
              requestKey: ""
            });
            return;
          }

          let provisionedDevices = [...this.state.deviceProvisioned];
          provisionedDevices.push(current_device);
          let successDevicesCopy = [...this.state.successDevices];
          let failureDevicesCopy = [...this.state.failureDevices];
          if (current_device.completed) {
            successDevicesCopy.push(current_device);
          } else {
            failureDevicesCopy.push(current_device);
          }
          this.setState({
            deviceProvisioned: provisionedDevices,
            successDevices: successDevicesCopy,
            failureDevices: failureDevicesCopy
          }, this.produceNextDevice);
        });
        return;
      }

      if (current_session.request !== "" && current_session.request !== null && current_session.request !== undefined) {
        this.appendDeviceProvisioningStatus(current_native_device, "-------------------");
        this.appendDeviceProvisioningStatus(current_native_device, "Sending request for [" + current_session.requestKey + "]");

        current_session.response = await connector.exchangeTCP(current_session.request);

        this.appendDeviceProvisioningStatus(current_native_device, "Received response for [" + current_session.requestKey + "]");
      }
      this.props.onExchangeSecureElementSession(current_session);
    } catch (error) {
      // got any failure -> disconnect current connection -> show failure error on record -> produce next device
      disposeConnector();
      this.appendDeviceProvisioningStatus(current_native_device, error);
      this.addFailureDeviceAndContinue(current_session.secureElement);
    }
  };

  requestVerifyExchangeDeviceSession = async (current_verify_session) => {
    const { current_native_device } = this.state;
    try {
      console.log("Verify Key: " + current_verify_session.requestKey);
      let current_device = current_verify_session.secureElement;
      // uptodate native device from service device
      if (current_device !== undefined && current_device !== null && current_device.keys !== undefined) {
        current_native_device.keys = [...current_device.keys];
        current_native_device.distinctKeys = [...current_device.distinctKeys];
        current_native_device.completed = current_device.completed;
      }
      if (this.state.connector === undefined) {
        let conn = getConnector();
        this.setState({
          connector: conn
        });
        let connected = await conn.connectTCP(
          current_native_device.ip,
          8767
        );
        if (!connected) {
          this.appendDeviceProvisioningStatus(current_native_device, "Cannot connected");
          disposeConnector();
          this.produceNextDevice();
          return;
        } else {
          this.appendDeviceProvisioningStatus(current_native_device, "Connected");
        }
      }
      const { connector } = this.state;
      // logging prev response
      if (current_verify_session.response !== "" && current_verify_session.response !== null && current_verify_session.response !== undefined) {
        this.appendDeviceProvisioningStatus(current_native_device, current_verify_session.response);
      }

      let completed = current_verify_session.secureElement !== undefined && current_verify_session.requestKey === "";
      if (completed) {
        console.log("Completed Verification");
        await connector.disconnectTCP();
        disposeConnector();
        this.appendDeviceProvisioningStatus(current_native_device, "-------------------");
        this.appendDeviceProvisioningStatus(current_native_device, "Disconnected");

        let provisionedDevices = [...this.state.deviceProvisioned];
        provisionedDevices.push(current_device);
        let successDevicesCopy = [...this.state.successDevices];
        let failureDevicesCopy = [...this.state.failureDevices];
        if (current_device.completed) {
          successDevicesCopy.push(current_device);
        } else {
          failureDevicesCopy.push(current_device);
        }
        this.setState({
          deviceProvisioned: provisionedDevices,
          successDevices: successDevicesCopy,
          failureDevices: failureDevicesCopy
        }, this.produceNextDevice);
        return;
      }

      if (current_verify_session.request !== "" && current_verify_session.request !== null && current_verify_session.request !== undefined) {
        this.appendDeviceProvisioningStatus(current_native_device, "-------------------");
        this.appendDeviceProvisioningStatus(current_native_device, "Sending verification request for [" + current_verify_session.requestKey + "]");

        current_verify_session.response = await connector.exchangeTCP(current_verify_session.request);

        this.appendDeviceProvisioningStatus(current_native_device, "Received verification response for [" + current_verify_session.requestKey + "]");
      }
      this.props.onVerifyExchangeSecureElementSession(current_verify_session);
    } catch (error) {
      // got any failure -> disconnect current connection -> show failure error on record -> produce next device
      disposeConnector();
      this.appendDeviceProvisioningStatus(current_native_device, error);
      if (current_verify_session.secureElement !== undefined) {
        this.addFailureDeviceAndContinue(current_verify_session.secureElement);
      }
    }
  };

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTabProgress: tab,
        })
        let progressValue = tab * 25
        this.setState({ progressValue })
      }
    }
  }

  render() {
    const { deviceSelected, deviceProvisioning, keysetSelected } = this.state;
    return (
      <Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Devices" breadcrumbItem="Secure Element Provisioning" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 1
                            })}
                            onClick={() => {
                              if (!this.state.provisioning_started) {
                                this.toggleTabProgress(1)
                              }
                            }}
                          >
                            <span className="step-number mr-2">01</span>
                            <span>Choose KEYSET</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 2
                            })}
                            onClick={() => {
                              if (!this.state.provisioning_started) {
                                this.toggleTabProgress(2)
                              }
                            }}
                          >
                            <span className="step-number mr-2">02</span>
                            Discovery Devices
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 3,
                            })}
                            onClick={() => {
                              this.toggleTabProgress(3)
                            }}
                          >
                            <span className="step-number mr-2">03</span>
                            Provisioning
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 4,
                            })}
                            onClick={() => {
                              if (this.state.provisioning_started) {
                                this.toggleTabProgress(4)
                              }
                            }}
                          >
                            <span className="step-number mr-2">04</span>
                            Summary
                          </NavLink>
                        </NavItem>
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={this.state.progressValue}
                        />
                        <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"/>
                      </div>
                      <TabContent
                        activeTab={this.state.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <KeysetSelection onSelectedChanged={key => this.handleKeysetChanged(key)} keysetType={"SECURE_ELEMENT"}/>
                        </TabPane>

                        <TabPane tabId={2}>
                          <DiscoverySAMView selectionMode={true} showVerificationColumn={true} toggleReset={this.state.toggleResetDiscovery} onSelectedChanged={devices => this.handleSelectedDevicesChanged(devices)}/>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            { !this.state.provisioning_starting && !isEmpty(deviceSelected) && (
                              <div className="button-items">
                                <Button
                                  color="success"
                                  className="btn btn-success btn-lg waves-effect"
                                  onClick={() => {
                                    this.setState({
                                      confirm_provisioning: true,
                                    })
                                  }}
                                >
                                  Start Provisioning
                                </Button>
                              </div>
                              )}
                            <br/>

                            {this.state.provisioning_starting ? (
                              <SweetAlert
                                title="Secure Element provisioning..."
                                showConfirm={false}
                                customClass="swal2-progressProvisioning"
                                onConfirm={() => {}}
                              >
                                <div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <div className="spinner-grow spinner-grow-8 text-success m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </SweetAlert>
                            ) : null}

                            {keysetSelected === undefined && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please select a KEYSET!
                                  </Alert>
                                </div>
                            )}
                            {keysetSelected !== undefined && (
                                <div>
                                  <Alert color="success" role="alert">
                                    Selected KEYSET: {keysetSelected.name}
                                  </Alert>
                                </div>
                            )}
                            {isEmpty(deviceSelected) && isEmpty(deviceProvisioning) && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please select one or more devices!
                                  </Alert>
                                </div>
                            )}
                            { this.state.provisioning_starting && (
                                <div>
                                  <Alert color="danger" role="alert">
                                    Please do not close this page during provisioning, otherwise the device may be damaged
                                  </Alert>
                                </div>
                            )}

                            { this.state.confirm_provisioning ? (
                              <SweetAlert
                                title="Are you sure you want to start provisioning?"
                                warning
                                showCancel
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                onConfirm={() => {
                                  this.setState({
                                    confirm_provisioning: false,
                                  });
                                  this.handleStartProvisioning()
                                }}
                                onCancel={() =>
                                  this.setState({
                                    confirm_provisioning: false,
                                  })
                                }
                              >
                                Please do not close this page during provisioning, otherwise the device may be damaged
                              </SweetAlert>
                            ) : null}

                            {!isEmpty(deviceSelected) && (
                              <div className="table-responsive">
                                <Table className="table table-nowrap table-centered">
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Device Address</th>
                                      <th scope="col">Serial</th>
                                      <th scope="col">State</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Log</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {map(deviceSelected, (item, index) => (
                                      <tr key={index}>
                                        <td>{item.ip}</td>
                                        <td>{item.serial}</td>
                                        {item.provisioning === null && (
                                          <>
                                            <td>No Key</td>
                                            <td>NEW</td>
                                          </>
                                        )}
                                        {item.provisioning !== null && item.provisioning !== undefined && (
                                          <>
                                            <td>
                                              <div>
                                                {map(orderBy(item.provisioning.distinctKeys, ['id'], ['asc']), (k, ki) => (
                                                  <span key={ki} className={"ml-2 badge " + (k.status === "COMPLETED" ? "badge-soft-success" : (k.status === "FAILED" ? "badge-soft-danger" : "badge-soft-primary"))}>
                                                    {k.name}
                                                  </span>
                                                ))}
                                              </div>
                                            </td>
                                            <td>
                                              <span className={"ml-2 badge " + (item.provisioning.completed === true ? "badge-soft-success" : "badge-soft-primary")}>
                                                {item.provisioning.completed === true ? "COMPLETE" : "IN-COMPLETE"}
                                              </span>
                                            </td>
                                          </>
                                        )}
                                        <td></td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                            {!isEmpty(deviceProvisioning) && (
                              <div className="table-responsive">
                                <Table className="table table-nowrap table-centered">
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Device Address</th>
                                      <th scope="col">Serial</th>
                                      <th scope="col">State</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Log</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {map(deviceProvisioning, (item, index) => (
                                      <tr key={index}>
                                        <td>{item.ip}</td>
                                        <td>{item.serial}</td>
                                        <td>
                                            <div>
                                              {map(orderBy(item.distinctKeys, ['id'], ['asc']), (k, ki) => (
                                                <span key={ki} className={"ml-2 badge " + (k.status === "COMPLETED" ? "badge-soft-success" : (k.status === "FAILED" ? "badge-soft-danger" : "badge-soft-primary"))}>
                                                  {k.name}
                                                </span>
                                              ))}
                                            </div>
                                        </td>
                                        <td>
                                          <span className={"ml-2 badge " + (item.completed === true ? "badge-soft-success" : "badge-soft-primary")}>
                                            {item.completed === true ? "COMPLETE" : "IN-COMPLETE"}
                                          </span>
                                        </td>
                                        <td>
                                          <div>
                                          {map(item.status, (s, si) => (
                                            <p className="mb-0" key={si}>
                                              <em>{s}</em>
                                            </p>
                                          ))}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div>
                                  <h5 className="mt-4">Provisioning Detail</h5>
                                  <p className="text-muted font-size-16 mt-4">
                                    <span className="badge badge-success font-size-16 mr-3">
                                      {size(this.state.successDevices)}
                                    </span>
                                    Successful Provisioning
                                    <span className="badge badge-danger font-size-16 mr-3 ml-3">
                                      {size(this.state.failureDevices)}
                                    </span>
                                    Failed Provisioning
                                  </p>
                                  { size(this.state.deviceProvisioned) > 0 && (
                                    <div className="button-items mt-4">
                                      <Button
                                        color="success"
                                        className="btn btn-success btn-lg waves-effect"
                                        onClick={() => {
                                          // reset local store
                                          this.cleanupData();
                                          // reset as new page
                                          let newState = ProvisioningSamInitState();
                                          newState.keysetSelected = keysetSelected;
                                          newState.activeTabProgress = 2;
                                          newState.progressValue = 50;
                                          newState.toggleResetDiscovery = !this.state.toggleResetDiscovery;
                                          this.setState(newState);
                                        }}
                                      >
                                        Start New Provisioning
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          className={
                            this.state.activeTabProgress === 1 || this.state.provisioning_starting
                            || (this.state.activeTabProgress === 3 && this.state.provisioning_started)
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (this.state.activeTabProgress === 1
                                  || this.state.provisioning_starting
                                  || (this.state.activeTabProgress === 3 && this.state.provisioning_started)) {
                                // nothing
                              } else {
                                this.toggleTabProgress(
                                  this.state.activeTabProgress - 1
                                )
                              }
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={
                            this.state.activeTabProgress === 4 || this.state.provisioning_starting ||
                            (this.state.activeTabProgress === 3 && !this.state.provisioning_started)
                              ? "next disabled"
                              : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (this.state.activeTabProgress === 4 || this.state.provisioning_starting ||
                                (this.state.activeTabProgress === 3 && !this.state.provisioning_started)) {
                                // nothing
                              } else {
                                this.toggleTabProgress(
                                  this.state.activeTabProgress + 1
                                )
                              }
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

ProvisioningSam.propTypes = {
  errorOfDevice: PropTypes.any,
  errorOfSession: PropTypes.any,
  current_device: PropTypes.object,
  current_session: PropTypes.object,
  onCreateSecureElement: PropTypes.func,
  onGetSecureElementDetails: PropTypes.func,
  onInitSecureElementSession: PropTypes.func,
  onExchangeSecureElementSession: PropTypes.func,
  onVerifyExchangeSecureElementSession: PropTypes.func,
  onClearSecureElementError: PropTypes.func,
  onClearSecureElementSessionError: PropTypes.func,
  onDeviceStoreReset: PropTypes.func,
  onSessionStoreReset: PropTypes.func,
  onCreateSecureElementAudit: PropTypes.func
}

const mapStateToProps = ({ secureElementReducer, secureElementSessionReducer }) => ({
  current_device: secureElementReducer.secureElementDetails,
  errorOfDevice: secureElementReducer.error,
  current_session: secureElementSessionReducer.secureElementSession,
  current_verify_session: secureElementSessionReducer.verifySecureElementSession,
  errorOfSession: secureElementSessionReducer.error,
})

const mapDispatchToProps = dispatch => ({
  onCreateSecureElement: device => dispatch(createSecureElement(device)),
  onGetSecureElementDetails: id => dispatch(getSecureElementDetails(id)),
  onInitSecureElementSession: session => dispatch(initSecureElementSession(session)),
  onExchangeSecureElementSession: session => dispatch(exchangeSecureElementSession(session)),
  onVerifyExchangeSecureElementSession: session => dispatch(verifyExchangeSecureElementSession(session)),
  onClearSecureElementError: () => dispatch(clearSecureElementError()),
  onClearSecureElementSessionError: () => dispatch(clearSecureElementSessionError()),
  onDeviceStoreReset: () => dispatch(deviceStoreReset()),
  onSessionStoreReset: () => dispatch(sessionStoreReset()),
  onCreateSecureElementAudit: audit => dispatch(createSecureElementAudit(audit)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProvisioningSam)