import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Card, CardBody, Col, Container, Row, Alert } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import { getHsmInfo } from "store/hsmInfo/actions"

class HsmInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hsmInfo: undefined,
    }
  }

  componentDidMount() {
    const { onGetHsmInfo } = this.props
    onGetHsmInfo()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { hsmInfo } = this.props
    if (hsmInfo !== prevProps.hsmInfo) {
      this.setState({ hsmInfo })
    }
  }

  render() {
    const { hsmInfo } = this.state
    const hsmConnected = hsmInfo !== undefined && hsmInfo.hsmPresent && hsmInfo.connectionAlive

    return (
      <Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Key Management" breadcrumbItem="HSM Info" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {!hsmConnected && (
                        <div>
                          <Alert color="danger" role="alert">
                            No Connection to HSM
                          </Alert>
                        </div>
                    )}
                    {hsmConnected && (
                        <div>
                          <Alert color="success" role="alert">
                            Connection: OK
                          </Alert>
                        </div>
                    )}
                    {hsmConnected && (
                        <div>
                          <Alert color="success" role="alert">
                            Generate KeyPair: {hsmInfo.generateKeyPairTimeInMs} ms
                          </Alert>
                        </div>
                    )}
                    {hsmConnected && (
                        <div>
                          <Alert color="success" role="alert">
                            Generate 1024 random bytes: {hsmInfo.generate1kRandomBytesTimeInMs} ms
                          </Alert>
                        </div>
                    )}
                    {hsmConnected && (
                        <div>
                          <Alert color="success" role="alert">
                            Sign Test: {hsmInfo.signTimeInMs} ms
                          </Alert>
                        </div>
                    )}
                    {hsmConnected && (
                        <div>
                          <Alert color="success" role="alert">
                            Verify Test: {hsmInfo.verifyTimeInMs} ms
                          </Alert>
                        </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

HsmInfo.propTypes = {
  hsmInfo: PropTypes.object,
  onGetHsmInfo: PropTypes.func,
}

const mapStateToProps = ({ hsmInfoReducer }) => ({
  hsmInfo: hsmInfoReducer.hsmInfo
})

const mapDispatchToProps = dispatch => ({
  onGetHsmInfo: () => dispatch(getHsmInfo())
})

export default connect(mapStateToProps, mapDispatchToProps)(HsmInfo)
