import { Link } from "react-router-dom";
import {
  UncontrolledTooltip
} from "reactstrap";
import { toDatetimeString } from "../../helpers/app_helper"

const getUsername = (row) => {
  if (row.user && row.user !== null && row.user !== undefined) {
    return (row.user.login.length > 20 ? (row.user.login.substring(0, 16) + '...') : row.user.login)
  } else {
    return "[Unknown]"
  }
}

const ExceptionColumns = (onSort, onView) => [
  {
    dataField: "userId",
    text: "Username",
    sort: false,
    headerStyle: (colum, colIndex) => {
      return { width: '100px' };
    },
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{
          getUsername(row)
        }</p>
      </>
    ),
  },
  {
    text: "Controller",
    dataField: "controller",
    sort: false,
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.controller.substr(row.controller.lastIndexOf(".") + 1)}</p>
      </>
    ),
  },
  {
    text: "Method",
    dataField: "method",
    sort: false,
    headerStyle: (colum, colIndex) => {
      return { width: '160px' };
    },
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{ row.method.length>25 ? (row.method.substring(0, 23) + '...') : row.method }</p>
      </>
    ),
  },
  {
    text: "Exception Class",
    dataField: "exceptionClass",
    sort: false,
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '260px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{ row.exceptionClass.length>25 ? (row.exceptionClass.substring(0, 23) + '...') : row.exceptionClass }</p>
      </>
    ),
  },
  {
    text: "Date Time",
    dataField: "dateTime",
    align: "center",
    sort: true,
    onSort: onSort,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{toDatetimeString(new Date(row.dateTime))}</p>
      </>
    ),
    headerStyle: (colum, colIndex) => {
      return { width: '135px', textAlign: 'center' };
    },
  },
  {
    text: "Build",
    dataField: "buildVersion",
    sort: false,
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '80px', textAlign: 'center' };
    },
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '50px', };
    },
    formatter: (cellContent, row) => (
      <>
        <Link to="#" className="text-primary">
          <i className="mdi mdi-eye font-size-18 mr-2" id="edittooltip" onClick={() => onView(row)} />
          <UncontrolledTooltip placement="top" target="edittooltip">
            View Details
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  }

]
export default ExceptionColumns

