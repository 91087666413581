import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_TRN_UPLOAD,
} from "./actionTypes"

import {
  getTrnUploadSuccess,
  getTrnUploadFail,
} from "./actions"


//Include Both Helper File with needed methods
import {
  getTrnUploadActive,
} from "helpers/backend_helper"

function* fetchTrnUpload() {
  try {
    const response = yield call(getTrnUploadActive)
    yield put(getTrnUploadSuccess(response))
  } catch (error) {
    yield put(getTrnUploadFail(error))
  }
}

function* trnUploadSaga() {
  yield takeEvery(GET_TRN_UPLOAD, fetchTrnUpload)
}

export default trnUploadSaga