import React, { Fragment, Component } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import SecureBuildListing from "../../components/SecureBuild/SecureBuildListing"


class SecureBuildListingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Devices" breadcrumbItem="Device List" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <SecureBuildListing />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}


export default SecureBuildListingPage