// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
import PropTypes from 'prop-types';
import { Component, Fragment } from "react";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
// action
import { userForgetPassword } from "../../store/actions";


class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleValidSubmit = () => {
    const { email } = this.state;
    const { history } = this.props;
    this.props.userForgetPassword(email, history)
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  renderAlert = () => {
    if (this.props.actionResult.result === 'RESULT_ERROR') {
      return <Alert color="danger" style={{marginTop: "13px"}}>{this.props.actionResult.error}</Alert>
    } 
    else if (this.props.actionResult.result === 'RESULT_OK') {
        return <Alert color="success" style={{marginTop: "13px"}}>Password has been reset. Please check your email.</Alert>
    }
    return null
  }

  render() {
    return (
      <Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="identiv-bg-default">
                    <Row>
                      <Col className="col-12">
                        <div className="identiv-text-default p-4">
                          <h5 className="identiv-text-default">Forgot Password</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    
                    <div className="p-2">
                      {this.renderAlert()}
                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={this.onChange}
                            required
                          />
                        </div>
                        <Row className="form-group">
                          <Col className="text-right">
                            <button
                              className="btn btn-primary identiv-btn-basic w-md waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    back to{" "}
                    <Link
                      to="login"
                      className="font-weight-medium text-primary"
                    >
                      Login
                    </Link>{" "}
                    page
                  </p>
                  <p>
                    © {new Date().getFullYear()} Identiv, Inc. | All Rights Reserved
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

ForgetPasswordPage.propTypes = {
  actionResult: PropTypes.object,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStateToProps = ({ ForgetPassword }) => ({
  actionResult: ForgetPassword.actionResult
})

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage)
)
