/* SECURE_BUILD_SESSION */
export const GET_SECURE_BUILD_SESSION = "GET_SECURE_BUILD_SESSION"
export const GET_SECURE_BUILD_SESSION_SUCCESS = "GET_SECURE_BUILD_SESSION_SUCCESS"
export const GET_SECURE_BUILD_SESSION_FAIL = "GET_SECURE_BUILD_SESSION_FAIL"
export const INIT_SECURE_BUILD_SESSION = "INIT_SECURE_BUILD_SESSION"
export const INIT_SECURE_BUILD_SESSION_SUCCESS = "INIT_SECURE_BUILD_SESSION_SUCCESS"
export const INIT_SECURE_BUILD_SESSION_FAIL = "INIT_SECURE_BUILD_SESSION_FAIL"
export const EXCHANGE_SECURE_BUILD_SESSION = "EXCHANGE_SECURE_BUILD_SESSION"
export const EXCHANGE_SECURE_BUILD_SESSION_SUCCESS = "EXCHANGE_SECURE_BUILD_SESSION_SUCCESS"
export const EXCHANGE_SECURE_BUILD_SESSION_FAIL = "EXCHANGE_SECURE_BUILD_SESSION_FAIL"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const RESET = "RESET"
