import { Fragment } from "react";
import { Container, Row, Col } from "reactstrap"
import { appVersion, deployedEnvironment } from "../../helpers/app_helper"

const Footer = () => {
  let deployedEnv = deployedEnvironment();
  let showDeployedEnv = deployedEnv !== undefined && deployedEnv !== '';
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>SmartBridge.</Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                  {new Date().getFullYear()} © Identiv, Inc. | All Rights Reserved | {appVersion()}
                  {showDeployedEnv ? " | " : ""}
                  {showDeployedEnv && (
                    <span className="badge badge-warning">{deployedEnv}</span>
                  )}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
}

export default Footer
