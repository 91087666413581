import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"
import { Card, CardBody, Col, Container, Row, FormGroup, Label } from "reactstrap"
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import { isEmpty, isEqual, isDate } from "lodash"
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "components/Common/Breadcrumb"
import ReportColumns from "./ReportColumns"

import { getDeviceReport, clearState } from "store/reports/actions"

class DeviceReport extends Component {

  constructor(props) {
    super(props)
    let today = new Date()
    this.state = {
      content: [],
      page: 1,
      size: 20,
      totalElements: -1,

      filterStartDate: today,
      filterEndDate: today,
      filterStatus: 'ALL',
      filterReportType: 'On Screen',

      trnTypeMode: 'ALL',

      actionResult: {},
    }
  }

  componentWillUnmount() {
    this.props.onSendAction_ClearState()
  }

  componentDidUpdate(prevProps, prevState) {
    const { contentPage } = this.props
    const { filterStatus } = this.state
    if (!isEmpty(contentPage)) {
      if (!isEqual(prevProps.contentPage, contentPage)) {
        const { filterReportType } = this.state
        if (!isEmpty(contentPage.dataToDownload) && filterReportType === "Export") {
          this.onSaveAsFile(contentPage.dataToDownload)
        }
        else {
          this.setState({
            content: contentPage.content,
            page: contentPage.page + 1, //SpringData: Page number start with 0.
            size: contentPage.size,
            totalElements: contentPage.totalElements,
  
            trnTypeMode: filterStatus, //only update this state after Generate button clicked
          })
        }
      }
    }
  }

  render() {
    return this.renderReportContent()
  }

  onGenerateClick(page) {
    const { onSendAction_GetDeviceReport } = this.props
    const { size, filterStartDate, filterEndDate, filterStatus, filterReportType} = this.state
    let sd = this.convertToServerFormat(filterStartDate)
    let ed = this.convertToServerFormat(filterEndDate)
    onSendAction_GetDeviceReport(page, size, {filterStartDate: sd, filterEndDate: ed, filterStatus, filterReportType})
  }

  convertToServerFormat = (sourceDate) => {
    let targetDate = ""
    // Server Format Date "MM/dd/yyyy"
    if (isDate(sourceDate)) {
      targetDate = sourceDate.getMonth() + 1 + "/" + sourceDate.getDate() + "/" + sourceDate.getFullYear()
    }
    return targetDate
  }

  onCloseErrorDlg = () => {
    this.setState({ errorMsg: '' })
  }

  renderErrorMessage = () => {
    const { errorMsg } = this.state
    if (!isEmpty(errorMsg)) {
      return <SweetAlert
        error={true}
        success={false}
        title="Error"
        onConfirm={this.onCloseErrorDlg}
      >
        {errorMsg}
      </SweetAlert>
    }
    return null
  }

  onFilterClick = () => {
    const { filterStartDate, filterEndDate } = this.state
    if (!isDate(filterStartDate) || !isDate(filterEndDate)) {
      this.setState({ content: [], totalElements: 0, page: 1, errorMsg: 'From Date and To Date are required' })
      return
    } else if (new Date(filterStartDate.toDateString()) > new Date(filterEndDate.toDateString())) {
      this.setState({ content: [], totalElements: 0, page: 1, errorMsg: 'From Date must be less than To Date' })
      return
    }
    this.onGenerateClick(1)
  }

  onFilterStartDateChange = date => {
    this.setState({
      filterStartDate: date,
    })
  }

  onFilterEndDateChange = date => {
    this.setState({
      filterEndDate: date,
    })
  }

  onFilerControlChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onPageChange = (page, sizePerPage) => {
    this.onGenerateClick(page)
  }

  onTableChange = (type, { searchText }) => {
  }

  renderReportContent = () => {
    let isShowOnScreen = this.state.filterReportType === "On Screen"
    return (
      <Fragment >
        <div className="page-content">
          {this.renderErrorMessage()}
          <Container fluid >
            <Breadcrumbs title="Reports" breadcrumbItem="Device Report" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Fragment>
                      <Row className="mb-2">
                        <Col sm="12">
                          { this.renderFilterBox() }
                        </Col>
                      </Row>
                    </Fragment>
                    { isShowOnScreen ? this.renderDataGrid() : <div style={{height: 110}}/> }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment >
    );
  }

  onSaveAsFile = (dataToDownload) => {
    const { filterStatus } = this.state    
    const element = document.createElement("a")
    const file = new Blob([dataToDownload], {type: 'text/plain'})
    const fn = filterStatus.trim().toLowerCase()
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    element.href = URL.createObjectURL(file)
    element.download = fn + "_device_report_" + date + ".csv"
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  renderDataGrid() {
    const { content, page, size, totalElements, trnTypeMode } = this.state
    const pageOptions = {
      page: page,
      sizePerPage: size,
      totalSize: totalElements,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
    }

    return <PaginationProvider
      pagination={paginationFactory(pageOptions)}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="id"
          data={content || []}
          columns={ReportColumns(trnTypeMode)}
          bootstrap4
        >
          {toolkitProps => (
            <Fragment>
              <Row className="mt-4">
                <Col xl="12">
                  <div>
                    <BootstrapTable
                      keyField={"id"}
                      responsive
                      remote
                      hover={true}
                      bordered={false}
                      striped={false}
                      classes={"table table-centered table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      onTableChange={this.onTableChange}
                      {...paginationTableProps} />
                  </div>
                </Col>
              </Row>
              <Row className="align-items-md-center mt-30">
                <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                  <PaginationListStandalone
                    {...paginationProps} />
                </Col>
              </Row>
            </Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>;
  }

  renderFilterBox() {
    return <AvForm
      className="form-horizontal"
      onValidSubmit={this.onFilterClick}
    >
      <Row>
        <FormGroup row={true}>
          <div className="col-xl col-sm-4">
            <AvGroup>
              <Label>From Date</Label>
              <DatePicker
                selected={this.state.filterStartDate}
                onChange={this.onFilterStartDateChange}
                className="form-control"
                placeholderText="Select date"
                required={true}/>
            </AvGroup>
          </div>
          <div className="col-xl col-sm-4">
            <AvGroup>
              <Label>To Date</Label>
              <DatePicker
                selected={this.state.filterEndDate}
                onChange={this.onFilterEndDateChange}
                className="form-control"
                placeholderText="Select date"
                required={true} />
            </AvGroup>
          </div>
          <div className="col-xl col-sm-4">
            <AvGroup>
              <AvField type="select" name="select-multiple" label="State" 
                  onChange={this.onFilerControlChange} id="filterStatus" name="filterStatus">
                <option defaultValue>ALL</option>
                <option defaultValue>SAM COMPLETED</option>
                <option defaultValue>SAM FAILED</option>
                <option defaultValue>FIRMWARE COMPLETED</option>
                <option defaultValue>FIRMWARE FAILED</option>
              </AvField>
            </AvGroup>
          </div>
          <div className="col-xl col-sm-4">
            <AvGroup>
              <AvField type="select" name="select-multiple" label="Type" 
                  onChange={this.onFilerControlChange} id="filterReportType" name="filterReportType">
                <option defaultValue>On Screen</option>
                <option>Export</option>
              </AvField>
            </AvGroup>
          </div>          
          <div className="col-xl col-sm-4 align-self-end">
            <AvGroup>
              <button
                type="submit"
                className="btn btn-primary mr-1 "
              >
                Generate
              </button>
            </AvGroup>
          </div>
        </FormGroup>
      </Row>
    </AvForm>;
  }
}

DeviceReport.propTypes = {
  contentPage: PropTypes.object,
  actionResult: PropTypes.object,
  onSendAction_GetDeviceReport: PropTypes.func,
  onSendAction_ClearState: PropTypes.func
}

const mapStateToProps = ({ reportsReducer }) => ({
  contentPage: reportsReducer.contentPage,
  actionResult: reportsReducer.actionResult,
})

const mapDispatchToProps = dispatch => ({
  onSendAction_GetDeviceReport: (page, size, search) => { dispatch(getDeviceReport(page - 1, size, search)) },
  onSendAction_ClearState: () => dispatch(clearState())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceReport)
