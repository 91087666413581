import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { clearState, getAudits } from "store/audits/actions";
import AuditDetailsPopup from "./AuditDetailsPopup";
import AuditsListColumns from "./AuditsListColumns";


class AuditsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content: [],
      page: 1,
      size: 20,
      totalPages: 0,
      totalElements: -1,      

      isShowingDetailsDlg: false,
      currentRow: {},
    }
  }

  componentDidMount() {
    const { onSendAction_GetAudits } = this.props
    const { page, size } = this.state
    onSendAction_GetAudits(page, size)
  }

  componentWillUnmount() {
    const { onSendAction_ClearState } = this.props
    onSendAction_ClearState()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { contentPage } = this.props
    if (!isEmpty(contentPage)) {
      if ( !isEqual(prevProps.contentPage, contentPage) ) {
         this.setState({
          content: contentPage.content,
          page: contentPage.page + 1, //SpringData: Page number start with 0.
          size: contentPage.size,
          totalPages: contentPage.totalPages,
          totalElements: contentPage.totalElements,
        })
      }
    }
  }

  onPageChange = (page) => {
    const { onSendAction_GetAudits } = this.props
    const { size, } = this.state
    onSendAction_GetAudits(page, size)
  }

  onTableChange = (type, { page, searchText }) => {}

  onToggle = (currentRow) => {
    let mode = !this.state.isShowingDetailsPopup
    this.setState({currentRow, isShowingDetailsPopup: mode})
  }

  renderDetailsPopup = () => {
    const { currentRow } = this.state
    if (currentRow === undefined || currentRow === null) return null

    return  this.state.isShowingDetailsPopup ? <AuditDetailsPopup 
              data={currentRow}
              modal={this.state.isShowingDetailsPopup}
              onToggle={this.onToggle}
              readOnly={true} /> : null
  }

  render() {
    return (
      <Fragment>
        <div className="page-content">
        {this.renderDetailsPopup()}
          <Container fluid>
            <Breadcrumbs title="Maintenance" breadcrumbItem="Audit" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    { this.renderDataGrid() }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }

  renderDataGrid() {
    const { content, page, size, totalPages, totalElements } = this.state
    const pageOptions = {
      page: page,
      sizePerPage: size,
      totalSize: totalElements,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
    } 
    return <PaginationProvider
      pagination={paginationFactory(pageOptions)}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="id"
          data={content || []}
          columns={AuditsListColumns(this.onToggle)}
          bootstrap4
          search
        >
          {toolkitProps => (
            <Fragment>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      responsive
                      remote
                      bordered={false}
                      striped={false}
                      classes={"table table-centered table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      onTableChange={this.onTableChange}
                      {...paginationTableProps} />
                  </div>
                </Col>
              </Row>
              { totalPages > 1 ?
                <Row className="align-items-md-center mt-30">
                  <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                    <PaginationListStandalone
                      {...paginationProps} />
                  </Col>
                </Row> : null
              }
            </Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>;
  }
}

AuditsList.propTypes = {
  contentPage: PropTypes.object,
  onSendAction_GetAudits: PropTypes.func,
  onSendEvent_ClearState: PropTypes.func
}

const mapStateToProps = ({ auditsReducer }) => ({
  contentPage: auditsReducer.contentPage
})

const mapDispatchToProps = dispatch => ({
  //SpringData: Page number start with 0.
  onSendAction_GetAudits: (page, size) => dispatch(getAudits(page - 1, size)),
  onSendAction_ClearState: () => dispatch(clearState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditsList)
