import {
  GET_TRN_UPLOAD_SUCCESS,
  GET_TRN_UPLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  trnUpload: {},
  error: {}
}

const trnUploadReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_TRN_UPLOAD_SUCCESS:
      {
        return {
          ...state,
          trnUpload: action.payload
        }
      }

    case GET_TRN_UPLOAD_FAIL:
      {
        return {
          ...state,
          error: { message: action.payload.message },
        }
      }

    default:
      return state
  }
}

export default trnUploadReducer
