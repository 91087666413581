import { AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button, Card, CardBody, Col, Container, Modal,
  ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { addConf, deleteConf, editConf, getConfs, clearState } from "store/configurations/actions";
import { ActionResultBox } from "./ActionResultBox";
import { DescField as DescField, ModifiedByField, ModifiedDateField, ModuleNameField, 
  NameField as NameField, OldValueField as OldValueField, ValueField as ValueField } from "./ConfFieldControls";
import ConfigurationColumns from "./ConfigurationColumns";


class ConfigurationsList extends Component {

  constructor(props) {
    super(props)
    const dataFields = this.getDataFields()
    this.state = {
      content: [],
      page: 1,
      size: 10,
      totalPages: 0,
      totalElements: -1,
      sortField: '',
      sortOrder: '',
      search: '',

      isShowingPopupCreateNew: false,
      isShowingPopupEdit: false,
      isShowingPopupConfirmDelete: false,
      rowDeleting: {},
      rowEditing: {},
      actionResult: {},
      ...dataFields,
    }
  }

  getDataFields = () => {
    return {
      name: '',
      description: '',
      value: '',
      moduleName: 'General',
      enabled: false,
    }
  }

  componentDidMount() {
    const { onSendAction_GetConfs } = this.props
    const { page, size, sortField, sortOrder, search} = this.state
    onSendAction_GetConfs(page, size, sortField, sortOrder, search)
  }

  componentWillUnmount() {
    this.props.sendAction_ClearState()
  }

  componentDidUpdate(prevProps, prevState) {
    const { contentPage, actionResult } = this.props
    var serverResponseStates = {}
    var gridViewStates = {}

    if (!isEmpty(actionResult) && !isEqual(prevProps.actionResult, actionResult)) {
      serverResponseStates= { actionResult}
    }

    if (!isEmpty(contentPage)) {
      if ( !isEqual(prevProps.contentPage, contentPage) ) {
        gridViewStates= {
          content: contentPage.content,
          page: contentPage.page + 1, //SpringData: Page number start with 0
          size: contentPage.size,
          totalPages: contentPage.totalPages,
          totalElements: contentPage.totalElements,
          sortField: contentPage.sortField,
          sortOrder: contentPage.sortOrder,
        }
      }
    }

    if (!isEmpty(serverResponseStates) || !isEmpty(gridViewStates)) {
      this.setState({...serverResponseStates, ...gridViewStates})
    }
  }

  render() {
    const {actionResult} = this.state
    return (
      <Fragment >
        <div className="page-content">
          <ActionResultBox actionResult={actionResult} onCloseResultDlg={this.closeResultDlg} />
          { this.renderConfirmDeleteDlg() }
          { this.renderCreateNewConfPopup() }
          { this.renderEditConfPopup() }
          <Container fluid>
            <Breadcrumbs title="Maintenance" breadcrumbItem="Configuration" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    { this.renderDataGrid() }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment >
    )
  }

  onTableChange = (type, { searchText }) => {
    if (type === 'search') {
      this.setState({
        search: searchText
      })
      const { onSendAction_GetConfs } = this.props
      const { page, size, sortOrder, sortField } = this.state
      onSendAction_GetConfs(page, size, sortField, sortOrder, searchText);
    }
  }

  onChangeFieldControl = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onChangeFieldEnable = () => {
    this.setState({
      enabled: !this.state.enabled,
    })
  }

  onSubmitNew = (event) => {
    event.preventDefault();
    const { onSendAction_AddConf } = this.props
    const { name, description, value, moduleName, enabled} = this.state
    onSendAction_AddConf({
      name,
      description,
      value,
      moduleName,
      enabled
    });
    this.setState({ isShowingPopupCreateNew: false })
  }

  onSubmitEdit = (event) => {
    event.preventDefault();
    const { onSendAction_EditConf } = this.props
    const { name, description, value, moduleName, enabled, rowEditing } = this.state
    onSendAction_EditConf({
      id: rowEditing.id,
      name,
      description,
      value,
      enabled,
      moduleName
    });
    this.setState({ isShowingPopupEdit: false })
  }

  showConfirmDelete = (row) => {
    this.setState({
      isShowingPopupConfirmDelete: true,
      rowDeleting: row
    })
  }

  showEditPopup = (row) => {
    this.setState({
      isShowingPopupEdit: !this.state.modal,
      rowEditing: row,
      name: row.name,
      description: row.description,
      value: row.value,
      originValue: row.originValue,
      moduleName: row.moduleName,
      enabled: row.enabled,
    })
  }

  onPageChange = (page) => {
    const { onSendAction_GetConfs } = this.props
    const { size, sortField, sortOrder, search } = this.state
    onSendAction_GetConfs(page, size, sortField, sortOrder, search)
  }

  onSort = (field, order) => {
    const { onSendAction_GetConfs } = this.props
    const { page, size, search } = this.state
    onSendAction_GetConfs(page, size, field, order, search)
  }

  closeResultDlg = (reloadPage) => {
    this.setState({ actionResult: {} })
    if (reloadPage === true) {
      const { page, size, sortField, sortOrder, search } = this.state
      this.props.onSendAction_GetConfs(page, size, sortField, sortOrder, search);
    }
  }

  renderConfirmDeleteDlg = () => {
    if (this.state.isShowingPopupConfirmDelete) {
      return <SweetAlert
        title={`Are you sure you want to delete configuration ${this.state.rowDeleting.name}"?`}
        warning
        showCancel
        confirmButtonText="Yes, delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        closeOnClickOutside={false}
        onConfirm={() => {
          this.props.onSendAction_DeleteConf(this.state.rowDeleting.id)
          this.setState({
            isShowingPopupConfirmDelete: false,
            dynamic_title: "Delete Configuration",
            dynamic_description: `Configuration ${this.state.rowDeleting.name} has been deleted.`,
          })
        }
        }
        onCancel={() =>
          this.setState({
            isShowingPopupConfirmDelete: false,
            rowDeleting: {},
          })
        }
      >
        You won't be able to revert this!
      </SweetAlert>;
    }
    return null;
  }

  renderEditConfPopup = () => {
    const dataFields = this.getDataFields()
    return <Modal
      //size="lg"
      isOpen={this.state.isShowingPopupEdit}
      toggle={() =>
        this.setState({ isShowingPopupEdit: !this.state.isShowingPopupEdit, rowEditing: null })
      }
      centered={true}
      backdrop="static"
    >
      <ModalHeader
        toggle={() =>
          this.setState({ isShowingPopupEdit: !this.state.isShowingPopupEdit, rowEditing: null })
        }
      >
        Edit Configuration
      </ModalHeader>
      <AvForm
        className="form-horizontal"
        onValidSubmit={this.onSubmitEdit}
      >
        <ModalBody>
          <Row>
              <Col lg={12}>{ ModuleNameField(this.state.moduleName, this.onChangeFieldControl) }</Col>
          </Row>             
          <Row>
            <Col lg={12}>{ NameField(this.state.name, this.onChangeFieldControl) }</Col>
          </Row>
          <Row>
            <Col lg={12}>{ DescField(this.state.description, this.onChangeFieldControl) }</Col>
          </Row>          
          <Row>
            <Col lg={12}>{ OldValueField(this.state.originValue) }</Col>
          </Row>
          <Row>
            <Col lg={12}>{ ValueField(this.state.value, this.onChangeFieldControl) }</Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div
                className="custom-control custom-switch mb-3"
                dir="ltr"
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                  defaultChecked={this.state.enabled} />
                <label
                  className="custom-control-label"
                  htmlFor="customSwitch1"
                  onClick={this.onChangeFieldEnable}
                >
                  Enable
            </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>{ModifiedByField(this.state.rowEditing!==null?this.state.rowEditing.modifiedByUser:"")}</Col>
          </Row>
          <Row>
            <Col lg={12}>{ModifiedDateField(this.state.rowEditing!==null?this.state.rowEditing.modifiedDateDisplay:"")}</Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col lg={12}>
              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-primary mr-1 "
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({
                      isShowingPopupEdit: !this.state.isShowingPopupEdit,
                      ...dataFields
                    })}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </AvForm>
    </Modal>;
  }

  renderCreateNewConfPopup = () => {
    return <Modal
      //size="lg"
      isOpen={this.state.isShowingPopupCreateNew}
      toggle={() =>
        this.setState({ isShowingPopupCreateNew: !this.state.isShowingPopupCreateNew, rowEditing: null })
      }
      centered={true}
      backdrop="static"
    >
      <ModalHeader
        toggle={() =>
          this.setState({ isShowingPopupCreateNew: !this.state.isShowingPopupCreateNew, rowEditing: null })
        }
      >
        New configuration
      </ModalHeader>
      <AvForm
        className="form-horizontal"
        onValidSubmit={this.onSubmitNew}
      >
        <ModalBody>
          <Row>
            <Col lg={12}>{ModuleNameField(this.state.moduleName, this.onChangeFieldControl)}</Col>
          </Row>          
          <Row>
            <Col lg={12}>{NameField(this.state.name, this.onChangeFieldControl)}</Col>
          </Row>
          <Row>
            <Col lg={12}>{DescField(this.state.description, this.onChangeFieldControl)}</Col>
          </Row>
          <Row>
            <Col lg={12}>{ValueField(this.state.value, this.onChangeFieldControl)}</Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div
                className="custom-control custom-switch mb-3"
                dir="ltr"
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                  defaultChecked={this.state.enabled} />
                <label
                  className="custom-control-label"
                  htmlFor="customSwitch1"
                  onClick={this.onChangeFieldEnable}
                >
                  Enable
            </label>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col lg={12}>
              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-primary mr-1 "
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({ isShowingPopupCreateNew: !this.state.isShowingPopupCreateNew })}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </AvForm>
    </Modal>;
  }

  renderDataGrid() {
    const dataFields = this.getDataFields()
    const { content, page, size, totalPages, totalElements } = this.state
    const pageOptions = {
      page: page,
      sizePerPage: size,
      totalSize: totalElements,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
    }
    function customMatchFunc({
      searchText,
      value,
    }) {
      if (typeof value !== 'undefined') {
        return value.startsWith(searchText);
      }
      return false;
    }
    
    return <PaginationProvider
      pagination={paginationFactory(pageOptions)}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="id"
          data={content || []}
          columns={ConfigurationColumns(this.showConfirmDelete, this.showEditPopup, this.onSort)}
          bootstrap4
          search={{ customMatchFunc }}
        >
          {toolkitProps => (
            <Fragment>
              <Row className="mb-2">
                <Col sm="4">
                  <div className="search-box mr-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <Search.SearchBar
                        {...toolkitProps.searchProps} />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
                <Col sm="8">
                  <div className="text-sm-right">
                    <Link
                      onClick={() => this.setState({
                        isShowingPopupCreateNew: true,
                        ...dataFields
                      })}
                      to="#"
                    >
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded waves-effect waves-light mb-2 mr-2"
                      >
                        <i className="mdi mdi-plus mr-1" />
                        New Configuration
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <div>
                    <BootstrapTable
                      keyField={"id"}
                      responsive
                      remote
                      hover={true}
                      bordered={false}
                      striped={false}
                      classes={"table table-centered table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      onTableChange={this.onTableChange}
                      {...paginationTableProps} />
                  </div>
                </Col>
              </Row>
              { totalPages > 1 ?
                <Row className="align-items-md-center mt-30">
                  <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                    <PaginationListStandalone
                      {...paginationProps} />
                  </Col>
                </Row> : null
              }
            </Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>;
  }
}

ConfigurationsList.propTypes = {
  contentPage: PropTypes.object,
  actionResult: PropTypes.object,
  onSendAction_GetConfs: PropTypes.func,
  onSendAction_AddConf: PropTypes.func,
  onSendAction_EditConf: PropTypes.func,
  onSendAction_DeleteConf: PropTypes.func,
  sendAction_ClearState: PropTypes.func,
}

const mapStateToProps = ({ confsReducer }) => ({
  contentPage: confsReducer.contentPage,
  actionResult: confsReducer.actionResult,
})

const mapDispatchToProps = dispatch => ({
  //SpringData: Page number start with 0.
  onSendAction_GetConfs: (page, size, sortField, sortOrder, search) => dispatch(getConfs(page - 1, size, sortField, sortOrder, search)),
  onSendAction_AddConf: (row) => dispatch(addConf(row)),
  onSendAction_EditConf: (row) => dispatch(editConf(row)),
  onSendAction_DeleteConf: (id) => dispatch(deleteConf(id)),
  sendAction_ClearState: () => dispatch(clearState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationsList)
