/* SECURE_ELEMENT_AUDITS */

export const GET_SECURE_ELEMENT_AUDITS = "GET_SECURE_ELEMENT_AUDITS"
export const GET_SECURE_ELEMENT_AUDITS_SUCCESS = "GET_SECURE_ELEMENT_AUDITS_SUCCESS"
export const GET_SECURE_ELEMENT_AUDITS_FAIL = "GET_SECURE_ELEMENT_AUDITS_FAIL"

export const CREATE_SECURE_ELEMENT_AUDIT = "CREATE_SECURE_ELEMENT_AUDIT"
export const CREATE_SECURE_ELEMENT_AUDIT_SUCCESS = "CREATE_SECURE_ELEMENT_AUDIT_SUCCESS"
export const CREATE_SECURE_ELEMENT_AUDIT_FAIL = "CREATE_SECURE_ELEMENT_AUDIT_FAIL"

/* CLEAR ERROR */
export const CLEAR_ERROR = "CLEAR_ERROR"
export const CLEAR_ACTION_RESULT = "CLEAR_ACTION_RESULT"

export const RESET = "RESET"

export const RESULT_OK = "RESULT_OK"
export const RESULT_ERROR = "RESULT_ERROR"
