import React, { Fragment, Component } from "react"

import {
  Container
} from "reactstrap"


import Breadcrumbs from "../../components/Common/Breadcrumb"
import DiscoverySecureBuildView from "../../components/Discovery/DiscoverySecureBuildView"


class DiscoverySecureBuild extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Device Discovery" breadcrumbItem="EG-2 Device" />

            <DiscoverySecureBuildView/>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default DiscoverySecureBuild