import { call, put, takeEvery } from "redux-saga/effects"

import {
  SAM_DISCOVERY_REQUEST,
  SAM_DISCOVERY_RESPONSE
} from "./actionTypes"

import {
  getSamDiscoveryRequestSuccess,
  getSamDiscoveryRequestFail,
  getSamDiscoveryResponseSuccess,
  getSamDiscoveryResponseFail
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getSamDiscoveryRequest,
  getSamDiscoveryResponse
} from "helpers/backend_helper"

function* fetchSamDiscoveryRequest() {
  try {
    const response = yield call(getSamDiscoveryRequest)
    yield put(getSamDiscoveryRequestSuccess(response))
  } catch (error) {
    yield put(getSamDiscoveryRequestFail(error))
  }
}

function* parseSamDiscoveryResponse({ payload }) {
  try {
    const response = yield call(getSamDiscoveryResponse, payload)
    yield put(getSamDiscoveryResponseSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getSamDiscoveryResponseFail(error.response.data))
    } else {
      yield put(getSamDiscoveryResponseFail(error.message))
    }
  }
}

function* samDiscoverySaga() {
  yield takeEvery(SAM_DISCOVERY_REQUEST, fetchSamDiscoveryRequest)
  yield takeEvery(SAM_DISCOVERY_RESPONSE, parseSamDiscoveryResponse)
}

export default samDiscoverySaga