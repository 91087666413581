import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, UPDATE_PASSWORD } from "./actionTypes"
import { profileSuccess, profileError, updatePasswordSuccess, updatePasswordError } from "./actions"

import {
  editUserProfile,
  updatePassword,
} from "../../../helpers/backend_helper"
import { isEmpty } from "lodash"

function* onEditProfile({ payload }) {
  try {
    const response = yield call(editUserProfile, payload)
    yield put(profileSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(profileError(error.response.data))
    } else {
      yield put(profileError(error.message))
    }
  }
}

function* onUpdatePassword({ payload }) {
  try {
    const response = yield call(updatePassword, payload)
    yield put(updatePasswordSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(updatePasswordError(error.response.data))
    } else {
      yield put(updatePasswordError(error.message))
    }
  }
}

function* ProfileSaga() {
  yield takeEvery(EDIT_PROFILE, onEditProfile)
  yield takeEvery(UPDATE_PASSWORD, onUpdatePassword)
}

export default ProfileSaga
