/* COMMON */
export const CLEAR_STATE = "CLEAR_STATE"
export const RESULT_OK = "RESULT_OK"
export const RESULT_ERROR = "RESULT_ERROR"

/* Configuration */
export const GET_CONFS = "GET_CONFS"
export const GET_CONFS_SUCCESS = "GET_CONFS_SUCCESS"
export const GET_CONFS_FAIL = "GET_CONFS_FAIL"
export const ADD_CONF = "ADD_CONF"
export const ADD_CONF_SUCCESS = "ADD_CONF_SUCCESS";
export const ADD_CONF_FAIL = "ADD_CONF_FAIL"
export const EDIT_CONF = "EDIT_CONF"
export const EDIT_CONF_SUCCESS = "EDIT_CONF_SUCCESS";
export const EDIT_CONF_FAIL = "EDIT_CONF_FAIL"
export const DELETE_CONF = "DELETE_CONF"
export const DELETE_CONF_SUCCESS = "DELETE_CONF_SUCCESS"
export const DELETE_CONF_FAIL = "DELETE_CONF_FAIL"