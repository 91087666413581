import {
  GET_SECURE_ELEMENTS,
  GET_SECURE_ELEMENTS_SUCCESS,
  GET_SECURE_ELEMENTS_FAIL,
  GET_SECURE_ELEMENT_DETAILS_SUCCESS,
  GET_SECURE_ELEMENT_DETAILS_FAIL,
  CREATE_SECURE_ELEMENT_SUCCESS,
  CREATE_SECURE_ELEMENT_FAIL,
  UPDATE_SECURE_ELEMENT_SUCCESS,
  UPDATE_SECURE_ELEMENT_FAIL,
  CLEAR_ERROR,
  CLEAR_ACTION_RESULT,
  RESULT_OK,
  RESULT_ERROR,
  RESET
} from "./actionTypes"

const INIT_STATE = {
  secureElementPage: {},
  secureElementDetails: undefined,
  error: {},
  actionResult: undefined
}

const secureElementReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CLEAR_ACTION_RESULT:
      {
        return {
          ...state,
          actionResult: undefined
        }
      }

    case GET_SECURE_ELEMENTS_SUCCESS:
      {
        return {
          ...state,
          secureElementPage: action.payload,
          actionResult: { action: GET_SECURE_ELEMENTS, result: RESULT_OK, error: '' },
        }
      }
    case GET_SECURE_ELEMENTS_FAIL: {
      return {
        ...state,
        secureElementPage: {},
        actionResult: { action: GET_SECURE_ELEMENTS, result: RESULT_ERROR, error: action.payload },
      }
    }

    case CREATE_SECURE_ELEMENT_FAIL:
    case UPDATE_SECURE_ELEMENT_FAIL:
    case GET_SECURE_ELEMENT_DETAILS_FAIL: {
      return {
        ...state,
        error: { message: action.payload },
      }
    }

    case GET_SECURE_ELEMENT_DETAILS_SUCCESS:
    case CREATE_SECURE_ELEMENT_SUCCESS:
    case UPDATE_SECURE_ELEMENT_SUCCESS:
      {
        return {
          ...state,
          secureElementDetails: action.payload
        }
      }

    case CLEAR_ERROR:
      {
        return {
          ...state,
          error: {}
        }
      }

    case RESET:
      {
        return INIT_STATE
      }

    default:
      return state
  }
}

export default secureElementReducer
