import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SECURE_ELEMENT_AUDITS,
  CREATE_SECURE_ELEMENT_AUDIT,
} from "./actionTypes"

import {
  getSecureElementAuditsSuccess,
  getSecureElementAuditsFail,
  createSecureElementAuditSuccess,
  createSecureElementAuditFail,
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getSecureElementAudits,
  createSecureElementAudit,
} from "helpers/backend_helper"

function* fetchSecureElementAudits({ payload }) {
  try {
    const response = yield call(getSecureElementAudits, payload.secureElementId, {
      params: {
        page: payload.page,
        size: payload.size,
        sortField: payload.sortField,
        sortOrder: payload.sortOrder,
        search: payload.search
      }
    })
    yield put(getSecureElementAuditsSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getSecureElementAuditsFail(error.response.data.message))
    } else {
      yield put(getSecureElementAuditsFail(error.message))
    }
  }
}

function* onCreateSecureElementAudit({ payload }) {
  try {
    const response = yield call(createSecureElementAudit, payload)
    yield put(createSecureElementAuditSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(createSecureElementAuditFail(error.response.data))
    } else {
      yield put(createSecureElementAuditFail(error.message))
    }
  }
}

function* secureElementAuditSaga() {
  yield takeEvery(GET_SECURE_ELEMENT_AUDITS, fetchSecureElementAudits)
  yield takeEvery(CREATE_SECURE_ELEMENT_AUDIT, onCreateSecureElementAudit)
}

export default secureElementAuditSaga