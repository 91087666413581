import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Modal,
  ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { clearActionResult, clearState, getExceptions } from "store/exceptions/actions";
import { RESULT_ERROR, RESULT_OK, GET_EXCEPTIONS } from "store/exceptions/actionTypes";
import ExceptionColumns from "./ExceptionColumns";


class ExceptionsList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      content: [],
      page: 1,
      size: 20,
      sortField: '',
      sortOrder: '',
      totalPages: 0,
      totalElements: -1,

      actionResultChanged: false,
      actionResult: {},
      errorMsg: '',
      rowViewDetails: {},
      showViewDetailsModal: false,
    }
  }

  componentDidMount() {
    const { onSendAction_GetExceptions } = this.props
    const { page, size } = this.state
    onSendAction_GetExceptions(page, size, "", "")
  }

  componentWillUnmount() {
    this.props.onSendAction_ClearState()
  }
  
  componentDidUpdate(prevProps, prevState) {
    const { contentPage, actionResult } = this.props
    if (!isEmpty(actionResult) && !isEqual(prevProps.actionResult, actionResult)) {
      this.setState({ actionResult, actionResultChanged: true })
    }

    if (!isEmpty(contentPage)) {
      if (!isEqual(prevProps.contentPage, contentPage)) {
        this.setState({
          content: contentPage.content,
          page: contentPage.page + 1, // SpringData: Page number starts with 0
          size: contentPage.size,
          totalPages: contentPage.totalPages,
          totalElements: contentPage.totalElements,
          sortField: contentPage.sortField,
          sortOrder: contentPage.sortOrder,
          actionResultChanged: true,
        })
      }
    }
  }

  render() {
    return (
      <Fragment >
        <div className="page-content">
          {this.renderActionResultDlg()}
          {this.renderErrorMessage()}
          {this.renderViewDetailsPopup()}
          <Container fluid>
            <Breadcrumbs title="Maintenance" breadcrumbItem="Exception" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    { this.renderDataGrid() }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment >
    )
  }

  onPageChange = (page, sizePerPage) => {
    const { onSendAction_GetExceptions } = this.props
    const { size, sortField, sortOrder } = this.state
    onSendAction_GetExceptions(page, size, sortField, sortOrder)
  }

  onSort = (field, order) => {
    const { onSendAction_GetExceptions } = this.props
    const { page, size } = this.state
    onSendAction_GetExceptions(page, size, field, order)
  }

  onTableChange = (type, { page, searchText }) => {
    console.log("handleTableChange", type);
  }

  onCloseErrorDlg = () => {
    this.setState({ errorMsg: '' })
  }

  renderErrorMessage = () => {
    const { errorMsg } = this.state
    if (!isEmpty(errorMsg)) {
      return <SweetAlert
        error={true}
        success={false}
        title="Error"
        onConfirm={this.onCloseErrorDlg}
      >
        {errorMsg}
      </SweetAlert>
    }
    return null
  }

  showViewDetailsPopup = (row) => {
    this.setState({
      showViewDetailsModal: !this.state.modal,
      rowViewDetails: row,
    })
  }

  renderViewDetailsPopup = () => {
    const { rowViewDetails } = this.state
    return <Modal
      size="lg"
      isOpen={this.state.showViewDetailsModal}
      toggle={() =>
        this.setState({ showViewDetailsModal: !this.state.showViewDetailsModal, rowViewDetails: null })
      }
      centered={true}
      backdrop="static"
    >
      <ModalHeader
        toggle={() =>
          this.setState({ showViewDetailsModal: !this.state.showViewDetailsModal, rowViewDetails: null })
        }
      >
        Details
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <Form
              className="form-horizontal"
            >
              <FormGroup>
                <Label for="exceptionClass">Service Path</Label>
                <Input type="text" name="path" id="path"
                  onChange={() => { }}
                  value={rowViewDetails !== null ? rowViewDetails.servicePath : ""} />
              </FormGroup>
              <FormGroup>
                <Label for="exceptionClass">Class</Label>
                <Input type="text" name="class" id="class"
                  onChange={() => { }}
                  value={rowViewDetails !== null ? rowViewDetails.exceptionClass : ""} />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Message</Label>
                <Input type="textarea" name="message" id="message"
                  onChange={() => { }}
                  value={rowViewDetails !== null ? rowViewDetails.message : ""} />
              </FormGroup>
              <FormGroup>
                <Label for="trace">Stack Trace</Label>
                <Input type="textarea" id="trace" rows="3" wrap='off'
                  value={rowViewDetails !== null ? rowViewDetails.stackTraceMessage : ""}
                  onChange={() => { }}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col lg={12}>
            <div className="text-right">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() =>
                  this.setState({
                    showViewDetailsModal: !this.state.showViewDetailsModal,
                    rowViewDetails: null,
                  })}
              >
                Close
                </button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>;
  }

  onCloseResultDlg = (reloadPage) => {
    this.setState({ actionResultChanged: false })
    this.props.onSendAction_ClearActionResult()
  }

  renderActionResultDlg = () => {
    let { actionResult, actionResultChanged } = this.state
    let reloadPage = false
    if (actionResultChanged === true && !isEmpty(actionResult)) {
      let success = false
      let title = ''
      let message = ''
      if (actionResult.action === GET_EXCEPTIONS) {
        if (actionResult.result === RESULT_OK) {
          return null
        } else if (actionResult.result === RESULT_ERROR) {
          title = "Fetch Exceptions Fail"
          message = "Fetch Exceptions failed. " + actionResult.error
        }
      }
      return <SweetAlert
        error={!success}
        success={success}
        title={title}
        onConfirm={() => this.onCloseResultDlg(reloadPage)}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  renderDataGrid() {
    const { content, page, size, totalPages, totalElements } = this.state
    const pageOptions = {
      page: page,
      sizePerPage: size,
      totalSize: totalElements,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
    }
    return <PaginationProvider
      pagination={paginationFactory(pageOptions)}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="id"
          data={content || []}
          columns={ExceptionColumns(this.onSort, this.showViewDetailsPopup)}
          bootstrap4
        >
          {toolkitProps => (
            <Fragment>
              <Row className="mt-4">
                <Col xl="12">
                  <div>
                    <BootstrapTable
                      keyField={"id"}
                      responsive
                      remote
                      hover={true}
                      bordered={false}
                      striped={false}
                      classes={"table table-centered table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      onTableChange={this.onTableChange}
                      {...paginationTableProps} />
                  </div>
                </Col>
              </Row>
              { totalPages > 1 ?
                <Row className="align-items-md-center mt-30">
                  <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                    <PaginationListStandalone
                      {...paginationProps} />
                  </Col>
                </Row> : null
              }
            </Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>;
  }
}

ExceptionsList.propTypes = {
  contentPage: PropTypes.object,
  actionResult: PropTypes.object,
  onSendAction_GetExceptions: PropTypes.func,
  onSendAction_ClearActionResult: PropTypes.func,
  onSendAction_ClearState: PropTypes.func,
}

const mapStateToProps = ({ exceptionsReducer }) => ({
  contentPage: exceptionsReducer.contentPage,
  actionResult: exceptionsReducer.actionResult,
})

const mapDispatchToProps = dispatch => ({
  //SpringData: Page number values start with 0.
  onSendAction_GetExceptions: (page, size, sortField, sortOrder) => {
    dispatch(getExceptions(page - 1, size, sortField, sortOrder))
  },
  onSendAction_ClearActionResult: () => dispatch(clearActionResult()),
  onSendAction_ClearState: () => dispatch(clearState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExceptionsList)
