import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import { clearActionResult, getTrns } from "store/trns/actions";
import CardTrn from "../card-trn";
import cardTrnPagination from "../card-trn-pagination";
import TrnDetails from "../TrnDetails";
import TrnVersionDetailsPopup from "../TrnVersionDetailsPopup";


class ProdTrnsList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      content: [],
      page: 1,
      size: 12,
      totalElements: 0,

      actionResult: {},
      viewTrnDetailMode: false,
      modal: false,
      trnClicked: {},
      versionClicked: {},
      show_confirm_selectForProv: false,
      selectedVerIdForProvisioning: -1
    }
  }

  componentDidMount() {
    const { onSendAction_GetTrns } = this.props
    const { page, size } = this.state
    onSendAction_GetTrns(page, size)
    if (localStorage.getItem("userRoles")) {
      let savedUserRoles = JSON.parse(localStorage.getItem("userRoles"));
      if (savedUserRoles !== undefined) {
        this.setState({
          readOnly: savedUserRoles.vendor === true
        });
      }
    }
  }

  onNavToViewTrnList = () => {
    if (!isEmpty(this.state.trnClicked)) {
      this.setState({
        viewTrnDetailMode: false,
        trnClicked: {},
        versionClicked: {},
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { contentPage } = this.props

    if (!isEmpty(contentPage)) {
      if (!isEqual(prevProps.contentPage, contentPage) || contentPage.totalElements !== this.state.totalElements) {
        this.setState({
          content: contentPage.content,
          page: contentPage.page + 1,
          size: contentPage.size,
          totalElements: contentPage.totalElements,
          totalPages: contentPage.totalPages,
          actionResultChanged: true,
        })
      }
    }

    if (prevState.viewTrnDetailMode === true) {
      this.onNavToViewTrnList()
    }

  }

  render() {
    return (this.state.viewTrnDetailMode === true ? this.renderTrnDetail() : this.renderTrnList())
  }

  onHandlePageClick = page => {
    if (!isEqual(page, this.state.page)) {
      const { onSendAction_GetTrns } = this.props
      this.setState({ page })
      onSendAction_GetTrns(page, this.state.size)
    }
  }

  onHandleValidEventSubmit = () => {
    this.onToggle()
  }

  onHandleTrnClick = (trnClicked) => {
    this.setState({
      viewTrnDetailMode: true,
      trnClicked,
      versionClicked: {}
    })
  }

  onHandleVersionClick = (trnClicked, versionClicked) => {
    console.log(versionClicked)
    this.setState({ trnClicked, versionClicked })
    this.onToggle()
  }

  onToggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  renderTrnDetail = () => {
    return (<Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="TRN Management" breadcrumbItem="TRN Details" />
          <Row>
            <TrnDetails trn={this.state.trnClicked} onGoBack={this.onNavToViewTrnList} />
          </Row>
        </Container>
      </div>
    </Fragment>)
  }

  onUpdateVersionClicked = (versionClicked) => {
    this.setState({versionClicked})
  }

  renderTrnList = () => {
    const { content, page, totalPages } = this.state
    return (
      <Fragment>
        <TrnVersionDetailsPopup
          trnClicked={this.state.trnClicked}
          versionClicked={this.state.versionClicked}
          modal={this.state.modal}
          onToggle={this.onToggle}
          readOnly={this.state.readOnly}
          onHandleValidEventSubmit={this.onHandleValidEventSubmit} 
          onUpdateVersionClicked={this.onUpdateVersionClicked}/>

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="TRN Management" breadcrumbItem="TRNs" />
            <Row>
              <CardTrn
                trns={content}
                onVersionClick={this.onHandleVersionClick}
                onTrnClick={this.onHandleTrnClick} />
            </Row>
            <Row>
              {totalPages > 1 ? cardTrnPagination(page, totalPages, this.onHandlePageClick) : null}
            </Row>
          </Container>
        </div>
      </Fragment>
    )
  }

}

ProdTrnsList.propTypes = {
  contentPage: PropTypes.object,
  actionResult: PropTypes.object,
  onSendAction_GetTrns: PropTypes.func,
  onSendAction_ClearActionResult: PropTypes.func,
}

const mapStateToProps = ({ trnsReducer }) => ({
  contentPage: trnsReducer.contentPage,
  actionResult: trnsReducer.actionResult,
})

const mapDispatchToProps = dispatch => ({
  onSendAction_GetTrns: (page, size) => dispatch(getTrns(page - 1, size)),
  onSendAction_ClearActionResult: () => dispatch(clearActionResult()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProdTrnsList)
