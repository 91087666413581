/* COMMON */
export const CLEAR_STATE = "CLEAR_STATE"
export const RESULT_OK = "RESULT_OK"
export const RESULT_ERROR = "RESULT_ERROR"

/* REPORTS */
export const GET_TRN_REPORT = "GET_TRN_REPORT"
export const GET_TRN_REPORT_SUCCESS = "GET_TRN_REPORT_SUCCESS"
export const GET_TRN_REPORT_FAIL = "GET_TRN_REPORT_FAIL"
export const GET_DEVICE_REPORT = "GET_DEVICE_REPORT"
export const GET_DEVICE_REPORT_SUCCESS = "GET_DEVICE_REPORT_SUCCESS"
export const GET_DEVICE_REPORT_FAIL = "GET_DEVICE_REPORT_FAIL"
export const GET_DASHBOARD_REPORT = "GET_DASHBOARD_REPORT"
export const GET_DASHBOARD_REPORT_SUCCESS = "GET_DASHBOARD_REPORT_SUCCESS"
export const GET_DASHBOARD_REPORT_FAIL = "GET_DASHBOARD_REPORT_FAIL"
