import { Fragment, Component } from "react";
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { logoutUser } from "../../store/actions"
import logo from "../../assets/images/identivnew-logo.png";
import { appVersion, deployedEnvironment } from "../../helpers/app_helper";
import { AvForm } from "availity-reactstrap-validation";
import { withCookies } from 'react-cookie';

class Logout extends Component {

  componentDidMount = () => {
    // emit the event
    // this.props.logoutUser(this.props.history)
  }
  
  handleValidSubmit = (event, values) => {
    const { cookies, history } = this.props;
    if (values.rememberedlogin === false) {
      cookies.remove('token')
    }
    history.push("/login")
  }

  render() {
    const { cookies } = this.props;
    let deployedEnv = deployedEnvironment();
    let showDeployedEnv = deployedEnv !== undefined && deployedEnv !== '';
    return (
      <Fragment>
           <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="identiv-bg-default">
                    <Row>
                      <Col className="col-12">
                        <div className="identiv-text-default p-4">
                          <h5 className="identiv-text-default">SmartBridge Portal - Sign Out </h5>
                          <p></p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="44"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <div className="mt-3">
                          <button
                            className="btn btn-primary identiv-btn-basic btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} Identiv, Inc. | All Rights Reserved {appVersion()}
                    {showDeployedEnv ? " | " : ""}
                    {showDeployedEnv && (
                      <span className="badge badge-warning">{deployedEnv}</span>
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>  
    </Fragment>
    );
  }
}

export default withRouter(connect(null, { logoutUser })(withCookies(Logout)))