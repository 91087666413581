import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { RESULT_OK } from "store/baseActionTypes";
import { ADD_USER, CHANGE_PASSWORD_BY_ADMIN, DELETE_USER, EDIT_USER, GET_USERS, RESET_PWD } 
    from "store/users/actionTypes";
import { isEmpty } from "lodash";


export const ActionResultBox = ({actionResult, onCloseResultDlg}) => {
    if (!isEmpty(actionResult)) {      
      if (actionResult.action === RESET_PWD) {
        return renderResetPwd(actionResult, onCloseResultDlg)
      }
      else if (actionResult.action === DELETE_USER) {
        return renderDeleteUser(actionResult, onCloseResultDlg)
      }
      else if (actionResult.action === ADD_USER) {
        return renderAddUser(actionResult, onCloseResultDlg)
      }
      else if (actionResult.action === EDIT_USER) {
        return renderEditUser(actionResult, onCloseResultDlg)
      }
      else if (actionResult.action === CHANGE_PASSWORD_BY_ADMIN) {
        return renderChangePwdByAdmin(actionResult, onCloseResultDlg)
      }
      else if (actionResult.action === GET_USERS) {
        return renderGetUsers(actionResult, onCloseResultDlg)
      }
    }
    return null;
}

const renderDeleteUser = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      title = "Delete User Success"
      message = "User has been deleted"
      reloadPage = true
    } else {
      title = "Delete User Fail"
      message = "Delete User failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

  const renderAddUser = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      title = "Add User Success"
      message = "User has been created"
      reloadPage = true
    } else {
      title = "Add User Fail"
      message = "Add User failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

  const renderGetUsers = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      return null
    } else {
      title = "Fetch Users Fail"
      message = "Fetch Users failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

  const renderChangePwdByAdmin = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      title = "Change Password Success"
      message = "Password has been changed"
    } else {
      title = "Change Password Fail"
      message = "Change Password failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

  const renderEditUser = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      title = "Edit User Success"
      message = "User has been edited"
      reloadPage = true
    } else {
      title = "Edit User Fail"
      message = "Edit User failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

const renderResetPwd = (actionResult, onCloseResultDlg) => {
    let success = actionResult.result === RESULT_OK
    let reloadPage = false
    let title = ''
    let message = ''
    if (success) {
      title = "Reset Password Success"
      message = "Password has been reseted"
      reloadPage = true
    } else {
      title = "Reset Password Fail"
      message = "Reset password failed. " + actionResult.error
    }
    return renderActionResultDlgImpl(success, title, message, reloadPage, onCloseResultDlg)
  }

  const renderActionResultDlgImpl = (success, title, message, reloadPage, onCloseResultDlg) => {
    return <SweetAlert
      error={!success}
      success={success}
      title={title}
      onConfirm={()=>onCloseResultDlg(reloadPage)}
    >
      {message}
    </SweetAlert>;
  }