import {
  SAM_DISCOVERY_REQUEST_SUCCESS,
  SAM_DISCOVERY_REQUEST_FAIL,
  SAM_DISCOVERY_RESPONSE_FAIL,
  SAM_DISCOVERY_RESPONSE_SUCCESS,
  CLEAR_ERROR
} from "./actionTypes"

const INIT_STATE = {
  samDiscoveryRequest: {},
  samDiscoveryResponse: {},
  error: {}
}

const samDiscoveryReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case SAM_DISCOVERY_REQUEST_SUCCESS:
      {
        return {
          ...state,
          samDiscoveryRequest: action.payload
        }
      }
    case SAM_DISCOVERY_RESPONSE_SUCCESS:
      {
        return {
          ...state,
          samDiscoveryResponse: action.payload
        }
      }

    case SAM_DISCOVERY_REQUEST_FAIL:
    case SAM_DISCOVERY_RESPONSE_FAIL: {
      return {
        ...state,
        error: { message: action.payload.message },
      }
    }

    case CLEAR_ERROR:
      {
        return {
          ...state,
          error: {}
        }
      }

    default:
      return state
  }
}

export default samDiscoveryReducer
