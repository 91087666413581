import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import { map } from "lodash"

const TRNColumns = (onSelectedChanged, onVersionChanged, onSort) => [
  {
    dataField: "checked",
    text: "#",
    headerStyle: (colum, colIndex) => {
      return { width: '60px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => {
      return (
        <div className="ml-2 custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={"TRN_" + row.id}
            onChange={e => onSelectedChanged(e.target.checked, row)}
            checked={row.checked}
          />
          <label
            className="custom-control-label"
            htmlFor={"TRN_" + row.id}
          />
        </div>
    )},
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    onSort: onSort,
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    onSort: onSort,
    style: { whiteSpace: "unset"}
  },
  {
    dataField: "type",
    text: "Type",
    sort: true,
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '210px', textAlign: 'center' };
    },
  },
  {
    dataField: "selectedVersion",
    isDummyField: true,
    text: "Selected Version",
    headerStyle: (colum, colIndex) => {
      return { width: '150px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => {
      let versions = row.versions;
      return (
        <UncontrolledDropdown>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <span className="ml-2 mr-1">
              {row.selectedVersion !== undefined && row.selectedVersion.trnVer}
              {row.selectedVersion === undefined && "Choose a version"}
            </span>
            <i className="mdi mdi-chevron-down"/>
          </DropdownToggle>
          <DropdownMenu right>
            {map(versions, (version, index) => (
              <DropdownItem
                key={version.id}
                onClick={() => onVersionChanged(row, version)}
                className={`notify-item ${
                  row.selectedVersion !== undefined && row.selectedVersion.id === version.id ? "active" : "none"
                }`}
              >
                <span className="align-middle">{version.trnVer}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
]

export default TRNColumns
