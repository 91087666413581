import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Col, Row } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { searchingTRNs, clearActionResult } from "store/trns/actions"
import TRNColumns from "./TRNColumns"
import { SEARCHING_TRNS } from "store/trns/actionTypes"
import { RESULT_OK, RESULT_ERROR } from "store/baseActionTypes"

class TRNVersionSelection extends Component {

  constructor(props) {
    super(props)
    this.state = {
      multiple: props.multiple,
      selectedTRNs: [],
      selectedVersions: [],
      searchingPage: {
        currentPage: 0,
        totalPages: 0,
        pageSize: 3,
        totalItems: 0,
        content: []
      },
      sortField: '',
      sortOrder: '',
      search: '',
      actionResult: undefined,
      forceReload: false,
      syncRows: false
    }
  }

  componentDidMount() {
    const { onSeachingTRNs } = this.props
    onSeachingTRNs(this.state.searchingPage.currentPage, this.state.searchingPage.pageSize, this.state.sortField, this.state.sortOrder, this.state.search)
  }

  componentDidUpdate(prevProps, prevState) {
    const { onSeachingTRNs } = this.props
    const { searchingPage, sortField, sortOrder, search, forceReload, syncRows } = this.state

    if (searchingPage.currentPage !== prevState.searchingPage.currentPage
        || searchingPage.pageSize !== prevState.searchingPage.pageSize
        || sortField !== prevState.sortField
        || sortOrder !== prevState.sortOrder
        || search !== prevState.search) {
      onSeachingTRNs(searchingPage.currentPage, searchingPage.pageSize, sortField, sortOrder, search);
      return;
    }
    if (forceReload === true) {
      searchingPage.currentPage = 0; // reset to first page
      onSeachingTRNs(searchingPage.currentPage, searchingPage.pageSize, sortField, sortOrder, search);
      this.setState({
        forceReload: false,
        searchingPage,
      })
      return;
    }
    if (syncRows === true) {
      let { selectedTRNs, selectedVersions } = this.state;
      let searchingPageCopy = _.cloneDeep(this.state.searchingPage);
      for (let i = 0; i < searchingPageCopy.content.length; i++) {
        let trn = searchingPageCopy.content[i];
        // filter prodVersions by selectedProvisioning
        trn.versions = [];
        for (let v of trn.prodVersions) {
          if (v.selectedProvisioning === true) {
            trn.versions.push(v);
          }
        }
        // default selectedVersion is newest version
        trn.selectedVersion = trn.versions[0];
        trn.checked = false;
        for (let t of selectedTRNs) {
          if (t.id === trn.id) {
            trn.checked = true;
            break;
          }
        }
        // change selectedVersion if user changed it before
        for (let v of selectedVersions) {
          if (v.trnId === trn.id) {
            trn.selectedVersion = v;
            break;
          }
        }
        searchingPageCopy.content[i] = trn;
      }
      this.setState({
        syncRows: false,
        searchingPage: searchingPageCopy
      })
      return;
    }

    if (this.props.searchingPage !== undefined && this.props.searchingPage.content !== undefined && this.props.searchingPage !== prevProps.searchingPage) {
      this.setState({
        searchingPage: this.props.searchingPage,
        syncRows: true
      })
      return;
    }
    if (this.props.actionResult !== prevProps.actionResult) {
      this.setState({
        actionResult: this.props.actionResult
      })
      return;
    }
  }

  onPageChange = (page, sizePerPage) => {
    let searchingPage = _.cloneDeep(this.state.searchingPage);
    searchingPage.currentPage = page - 1;
    this.setState({
      searchingPage
    })
  }

  onSizePerPageChange = (page, sizePerPage) => {
    let searchingPage = _.cloneDeep(this.state.searchingPage);
    searchingPage.currentPage = 0;
    searchingPage.pageSize = sizePerPage;
    this.setState({
      searchingPage
    })
  }

  onSort = (field, order) => {
    let searchingPage = _.cloneDeep(this.state.searchingPage);
    searchingPage.currentPage = 0;
    this.setState({
      sortField: field,
      sortOrder: order,
      searchingPage
    })
  }

  handleTableChange = (type, { page, searchText }) => {
    if (type === 'search') {
      let searchingPage = _.cloneDeep(this.state.searchingPage);
      searchingPage.currentPage = 0;
      this.setState({
        search: searchText,
        searchingPage
      })
    }
  }

  selectedChanged = (checked, trn) => {
    if (trn === undefined || trn == null) {
      console.error("NO TRN selected");
      return;
    }
    trn.checked = checked;

    let selectedTRNs = _.cloneDeep(this.state.selectedTRNs);
    let found = false;
    for (let i = 0; i < selectedTRNs.length; i++) {
      if (selectedTRNs[i].id === trn.id) {
        found = true;
        break;
      }
    }

    if (checked === true) {
      if (trn.selectedVersion === undefined || trn.selectedVersion == null) {
        console.error("NO TRN Version selected");
        return;
      }
      if (!found) {
        if (!this.state.multiple) {
          selectedTRNs = [];
        }
        selectedTRNs.push(trn);
        this.setState({
          selectedTRNs,
          syncRows: true
        });
      }
    } else {
      selectedTRNs = this.state.multiple ? selectedTRNs.filter(function(value, index, arr) {
          return value.id !== trn.id;
      }) : [];
      this.setState({
        selectedTRNs,
        syncRows: true
      });
    }
    this.props.onSelectedChanged(selectedTRNs);
  }

  versionChanged = (trn, version) => {
    version.trnId = trn.id;
    let selectedVersions = _.cloneDeep(this.state.selectedVersions);
    let found = false;
    for (let i = 0; i < selectedVersions.length; i++) {
      // override version if same trn
      if (selectedVersions[i].trnId === trn.id) {
        selectedVersions[i] = version;
        found = true;
        break;
      }
    }
    if (!found) {
      selectedVersions.push(version);
    }
    // update reference of selectedTRNs
    let selectedTRNs = _.cloneDeep(this.state.selectedTRNs);
    let trnChanged = false;
    for (let i = 0; i < selectedTRNs.length; i++) {
      if (selectedTRNs[i].id === trn.id) {
        selectedTRNs[i].selectedVersion = version;
        trnChanged = true;
        break;
      }
    }
    this.setState({
      selectedTRNs,
      selectedVersions,
      syncRows: true
    });
    if (trnChanged === true) {
      this.props.onSelectedChanged(selectedTRNs);
    }
  }

  onCloseResultDlg = (reloadPage) => {
    this.props.onClearActionResult();
    if (reloadPage === true) {
      this.setState({
        forceReload: true
      })
    }
  }

  renderActionResultDlg = () => {
    let { actionResult } = this.state
    let reloadPage = false
    if (actionResult !== undefined) {
      let error = false
      let title = ''
      let message = ''
      switch (actionResult.action) {
        case SEARCHING_TRNS:
          if (actionResult.result === RESULT_OK) {
            return null
          } else if (actionResult.result === RESULT_ERROR) {
            error = true
            title = "Failed to fetch TRN"
            message = actionResult.error
          }
          break;
      }
      return <SweetAlert
        error={error}
        success={!error}
        title={title}
        onConfirm={() => this.onCloseResultDlg(reloadPage)}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  render() {
    const { searchingPage } = this.state
    const pageOptions = {
      page: searchingPage.currentPage + 1, //SpringData: Page number values start with 0.
      sizePerPage: searchingPage.pageSize,
      totalSize: searchingPage.totalItems,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
      onSizePerPageChange: this.onSizePerPageChange,
    }
    const { SearchBar } = Search
    function customMatchFunc({
      searchText,
      value,
    }) {
      if (typeof value !== 'undefined') {
        return value.startsWith(searchText);
      }
      return false;
    }

    return (
      <Fragment >
        <div>
          {this.renderActionResultDlg()}
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={searchingPage.content || []}
                columns={TRNColumns(this.selectedChanged, this.versionChanged, this.onSort)}
                bootstrap4
                search={{ customMatchFunc }}
              >
                {toolkitProps => (
                  <Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box mr-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar
                              {...toolkitProps.searchProps}
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div>
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            remote
                            hover={true}
                            bordered={false}
                            striped={false}
                            classes={
                              "table table-centered table-nowrap"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={this.handleTableChange}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </div>
      </Fragment >
    );
  }
}

TRNVersionSelection.propTypes = {
  multiple: PropTypes.bool,
  onSelectedChanged: PropTypes.func,
  actionResult: PropTypes.object,
  searchingPage: PropTypes.object,
  onSeachingTRNs: PropTypes.func,
  onClearActionResult: PropTypes.func,
}

const mapStateToProps = ({ trnsReducer }) => ({
  searchingPage: trnsReducer.searchingPage,
  actionResult: trnsReducer.actionResult,
})
const mapDispatchToProps = dispatch => ({
  onSeachingTRNs: (page, size, sortField, sortOrder, search) => dispatch(searchingTRNs(page, size, sortField, sortOrder, search)),
  onClearActionResult: () => dispatch(clearActionResult()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TRNVersionSelection)