import React, { Component } from "react";
import PropTypes from "prop-types";
import { upload } from "../../helpers/api_helper";

class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.onUpload.bind(this)
    this.onSelectFile.bind(this)

    this.state = {
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: ""
    };
  }

  onSelectFile(event) {
    this.setState({
      selectedFiles: event.target.files,
    });
  }

  onUpload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({
      progress: 0,
      currentFile: currentFile,
      message: ""
    });

    upload(this.props.uploadURL, this.props.onPrepareFormData(currentFile), (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    }).then((response) => {
        // response.data.message
        this.props.onUploadSuccess(response.data);
      }).catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
        });
      });

    this.setState({
      selectedFiles: undefined,
    });
  }

  render() {
    const {
      selectedFiles,
      currentFile,
      progress,
      message
    } = this.state;

    return (
      <div>
        {currentFile && (
          <div className="progress">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        <label className="btn btn-default">
          <input type="file" onChange={e => this.onSelectFile(e)} />
        </label>

        <button
          className="btn btn-success"
          disabled={!selectedFiles}
          onClick={() => this.onUpload()}
        >
          Upload
        </button>

        <div className="alert alert-light" role="alert">
          {message}
        </div>

      </div>
    );
  }
}

UploadFiles.propTypes = {
  // public
  uploadURL: PropTypes.any,
  onPrepareFormData: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  //onUploadFailure: PropTypes.func
}

export default UploadFiles