/* SECURE_ELEMENT_SESSION */
export const GET_SECURE_ELEMENT_SESSION = "GET_SECURE_ELEMENT_SESSION"
export const GET_SECURE_ELEMENT_SESSION_SUCCESS = "GET_SECURE_ELEMENT_SESSION_SUCCESS"
export const GET_SECURE_ELEMENT_SESSION_FAIL = "GET_SECURE_ELEMENT_SESSION_FAIL"
export const INIT_SECURE_ELEMENT_SESSION = "INIT_SECURE_ELEMENT_SESSION"
export const INIT_SECURE_ELEMENT_SESSION_SUCCESS = "INIT_SECURE_ELEMENT_SESSION_SUCCESS"
export const INIT_SECURE_ELEMENT_SESSION_FAIL = "INIT_SECURE_ELEMENT_SESSION_FAIL"
export const EXCHANGE_SECURE_ELEMENT_SESSION = "EXCHANGE_SECURE_ELEMENT_SESSION"
export const EXCHANGE_SECURE_ELEMENT_SESSION_SUCCESS = "EXCHANGE_SECURE_ELEMENT_SESSION_SUCCESS"
export const EXCHANGE_SECURE_ELEMENT_SESSION_FAIL = "EXCHANGE_SECURE_ELEMENT_SESSION_FAIL"
export const VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION = "VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION"
export const VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION_SUCCESS = "VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION_SUCCESS"
export const VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION_FAIL = "VERIFY_EXCHANGE_SECURE_ELEMENT_SESSION_FAIL"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const RESET = "RESET"
