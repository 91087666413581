import { map } from "lodash"
import {
    Col, Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap"

const CardTrnPagination = (page, totalPages, handlePageClick) => {
    return (
        <Col lg="12">
            <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                <PaginationItem disabled={page === 1}>
                    <PaginationLink
                        previous
                        href="#"
                        onClick={() => handlePageClick(page - 1)}
                    />
                </PaginationItem>
                {map(Array(totalPages), (item, i) => (
                    <PaginationItem active={i + 1 === page} key={i}>
                        <PaginationLink
                            onClick={() => handlePageClick(i + 1)}
                            href="#"
                        >
                            {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={page === totalPages}>
                    <PaginationLink
                        next
                        href="#"
                        onClick={() => handlePageClick(page + 1)}
                    />
                </PaginationItem>
            </Pagination>
        </Col>
    )
}
export default CardTrnPagination