import {
  GET_HSM_INFO,
  GET_HSM_INFO_FAIL,
  GET_HSM_INFO_SUCCESS
} from "./actionTypes"

export const getHsmInfo = () => ({
  type: GET_HSM_INFO,
})

export const getHsmInfoSuccess = hsmInfo => ({
  type: GET_HSM_INFO_SUCCESS,
  payload: hsmInfo,
})

export const getHsmInfoFail = error => ({
  type: GET_HSM_INFO_FAIL,
  payload: error,
})
