import { toDatetimeString } from "../../../helpers/app_helper"

const ReportColumns = (trnTypeMode) => {
  let isRejected = trnTypeMode === "Rejected"
  return [
    {
      text: "Name",
      dataField: "name",
      headerStyle: (colum, colIndex) => {
        return { width: '200px'};
      },
    },
    {
      text: "Version",
      dataField: "version",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { width: '140px', textAlign: 'center' };
      },
    },
    {
      text: "Description",
      dataField: "description",
      hidden: isRejected,
    },
    {
      text: "Rejected Reason",
      dataField: "rejectedReason",
      hidden: !isRejected,
    },
    {
      text: "Datetime",
      dataField: "datetime",
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { width: '160px', textAlign: 'center' };
      },
      formatter: (cellContent, row) => (
        <>
          <span className="ml-2 mr-1">
            {toDatetimeString(new Date(row.datetime))}
          </span>
        </>
      ),      
    },
  ]
}
export default ReportColumns
