import {
  CLEAR_STATE,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  RESET_PWD,
  RESET_PWD_SUCCESS,
  RESET_PWD_FAIL,
  CHANGE_PASSWORD_BY_ADMIN,
  CHANGE_PASSWORD_BY_ADMIN_SUCCESS,
  CHANGE_PASSWORD_BY_ADMIN_FAIL,
} from "./actionTypes"


export const clearState = () => ({
  type: CLEAR_STATE,
})

export const getUsers = (page, size, sortField, sortOrder, search) => ({
  type: GET_USERS,
  payload: { page, size, sortField, sortOrder, search }
})

export const getUsersSuccess = contentPage => ({
  type: GET_USERS_SUCCESS,
  payload: { contentPage },
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const addUser = (user) => ({
  type: ADD_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const editUser = (user) => ({
  type: EDIT_USER,
  payload: user,
})

export const editUserSuccess = (user) => ({
  type: EDIT_USER_SUCCESS,
  payload: user,
})

export const editUserFail = (error) => ({
  type: EDIT_USER_FAIL,
  payload: error,
})

export const deleteUser = (id) => ({
  type: DELETE_USER,
  payload: id,
})

export const deleteUserSuccess = (id) => ({
  type: DELETE_USER_SUCCESS,
  payload: id,
})

export const deleteUserFail = (error) => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

export const resetPwd = (id) => ({
  type: RESET_PWD,
  payload: id,
})

export const resetPwdSuccess = (id) => ({
  type: RESET_PWD_SUCCESS,
  payload: id,
})

export const resetPwdFail = (error) => ({
  type: RESET_PWD_FAIL,
  payload: error,
})

export const changePasswordByAdmin = (password) => ({
  type: CHANGE_PASSWORD_BY_ADMIN,
  payload: password,

})

export const changePasswordByAdminFail = (error) => ({
  type: CHANGE_PASSWORD_BY_ADMIN_FAIL,
  payload: error,

})

export const changePasswordByAdminSuccess = (payload) => ({
  type: CHANGE_PASSWORD_BY_ADMIN_SUCCESS,
})


