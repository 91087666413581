import {
  GET_SECURE_BUILD_SESSION,
  GET_SECURE_BUILD_SESSION_FAIL,
  GET_SECURE_BUILD_SESSION_SUCCESS,
  INIT_SECURE_BUILD_SESSION,
  INIT_SECURE_BUILD_SESSION_FAIL,
  INIT_SECURE_BUILD_SESSION_SUCCESS,
  EXCHANGE_SECURE_BUILD_SESSION,
  EXCHANGE_SECURE_BUILD_SESSION_FAIL,
  EXCHANGE_SECURE_BUILD_SESSION_SUCCESS,
  CLEAR_ERROR,
  RESET
} from "./actionTypes"

export const clearError = () => ({
  type: CLEAR_ERROR,
})

export const reset = () => ({
  type: RESET
})

export const getSecureBuildSession = () => ({
  type: GET_SECURE_BUILD_SESSION,
})

export const getSecureBuildSessionSuccess = session => ({
  type: GET_SECURE_BUILD_SESSION_SUCCESS,
  payload: session,
})

export const getSecureBuildSessionFail = error => ({
  type: GET_SECURE_BUILD_SESSION_FAIL,
  payload: error,
})

export const initSecureBuildSession = (session) => ({
  type: INIT_SECURE_BUILD_SESSION,
  payload: session,
})

export const initSecureBuildSessionSuccess = session => ({
  type: INIT_SECURE_BUILD_SESSION_SUCCESS,
  payload: session,
})

export const initSecureBuildSessionFail = error => ({
  type: INIT_SECURE_BUILD_SESSION_FAIL,
  payload: error,
})

export const exchangeSecureBuildSession = (session) => ({
  type: EXCHANGE_SECURE_BUILD_SESSION,
  payload: session,
})

export const exchangeSecureBuildSessionSuccess = session => ({
  type: EXCHANGE_SECURE_BUILD_SESSION_SUCCESS,
  payload: session,
})

export const exchangeSecureBuildSessionFail = error => ({
  type: EXCHANGE_SECURE_BUILD_SESSION_FAIL,
  payload: error,
})
