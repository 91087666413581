import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_SECURE_BUILD_SESSION,
  INIT_SECURE_BUILD_SESSION,
  EXCHANGE_SECURE_BUILD_SESSION
} from "./actionTypes"

import {
  getSecureBuildSessionSuccess,
  getSecureBuildSessionFail,
  initSecureBuildSessionSuccess,
  initSecureBuildSessionFail,
  exchangeSecureBuildSessionSuccess,
  exchangeSecureBuildSessionFail
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getSecureBuildSession,
  initSecureBuildSession,
  exchangeSecureBuildSession,
} from "helpers/backend_helper"

function* fetchSecureBuildSession({ sessionId}) {
  try {
    const response = yield call(getSecureBuildSession, sessionId)
    yield put(getSecureBuildSessionSuccess(response))
  } catch (error) {
    yield put(getSecureBuildSessionFail(error))
  }
}

function* onInitSecureBuildSession({ payload }) {
  try {
    const response = yield call(initSecureBuildSession, payload)
    yield put(initSecureBuildSessionSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(initSecureBuildSessionFail(error.response.data))
    } else {
      yield put(initSecureBuildSessionFail(error.message))
    }
  }
}

function* onExchangeSecureBuildSession({ payload }) {
  try {
    const response = yield call(exchangeSecureBuildSession, payload)
    yield put(exchangeSecureBuildSessionSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(exchangeSecureBuildSessionFail(error.response.data))
    } else {
      yield put(exchangeSecureBuildSessionFail(error.message))
    }
  }
}

function* secureBuildSessionSaga() {
  yield takeEvery(GET_SECURE_BUILD_SESSION, fetchSecureBuildSession)
  yield takeEvery(INIT_SECURE_BUILD_SESSION, onInitSecureBuildSession)
  yield takeEvery(EXCHANGE_SECURE_BUILD_SESSION, onExchangeSecureBuildSession)
}

export default secureBuildSessionSaga