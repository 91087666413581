import { AvField, AvForm } from "availity-reactstrap-validation";
import { map, orderBy } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import {
  Col, Modal,
  ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { toDatetimeString } from "../../helpers/app_helper"

class SecureElementDetails extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
      device: props.device,
      onClosed: props.onClosed
    }
  }


  render() {
    const { device, isOpen, onClosed } = this.state

    return (
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => {
          this.setState({ isOpen: false });
          onClosed();
        }}
        centered={true}
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            this.setState({ isOpen: false });
            onClosed();
          }}
        >
          { "SECURE ELEMENT DETAILS" }
        </ModalHeader>
        <AvForm
          className="form-horizontal"
        >
          <ModalBody>
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <AvField
                      name="serial"
                      id="serial"
                      label="Device Serial"
                      value={device.serial}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <AvField
                      name="certSerial"
                      id="certSerial"
                      label="Certificate Serial"
                      value={device.certSerial}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <AvField
                      name="ip"
                      id="ip"
                      label="Last IP"
                      value={device.ip}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <AvField
                      name="appVersion"
                      id="appVersion"
                      label="App Version"
                      value={device.appVersion}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <AvField
                      name="cplc"
                      id="cplc"
                      label="CPLC"
                      value={device.cplc}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <AvField
                      name="identifyData"
                      id="identifyData"
                      label="Card Identify Data"
                      value={device.identifyData}
                      className="form-control"
                      type="text"
                      readOnly={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <h4 className="card-title mb-4">Keys</h4>
              </Col>
            </Row>
            {map(orderBy(device.keys, ['id'], ['asc']), (k, ki) => (
              <Row>
                <Col lg={12}>
                    <p>
                      <em>
                        <strong>{k.name}</strong> ({toDatetimeString(new Date(k.provisioningDate))} by {k.enroller.firstName + " " + k.enroller.lastName}) {" - "}
                      </em>
                      <span className={k.status === "COMPLETED" ? "badge badge-soft-success" : (k.status === "FAILED" ? "badge badge-soft-danger" : "badge badge-soft-primary")}>
                        {k.status}
                      </span>
                      {k.status === "FAILED" && (
                        <em>
                          {" (" + k.lastError + ")"}
                        </em>
                      )}
                    </p>
                </Col>
              </Row>
            ))}
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col lg={12}>
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.setState({ isOpen: false });
                      onClosed();
                    }}
                  >
                    Close
                  </button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </AvForm>

      </Modal>
    );
  }
}

SecureElementDetails.propTypes = {
  device: PropTypes.object,
  onClosed: PropTypes.func,
}

export default SecureElementDetails