import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_TRN_REPORT,
  GET_DEVICE_REPORT,
  GET_DASHBOARD_REPORT
} from "./actionTypes"
import {
  getTrnReportFail,
  getTrnReportSuccess,
  getDeviceReportFail,
  getDeviceReportSuccess,
  getDashboardReportFail,
  getDashboardReportSuccess,
} from "./actions"

import { isEmpty } from "lodash"

import { apiGetTrnReport, apiGetDeviceReport, apiGetDashboardReport } from "helpers/backend_helper"

function* onGetTrnReport({ payload }) {
  try {
    const response = yield call(apiGetTrnReport, {
      params: {
        page: payload.page, size: payload.size,
        filterStartDate: payload.search.filterStartDate,
        filterEndDate: payload.search.filterEndDate,
        filterTrnName: payload.search.filterTrnName,
        filterTrnVersion: payload.search.filterTrnVersion,
        filterTrnType: payload.search.filterTrnType,
        filterReportType: payload.search.filterReportType,
      }
    })
    if (payload.search.filterReportType === "Export") {
      yield put(getTrnReportSuccess({
        dataToDownload: response,
        search: payload.search
      }))  
    } else {
      yield put(getTrnReportSuccess({
        content: response.content,
        page: response.number,
        size: response.size,
        totalElements: response.totalElements,
        search: payload.search
      }))  
    }
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getTrnReportFail(error.response.data.message))
    } else {
      yield put(getTrnReportFail(error.message))
    }
  }
}

function* onGetDeviceReport({ payload }) {
  try {
    const response = yield call(apiGetDeviceReport, {
      params: {
        page: payload.page, size: payload.size,
        filterStartDate: payload.search.filterStartDate,
        filterEndDate: payload.search.filterEndDate,
        filterStatus: payload.search.filterStatus,
        filterReportType: payload.search.filterReportType,
      }
    })
    if (payload.search.filterReportType === "Export") {
      yield put(getDeviceReportSuccess({
        dataToDownload: response,
        search: payload.search
      }))  
    } else {
      yield put(getDeviceReportSuccess({
        content: response.content,
        page: response.number,
        size: response.size,
        totalElements: response.totalElements,
        search: payload.search
      }))  
    }
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getDeviceReportFail(error.response.data.message))
    } else {
      yield put(getDeviceReportFail(error.message))
    }
  }
}

function* onGetDashboardReport({ payload }) {
  try {
    const response = yield call(apiGetDashboardReport)
      yield put(getDashboardReportSuccess({
        content: response,
      })) 
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getDashboardReportFail(error.response.data.message))
    } else {
      yield put(getDashboardReportFail(error.message))
    }
  }
}

function* reportsSaga() {
  yield takeEvery(GET_TRN_REPORT, onGetTrnReport)
  yield takeEvery(GET_DEVICE_REPORT, onGetDeviceReport)
  yield takeEvery(GET_DASHBOARD_REPORT, onGetDashboardReport)
}

export default reportsSaga
