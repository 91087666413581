import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { toDatetimeString } from "../../../helpers/app_helper"

const RejectedTrnColumns = (onView, onSort) => [
  {
    text: "Name",
    dataField: "trnName",
  },
  {
    text: "Version",
    dataField: "trnVersion",
  },
  {
    text: "Date Time",
    dataField: "createdDate",
    headerAlign: "center",
    align: "center",
    sort: true,
    onSort: onSort,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{toDatetimeString(new Date(row.createdDate))}</p>
      </>
    ),
  },
  {
    text: "",
    isDummyField: true,
    dataField: "menu",
    align: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '50px', };
    },
    formatter: (cellContent, row) => (
      <>
        <Link to="#" className="text-primary">
          <i className="mdi mdi-eye font-size-18 mr-2" id="edittooltip" onClick={() => onView(row)} />
          <UncontrolledTooltip placement="top" target="edittooltip">
            View Details
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
]

export default RejectedTrnColumns
