import { apiGetExceptions } from "helpers/backend_helper"
import { isEmpty } from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  getExceptionsFail, getExceptionsSuccess
} from "./actions"
import {
  GET_EXCEPTIONS
} from "./actionTypes"


function* onGetExceptions({ payload }) {
  try {
    const response = yield call(apiGetExceptions, {
      params: {
        page: payload.page,
        size: payload.size,
        sortField: payload.sortField,
        sortOrder: payload.sortOrder,
      }
    })
    yield put(getExceptionsSuccess({
      content: response.content,
      page: response.number,
      size: response.size,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
      sortField: payload.sortField,
      sortOrder: payload.sortOrder,
    }))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getExceptionsFail(error.response.data.message))
    } else {
      yield put(getExceptionsFail(error.message))
    }
  }
}

function* exceptionsSaga() {
  yield takeEvery(GET_EXCEPTIONS, onGetExceptions)
}

export default exceptionsSaga
