import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

import {
  postJwtForgetPwd,
} from "../../../helpers/backend_helper"

function* forgetUser({ payload: { email, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      yield call(postJwtForgetPwd, { email: email })
      yield put(
        userForgetPasswordSuccess(
          "Reset link are sended to your mailbox, check there first"
        ))
      //history.push("/login")
    }
  } catch (error) {
    if (error.response.status === 404) {
      yield put(userForgetPasswordError("Invalid email"))
    } else if (error.response.status === 500) {
      yield put(userForgetPasswordError("Internal Server Error"))
    } else {
      yield put(userForgetPasswordError(error))
    }
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

export default forgetPasswordSaga
