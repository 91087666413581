import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_KEYSETS,
  GET_KEYSET_DETAILS,
  CREATE_KEYSET,
  UPDATE_KEYSET,
  MAKE_DEFAULT_KEYSET,
  DELETE_KEYSET
} from "./actionTypes"

import {
  getKeysetsSuccess,
  getKeysetsFail,
  getKeysetDetailsSuccess,
  getKeysetDetailsFail,
  createKeysetSuccess,
  createKeysetFail,
  updateKeysetSuccess,
  updateKeysetFail,
  makeDefaultKeysetSuccess,
  makeDefaultKeysetFail,
  deleteKeysetSuccess,
  deleteKeysetFail
} from "./actions"

import { isEmpty } from "lodash"

//Include Both Helper File with needed methods
import {
  getKeysets,
  getKeysetDetails,
  createKeyset,
  updateKeyset,
  makeDefaultKeyset,
  deleteKeyset
} from "helpers/backend_helper"

function* fetchKeysets({ payload }) {
  try {
    const response = yield call(getKeysets, {
      params: {
        page: payload.page,
        size: payload.size,
        sortField: payload.sortField,
        sortOrder: payload.sortOrder,
        type: payload.type,
        search: payload.search
      }
    })
    yield put(getKeysetsSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(getKeysetsFail(error.response.data.message))
    } else {
      yield put(getKeysetsFail(error.message))
    }
  }
}

function* fetchKeysetDetail({ payload }) {
  try {
    const response = yield call(getKeysetDetails, payload)
    yield put(getKeysetDetailsSuccess(response))
  } catch (error) {
    yield put(getKeysetDetailsFail(error))
  }
}

function* onCreateKeyset({ payload }) {
  try {
    const response = yield call(createKeyset, payload)
    yield put(createKeysetSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(createKeysetFail(error.response.data))
    } else {
      yield put(createKeysetFail(error.message))
    }
  }
}

function* onUpdateKeyset({ payload }) {
  try {
    const response = yield call(updateKeyset, payload)
    yield put(updateKeysetSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(updateKeysetFail(error.response.data))
    } else {
      yield put(updateKeysetFail(error.message))
    }
  }
}

function* onDeleteKeyset({ payload }) {
  try {
    yield call(deleteKeyset, payload)
    yield put(deleteKeysetSuccess())
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(deleteKeysetFail(error.response.data))
    } else {
      yield put(deleteKeysetFail(error.message))
    }
  }
}

function* onMakeDefaultKeyset({ payload }) {
  try {
    const response = yield call(makeDefaultKeyset, payload)
    yield put(makeDefaultKeysetSuccess(response))
  } catch (error) {
    if (!isEmpty(error.response) && !isEmpty(error.response.data)) {
      yield put(makeDefaultKeysetFail(error.response.data))
    } else {
      yield put(makeDefaultKeysetFail(error.message))
    }
  }
}

function* keysetsSaga() {
  yield takeEvery(GET_KEYSETS, fetchKeysets)
  yield takeEvery(GET_KEYSET_DETAILS, fetchKeysetDetail)
  yield takeEvery(CREATE_KEYSET, onCreateKeyset)
  yield takeEvery(UPDATE_KEYSET, onUpdateKeyset)
  yield takeEvery(MAKE_DEFAULT_KEYSET, onMakeDefaultKeyset)
  yield takeEvery(DELETE_KEYSET, onDeleteKeyset)
}

export default keysetsSaga