/* COMMON */
export const CLEAR_STATE = "CLEAR_STATE"
export const CLEAR_ACTION_RESULT = "CLEAR_ACTION_RESULT"
export const RESULT_OK = "RESULT_OK"
export const RESULT_ERROR = "RESULT_ERROR"

/* TRNS */
export const GET_TRNS = "GET_TRNS"
export const GET_TRNS_SUCCESS = "GET_TRNS_SUCCESS"
export const GET_TRNS_FAIL = "GET_TRNS_FAIL"
export const GET_TRNS_VERSIONS = "GET_TRNS_VERSIONS"
export const GET_TRNS_VERSIONS_SUCCESS = "GET_TRNS_VERSIONS_SUCCESS"
export const GET_TRNS_VERSIONS_FAIL = "GET_TRNS_VERSIONS_FAIL"
export const GENERATE_TRNS = "GENERATE_TRNS"

export const GET_TRN_AUDITS = "GET_TRN_AUDITS"
export const GET_TRN_AUDITS_SUCCESS = "GET_TRN_AUDITS_SUCCESS"
export const GET_TRN_AUDITS_FAIL = "GET_TRN_AUDITS_FAIL"

export const SEARCHING_TRNS = "SEARCHING_TRNS"
export const SEARCHING_TRNS_SUCCESS = "SEARCHING_TRNS_SUCCESS"
export const SEARCHING_TRNS_FAIL = "SEARCHING_TRNS_FAIL"

export const GET_REJECTED_TRNS = "GET_REJECTED_TRNS"
export const GET_REJECTED_TRNS_SUCCESS = "GET_REJECTED_TRNS_SUCCESS"
export const GET_REJECTED_TRNS_FAIL = "GET_REJECTED_TRNS_FAIL"

export const SELECT_FOR_PROVISIONING = "SELECT_FOR_PROVISIONING"
export const SELECT_FOR_PROVISIONING_SUCCESS = "SELECT_FOR_PROVISIONING_SUCCESS"
export const SELECT_FOR_PROVISIONING_FAIL = "SELECT_FOR_PROVISIONING_FAIL"