import { map, orderBy } from "lodash"
import { Button } from "reactstrap"

const SecureElementColumns = (onViewDetails, onViewAudits, onSort) => [
  {
    dataField: "serial",
    text: "Serial",
    sort: true,
    onSort: onSort,
    headerStyle: (colum, colIndex) => {
      return { width: '120px', textAlign: 'center' };
    },
  },
  {
    dataField: "keys",
    text: "State",
    align: "center",
    headerAlign: "center",
    formatter: (cellContent, row) => (
      <>
        {map(orderBy(row.distinctKeys, ['id'], ['asc']), (k, ki) => (
          <span key={ki} className={"ml-2 badge " + (k.status === "COMPLETED" ? "badge-soft-success" : (k.status === "FAILED" ? "badge-soft-danger" : "badge-soft-primary"))}>
            {k.name}
          </span>
        ))}
      </>
    ),
  },
  {
    dataField: "completed",
    text: "Status",
    align: "center",
    headerAlign: "center",
    headerStyle: (colum, colIndex) => {
      return { width: '100px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <span>
          {row.completed === true ? "COMPLETE" : "IN-COMPLETE"}
        </span>
      </>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    headerStyle: (colum, colIndex) => {
      return { width: '150px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <div className="button-items">
          <Button
            color="primary"
            className="btn btn-primary btn-sm waves-effect"
            onClick={() => onViewDetails(row)}
          >
            Summary
          </Button>
          <Button
            color="primary"
            className="btn btn-primary btn-sm waves-effect"
            onClick={() => onViewAudits(row)}
          >
            Audit
          </Button>
        </div>
      </>
    ),
  },
]

export default SecureElementColumns
