import React, { Fragment, Component } from "react"

import {
  Container
} from "reactstrap"


import Breadcrumbs from "../../components/Common/Breadcrumb"
import DiscoverySAMView from "../../components/Discovery/DiscoverySAMView"


class DiscoverySAM extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Device Discovery" breadcrumbItem="Secure Element" />

            <DiscoverySAMView showVerificationButton={true}/>

          </Container>
        </div>
      </Fragment>
    );
  }
}

export default DiscoverySAM