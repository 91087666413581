import {
  CLEAR_STATE,
  RESULT_OK,
  RESULT_ERROR,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  RESET_PWD,
  RESET_PWD_SUCCESS,
  RESET_PWD_FAIL,
  CHANGE_PASSWORD_BY_ADMIN,
  CHANGE_PASSWORD_BY_ADMIN_SUCCESS,
  CHANGE_PASSWORD_BY_ADMIN_FAIL,
} from "./actionTypes"


const INIT_STATE = {
  contentPage: {},
  actionResult: {},
}

const usersReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CLEAR_STATE:
      {
        return {
          ...INIT_STATE
        }
      }
    case GET_USERS_SUCCESS:
      {
        return {
          ...state,
          contentPage: action.payload.contentPage,
          actionResult: { action: GET_USERS, result: RESULT_OK, error: '' },
        }
      }
    case GET_USERS_FAIL: {
      return {
        ...state,
        contentPage: {},
        actionResult: { action: GET_USERS, result: RESULT_ERROR, error: action.payload },
      }
    }
    case ADD_USER_SUCCESS:
      {
        return {
          ...state,
          actionResult: { action: ADD_USER, result: RESULT_OK, error: '' },
        }
      }
    case ADD_USER_FAIL:
      return {
        ...state,
        actionResult: { action: ADD_USER, result: RESULT_ERROR, error: action.payload },
      }
    case EDIT_USER_FAIL:
      return {
        ...state,
        actionResult: { action: EDIT_USER, result: RESULT_ERROR, error: action.payload },
      }
    case DELETE_USER_FAIL:
      return {
        ...state,
        actionResult: { action: DELETE_USER, result: RESULT_ERROR, error: action.payload },
      }
    case RESET_PWD_FAIL:
      return {
        ...state,
        actionResult: { action: RESET_PWD, result: RESULT_ERROR, error: action.payload },
      }
    case EDIT_USER_SUCCESS:
      {
        const { payload } = action
        const { content } = state.contentPage
        const index = content.findIndex(item => item.id === payload.id);
        if (index !== -1) {
          const newContent = [
            ...content.slice(0, index),
            payload,
            ...content.slice(index + 1),
          ];
          return {
            ...state,
            contentPage: {
              ...state.contentPage,
              content: newContent
            },
            actionResult: { action: EDIT_USER, result: RESULT_OK, error: '' },
          };
        }
        return {
          ...state,
          actionResult: { action: EDIT_USER, result: RESULT_OK, error: '' },
        }
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        actionResult: { action: DELETE_USER, result: RESULT_OK, error: '' },
      }
    case RESET_PWD_SUCCESS:
      return {
        ...state,
        actionResult: { action: RESET_PWD, result: RESULT_OK, error: '' },
      }
    case CHANGE_PASSWORD_BY_ADMIN_SUCCESS:
      return {
        ...state,
        actionResult: { action: CHANGE_PASSWORD_BY_ADMIN, result: RESULT_OK, error: '' },
      }
    case CHANGE_PASSWORD_BY_ADMIN_FAIL:
      return {
        ...state,
        actionResult: { action: CHANGE_PASSWORD_BY_ADMIN, result: RESULT_ERROR, error: action.payload },
      }
    default:
      return state
  }
}

export default usersReducer
