import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { isEmpty } from "lodash"

import Breadcrumbs from "components/Common/Breadcrumb"
import UploadFiles from "components/Common/UploadFiles"
import { getTrnUpload } from "store/trnUpload/actions"
import * as url from "helpers/url_helper"
import { toDatetimeString } from "../../helpers/app_helper"

class TrnUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trnUpload: undefined,
    }
  }

  componentDidMount() {
    this.props.onGetTrnUpload()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { trnUpload } = this.props
    if (trnUpload !== prevProps.trnUpload) {
      this.setState({ trnUpload })
    }
  }

  handleUploadSuccess = (data) => {
    console.log("Upload file successful");
    console.log(data);
    this.props.onGetTrnUpload()
  }

  render() {
    const { trnUpload } = this.state;
    const trnUploadActive = trnUpload !== undefined && trnUpload.id !== undefined && trnUpload.active === true;

    return (
      <Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="TRN Management" breadcrumbItem="Upload TRN" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <UploadFiles
                        uploadURL={url.TRN_UPLOAD}
                        onPrepareFormData={file => {
                          this.setState({ trnUpload: undefined })
                          let formData = new FormData();
                          formData.append("file", file);
                          return formData;
                        }}
                        onUploadSuccess={data => this.handleUploadSuccess(data)}/>

                    {!trnUploadActive && (
                        <div>
                          <h5 className="mt-4">No TRN</h5>
                        </div>
                    )}
                    {trnUploadActive && (
                        <div className="table-responsive">
                          <h5 className="mt-4">Last Uploaded TRN Detail</h5>
                          <Table className="table table-nowrap table-centered">
                            <tbody>
                                <tr>
                                  <td>Firmware Name</td>
                                  <td><strong>{trnUpload.fileName}</strong></td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td><strong>{toDatetimeString(new Date(trnUpload.modifiedDate))}</strong></td>
                                </tr>
                                <tr>
                                  <td>Status</td>
                                  <td><strong>{trnUpload.status}</strong></td>
                                </tr>
                                <tr>
                                  <td>TRN Name</td>
                                  <td><strong>{trnUpload.trnName}</strong></td>
                                </tr>
                                <tr>
                                  <td>TRN Version</td>
                                  <td><strong>{trnUpload.trnVersion}</strong></td>
                                </tr>
                                <tr>
                                  <td>Digest</td>
                                  <td><strong>{trnUpload.hash}</strong></td>
                                </tr>
                                <tr>
                                  <td>Download</td>
                                  <td>
                                    {trnUpload.downloadURL && !isEmpty(trnUpload.downloadURL) && (
                                      <strong><a href={trnUpload.downloadURL} target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: 'blue', textDecoration: 'none'}}>Click here</a>
                                      </strong>
                                    )}
                                  </td>
                                </tr>
                            </tbody>
                          </Table>
                        </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

TrnUpload.propTypes = {
  trnUpload: PropTypes.object,
  onGetTrnUpload: PropTypes.func,
}

const mapStateToProps = ({ trnUploadReducer }) => ({
  trnUpload: trnUploadReducer.trnUpload
})

const mapDispatchToProps = dispatch => ({
  onGetTrnUpload: () => dispatch(getTrnUpload())
})

export default connect(mapStateToProps, mapDispatchToProps)(TrnUpload)
