import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  userProfile: {},
  authUser: '',
}

const login = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        authUser: action.payload, //Important!!!
      }
      break
    case LOGOUT_USER:
      state = {
        ...state,
        userProfile: null,
        authUser: ''
      }
      break
    case LOGOUT_USER_SUCCESS:
      state = null
      // state = { ...state }
      break
    case GET_USER_PROFILE_SUCCESS:
      state = {
        ...state,
        userProfile: action.payload
      }
      break
    case API_ERROR:
      var err = action.payload
      state = { ...state, error: err, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
