import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes"

import {
  RESULT_OK,
  RESULT_ERROR
} from "../../baseActionTypes"

const initialState = {
  actionResult: {result: ''}
}

const forgetPassword = (state = initialState, action = {}) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        actionResult: { action: FORGET_PASSWORD, result: RESULT_OK, error: '' }
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = { 
        ...state,
        actionResult: { action: FORGET_PASSWORD, result: RESULT_ERROR, error: action.payload } 
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
