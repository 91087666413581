import { toDatetimeString } from "../../helpers/app_helper"

const SecureBuildAuditColumns = (onSort) => [
  {
    dataField: "action",
    text: "Action",
    sort: false,
    headerStyle: (colum, colIndex) => {
      return { width: '130px', textAlign: 'center' };
    },
  },
  {
    dataField: "description",
    text: "Description",
    sort: false
  },
  {
    dataField: "status",
    text: "Status",
    sort: false,
    headerStyle: (colum, colIndex) => {
      return { width: '80px', textAlign: 'center' };
    },
  },
  {
    dataField: "createdDate",
    text: "Date/Time",
    sort: false,
    headerStyle: (colum, colIndex) => {
      return { width: '160px', textAlign: 'center' };
    },
    formatter: (cellContent, row) => (
      <>
        <span className="ml-2 mr-1">
          {toDatetimeString(new Date(row.createdDate))}
        </span>
      </>
    ),
  },
]

export default SecureBuildAuditColumns
