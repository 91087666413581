import {
  GET_TRN_AUDITS,
  GET_TRN_AUDITS_SUCCESS,
  GET_TRN_AUDITS_FAIL,
} from "./actionTypes"
import {
  CLEAR_ACTION_RESULT,
  RESULT_OK,
  RESULT_ERROR,
} from "../baseActionTypes"

const INIT_STATE = {
  contentPage: {},
  actionResult: {},
}

const trnAuditReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_TRN_AUDITS_SUCCESS:
      {
        return {
          ...state,
          contentPage: action.payload.contentPage,
          actionResult: { action: GET_TRN_AUDITS, result: RESULT_OK, error: '' },
        }
      }

    case GET_TRN_AUDITS_FAIL: {
      return {
        ...state,
        contentPage: {},
        actionResult: { action: GET_TRN_AUDITS, result: RESULT_ERROR, error: action.payload },
      }
    }

    case CLEAR_ACTION_RESULT:
      {
        return {
          ...state,
          actionResult: {},
        }
      }

    default:
      return state
  }
}

export default trnAuditReducer
