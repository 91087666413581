/* COMMON */
export const CLEAR_STATE = "CLEAR_STATE"
export const CLEAR_ACTION_RESULT = "CLEAR_ACTION_RESULT"
export const RESULT_OK = "RESULT_OK"
export const RESULT_ERROR = "RESULT_ERROR"

/* Exceptions */
export const GET_EXCEPTIONS = "GET_EXCEPTIONS"
export const GET_EXCEPTIONS_SUCCESS = "GET_EXCEPTIONS_SUCCESS"
export const GET_EXCEPTIONS_FAIL = "GET_EXCEPTIONS_FAIL"
