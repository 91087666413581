import { Fragment, Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Col, Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { getSecureElementAudits, clearActionResult } from "store/secureElementAudit/actions"
import SecureBuildAuditColumns from "../SecureBuild/SecureBuildAuditColumns"
import { GET_SECURE_ELEMENT_AUDITS } from "store/secureElementAudit/actionTypes"
import { RESULT_OK, RESULT_ERROR } from "store/baseActionTypes"

class SecureElementAuditListing extends Component {

  constructor(props) {
    super(props)
    this.state = {
      secureElementAuditPage: {
        currentPage: 0,
        totalPages: 0,
        pageSize: 10,
        totalItems: 0,
        content: []
      },
      sortField: '',
      sortOrder: '',
      search: '',
      actionResult: undefined,
      isOpen: true,
      onClosed: props.onClosed
    }
  }

  componentDidMount() {
    const { onGetSecureElementAudits, secureElement } = this.props
    onGetSecureElementAudits(secureElement.id, this.state.secureElementAuditPage.currentPage, this.state.secureElementAuditPage.pageSize, this.state.sortField, this.state.sortOrder, this.state.search)
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGetSecureElementAudits, secureElement } = this.props
    const { secureElementAuditPage, sortField, sortOrder, search } = this.state

    if (secureElementAuditPage.currentPage !== prevState.secureElementAuditPage.currentPage
        || secureElementAuditPage.pageSize !== prevState.secureElementAuditPage.pageSize
        || sortField !== prevState.sortField
        || sortOrder !== prevState.sortOrder) {
      onGetSecureElementAudits(secureElement.id, secureElementAuditPage.currentPage, secureElementAuditPage.pageSize, sortField, sortOrder, search);
      return;
    }

    if (this.props.secureElementAuditPage !== undefined && this.props.secureElementAuditPage.content !== undefined && this.props.secureElementAuditPage !== prevProps.secureElementAuditPage) {
      this.setState({
        secureElementAuditPage: this.props.secureElementAuditPage
      })
      return;
    }
    if (this.props.actionResult !== prevProps.actionResult) {
      this.setState({
        actionResult: this.props.actionResult
      })
      return;
    }
  }

  onPageChange = (page, sizePerPage) => {
    let secureElementAuditPage = Object.assign({}, this.state.secureElementAuditPage);
    secureElementAuditPage.currentPage = page - 1;
    this.setState({
      secureElementAuditPage
    })
  }

  onSizePerPageChange = (page, sizePerPage) => {
    let secureElementAuditPage = Object.assign({}, this.state.secureElementAuditPage);
    secureElementAuditPage.currentPage = 0;
    secureElementAuditPage.pageSize = sizePerPage;
    this.setState({
      secureElementAuditPage
    })
  }

  onSort = (field, order) => {
    let secureElementAuditPage = Object.assign({}, this.state.secureElementAuditPage);
    secureElementAuditPage.currentPage = 0;
    this.setState({
      sortField: field,
      sortOrder: order,
      secureElementAuditPage
    })
  }

  handleTableChange = (type, { page, searchText }) => {
    if (type === 'search') {
      let secureElementAuditPage = Object.assign({}, this.state.secureElementAuditPage);
      secureElementAuditPage.currentPage = 0;
      this.setState({
        search: searchText,
        secureElementAuditPage
      })
    }
  }

  renderActionResultDlg = () => {
    let { actionResult } = this.state
    if (actionResult !== undefined) {
      let error = false
      let title = ''
      let message = ''
      if (actionResult.action === GET_SECURE_ELEMENT_AUDITS) {
          if (actionResult.result === RESULT_OK) {
            return null
          } else if (actionResult.result === RESULT_ERROR) {
            error = true
            title = "Failed to fetch data"
            message = actionResult.error
          }
      }
      return <SweetAlert
        error={error}
        success={!error}
        title={title}
        onConfirm={() => { this.props.onClearActionResult(); }}
      >
        {message}
      </SweetAlert>
    }
    return null;
  }

  render() {
    const { isOpen, onClosed } = this.state
    const { secureElementAuditPage } = this.state
    const pageOptions = {
      page: secureElementAuditPage.currentPage + 1, //SpringData: Page number values start with 0.
      sizePerPage: secureElementAuditPage.pageSize,
      totalSize: secureElementAuditPage.totalItems,
      showTotal: true,
      custom: true,
      onPageChange: this.onPageChange,
      onSizePerPageChange: this.onSizePerPageChange,
    }

    return (
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => {
          this.setState({ isOpen: false });
          onClosed();
        }}
        centered={true}
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            this.setState({ isOpen: false });
            onClosed();
          }}
        >
          Secure Element Audits
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <div>
                {this.renderActionResultDlg()}
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={secureElementAuditPage.content || []}
                      columns={SecureBuildAuditColumns(this.onSort)}
                      bootstrap4
                    >
                      {toolkitProps => (
                        <Fragment>
                          <Row>
                            <Col xl="12">
                              <div>
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  remote
                                  hover={true}
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table table-centered table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={this.handleTableChange}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
            <Row>
              <Col lg={12}>
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.setState({ isOpen: false });
                      onClosed();
                    }}
                  >
                    Close
                  </button>
                </div>
              </Col>
            </Row>
        </ModalFooter>

      </Modal>
    );
  }
}

SecureElementAuditListing.propTypes = {
  // private
  actionResult: PropTypes.object,
  secureElementAuditPage: PropTypes.object,
  onGetSecureElementAudits: PropTypes.func,
  onClearActionResult: PropTypes.func,
  // public
  onClosed: PropTypes.func,
  secureElement: PropTypes.object,
}

const mapStateToProps = ({ secureElementAuditReducer }) => ({
  secureElementAuditPage: secureElementAuditReducer.secureElementAuditPage,
  actionResult: secureElementAuditReducer.actionResult,
})
const mapDispatchToProps = dispatch => ({
  onGetSecureElementAudits: (secureElementId, page, size, sortField, sortOrder, search) => dispatch(getSecureElementAudits(secureElementId, page, size, sortField, sortOrder, search)),
  onClearActionResult: () => dispatch(clearActionResult()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecureElementAuditListing)