import {
  GET_SECURE_ELEMENTS,
  GET_SECURE_ELEMENTS_FAIL,
  GET_SECURE_ELEMENTS_SUCCESS,
  GET_SECURE_ELEMENT_DETAILS,
  GET_SECURE_ELEMENT_DETAILS_FAIL,
  GET_SECURE_ELEMENT_DETAILS_SUCCESS,
  CREATE_SECURE_ELEMENT,
  CREATE_SECURE_ELEMENT_FAIL,
  CREATE_SECURE_ELEMENT_SUCCESS,
  UPDATE_SECURE_ELEMENT,
  UPDATE_SECURE_ELEMENT_FAIL,
  UPDATE_SECURE_ELEMENT_SUCCESS,
  CLEAR_ERROR,
  CLEAR_ACTION_RESULT,
  RESET
} from "./actionTypes"

export const clearActionResult = () => ({
  type: CLEAR_ACTION_RESULT,
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})

export const reset = () => ({
  type: RESET
})

export const getSecureElements = (page, size, sortField, sortOrder, search) => ({
  type: GET_SECURE_ELEMENTS,
  payload: { page, size, sortField, sortOrder, search }
})

export const getSecureElementsSuccess = (devicePage) => ({
  type: GET_SECURE_ELEMENTS_SUCCESS,
  payload: devicePage
})

export const getSecureElementsFail = error => ({
  type: GET_SECURE_ELEMENTS_FAIL,
  payload: error,
})

export const getSecureElementDetails = () => ({
  type: GET_SECURE_ELEMENT_DETAILS,
})

export const getSecureElementDetailsSuccess = device => ({
  type: GET_SECURE_ELEMENT_DETAILS_SUCCESS,
  payload: device,
})

export const getSecureElementDetailsFail = error => ({
  type: GET_SECURE_ELEMENT_DETAILS_FAIL,
  payload: error,
})

export const createSecureElement = (device) => ({
  type: CREATE_SECURE_ELEMENT,
  payload: device,
})

export const createSecureElementSuccess = device => ({
  type: CREATE_SECURE_ELEMENT_SUCCESS,
  payload: device,
})

export const createSecureElementFail = error => ({
  type: CREATE_SECURE_ELEMENT_FAIL,
  payload: error,
})

export const updateSecureElement = (device) => ({
  type: UPDATE_SECURE_ELEMENT,
  payload: device,
})

export const updateSecureElementSuccess = device => ({
  type: UPDATE_SECURE_ELEMENT_SUCCESS,
  payload: device,
})

export const updateSecureElementFail = error => ({
  type: UPDATE_SECURE_ELEMENT_FAIL,
  payload: error,
})
