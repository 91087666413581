// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
import { isEmpty } from "lodash";
import PropTypes from 'prop-types';
import { Component, Fragment } from "react";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
// import images
import logo from "../../assets/images/identivnew-logo.png";
import { appVersion, deployedEnvironment } from "../../helpers/app_helper";
// actions
import { apiError, loginUser } from "../../store/actions";

import { withCookies } from 'react-cookie';
import { obfuscatedValue } from "../../helpers/app_helper"

class Login extends Component {
  constructor(props) {
      super(props)
      this.state = {
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)

    const { cookies } = this.props;
    if (cookies.get("token")) {
      this.props.loginUser(null, this.props.history, cookies)
    }
  
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    const { cookies } = this.props;
    this.props.loginUser(values, this.props.history, cookies)
  }

  componentDidMount() {
    this.props.apiError("")
  }

  render() {
    let deployedEnv = deployedEnvironment();
    let showDeployedEnv = deployedEnv !== undefined && deployedEnv !== '';
    return (
      <Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="identiv-bg-default">
                    <Row>
                      <Col className="col-12">
                        <div className="identiv-text-default p-4">
                          <h5 className="identiv-text-default">SmartBridge Portal - Sign In </h5>
                          <p></p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="44"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {!isEmpty(this.props.error) ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="username"
                            label="Username"
                            value=''
                            className="form-control"
                            placeholder="Username"
                            type="text"
                            errorMessage="Username is invalid"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="password"
                            label="Password"
                            autoComplete="on"
                            value=''
                            type="password"
                            errorMessage="Password is invalid"
                            required
                            placeholder="Password"
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            type="checkbox"
                            name="rememberme"
                            label="Remember me"
                          />
                        </div>

                        <div className="mt-3">
                          <button
                            className="btn btn-primary identiv-btn-basic btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock mr-1" /> Forgot your password?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} Identiv, Inc. | All Rights Reserved | {appVersion()}
                    {showDeployedEnv ? " | " : ""}
                    {showDeployedEnv && (
                      <span className="badge badge-warning">{deployedEnv}</span>
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}
export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(withCookies(Login))
)
