import {
  CLEAR_STATE,
  RESULT_OK,
  RESULT_ERROR,
  GET_REJECTED_TRNS,
  GET_REJECTED_TRNS_SUCCESS,
  GET_REJECTED_TRNS_FAIL
} from "./actionTypes"


const INIT_STATE = {
  contentPage: {},
  actionResult: {},
}

const rejectedTrnReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CLEAR_STATE:
      {
        return {
          ...INIT_STATE
        }
      }

    case GET_REJECTED_TRNS_SUCCESS:
      {
        return {
          ...state,
          contentPage: action.payload.contentPage,
          actionResult: { action: GET_REJECTED_TRNS, result: RESULT_OK, error: '' },
        }
      }

    case GET_REJECTED_TRNS_FAIL: {
      return {
        ...state,
        contentPage: {},
        actionResult: { action: GET_REJECTED_TRNS, result: RESULT_ERROR, error: action.payload },
      }
    }

    default:
      return state
  }
}

export default rejectedTrnReducer
